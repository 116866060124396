import { Edit } from "@mui/icons-material";
import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { useState, MouseEvent } from "react";

import { TResponse } from "../../../../../../models";
import { TUpdateActivity } from "../../../../../../services/activities/update-activity";
import { TSectionActivity } from "../../types";

export interface IOptionStatus {
    name: string;
    id_e: string;
    color: string;
    secondary_color: string;
    categoryId: string;
}

interface IStatusDropdownProps {
    activity?: TSectionActivity;
    handleStatusModal: (status?: IOptionStatus) => void;
    statusOptions: IOptionStatus[];
    updateActivity: TResponse<unknown, TUpdateActivity>;
}

const StatusDropdown: React.FC<IStatusDropdownProps> = ({
    activity,
    handleStatusModal,
    statusOptions,
    updateActivity,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (
        event:
            | MouseEvent<HTMLButtonElement>
            | MouseEvent<HTMLLIElement, globalThis.MouseEvent>
    ) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleCreateStatus = (
        event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>
    ) => {
        event.stopPropagation();
        handleStatusModal();
    };

    const handleEditStatus = (
        e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
        option: IOptionStatus
    ) => {
        e.stopPropagation();
        handleStatusModal(option);
    };

    const handleSelectStatus = (
        event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>,
        option: IOptionStatus
    ) => {
        event.stopPropagation();
        updateActivity.onRefresh({
            activityIds: [activity?.id_e],
            statusId: option.id_e,
        });
        handleClose(event);
    };

    const color = activity?.ActivityStatus
        ? activity?.ActivityStatus?.color
        : "inherit";
    const secondaryColor = activity?.ActivityStatus
        ? activity?.ActivityStatus.secondary_color
        : "inherit";
    const name = activity?.ActivityStatus
        ? activity?.ActivityStatus.name
        : "A Fazer";
    return (
        <div>
            <IconButton onClick={handleClick}>
                <Box
                    sx={{
                        display: "flex",
                        padding: "0.25rem",
                        justifyContent: "center",
                        gap: "0.5rem",
                        borderRadius: "0.25rem",
                        border: `1px solid ${color}`,
                        backgroundColor: `${secondaryColor}`,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Noto Sans",
                            fontSize: "0.75rem",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                            letterSpacing: "0.0075rem",
                            color: `${color}`,
                        }}
                    >
                        {name ?? "A Fazer"}
                    </Typography>
                </Box>
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {statusOptions.map((option) => (
                    <MenuItem
                        key={option.id_e}
                        onClick={(e) => handleSelectStatus(e, option)}
                    >
                        <Typography variant="body1">{option.name}</Typography>{" "}
                        {option.name !== "A Fazer" && (
                            <IconButton
                                onClick={(e) => handleEditStatus(e, option)}
                            >
                                <Edit />
                            </IconButton>
                        )}
                    </MenuItem>
                ))}

                <MenuItem onClick={handleCreateStatus}>
                    <Typography variant="body1">
                        <strong>+ Adicionar Status</strong>
                    </Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default StatusDropdown;
