import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useState } from "react";
import { Control, Controller, Path } from "react-hook-form";

import { useFetch } from "../../../../hooks";
import { createBudgetType } from "../../../../services/budget/type";
import {
    TCreateBudgetTypeResponse,
    TCreateBudgetType,
} from "../../../../services/budget/type/create-budget-type";
import type {
    TReadBudgetTypesResponse,
    TReadBudgetTypes,
} from "../../../../services/budget/type/read-budget-types";

type TBudgetTypeSelect<T extends Record<string, any>> = {
    budgetTypes: TReadBudgetTypesResponse[];
    refetch: (params?: TReadBudgetTypes) => Promise<void>;
    control: Control<T>;
    name: Path<T>;
    error?: boolean;
};

export const TypeSelect = <T extends Record<string, any>>({
    budgetTypes,
    refetch,
    control,
    error,
    name,
}: TBudgetTypeSelect<T>) => {
    const [inputValue, setInputValue] = useState<string>("");
    const createType = useFetch<
        TCreateBudgetType,
        TCreateBudgetTypeResponse,
        any
    >({
        fn: createBudgetType,
        params: { name: inputValue },
        start: false,
        subSequentReqs: [
            {
                fn: () => refetch(),
            },
        ],
    });

    const handleCreateType = async () => {
        if (!inputValue.trim()) return;
        await createType.onRefresh({ name: inputValue });
        refetch();
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    freeSolo
                    options={
                        inputValue &&
                        !budgetTypes.some((opt) => opt.name === inputValue)
                            ? [
                                  ...budgetTypes,
                                  { id_e: "new", name: inputValue },
                              ]
                            : budgetTypes
                    }
                    getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                    }
                    isOptionEqualToValue={(option, value) =>
                        typeof option === "string"
                            ? option === value
                            : option.id_e === value.id_e
                    }
                    value={
                        budgetTypes.find((opt) => opt.id_e === field.value) ||
                        null
                    }
                    onChange={(_, newValue) => {
                        if (typeof newValue === "string") {
                            setInputValue(newValue);
                        } else if (newValue?.id_e === "new") {
                            handleCreateType();
                        } else {
                            field.onChange(newValue?.id_e);
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={(_, newInputValue) =>
                        setInputValue(newInputValue)
                    }
                    loading={createType.loading}
                    renderOption={(props, option) => (
                        <li
                            {...props}
                            key={
                                typeof option === "string"
                                    ? option
                                    : option.id_e
                            }
                        >
                            {option.name}
                            {option.id_e === "new" && createType.loading && (
                                <CircularProgress size={16} sx={{ ml: 1 }} />
                            )}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tipo de Orçamento*"
                            placeholder="Digite ou selecione um tipo"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: createType.loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null,
                            }}
                            error={!!error}
                        />
                    )}
                />
            )}
        />
    );
};
