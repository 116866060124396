import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCreateActivityStatus = {
    params: {
        name: string;
        color: string;
        secondary_color: string;
        statusCategoryId: string;
    };
};

export default async ({ params }: TCreateActivityStatus) => {
    const headers = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/status`,
        method: "post",
        body: params,
        headers,
    });

    const { body, statusCode } = httpResponse;

    return handleStatusCases<TSection>({
        body,
        statusCode,
    });
};
