import { Close } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { theme } from "../../../../../../assets";
import { useReadBudgetFiles } from "../../../../../../hooks/react-query/use-read-budget-files";
import { deleteBudgetFile } from "../../../../../../services/budget";
import FilesForm from "./files-form";

function FilesTab() {
    const { budgetId } = useParams();
    const id = budgetId || "";

    const { data, refetch } = useReadBudgetFiles({ budgetId: id });

    const files = data?.data;
    const handleRemove = async (attachmentId: string) => {
        await deleteBudgetFile({ budgetId: id, attachmentId }).then(() =>
            toast.success("Anexo excluido com sucesso!")
        );
        await refetch();
    };
    return (
        <Stack>
            <Stack
                sx={{
                    justifyContent: "center",
                    gap: "0.5rem",
                    p: "1.5rem",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
            >
                <Typography variant="h5">Anexos</Typography>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: "rgba(62, 55, 78, 0.60)",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "18px",
                        letterSpacing: "0.07px",
                    }}
                >
                    Faça upload de arquivos importantes para documentação.
                </Typography>
            </Stack>
            <Stack
                sx={{
                    p: "1.5rem",
                    gap: "1.5rem",
                }}
            >
                <Typography variant="h5">Arquivos de Orçamento</Typography>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: "rgba(62, 55, 78, 0.60)",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "18px",
                        letterSpacing: "0.07px",
                    }}
                >
                    faça upload de arquivos importantes para documentação{" "}
                </Typography>
                <FilesForm budgetId={id} refetch={refetch} />
                <Stack
                    sx={{
                        gap: "1.5rem",
                        maxHeight: "28.5rem",
                        pt: "1rem",
                        overflowY: "auto",
                    }}
                >
                    {/* Files list */}
                    <Stack>
                        {files?.map((file) => (
                            <Box
                                key={file.id_e}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "auto",
                                    gap: "0.5rem",
                                    color: theme.primary.main,
                                }}
                            >
                                <Typography
                                    component="a"
                                    href={file.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        color: theme.primary.main,
                                        textDecoration: "underline",
                                    }}
                                >
                                    {file.name}
                                </Typography>
                                <IconButton
                                    onClick={() => handleRemove(file.id_e)}
                                    size="small"
                                >
                                    <Close
                                        fontSize="small"
                                        sx={{
                                            color: theme.primary.main,
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default FilesTab;
