import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Icons, theme } from "../../../../../assets";
import CustomTextField from "../../../../../components/molecules/custom-textfield/custom-textfield";
import { TProject, TResponse } from "../../../../../models";
import { TCreateSection } from "../../../../../services/activities/create-section";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import * as s from "./styled-components";

const validationSchema = yup.object().shape({
    sectionName: yup
        .string()
        .required("Nome da sessão é obrigatório")
        .max(150, "O nome deve ter no máximo 150 caracteres"),
});

type TAddSection = {
    isToCreateSection: boolean;
    setIsToCreateSection: React.Dispatch<React.SetStateAction<boolean>>;
    project: TProject;
    createSection: TResponse<unknown, TCreateSection>;
};

export const AddSection = ({
    isToCreateSection,
    setIsToCreateSection,
    project,
    createSection,
}: TAddSection) => {
    const { control, reset, handleSubmit, setFocus } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const submit = handleSubmit(({ sectionName }) => {
        createSection.onRefresh({
            name: sectionName,
            projectId: project.id_e,
        });
    });

    useEffect(() => {
        if (createSection.response) {
            setIsToCreateSection(false);
            reset();
        }
    }, [createSection.response]);

    useEffect(() => {
        if (isToCreateSection) {
            setFocus("sectionName");
        }
    }, [isToCreateSection]);

    return isToCreateSection ? (
        <form
            onSubmit={submit}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    setIsToCreateSection(false);
                    reset();
                }
                if (e.key === "Enter") {
                    document.getElementById("save")?.click();
                }
            }}
        >
            <Stack
                sx={{
                    maxWidth: "20rem",
                }}
            >
                <CustomTextField
                    control={control}
                    maxCharacters={150}
                    placeholder="Escreva o nome da sessão"
                    fullWidth
                    name="sectionName"
                />
                <s.ButtonsContainer>
                    <Button
                        variant="text"
                        onClick={() => {
                            setIsToCreateSection(false);
                            reset();
                        }}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        variant="contained"
                        id="save"
                        type="submit"
                        loading={createSection.loading}
                    >
                        ADICIONAR
                    </Button>
                </s.ButtonsContainer>
            </Stack>
        </form>
    ) : (
        <s.AddSectionButton
            onClick={() => {
                setIsToCreateSection(true);
                Mixpanel.track("Adicionar sessão");
            }}
        >
            <Icons.Plus color={theme.gray800} width="24" height="24" />
            Adicionar sessão
        </s.AddSectionButton>
    );
};
