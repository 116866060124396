import { useQuery } from "@tanstack/react-query";

import readBudgetsStatus, {
    TReadBudgetsStatusResponse,
} from "../../services/budget/read-budgets-status";

type ReadBudgetsParams = {
    page: number;
    length: number;
    filter?: string;
};

export function useReadBudgetsStatus({
    length,
    page,
    filter,
}: ReadBudgetsParams) {
    const filters = {
        length,
        page,
        filter,
    };

    return useQuery<
        TReadBudgetsStatusResponse,
        unknown,
        TReadBudgetsStatusResponse
    >({
        enabled: true,
        queryKey: ["readCosts", filters],
        queryFn: () => readBudgetsStatus(filters),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
