import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TCreateBudgetComment = {
    budgetId: string;
    commentId: string;
    params: {
        comment: string;
    };
};

export default async ({
    budgetId,
    commentId,
    params,
}: TCreateBudgetComment) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/comment/${commentId}`,
        method: "patch",
        body: params,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({ body, statusCode });
};
