import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCountBudgets = {
    filter?: string;
    budgetTypeIds?: string[];
    clientIds?: string[];
    statusIds?: string[];
    isArchived?: boolean;
};

export type TCountBudgetsResponse = {
    count: number;
};

export default async (params: TCountBudgets = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/count`,
        method: "get",
        params,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TCountBudgetsResponse[]>({
        body,
        statusCode,
    });
};
