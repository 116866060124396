import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Popover,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { TOption } from "../../../../../../models";
import { TFilterParams } from "../../tab-activities";

export type filterPopover = {
    statusIds?: string[];
    usersId?: string[];
    profilesIds?: string[];
};

type FilterPopoverProps = {
    anchorEl: HTMLElement | null;
    filterPopoverData: filterPopover | undefined;
    handleClose: () => void;
    handleSetPopoverData: (data: TFilterParams) => void;
    optionsStatus: { id_e: string; name: string; color: string }[];
    collaboratorsOptions: TOption[];
    profilesOptions: TOption[];
};

const titleCheckBoxLabelStyle = {
    color: "rgba(62, 55, 78, 0.60)",
    fontFamily: "Noto Sans",
    fontSize: "0.875rem",
    fontWeight: 600,
    textTransform: "uppercase",
};

function FilterPopover({
    anchorEl,
    filterPopoverData,
    handleClose,
    handleSetPopoverData,
    optionsStatus,
    collaboratorsOptions,
    profilesOptions,
}: FilterPopoverProps) {
    const { control, handleSubmit, watch } = useForm<TFilterParams>({
        defaultValues: {
            statusIds: filterPopoverData?.statusIds ?? [],
            activityUserIds: filterPopoverData?.usersId ?? [],
            profileIds: filterPopoverData?.profilesIds ?? [],
        },
    });

    const [filterText, setFilterText] = useState("");

    const selectedStatus = watch("statusIds");

    const filteredOptionsStatus = optionsStatus.filter(
        (status) =>
            status.name.toLowerCase().startsWith(filterText.toLowerCase()) ||
            selectedStatus?.includes(status.id_e)
    );

    const filteredOptionsUsers = collaboratorsOptions.filter(
        (status) =>
            status.label.toLowerCase().startsWith(filterText.toLowerCase()) ||
            selectedStatus?.includes(status.value)
    );

    const filteredeOptionsProfiles = profilesOptions.filter(
        (profile) =>
            profile.label.toLowerCase().startsWith(filterText.toLowerCase()) ||
            selectedStatus?.includes(profile.value)
    );

    const onSubmit = (data: TFilterParams) => {
        console.log("data", data);
        handleSetPopoverData({
            statusIds: data.statusIds,
            activityUserIds: data.activityUserIds,
            profileIds: data.profileIds,
        });
        handleClose();
    };

    const open = Boolean(anchorEl);

    return (
        <Popover
            id={"filter-popover"}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ padding: "1.5rem" }}
            >
                <Stack sx={{ gap: "1.5rem" }}>
                    {/* Input para filtrar opções */}
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Digite para filtrar..."
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                    />

                    <Box
                        sx={{
                            display: "flex",
                            gap: "2.125rem",
                            maxHeight: "30.125rem",
                            overflowY: "auto",
                        }}
                    >
                        {filteredOptionsStatus.length > 0 && (
                            <Stack sx={{ width: "100%", maxWidth: "16.25rem" }}>
                                <Typography
                                    variant="button"
                                    sx={titleCheckBoxLabelStyle}
                                >
                                    Status
                                </Typography>
                                {filteredOptionsStatus.map((status) => (
                                    <Controller
                                        key={status.id_e}
                                        name="statusIds"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                sx={{
                                                    color:
                                                        status.color !==
                                                        "#ffffff"
                                                            ? status.color
                                                            : "inherit",
                                                }}
                                                control={
                                                    <Checkbox
                                                        checked={field.value?.includes(
                                                            status.id_e
                                                        )}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target
                                                                    .checked &&
                                                                    field.value
                                                                    ? [
                                                                          ...field.value,
                                                                          status.id_e,
                                                                      ]
                                                                    : field.value?.filter(
                                                                          (
                                                                              item
                                                                          ) =>
                                                                              item !==
                                                                              status.id_e
                                                                      )
                                                            )
                                                        }
                                                    />
                                                }
                                                label={status.name}
                                            />
                                        )}
                                    />
                                ))}
                            </Stack>
                        )}
                        {filteredOptionsUsers.length > 0 && (
                            <Stack sx={{ width: "100%", maxWidth: "16.25rem" }}>
                                <Typography
                                    variant="button"
                                    sx={titleCheckBoxLabelStyle}
                                >
                                    Colaboradores
                                </Typography>
                                {filteredOptionsUsers.map((collaborators) => (
                                    <Controller
                                        key={collaborators.value}
                                        name="activityUserIds"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={field.value?.includes(
                                                            collaborators.value
                                                        )}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target
                                                                    .checked &&
                                                                    field.value
                                                                    ? [
                                                                          ...field.value,
                                                                          collaborators.value,
                                                                      ]
                                                                    : field.value?.filter(
                                                                          (
                                                                              item: string
                                                                          ) =>
                                                                              item !==
                                                                              collaborators.value
                                                                      )
                                                            )
                                                        }
                                                    />
                                                }
                                                label={collaborators.label}
                                            />
                                        )}
                                    />
                                ))}
                            </Stack>
                        )}
                        <Stack sx={{ width: "100%", maxWidth: "16.25rem" }}>
                            <Typography
                                variant="button"
                                sx={titleCheckBoxLabelStyle}
                            >
                                Status de Projeto
                            </Typography>
                            {filteredeOptionsProfiles.map((profile) => (
                                <Controller
                                    key={profile.value}
                                    name="profileIds"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={field.value?.includes(
                                                        profile.value
                                                    )}
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked &&
                                                                field.value
                                                                ? [
                                                                      ...field.value,
                                                                      profile.value,
                                                                  ]
                                                                : field.value?.filter(
                                                                      (item) =>
                                                                          item !==
                                                                          profile.value
                                                                  )
                                                        )
                                                    }
                                                />
                                            }
                                            label={profile.label}
                                        />
                                    )}
                                />
                            ))}
                        </Stack>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1rem",
                        }}
                    >
                        <Button onClick={handleClose} color="secondary">
                            Voltar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Filtrar
                        </Button>
                    </Box>
                </Stack>
            </form>
        </Popover>
    );
}

export default FilterPopover;
