import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormControlLabel,
    FormGroup,
    Stack,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { createProjectFromBudget } from "../../../../../../services/budget";
import { TCreateProjectFromBudgetBody } from "../../../../../../services/budget/create-project-from-budget";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budgets";

interface ICreateProjectBudget {
    transportAnnotations: boolean;
    filesToTransport: string[];
}

export const CreateProjectDialog = ({
    dataBudgetById,
    handleClose,
    open,
    optionsFiles,
}: {
    dataBudgetById: TReadBudgetsResponse;
    handleClose: () => void;
    open: boolean;
    optionsFiles: { label: string; value: string }[];
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { control, watch, setValue, handleSubmit } =
        useForm<ICreateProjectBudget>({
            defaultValues: {
                transportAnnotations: false,
                filesToTransport: [],
            },
        });
    const navigate = useNavigate();
    const submit = async (data: ICreateProjectBudget) => {
        setIsLoading(true);
        const body: TCreateProjectFromBudgetBody = {
            filesToTransport: data.filesToTransport,
            transportAnnotations: data.transportAnnotations,
            color: dataBudgetById.color,
            endDate: new Date().toISOString(),
            name: dataBudgetById.name,
            startDate: new Date().toISOString(),
        };
        await createProjectFromBudget({
            budgetId: dataBudgetById.id_e,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            body,
        })
            .then((data) => {
                navigate(
                    `/criar-projetos/${dataBudgetById.id_e}/${data.data.id_e}`
                );
                toast.success("Projeto criado com sucesso!");
            })
            .catch((error) => {
                if (error?.body?.message) {
                    toast.error(error?.body?.message);
                }
            });

        setIsLoading(false);
        handleClose();
    };

    const filesSelected = watch("filesToTransport");
    const isAllFilesSlected = optionsFiles.length === filesSelected.length;
    const handleSelecionarTodos = () => {
        setValue(
            "filesToTransport",
            isAllFilesSlected ? [] : optionsFiles.map((option) => option.value)
        );
    };
    return (
        <Dialog
            open={open}
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "33.75rem",
                    overflowY: "unset",
                },
            }}
        >
            <Box
                sx={{
                    p: "1rem",
                    width: "100%",
                }}
            >
                <form onSubmit={handleSubmit(submit)}>
                    <Stack
                        sx={{
                            gap: "0.75rem",
                            px: "1.5rem ",
                            marginTop: "1vh",
                        }}
                    >
                        <Typography variant="h6">
                            Manter Dados de Orçamento
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "rgba(62, 55, 78, 0.87)",
                                fontFamily: "Noto Sans",
                                fontSize: "0.875rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "1.125rem",
                                letterSpacing: "0.07px",
                            }}
                        >
                            Selecione os dados que deseja que a equipe de
                            projeto tenha acesso.
                        </Typography>
                    </Stack>

                    <DialogContent>
                        <Stack
                            sx={{
                                gap: "1rem",
                                width: "100%",
                            }}
                        >
                            <Stack>
                                <Typography
                                    variant="caption"
                                    sx={{ color: "rgba(62, 55, 78, 0.87)" }}
                                >
                                    Anotações
                                </Typography>
                                <Controller
                                    name="transportAnnotations"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="Manter Anotações"
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack
                                sx={{
                                    position: "relative",
                                    pr: "0.25rem",
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    sx={{ color: "rgba(62, 55, 78, 0.87)" }}
                                >
                                    Arquivos
                                </Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAllFilesSlected}
                                                onChange={handleSelecionarTodos}
                                            />
                                        }
                                        label={
                                            <strong>Selecionar Todos</strong>
                                        }
                                    />
                                    <Stack
                                        sx={{
                                            gap: "0.5rem",
                                            maxHeight: "25rem",
                                            overflowY: "auto",
                                        }}
                                    >
                                        {optionsFiles.map((arquivo) => (
                                            <Controller
                                                key={arquivo.value}
                                                name="filesToTransport"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={field.value.includes(
                                                                    arquivo.value
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const {
                                                                        checked,
                                                                    } =
                                                                        e.target;
                                                                    setValue(
                                                                        "filesToTransport",
                                                                        checked
                                                                            ? [
                                                                                  ...field.value,
                                                                                  arquivo.value,
                                                                              ]
                                                                            : field.value.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      arquivo.value
                                                                              )
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        label={arquivo.label}
                                                    />
                                                )}
                                            />
                                        ))}
                                    </Stack>
                                </FormGroup>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1rem",
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={handleClose}
                            disabled={isLoading}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={isLoading}
                        >
                            INiciar projeto
                        </Button>
                    </Box>
                </form>
            </Box>
        </Dialog>
    );
};
