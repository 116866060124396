import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCreateSolicitationEstimate = {
    budgetId: string;
    params: {
        profileIds: string[];
    };
};

export default async ({ budgetId, params }: TCreateSolicitationEstimate) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/solicit-time-estimation`,
        method: "post",
        body: params,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({ body, statusCode });
};
