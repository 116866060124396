import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { theme } from "../../../../../assets";
import CustomSelect from "../../../../../components/molecules/custom-select/custom-select";
import CustomTextField from "../../../../../components/molecules/custom-textfield/custom-textfield";
import DayPicker from "../../../../../components/molecules/day-picker/day-picker";
import { TResponse } from "../../../../../models";
import { TUpdateActivity } from "../../../../../services/activities/update-activity";
import { TPriority, TSectionActivity } from "../types";
import { Collaborators } from "./add-collaborators-button/add-collaborators-button";

export const formatPredictedHours = (value: string): string => {
    const cleanValue = value.replace(/[^0-9:]/g, "");
    let [hours, minutes] = cleanValue.split(":");
    if (!minutes && hours?.length > 2) {
        minutes = hours.slice(-2);
        hours = hours.slice(0, -2);
    }
    if (minutes && Number(minutes) > 59) minutes = "59";
    return `${hours || "0"}:${minutes || "00"}`;
};

const validationSchema = yup.object().shape({
    activityName: yup
        .string()
        .required("Nome da atividade é obrigatório")
        .min(3, "O nome deve ter no mínimo 3 caracteres")
        .max(150, "O nome deve ter no máximo 150 caracteres"),

    deadline: yup
        .date()
        .typeError("Data inválida")
        .required("O prazo é obrigatório"),

    priority: yup
        .string()
        // .shape({
        //     value: yup.string().required("A prioridade é obrigatória"),
        // })
        // .nullable()
        .required("A prioridade é obrigatória"),

    predictedHours: yup
        .string()
        .matches(/^\d{1,2}:\d{2}$/, "Formato inválido (HH:MM)")
        .required("Horas previstas são obrigatórias"),
});

type TPriorityOption = {
    value: TPriority;
    label: string;
    color: string;
};

type TFields = {
    activityName: string;
    deadline: Date | undefined;
    priority: string;
    predictedHours: string;
};

type TEditActivity = {
    activity: TSectionActivity;
    updateActivity: TResponse<unknown, TUpdateActivity>;
    sectionId?: string;
    setUpdateActivityStates: React.Dispatch<React.SetStateAction<string[]>>;
    updateActivityStates: string[];
    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    activityToAddCollaborators?: TSectionActivity;
};

export const EditActivity = ({
    activity,
    sectionId,
    updateActivity,
    updateActivityStates,
    setUpdateActivityStates,
    setActivityToAddCollaborators,
    activityToAddCollaborators,
}: TEditActivity) => {
    const priorityOptions: TPriorityOption[] = [
        {
            value: "low",
            label: "Baixa",
            color: theme.green1,
        },
        {
            value: "medium",
            label: "Média",
            color: theme.orange,
        },
        {
            value: "high",
            label: "Alta",
            color: "#D32F2F",
        },
    ];

    const [users, setUsers] = useState<TSectionActivity["Users"]>(
        activity.Users || []
    );

    const predictedMinutesToHours = (predictedMinutes?: number): string => {
        if (!predictedMinutes) return "00:00";
        const hours = Math.floor(predictedMinutes / 60);
        const minutes = predictedMinutes % 60;
        return `${hours < 10 ? `0${hours}` : hours}:${
            minutes < 10 ? `0${minutes}` : minutes
        }`;
    };

    const { handleSubmit, control, reset, setFocus } = useForm<TFields>({
        defaultValues: {
            activityName: activity.name,
            deadline: activity.deadline
                ? new Date(activity.deadline)
                : undefined,
            priority: activity.priority,
            predictedHours: predictedMinutesToHours(activity.expectedMinutes),
        },
        resolver: yupResolver(validationSchema),
    });

    const predictedHoursToMinutes = (
        predictedHours: string
    ): number | undefined => {
        if (!predictedHours) return undefined;
        const [hours, minutes] = predictedHours.split(":");
        return Number(hours) * 60 + Number(minutes);
    };

    const mapData = (data: TFields): TUpdateActivity => {
        return {
            name: data?.activityName,
            priority: data?.priority as TPriority,
            deadline: data?.deadline,
            expectedMinutes: predictedHoursToMinutes(data?.predictedHours),
            users: activity.Users?.map((user) => user.User.id_e) || [],
        };
    };

    const clearData = () => {
        reset();
    };

    const exit = () => {
        setUpdateActivityStates(
            updateActivityStates.filter((state) => state !== activity.id_e)
        );
    };

    // const formatOptionLabel = (
    //     option: string | TOption | null | undefined,
    //     input: { inputValue: string }
    // ) => {
    //     if (typeof option !== "object") return <p>{input.inputValue}</p>;
    //     const optionValue = priorityOptions.find(
    //         (priority) => priority.value === (option as TPriorityOption).value
    //     );
    //     return (
    //         <s.PriorityOption color={optionValue?.color}>
    //             <span>•</span>
    //             {optionValue?.label}
    //         </s.PriorityOption>
    //     );
    // };

    // const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const inputValue = e.target.value;
    //     const formattedValue = inputValue
    //         .replace(/[^0-9:]/g, "")
    //         .replace(/(\d{3})(\d{2})/, "$1:$2")
    //         .replace(/(:\d{2})\d+?$/, "$1");
    //     return formattedValue;
    // };

    const onSubmit = handleSubmit((data) => {
        updateActivity.onRefresh({
            ...mapData(data),
            users: users.map((user) => user.User.id_e),
            sectionId,
            activityIds: [activity.id_e],
        });
    });

    useEffect(() => {
        updateActivity.response = undefined;
    }, []);

    useEffect(() => {
        if (updateActivity.response) {
            clearData();
            exit();
        }
    }, [updateActivity.response]);

    useEffect(() => {
        setFocus("activityName");
    }, [setFocus]);

    useEffect(() => {
        if (activityToAddCollaborators?.Users) {
            setUsers(activityToAddCollaborators?.Users);
        }
    }, [activityToAddCollaborators]);

    return (
        <TableRow>
            <TableCell colSpan={9}>
                <form
                    onSubmit={onSubmit}
                    onKeyDown={(e) => {
                        if (e.key === "Escape") {
                            clearData();
                            exit();
                        }
                        if (e.key === "Enter") {
                            document.getElementById("save")?.click();
                        }
                    }}
                >
                    <Stack
                        sx={{
                            p: "1.5rem",
                            gap: "1.5rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "1.5rem",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    width: "100%",
                                    maxWidth: "40%",
                                }}
                            >
                                <CustomTextField
                                    control={control}
                                    fullWidth
                                    ref={(el) => el?.focus()}
                                    name="activityName"
                                    maxCharacters={150}
                                    placeholder="Escreva o nome da atividade"
                                />
                            </Box>
                            <Collaborators
                                key={activity.id_e + users.length}
                                openModal={() => {
                                    setActivityToAddCollaborators({
                                        ...activity,
                                        activitySection_id: sectionId || "",
                                        Users: users,
                                    });
                                }}
                                activity={{
                                    ...activity,
                                    Users: users,
                                }}
                                isEditing={true}
                            />

                            <Controller
                                name="deadline"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DayPicker
                                        value={value}
                                        placeholder="Data"
                                        heigth="3.65rem"
                                        backgroundColor="transparent"
                                        borderColor={theme.gray200}
                                        onSelect={(date) => {
                                            onChange(date);
                                        }}
                                        toYear={new Date().getFullYear() + 1}
                                    />
                                )}
                            />

                            <CustomSelect
                                label="Prioridade"
                                control={control}
                                fullWidth
                                name="priority"
                                options={priorityOptions}
                            />

                            <CustomTextField
                                control={control}
                                name="predictedHours"
                                label="Horas Previstas"
                                fullWidth
                                formatValue={formatPredictedHours}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    clearData();
                                    exit();
                                }}
                            >
                                CANCELAR
                            </Button>
                            <Button
                                id="save"
                                variant="contained"
                                type="submit"
                                loading={updateActivity.loading}
                            >
                                SALVAR
                            </Button>
                        </Box>
                    </Stack>
                </form>
            </TableCell>
        </TableRow>
    );
};
