/* eslint-disable no-restricted-syntax */
import {
    DragDropContext,
    DragUpdate,
    DraggableLocation,
    DropResult,
} from "@hello-pangea/dnd";
import { FilterListOutlined } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { theme } from "../../../../assets";
import { useFetch } from "../../../../hooks";
import { useReadProjectCategoryStatus } from "../../../../hooks/react-query/project/use-read-category-status";
import { TProject } from "../../../../models";
import { activities } from "../../../../services";
import { AddActivity } from "./components/add-activity";
import { AddSection } from "./components/add-section";
import { Archive } from "./components/archive/archive";
import FilterPopover, {
    filterPopover,
} from "./components/filter-popover/filter-popover";
import { HourProgress } from "./components/hour-progress/hour-progress";
import { Sections } from "./components/sections/sections";
import { AddCollaboratorsModal } from "./modals/add-collaborators/add-collaborators-modal";
import { DeleteConfirmationActivity } from "./modals/delete-confirmation-activity/delete-confirmation-activity";
import { DeleteConfirmationSection } from "./modals/delete-confirmation-section/delete-confirmation-section";
import * as s from "./styled-tab-activities";
import {
    TSectionActivity,
    TSection,
    TReadActivities,
    TOptions,
    TActivityIdsRef,
} from "./types";

function calculateProgress(section: TSection): {
    minutesDone: number;
    expectedMinutes: number;
} {
    let totalMinutesDone = 0;
    let totalExpectedMinutes = 0;

    const activities: TSectionActivity[] = Array.isArray(section.Activities)
        ? section.Activities
        : Object.values(section.Activities ?? {});

    for (const activity of activities) {
        totalMinutesDone += activity.hourProgress?.minutesDone ?? 0;
        totalExpectedMinutes += activity.expectedMinutes ?? 0;
    }

    if (section.Sections && section.Sections.length > 0) {
        for (const subSection of section.Sections) {
            const subProgress = calculateProgress(subSection);
            totalMinutesDone += subProgress.minutesDone;
            totalExpectedMinutes += subProgress.expectedMinutes;
        }
    }

    const percentageOfHoursDone =
        totalExpectedMinutes > 0
            ? (totalMinutesDone / totalExpectedMinutes) * 100
            : 0;

    section.hourProgress = {
        minutesDone: totalMinutesDone,
        percentageOfHoursDone,
    };
    section.expectedMinutes = totalExpectedMinutes;

    return {
        minutesDone: totalMinutesDone,
        expectedMinutes: totalExpectedMinutes,
    };
}

type TTabActivities = {
    loading: boolean;
    project: TProject;
};

export type TFilterParams = {
    activityUserIds?: string[];
    profileIds?: string[];
    statusIds?: string[];
};

export const TabActivities = ({ project }: TTabActivities) => {
    const [isToCreateSection, setIsToCreateSection] = useState<boolean>(false);
    const [activityToAddCollaborators, setActivityToAddCollaborators] =
        useState<TSectionActivity | undefined>(undefined);
    const [deleteConfirmationActivityId, setDeleteConfirmationActivityId] =
        useState<string>("");
    const [deleteConfirmationSectionId, setDeleteConfirmationSectionId] =
        useState<string>("");
    const [updateActivityStates, setUpdateActivityStates] = useState<string[]>(
        []
    );
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [activitiesOptions, setActivitiesOptions] = useState<TOptions>([]);
    const [openedSectionsIds, setOpenedSectionsIds] = useState<string[]>([]);
    const [archiveOpen, setArchiveOpen] = useState<boolean>(false);
    const [draggingDestination, setDraggingDestination] =
        useState<DragUpdate["destination"]>();

    const [filterParams, setFilterParams] = useState<TFilterParams>({
        activityUserIds: undefined,
        profileIds: undefined,
        statusIds: undefined,
    });
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const getSections = useQuery({
        queryKey: ["getSections", [filterParams]],
        queryFn: () =>
            activities.getSections({
                projectId: project.id_e,
                ...filterParams,
            }),
    });

    const { data, refetch } = useReadProjectCategoryStatus();

    const createSection = useFetch({
        fn: activities.createSection,
        start: false,
    });

    const createActivity = useFetch({
        fn: activities.createActivity,
        start: false,
    });

    const updateActivity = useFetch({
        fn: activities.updateActivity,
        start: false,
    });

    const updateSection = useFetch({
        fn: activities.updateSection,
        start: false,
    });

    const archiveActivity = useFetch({
        fn: activities.archiveActivity,
        start: false,
    });

    const unarchiveActivity = useFetch({
        fn: activities.unarchiveActivity,
        start: false,
    });

    const deleteActivity = useFetch({
        fn: activities.deleteActivity,
        start: false,
    });

    const deleteSection = useFetch({
        fn: activities.deleteSection,
        start: false,
    });

    const toggleActivityStatus = useFetch({
        fn: activities.toggleActivityStatus,
        start: false,
    });

    const archiveSection = useFetch({
        fn: activities.archiveSection,
        start: false,
    });

    const unarchiveSection = useFetch({
        fn: activities.unarchiveSection,
        start: false,
    });

    const duplicateActivity = useFetch({
        fn: activities.duplicateActivity,
        start: false,
    });

    const readActivities = useFetch({
        fn: activities.readActivities,
        start: false,
    });

    const sections: TSection[] = getSections.data?.sections ?? [];
    const archivedSection: TSection | undefined =
        getSections.data?.archive?.archiveSection;
    const archivedSections: TSection[] | undefined =
        getSections.data?.archive?.archivedSections;
    const activitiesWithoutSection: TSectionActivity[] =
        getSections.data?.activitiesWithoutSection ?? [];

    /*     const handleGameficationSocket = (data: GameficationChallenge) => {
        if (!data) return;
        if (data.finished_type !== "challenge") return;
        const { description } = data.challenge_completed;
        if (description === "Entrar nas atividades de um projeto") {
            const audio = new Audio(
                // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                require("../../../../assets/sounds/challenge-completed.mp3")
            );
            audio.play();
            toast.success(
                `Você acessou o mural de atividades de um projeto e ganhou ${data.challenge_completed.points} pts!`,
                challengeDoneToastOptions
            );
        }
        refetchDailyMission();
    };

    useEffect(() => {
        if (!user) return () => ({});
        const key = `gamefication-${user.id_e}`;
        socket.on(key, handleGameficationSocket);
        return () => {
            socket.off(key);
        };
    }, [user]); */

    useEffect(() => {
        if (createSection.response) {
            getSections.refetch();
            toast.success("Sessão criada com sucesso!");
        }
    }, [createSection.response]);

    useEffect(() => {
        if (createActivity.response) {
            getSections.refetch();
            toast.success("Atividade criada com sucesso!");
        }
    }, [createActivity.response]);

    useEffect(() => {
        if (updateActivity.response) {
            getSections.refetch();
            setActivityToAddCollaborators(undefined);
            toast.success("Atividade atualizada com sucesso!");
        }
    }, [updateActivity.response]);

    useEffect(() => {
        if (updateSection.response) {
            getSections.refetch();
            toast.success("Sessão atualizada com sucesso!");
        }
    }, [updateSection.response]);

    useEffect(() => {
        if (archiveActivity.response) {
            getSections.refetch();
            toast.success("Atividade arquivada com sucesso!");
        }
    }, [archiveActivity.response]);

    useEffect(() => {
        if (unarchiveActivity.response) {
            getSections.refetch();
            toast.success("Atividade desarquivada com sucesso!");
        }
    }, [unarchiveActivity.response]);

    useEffect(() => {
        if (deleteActivity.response) {
            getSections.refetch();
            toast.success("Atividade deletada com sucesso!");
        }
    }, [deleteActivity.response]);

    useEffect(() => {
        if (deleteSection.response) {
            getSections.refetch();
            toast.success("Sessão deletada com sucesso!");
        }
    }, [deleteSection.response]);

    useEffect(() => {
        if (toggleActivityStatus.response) {
            const { finished } = toggleActivityStatus.response;
            const message = finished
                ? "finalizada"
                : "desmarcada como finalizada";
            getSections.refetch();
            toast.success(`Atividade ${message}!`);
        }
    }, [toggleActivityStatus.response]);

    useEffect(() => {
        if (archiveSection.response) {
            getSections.refetch();
            toast.success("Sessão arquivada com sucesso!");
        }
    }, [archiveSection.response]);

    useEffect(() => {
        if (unarchiveSection.response) {
            getSections.refetch();
            toast.success("Sessão desarquivada com sucesso!");
        }
    }, [unarchiveSection.response]);

    useEffect(() => {
        if (duplicateActivity.response) {
            getSections.refetch();
            setUpdateActivityStates([
                ...updateActivityStates,
                duplicateActivity.response.id_e,
            ]);
            toast.success("Atividade duplicada com sucesso!");
        }
    }, [duplicateActivity.response]);

    useEffect(() => {
        const delay = setTimeout(() => {
            if (searchTerm === undefined) return;
            if (searchTerm === "") {
                setActivitiesOptions([]);
                return;
            }
            readActivities.onRefresh({
                projectId: project.id_e,
                search: searchTerm,
            });
        }, 500);

        return () => clearTimeout(delay);
    }, [searchTerm]);

    const handleOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [anchorEl, setAnchorEl]
    );

    const handleCleanFilters = () => {
        setFilterParams({
            activityUserIds: undefined,
            profileIds: undefined,
            statusIds: undefined,
        });
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [anchorEl, setAnchorEl]);

    const readActivitiesResponseToOptions = (
        response: TReadActivities
    ): TOptions => {
        const noArchivedSections = response.noArchived?.sections;
        const archivedSections = response.archived?.sections;
        const sectionsOptions = [
            ...(noArchivedSections?.map((section) => ({
                label: section.sectionName,
                options: section.activities?.map((activity) => ({
                    label: activity.name,
                    value: activity.id_e,
                })),
            })) ?? []),
            ...(archivedSections?.map((section) => ({
                label: section.sectionName,
                options: section.activities?.map((activity) => ({
                    label: activity.name,
                    value: activity.id_e,
                })),
            })) ?? []),
        ];
        const noArchivedActivitiesWithoutSection =
            response.noArchived?.activities;
        const archivedActivitiesWithoutSection = response.archived?.activities;
        const withoutSectionOptions = [
            {
                label: "Sem sessão",
                options: [
                    ...(noArchivedActivitiesWithoutSection?.map((activity) => ({
                        label: activity.name,
                        value: activity.id_e,
                    })) ?? []),
                    ...(archivedActivitiesWithoutSection?.map((activity) => ({
                        label: activity.name,
                        value: activity.id_e,
                    })) ?? []),
                ],
            },
        ];
        const options = [...sectionsOptions, ...withoutSectionOptions];
        return options;
    };

    useEffect(() => {
        if (readActivities.response) {
            const { response }: { response: TReadActivities } = readActivities;
            const options = readActivitiesResponseToOptions(response);
            setActivitiesOptions(options);
        }
    }, [readActivities.response]);

    const mapSectionId = (sectionId: string) => {
        if (sectionId.includes("archive")) return archivedSection?.id_e;
        return sectionId;
    };

    const chooseDestinationPosition = ({
        droppableId,
        index,
    }: DraggableLocation) => {
        if (droppableId.includes("withoutSection")) return index + 1;
        return index || 1;
    };

    const reorderActivity = (result: DropResult) => {
        setDraggingDestination(undefined);
        const { draggableId, destination, type, source } = result;

        if (
            !destination ||
            destination.index === null ||
            destination.index === undefined
        )
            return;

        if (
            type === "group" &&
            source.droppableId === destination.droppableId
        ) {
            updateSection.onRefresh({
                position: destination.index + 1,
                sectionId: draggableId,
            });
            return;
        }

        if (type.includes("activity")) {
            updateActivity.onRefresh({
                position: chooseDestinationPosition(destination),
                activityIds: [draggableId],
                sectionId: mapSectionId(destination.droppableId),
            });
            return;
        }

        if (type.includes("section")) {
            const parentId = type.replace("section", "");
            updateSection.onRefresh({
                position: destination.index + 1,
                sectionId: draggableId,
                parentId,
            });
            return;
        }

        if (destination.droppableId.includes("archiveSections")) {
            archiveSection.onRefresh({
                sectionId: draggableId,
            });
            return;
        }

        if (destination.droppableId.includes("withoutSectionSections")) {
            unarchiveSection.onRefresh({
                sectionId: draggableId,
            });
        }
    };

    const handleSetPopoverData = useCallback(
        (data: filterPopover) => {
            setFilterParams(data);
        },
        [filterParams, setFilterParams]
    );

    const getAllActivities = (): TSectionActivity[] => {
        const allSections = [
            ...sections,
            archivedSection,
            ...(archivedSections ?? []),
        ];
        return allSections
            .flatMap((section) => Object.values(section?.Activities ?? {}))
            .concat(activitiesWithoutSection);
    };

    const activitiesIdsRef = useMemo(() => {
        const refs = {} as TActivityIdsRef;
        getAllActivities()?.forEach((item) => {
            if (!item) return;
            refs[item?.id_e] = createRef();
        });
        return refs;
    }, [sections]);
    const goToActivity = (activityId: string) => {
        const section = sections.find((section) =>
            Object.values(section?.Activities)?.find(
                (activity) => activity.id_e === activityId
            )
        );
        const activity = getAllActivities().find(
            (activity) => activity.id_e === activityId
        );
        if (section) {
            setOpenedSectionsIds((prevOpenedSectionsIds) => [
                ...prevOpenedSectionsIds,
                section.id_e,
            ]);
        }
        if (activity?.isArchived) {
            setArchiveOpen(true);
        }
        const ref = activitiesIdsRef[activityId]?.current;
        if (!ref) {
            return;
        }
        ref.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
        ref.style.background = theme.gray50;
        setTimeout(() => {
            if (ref) {
                ref.style.background = "none";
            }
        }, 5000);
    };

    useEffect(() => {
        getSections.refetch();
    }, [filterParams]);

    const isSearching = Boolean(readActivities.loading && searchTerm);
    const isClosedScope = !!project?.Budget?.id_e;
    function processSections(sections: TSection[]): TSection[] {
        const result = sections.map((section) => {
            calculateProgress(section);
            return section;
        });
        return result;
    }

    const processProject = (sections: TSection[]) => {
        let minutesDone = 0;
        let totalExpectedMinutes = 0;

        sections.forEach((section) => {
            const progress = calculateProgress(section);
            minutesDone += progress.minutesDone;
            totalExpectedMinutes += progress.expectedMinutes;
        });

        return {
            hourProgress: {
                minutesDone,
                percentageOfHoursDone:
                    totalExpectedMinutes > 0
                        ? (minutesDone / totalExpectedMinutes) * 100
                        : 0,
            },
            totalExpectedMinutes,
        };
    };

    const sectionsToMap = useMemo(
        () => (sections ? processSections(sections) : []),
        [getSections]
    );
    const projectTotal = processProject(sectionsToMap);
    const statusOptions =
        data?.data?.flatMap((category) =>
            category.ActivityStatuses.map((status) => ({
                name: status.name,
                id_e: status.id_e,
                color: status.color,
                secondary_color: status.secondary_color,
                categoryId: status.activityStatusCategory_id_e,
            }))
        ) ?? [];

    const havePopoverFiltersApplied =
        (filterParams && filterParams.activityUserIds) ||
        filterParams.profileIds ||
        filterParams.statusIds;

    const collaboratorsOptions =
        project.Users?.map((user) => ({
            label: user.name,
            value: user.id_e,
        })) ?? [];

    const profilesOptions =
        project.Profiles?.map((profile) => ({
            label: profile.name,
            value: profile.id_e,
        })) ?? [];

    return (
        <Stack
            sx={{
                gap: "2.5rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    padding: "2rem 1.5rem",
                    justifyContent: "space-between",
                    alignItems: "center",

                    borderRadius: "4px",
                    border: "1px solid   #E0E0E0 ",
                }}
            >
                <Typography
                    sx={{
                        overflow: "hidden",
                        color: "  rgba(62, 55, 78, 0.87) ",
                        textOverflow: "ellipsis",
                        fontFamily: "Noto Sans",
                        fontSize: "24px",
                        fontStyle: " normal",
                        fontWeight: 400,
                        lineHeight: "33px",
                    }}
                >
                    {project.name}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        maxWidth: "36.6875rem",
                    }}
                >
                    <HourProgress
                        hourProgress={projectTotal.hourProgress}
                        expectedMinutes={projectTotal.totalExpectedMinutes}
                    />
                </Box>
            </Box>
            <Stack
                sx={{
                    gap: "1.5rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                    }}
                >
                    <s.SearchBar>
                        <Autocomplete
                            options={activitiesOptions}
                            getOptionLabel={(option) => option.label}
                            filterOptions={(options, state) =>
                                options.filter((option) =>
                                    option.label
                                        .toLowerCase()
                                        .includes(
                                            state.inputValue.toLowerCase()
                                        )
                                )
                            }
                            inputValue={searchTerm}
                            onInputChange={(_, value, reason) => {
                                if (reason === "input") setSearchTerm(value);
                            }}
                            onChange={(_, option) => {
                                if (!option || !option.options) return;

                                // Supondo que sempre há pelo menos um item dentro de `options`
                                const selectedOption = option.options[0];

                                if (!selectedOption) return; // Evita erros caso `options` esteja vazio

                                goToActivity(selectedOption.value);
                                setSearchTerm("");
                            }}
                            loading={isSearching}
                            noOptionsText="Sem opções"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Pesquise por atividade"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {isSearching ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </s.SearchBar>
                    <Button
                        variant="text"
                        sx={{
                            gap: "0.5rem",
                            color: havePopoverFiltersApplied
                                ? "#B93BDC"
                                : theme.primary.main,
                        }}
                        onClick={handleOpen}
                    >
                        <Box
                            sx={{
                                position: "relative",
                            }}
                        >
                            <FilterListOutlined />
                            {havePopoverFiltersApplied && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "2px",
                                        right: "0px",
                                        width: "0.5rem",
                                        height: "0.5rem",
                                        borderRadius: "50%",
                                        backgroundColor: "#EF6C00",
                                    }}
                                />
                            )}
                        </Box>
                        FILTROS
                    </Button>
                    <FilterPopover
                        anchorEl={anchorEl}
                        collaboratorsOptions={collaboratorsOptions}
                        filterPopoverData={filterParams}
                        handleClose={handleClose}
                        handleSetPopoverData={handleSetPopoverData}
                        optionsStatus={statusOptions}
                        profilesOptions={profilesOptions}
                    />
                    <Button
                        variant="text"
                        sx={{
                            gap: "0.5rem",
                            color: "#EF6C00",
                        }}
                        onClick={handleCleanFilters}
                    >
                        LIMPAR FILTROS
                    </Button>
                </Box>
                <s.ActivityList>
                    <s.ActivityListBody>
                        <DragDropContext
                            onDragEnd={reorderActivity}
                            onDragUpdate={(data) => {
                                if (data.destination !== draggingDestination)
                                    setDraggingDestination(data.destination);
                            }}
                        >
                            <Sections
                                refetchStatus={refetch}
                                statusData={data}
                                isClosedScope={isClosedScope}
                                sectionName="withoutSection"
                                sections={sectionsToMap}
                                updateActivity={updateActivity}
                                setDeleteConfirmationSectionId={
                                    setDeleteConfirmationSectionId
                                }
                                updateSection={updateSection}
                                project={project}
                                setActivityToAddCollaborators={
                                    setActivityToAddCollaborators
                                }
                                setDeleteConfirmationActivityId={
                                    setDeleteConfirmationActivityId
                                }
                                createActivity={createActivity}
                                activityToAddCollaborators={
                                    activityToAddCollaborators
                                }
                                archiveActivity={archiveActivity}
                                unarchiveActivity={unarchiveActivity}
                                toggleActivityStatus={toggleActivityStatus}
                                archiveSection={archiveSection}
                                activitiesWithoutSection={
                                    activitiesWithoutSection
                                }
                                duplicateActivity={duplicateActivity}
                                updateActivityStates={updateActivityStates}
                                setUpdateActivityStates={
                                    setUpdateActivityStates
                                }
                                activitiesIdsRef={activitiesIdsRef}
                                openedSectionsIds={openedSectionsIds}
                                setOpenedSectionsIds={setOpenedSectionsIds}
                                getSections={getSections}
                                draggingDestination={draggingDestination}
                            />
                            {!isClosedScope && (
                                <>
                                    <AddSection
                                        isToCreateSection={isToCreateSection}
                                        setIsToCreateSection={
                                            setIsToCreateSection
                                        }
                                        project={project}
                                        createSection={createSection}
                                    />
                                    <AddActivity
                                        createActivity={createActivity}
                                        project={project}
                                        setActivityToAddCollaborators={
                                            setActivityToAddCollaborators
                                        }
                                        activityToAddCollaborators={
                                            activityToAddCollaborators
                                        }
                                    />
                                </>
                            )}
                            <Archive
                                refetchStatus={refetch}
                                statusData={data}
                                isClosedScope={isClosedScope}
                                project={project}
                                archiveSection={archivedSection}
                                unarchiveActivity={unarchiveActivity}
                                setActivityToAddCollaborators={
                                    setActivityToAddCollaborators
                                }
                                setDeleteConfirmationActivityId={
                                    setDeleteConfirmationActivityId
                                }
                                archiveActivity={archiveActivity}
                                toggleActivityStatus={toggleActivityStatus}
                                activityToAddCollaborators={
                                    activityToAddCollaborators
                                }
                                updateActivity={updateActivity}
                                setDeleteConfirmationSectionId={
                                    setDeleteConfirmationSectionId
                                }
                                createActivity={createActivity}
                                updateSection={updateSection}
                                archivedSections={archivedSections}
                                unarchiveSection={unarchiveSection}
                                updateActivityStates={updateActivityStates}
                                setUpdateActivityStates={
                                    setUpdateActivityStates
                                }
                                activitiesIdsRef={activitiesIdsRef}
                                openedSectionsIds={openedSectionsIds}
                                setOpenedSectionsIds={setOpenedSectionsIds}
                                setArchiveOpen={setArchiveOpen}
                                archiveOpen={archiveOpen}
                                getSections={getSections}
                            />
                        </DragDropContext>
                    </s.ActivityListBody>
                    {/* </s.ActivityListBody> */}
                </s.ActivityList>
            </Stack>
            {activityToAddCollaborators && (
                <AddCollaboratorsModal
                    setActivity={setActivityToAddCollaborators}
                    activity={activityToAddCollaborators}
                    project={project}
                    updateActivity={updateActivity}
                    updateActivityStates={updateActivityStates}
                />
            )}
            {deleteConfirmationActivityId && (
                <DeleteConfirmationActivity
                    setOpen={setDeleteConfirmationActivityId}
                    activityId={deleteConfirmationActivityId}
                    deleteActivity={deleteActivity}
                />
            )}
            {deleteConfirmationSectionId && (
                <DeleteConfirmationSection
                    sectionId={deleteConfirmationSectionId}
                    deleteSection={deleteSection}
                    setOpen={setDeleteConfirmationSectionId}
                />
            )}
        </Stack>
    );
};
