import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogContent, Stack, Typography } from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { theme } from "../../../../../../assets";
import ColorPicker, {
    PickableColor,
} from "../../../../../../components/atoms/color-picker/color-picker";
import CustomSelect from "../../../../../../components/molecules/custom-select/custom-select";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import {
    createActivityStatus,
    updateActivityStatus,
} from "../../../../../../services/activities";
import {
    TProjectCategory,
    TProjectCategoryStatusResponse,
} from "../../../../../../services/activities/read-category-status";
import { TReadBudgetsStatusResponse } from "../../../../../../services/budget/read-budgets-status";
import { IOptionStatus } from "../status-dropdown/status-dropdown";

const getSecondaryColor = (color: PickableColor): string => {
    const SECONDARY_COLOR_MAP = {
        "#03BA78": "#a9d4c4",
        "#0B49EC": "#abc2ff",
        "#0CB8B8": "#b5ffff",
        "#278027": theme.lightGreen,
        "#333333": "#d1d1d1",
        "#4B07BF": "#cbb3f5",
        "#800380": "#b594b5",
        "#824700": "#e8c090",
        "#8BB908": "#cee09b",
        "#9933FF": theme.purple100,
        "#9D0000": "#e8a7a7",
        "#D14141": theme.softRed,
        "#DD04DD": theme.pink200,
        "#F28500": "#e0caaf",
        "#FF015C": theme.pink100,
    } satisfies Record<PickableColor, string>;

    return SECONDARY_COLOR_MAP[color];
};

const validationSchema = yup.object().shape({
    name: yup.string().required("O nome é obrigatório"),
    color: yup.string().required("A cor é obrigatória"),
    statusCategoryId: yup.string().required("A categoria é obrigatória"),
});

interface IStatusProject {
    name: string;
    color: string;
    statusCategoryId: string;
}

function StatusForm({
    categorys,
    handleClose,
    handleDeleteMode,
    refetch,
    refetchStatus,
    selectStatusToEdit,
    setIsLoading,
    isLoading,
}: {
    handleClose: () => void;
    handleDeleteMode: () => void;
    refetchStatus: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TProjectCategoryStatusResponse, unknown>>;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsStatusResponse, unknown>>;
    selectStatusToEdit?: IOptionStatus;
    categorys: TProjectCategory[];
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
}) {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IStatusProject>({
        defaultValues: {
            name: selectStatusToEdit?.name ?? "",
            color: selectStatusToEdit?.color ?? "",
            statusCategoryId: selectStatusToEdit?.categoryId ?? "",
        },
        resolver: yupResolver(validationSchema),
    });

    const submit = async (data: IStatusProject) => {
        setIsLoading(true);
        const params = {
            ...data,
            categoryId: undefined,
            secondary_color: getSecondaryColor(data.color as PickableColor),
        };
        if (selectStatusToEdit?.id_e) {
            await updateActivityStatus({
                statusId: selectStatusToEdit?.id_e,
                params: data,
            }).then(() => toast.success("Status editado com sucesso!"));
        } else {
            await createActivityStatus({ params }).then(() =>
                toast.success("Status criado com sucesso!")
            );
        }
        await refetch();
        await refetchStatus();
        setIsLoading(false);
        handleClose();
    };

    const categoryOptions = categorys.map((category) => ({
        label: category.name,
        value: category.id_e,
    }));

    const errorColor = errors && errors.color;
    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack
                sx={{
                    gap: "0.75rem",
                    px: "1.5rem ",
                    marginTop: "1vh",
                }}
            >
                <Typography variant="h6">Adicionar um status</Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.gray500,
                    }}
                >
                    Adicione outros status "em andamento" para representar
                    diferentes estágios no processo de desenvolvimento da sua
                    equipe.
                </Typography>
            </Stack>

            <DialogContent>
                <Stack
                    sx={{
                        gap: "1.5rem",
                        width: "100%",
                    }}
                >
                    <CustomTextField
                        control={control}
                        name={`name`}
                        label={`Nome do status`}
                        fullWidth
                    />
                    <CustomSelect
                        control={control}
                        name="statusCategoryId"
                        label={"Categoria"}
                        options={categoryOptions}
                    />
                    <Stack
                        sx={{
                            position: "relative",
                            pr: "0.25rem",
                        }}
                    >
                        <ColorPicker
                            onSelect={(color) => setValue(`color`, color)}
                            title="Defina uma cor para esse Status"
                        />
                        {errorColor && (
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    position: "absolute",
                                    bottom: "-1.5rem",
                                    fontSize: "0.75rem",
                                    marginLeft: "0.875rem",
                                    marginRight: "0.875rem",
                                    color: "#d32f2f",
                                }}
                            >
                                {errorColor?.message}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    {selectStatusToEdit?.id_e && (
                        <Button
                            variant="text"
                            onClick={handleDeleteMode}
                            disabled={isLoading}
                            color="error"
                        >
                            Excluir
                        </Button>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1rem",
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                    >
                        ADICIONAR
                    </Button>
                </Box>
            </Box>
        </form>
    );
}

export default StatusForm;
