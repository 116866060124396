import { yupResolver } from "@hookform/resolvers/yup";
import { WarningAmberOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import { Dispatch, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { theme } from "../../../../../../assets";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { TBudgetProfileLevelDedicatedHours } from "../../../../../../services/budget/read-budgets-levels-detailed-by-id";
import { onlyNumbers } from "../../../../../../utils";

const validation = yup.object().shape({
    name: yup.string(),
    abbreviation: yup
        .string()
        .max(4, "A abreviação deve conter no máximo 4 carácteres"),
});

const s4Style = {
    color: "rgba(62, 55, 78, 0.87)",
    fontFamily: "Noto Sans",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.125rem",
    letterSpacing: "0.0044rem",
};

export type EditProfile = {
    name: string;
    abbreviation: string;
    costPerHour: string;
    dependencyPercentage: string;
};

export type TProfile = {
    BudgetProfileLevelDedicatedHours: TBudgetProfileLevelDedicatedHours;
    BudgetProfileTimeDependancy: {
        DependsOn: {
            Profile: {
                name: string;
            };
            id_e: string;
        };
    }[];
    Profile: {
        id_e: string;
        name: string;
        abbreviation: string;
    };
    id_e: string;
    name: string;
    costPerHour: string;
    responsible: {
        avatar: string;
        id_e: string;
        name: string;
    } | null;
    totals: {
        totalHours: number;
        totalPrice: number;
    };
    dependencyMode?: string | null;
    dependencyPercentage?: number | null;
};

export const EditProfileDialog = ({
    handleEditProfile,
    open,
    profilesFromBudget,
    profile,
    setOpen,
}: {
    open: boolean;
    setOpen: Dispatch<boolean>;
    handleEditProfile: (
        params: {
            name?: string;
            abbreviation?: string;
            costPerHour?: number;
            dependsOn?: string[];
            dependencyMode?: string | null;
            dependencyPercentage?: string;
        },
        profileId: string
    ) => Promise<void>;
    profile?: TProfile;
    profilesFromBudget?: TProfile[];
}) => {
    const checkboxOptions = profile?.BudgetProfileTimeDependancy
        ? profile?.BudgetProfileTimeDependancy.map(
              (profilesDependency) => profilesDependency.DependsOn.id_e
          )
        : [];
    const [hableAddictionConfigs, setHableAddictionConfigs] = useState(
        !!profile?.dependencyMode
    );
    const [selectedOption, setSelectedOption] = useState(
        profile?.dependencyMode || "total"
    );
    const [selectedOptions, setSelectedOptions] =
        useState<string[]>(checkboxOptions);
    const { control, handleSubmit } = useForm<EditProfile>({
        defaultValues: {
            name: profile?.Profile?.name || "",
            abbreviation: profile?.Profile?.abbreviation || "",
            costPerHour: String(profile?.costPerHour).replace(".", ",") || "",
            dependencyPercentage: profile?.dependencyPercentage
                ? `${profile?.dependencyPercentage}`
                : "0",
        },
        resolver: yupResolver(validation),
    });

    const options =
        profilesFromBudget
            ?.filter(
                (profilesBudgetFiltred) =>
                    profilesBudgetFiltred?.BudgetProfileTimeDependancy
                        ?.length === 0
            )
            ?.map((profile) => ({
                label: profile.Profile.name,
                value: profile.id_e,
            }))
            .filter(
                (profileFiltred) =>
                    profileFiltred.label !== profile?.Profile.name
            ) || [];

    const submit = (data: EditProfile) => {
        const cost = Number(
            data.costPerHour.replace("R$", "").replace(",", ".")
        );
        const dependsOnValues =
            selectedOption === "total" ? [] : selectedOptions;
        handleEditProfile(
            {
                ...data,
                dependsOn: dependsOnValues,
                costPerHour: Number(cost),
                dependencyMode:
                    hableAddictionConfigs === true ? selectedOption : null,
            },
            profile?.id_e || ""
        );
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleChange = (value: string) => {
        setSelectedOptions((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );
    };

    const haveProfilesSelected = profile?.responsible?.name;

    return (
        <Dialog open={open}>
            <form onSubmit={handleSubmit(submit)}>
                <DialogContent>
                    <Typography
                        variant="h6"
                        sx={{ marginTop: "1vh", p: "1rem 1.5rem" }}
                    >
                        Editar perfil
                    </Typography>

                    <DialogContent>
                        <Stack
                            sx={{
                                gap: "1.5rem",
                            }}
                        >
                            <Typography sx={s4Style}>
                                Perfis de atividade representam categorias
                                específicas de trabalhadores dentro de um
                                projeto, organizando as funções e
                                responsabilidades de forma clara.
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "1rem",
                                }}
                            >
                                <CustomTextField
                                    autoFocus
                                    required
                                    sx={{
                                        margin: "dense",
                                    }}
                                    control={control}
                                    name="name"
                                    label="Nome do Perfil"
                                    fullWidth
                                    variant="outlined"
                                    disabled
                                />
                                <Stack>
                                    <CustomTextField
                                        required
                                        name="abbreviation"
                                        sx={{
                                            margin: "dense",
                                        }}
                                        control={control}
                                        label="Abreviação*"
                                        fullWidth
                                        variant="outlined"
                                        maxCharacters={4}
                                        disabled
                                    />
                                </Stack>
                            </Box>
                            <CustomTextField
                                autoFocus
                                required
                                sx={{
                                    margin: "dense",
                                }}
                                control={control}
                                name="costPerHour"
                                label="Valor por Hora"
                                isCurrency
                                fullWidth
                                variant="outlined"
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}
                            >
                                <Switch
                                    value={hableAddictionConfigs}
                                    onChange={() =>
                                        setHableAddictionConfigs(
                                            (prev) => !prev
                                        )
                                    }
                                />{" "}
                                <Typography variant="body1">
                                    Configurar Condicionais de horas
                                </Typography>
                            </Box>
                            <CustomTextField
                                autoFocus
                                required
                                sx={{
                                    margin: "dense",
                                }}
                                control={control}
                                name="dependencyPercentage"
                                label="% Cálculo de horas"
                                fullWidth
                                formatValue={onlyNumbers}
                                variant="outlined"
                                startAdornment={
                                    <InputAdornment position="start">
                                        %
                                    </InputAdornment>
                                }
                                disabled={!hableAddictionConfigs}
                            />
                            <FormControl>
                                <RadioGroup
                                    value={selectedOption}
                                    onChange={(e) =>
                                        setSelectedOption(e.target.value)
                                    }
                                >
                                    <FormControlLabel
                                        value="total"
                                        disabled={!hableAddictionConfigs}
                                        control={<Radio />}
                                        label="Calcular em cima do valor Geral de Horas"
                                    />
                                    <FormControlLabel
                                        value="byProfiles"
                                        control={<Radio />}
                                        disabled={!hableAddictionConfigs}
                                        label="Selecionar Perfis Específicos"
                                    />
                                </RadioGroup>
                            </FormControl>

                            {selectedOption === "byProfiles" ? (
                                <>
                                    <FormGroup row>
                                        {options.map((option) => (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                                key={option.value}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedOptions.includes(
                                                                option.value
                                                            )}
                                                            onChange={() =>
                                                                handleChange(
                                                                    option.value
                                                                )
                                                            }
                                                            sx={{
                                                                color: "#673ab7",
                                                                "&.Mui-checked":
                                                                    {
                                                                        color: "#673ab7",
                                                                    },
                                                            }}
                                                            disabled={
                                                                !hableAddictionConfigs
                                                            }
                                                        />
                                                    }
                                                    label={option.label}
                                                />
                                            </Box>
                                        ))}
                                    </FormGroup>
                                    <Typography sx={s4Style}>
                                        Ao configurar condicionais de horas este
                                        perfil terá horas atribuídas
                                        automaticamente referente aos valores
                                        inseridos nas categorias.
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography sx={s4Style}>
                                        Ao configurar condicionais de horas este
                                        perfil terá horas atribuídas
                                        automaticamente referente aos valores
                                        inseridos nas categorias.
                                    </Typography>
                                    {!haveProfilesSelected && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                padding: "0.375rem 1rem",
                                                borderRadius:
                                                    "var(--borderRadius, 4px)",
                                                border: "1px solid var(--warning-main, #EF6C00)",
                                                gap: "0.75rem",
                                            }}
                                        >
                                            <WarningAmberOutlined
                                                sx={{
                                                    color: "#EF6C00",
                                                }}
                                            />
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: "rgba(102, 60, 0, 1)",
                                                }}
                                            >
                                                Sem Responsáveis no momento.
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="text"
                            onClick={handleCancel}
                            sx={{
                                color: theme.purple500,
                                fontWeight: 500,
                                fontSize: "0.875rem",
                            }}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                color: theme.white,
                                fontWeight: 500,
                                fontSize: "0.875rem",
                            }}
                        >
                            AVANÇAR
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    );
};
