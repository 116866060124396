import { Add, InfoOutlined, Settings } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { theme } from "../../../../../../assets";
import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { useReadBudgetDetailedLevelById } from "../../../../../../hooks/react-query/use-read-budget-detailed-levels";
import {
    createBudgetLevel,
    deleteBudgetLevel,
} from "../../../../../../services/budget";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import { TLevel } from "../../../../../../services/budget/read-budgets";
import { ScopeEditTimeDialog } from "./edit-time-dialog";
import FeatureDrawer from "./feature-drawer";
import { LevelSelect } from "./level-select";
import { ScopeDialog } from "./scope-dialog";
import ScopeTable from "./scope-table";

type TFeatureContentProps = {
    budgetId: string;
    haveRequiriments: boolean;
    levelConfig: TLevel[];
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsResponse, unknown>>;
    dataBudgetById: TReadBudgetsResponse | undefined;
};

type FormValues = {
    feature: Record<string, string>;
};

function FeatureContent({
    budgetId,
    dataBudgetById,
    haveRequiriments,
    levelConfig,
    refetch,
}: TFeatureContentProps) {
    const [openEditScopeDialog, setOpenEditScopeDialog] = useState(false);
    const [openFeatureDrawer, setOpenFeatureDrawer] = useState(false);
    const [openEditTimeDialog, setOpenEditTimeDialog] = useState(false);
    const [levelId, setLevelId] = useState<string>();
    const [search, setSearch] = useState<string>();
    const { control, getValues, handleSubmit, resetField, setValue, watch } =
        useForm<FormValues>({
            defaultValues: {
                feature: levelConfig.reduce(
                    (acc, level) => ({
                        ...acc,
                        [`level_${level.levelOrder}`]: "",
                    }),
                    {}
                ),
            },
        });

    const params = {
        page: 1,
        length: 100,
        search,
    };

    const { data, refetch: refetchLevels } = useReadBudgetDetailedLevelById({
        budgetId,
        params,
    });

    const dataBudgetLevelDetails = data?.data;

    const submit = () => {
        const featuresValue = getValues(`feature.level_${levelConfig.length}`);
        const parent = getValues(`feature.level_${levelConfig.length - 1}`);

        if (!featuresValue || !parent) {
            toast.error("Preencha todos os campos obrigatórios!");
            return;
        }

        const params = {
            levelOrder: levelConfig.length,
            name: featuresValue,
            parent,
        };

        createBudgetLevel(budgetId, params)
            .then(() => {
                toast.success(
                    `${
                        levelConfig?.[levelConfig.length - 1]?.name || "Feature"
                    } adicionada com sucesso!`
                );
                refetchLevels();
                refetch();
            })
            .catch(() => {
                toast.error(
                    `Erro ao adicionar a ${
                        levelConfig?.[levelConfig.length - 1]?.name || "Feature"
                    }.`
                );
            });
    };

    const generateOptions = (levelId: string, prevSelectedValue?: string) => {
        if (!prevSelectedValue) {
            // Primeiro nível
            const level = levelConfig.find((lvl) => lvl.id_e === levelId);

            const result =
                level?.Level?.map((option) => ({
                    value: option.id_e,
                    label: option.name,
                    color: option.MainLevel?.color ?? "inherit",
                })) || [];

            return result;
        }

        // Níveis subsequentes
        const prevLevel = levelConfig.find((lvl) =>
            lvl.Level?.some((opt) => opt.id_e === prevSelectedValue)
        );

        const selectedOption = prevLevel?.Level?.find(
            (opt) => opt.id_e === prevSelectedValue
        );

        return (
            selectedOption?.Children?.map((option) => ({
                value: option.level_id_e,
                label: option.Level.name,
                color: "inherit",
            })) || []
        );
    };

    const handleSetValueOnForm = (name: string, value: string) => {
        const currentLevel = parseInt(name.split("_").pop() || "0", 10);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const previousValue = getValues(name);

        if (previousValue === value) return; // Evita re-execução desnecessária
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue(name, value, { shouldValidate: true, shouldDirty: true });

        // Resetando os campos subsequentes
        levelConfig.forEach((level) => {
            if (level.levelOrder > currentLevel) {
                resetField(`feature.level_${level.levelOrder}`);
            }
        });
    };

    const handleModalEditScope = useCallback(() => {
        setOpenEditScopeDialog((prev) => !prev);
    }, [openEditScopeDialog]);

    const disableTextField =
        watch(`feature.level_${levelConfig.length - 1}`).length === 0;

    const allFieldsFilled = levelConfig.every((level) =>
        watch(`feature.level_${level.levelOrder}`)
    );
    const handleDeleteLevel = async (levelId: string) => {
        await deleteBudgetLevel({ budgetId, levelId }).then(async () => {
            await refetchLevels();
            toast.success("Item apagado com sucesso");
        });
    };

    const handleFeatureDrawer = (data: boolean, levelId?: string) => {
        if (levelId) {
            setLevelId(levelId);
        }
        setOpenFeatureDrawer(data);
    };

    const handleEditTimeDialog = useCallback(() => {
        setOpenEditTimeDialog((prev) => !prev);
    }, [openEditScopeDialog]);

    const isButtonDisabled = !allFieldsFilled;
    return (
        <Stack
            sx={{
                gap: "2rem",
            }}
        >
            <Stack
                sx={{
                    gap: "1.5rem",
                    width: "100%",
                    my: "2rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h5">Adicionar Escopo</Typography>
                        <Tooltip
                            side="bottom"
                            backgroundColor="rgba(97, 97, 97, 0.9)"
                            content={
                                <Box
                                    sx={{
                                        padding: "0.25rem 0.5rem",
                                        maxWidth: "27.5rem",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#FFF",
                                            fontFamily: "Noto Sans",
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "1.25rem",
                                            letterSpacing: "0.0088rem",
                                        }}
                                    >
                                        Defina o primeiro item em cada campo
                                        para criar uma lista com seu escopo e
                                        customizar seu orçamento.
                                    </span>
                                </Box>
                            }
                        >
                            <InfoOutlined
                                sx={{
                                    color: "#B93BDC",
                                }}
                            />
                        </Tooltip>
                    </Box>
                    <Button
                        onClick={handleModalEditScope}
                        variant="outlined"
                        sx={{
                            gap: "0.5rem",
                        }}
                        disabled={!haveRequiriments}
                    >
                        <Settings
                            sx={{
                                width: "24px",
                                height: "24px",
                                color: theme.primary.main,
                            }}
                        />{" "}
                        CONFIGURAR ESCOPO
                    </Button>
                </Box>
                <form onSubmit={handleSubmit(submit)}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.5rem",
                        }}
                    >
                        {levelConfig.map((level, index) => {
                            const prevLevel = levelConfig[index - 1];
                            const prevSelectedValue = watch(
                                `feature.level_${prevLevel?.levelOrder}`
                            );
                            const disabled =
                                index !== 0 && prevSelectedValue?.length === 0;

                            const isLastItem = index + 1 === levelConfig.length;

                            const labelLevel = `${index + 1}° Nível`;

                            if (isLastItem) return null;
                            return (
                                <Box
                                    key={level.id_e}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}
                                >
                                    <LevelSelect
                                        control={control}
                                        budgetId={budgetId}
                                        levelOptions={generateOptions(
                                            level.id_e,
                                            prevSelectedValue
                                        )}
                                        parent={prevSelectedValue}
                                        levelOrder={level.levelOrder}
                                        refetch={refetch}
                                        name={`feature.level_${level.levelOrder}`}
                                        handleSetValueOnForm={
                                            handleSetValueOnForm
                                        }
                                        label={level.name}
                                        disabled={disabled}
                                    />
                                    <label
                                        style={{
                                            fontFamily: "Noto Sans",
                                            fontSize: "0.75rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "166%" /* 19.92px */,
                                            letterSpacing: "0.025rem",
                                            padding: "0 0.875rem",
                                        }}
                                    >
                                        {labelLevel}
                                    </label>
                                </Box>
                            );
                        })}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <CustomTextField
                                control={control}
                                name={`feature.level_${levelConfig.length}`}
                                label={
                                    levelConfig?.[levelConfig.length - 1]
                                        ?.name || ""
                                }
                                fullWidth
                                disabled={disableTextField}
                            />
                            <label
                                style={{
                                    fontFamily: "Noto Sans",
                                    fontSize: "0.75rem",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "166%" /* 19.92px */,
                                    letterSpacing: "0.025rem",
                                    padding: "0 0.875rem",
                                }}
                            >
                                {`${levelConfig.length}° Nível`}
                            </label>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isButtonDisabled}
                            sx={{
                                gap: "0.5rem",
                                width: "100%",
                                maxWidth: "9.9375rem",
                                height: "2.5rem",
                            }}
                        >
                            <Add /> ADICIONAR
                        </Button>
                    </Box>
                </form>
            </Stack>
            {haveRequiriments && (
                <ScopeTable
                    dataBudgetById={dataBudgetById}
                    handleEditTimeDialog={handleEditTimeDialog}
                    handleFeatureDrawer={handleFeatureDrawer}
                    dataBudgetLevelDetails={dataBudgetLevelDetails}
                    handleDeleteLevel={handleDeleteLevel}
                    refetchLevels={refetchLevels}
                    setSearch={setSearch}
                />
            )}
            <ScopeDialog
                handleClose={handleModalEditScope}
                open={openEditScopeDialog}
                levelConfig={levelConfig}
                budgetId={budgetId}
                refetch={refetch}
            />
            {openEditTimeDialog && (
                <ScopeEditTimeDialog
                    open={openEditTimeDialog}
                    handleClose={handleEditTimeDialog}
                    handleFeatureDrawer={handleFeatureDrawer}
                    dataBudgetLevelDetails={dataBudgetLevelDetails}
                    dataBudgetById={dataBudgetById}
                    budgetId={budgetId}
                    refetch={refetch}
                    refetchLevels={refetchLevels}
                />
            )}
            {levelId && (
                <FeatureDrawer
                    open={openFeatureDrawer}
                    toggleDrawer={handleFeatureDrawer}
                    refetchLevelDetail={refetchLevels}
                    budgetId={budgetId}
                    levelId={levelId}
                    dataBudgetLevelDetails={dataBudgetLevelDetails}
                    refetchLevels={refetchLevels}
                />
            )}
        </Stack>
    );
}

export default FeatureContent;
