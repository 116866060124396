import { useQuery } from "@tanstack/react-query";

import { readProfiles } from "../../services/budget";
import {
    TReadBudgetsProfiles,
    TReadBudgetsProfilesResponse,
} from "../../services/budget/read-profiles";

export function useReadProfile({
    length,
    page,
    userId,
    filter,
}: TReadBudgetsProfiles) {
    return useQuery<
        TReadBudgetsProfilesResponse,
        unknown,
        TReadBudgetsProfilesResponse
    >({
        enabled: true,
        queryKey: [`readProfile/${length}/${page}/${filter}`],
        queryFn: () => readProfiles({ length, page, userId, filter }),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
