import { ChevronRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { Icons, theme } from "../../../../../../../assets";
import { PopoverRadix } from "../../../../../../../components";
import { TResponse } from "../../../../../../../models";
import { TArchiveSection } from "../../../../../../../services/activities/archive-section";
import { TUnarchiveSection } from "../../../../../../../services/activities/unarchive-section";
import { TSection } from "../../../types";
import { HourProgress } from "../../hour-progress/hour-progress";
import * as s from "../styled-section";

type TSectionHeader = {
    archiveSection?: TResponse<unknown, TArchiveSection>;
    unarchiveSection?: TResponse<unknown, TUnarchiveSection>;
    setDeleteConfirmationSectionId: React.Dispatch<
        React.SetStateAction<string>
    >;
    openedSectionsIds: string[];
    index: number;
    archive?: boolean;
    section: TSection;
    toggleSectionVisibility: (sectionId: string) => void;
    toggleSectionEdit: (index: number) => void;
    paddingRightTitle?: string;
    isClosedScope: boolean;
    hourProgressMaxWidth?: string;
    isSubSection?: boolean;
};

export const SectionHeader = ({
    archiveSection,
    unarchiveSection,
    setDeleteConfirmationSectionId,
    toggleSectionVisibility,
    openedSectionsIds,
    index,
    section,
    archive,
    toggleSectionEdit,
    paddingRightTitle,
    isClosedScope,
    hourProgressMaxWidth,
    isSubSection = false,
}: TSectionHeader) => {
    const handleArchiveSection = (sectionId: string) => {
        archiveSection?.onRefresh({
            sectionId,
        });
    };

    const handleUnarchiveSection = (sectionId: string) => {
        unarchiveSection?.onRefresh({
            sectionId,
        });
    };
    return (
        <Box
            sx={{
                p: "1rem",
                cursor: "pointer",
                ":hover": {
                    backgroundColor: theme.gray50,
                },
                ":hover .hidden": {
                    opacity: 1,
                },
                ".hidden": {
                    opacity: 0,
                },
                borderLeft: `2px solid ${section.color ?? "#EDEDED"}`,
            }}
            onClick={() => toggleSectionVisibility(section.id_e)}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.75rem",
                        width: "100%",
                    }}
                >
                    <Icons.Drag className="hidden" />
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            pl: paddingRightTitle ?? "unset",
                        }}
                    >
                        <ChevronRight
                            sx={{
                                color: "rgba(62, 55, 78, 0.87)",
                                transform: openedSectionsIds.includes(
                                    section.id_e
                                )
                                    ? "rotate(+90deg)"
                                    : "rotate(0deg)",
                            }}
                        />
                        <Typography
                            variant="h4"
                            sx={{
                                overflow: "hidden",
                                color: "rgba(62, 55, 78, 0.87)",
                                textOverflow: "ellipsis",
                                fontFamily: "Noto Sans",

                                fontSize: isSubSection ? "1.25rem" : "1.5rem",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "2.0625rem",
                            }}
                        >
                            {section.name}
                        </Typography>
                    </Box>
                </Box>
                {isClosedScope && (
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            maxWidth: hourProgressMaxWidth ?? "31.25rem",
                        }}
                    >
                        <HourProgress
                            hourProgress={section.hourProgress!}
                            expectedMinutes={section.expectedMinutes}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.75rem",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <PopoverRadix
                        align="end"
                        trigger={
                            <s.More className="hidden">
                                <Icons.More />
                            </s.More>
                        }
                    >
                        <s.MenuContainer>
                            {!archive && (
                                <s.MenuOption
                                    edit
                                    onClick={() => toggleSectionEdit(index)}
                                >
                                    <Icons.PencilOutline
                                        color={theme.gray800}
                                    />
                                    Editar
                                </s.MenuOption>
                            )}
                            {archive ? (
                                <s.MenuOption
                                    onClick={() =>
                                        handleUnarchiveSection(section.id_e)
                                    }
                                >
                                    <Icons.Unarchive color={theme.gray600} />
                                    Desarquivar
                                </s.MenuOption>
                            ) : (
                                <s.MenuOption
                                    onClick={() =>
                                        handleArchiveSection(section.id_e)
                                    }
                                >
                                    <Icons.IconArchive color={theme.gray600} />
                                    Arquivar
                                </s.MenuOption>
                            )}
                            <s.MenuOption
                                danger
                                onClick={() => {
                                    setDeleteConfirmationSectionId(
                                        section.id_e
                                    );
                                }}
                            >
                                <Icons.Trash color={theme.red} />
                                Deletar
                            </s.MenuOption>
                        </s.MenuContainer>
                    </PopoverRadix>
                </Box>
            </Box>
        </Box>
    );
};
