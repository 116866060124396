import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TProjectCategory = {
    ActivityStatuses: {
        activityStatusCategory_id_e: string;
        color: string;
        id_e: string;
        name: string;
        order: number;
        secondary_color: string;
    }[];
    id_e: string;
    name: string;
    order: number;
    organization_id_e: string;
};

export type TProjectCategoryStatusResponse = {
    data: TProjectCategory[];
};

export default async () => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/statusCategory`,
        method: "get",

        headers: {
            auth: true,
        },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
