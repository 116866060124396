import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import { TLevel } from "../../../../../../services/budget/read-budgets";
import { formatCurrency } from "../../../../../organization/project/tab-costs/utils";

const CollapsibleRow = ({
    average,
    father,
    grandFather,
    lastLevel,
    total,
}: {
    average: string;
    father?: TLevel;
    grandFather?: TLevel;
    lastLevel?: TLevel;
    total: string;
}) => {
    return (
        <>
            <TableRow>
                {grandFather && (
                    <TableCell align="center">
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: "Noto Sans",
                            }}
                        >
                            {grandFather.Level.length}
                        </Typography>
                    </TableCell>
                )}
                {father && (
                    <TableCell align="center">
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: "Noto Sans",
                            }}
                        >
                            {father.Level.length}
                        </Typography>
                    </TableCell>
                )}
                {lastLevel && (
                    <TableCell align="center">
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: "Noto Sans",
                            }}
                        >
                            {/* Cálculo de porcentagem de horas */}
                            {lastLevel.Level.length}
                        </Typography>
                    </TableCell>
                )}
                <TableCell
                    align="center"
                    sx={{
                        width: "60%",
                    }}
                />
                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {average}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {total}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
};

export const CustomTable = ({
    dataBudgetById,
}: {
    dataBudgetById?: TReadBudgetsResponse;
}) => {
    const levelConfig = dataBudgetById?.LevelConfig;
    const grandFather = levelConfig?.find((level) => level.levelOrder === 1);
    const father = levelConfig?.find((level) => level.levelOrder === 2);
    const lastLevel = levelConfig?.find((level) => level.levelOrder === 3);
    const total =
        formatCurrency(dataBudgetById?.totalPrice.toFixed(2)) ?? "R$ 0,00";
    const average = dataBudgetById?.totalHours?.toFixed(0) ?? "0";

    return (
        <TableContainer
            component={Paper}
            sx={{
                boxShadow: "unset !important",
                backgroundColor: "transparent",
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {levelConfig?.map((level) => (
                            <TableCell key={level.id_e} align="center">
                                {level.name}
                            </TableCell>
                        ))}
                        <TableCell
                            align="center"
                            sx={{
                                width: "60%",
                            }}
                        />
                        <TableCell align="center">Horas Estimadas</TableCell>
                        <TableCell align="center">Valor Final</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <CollapsibleRow
                        grandFather={grandFather}
                        father={father}
                        lastLevel={lastLevel}
                        total={total}
                        average={average}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

function OverviewTable({
    dataBudgetById,
}: {
    dataBudgetById?: TReadBudgetsResponse;
}) {
    return (
        <Stack
            sx={{
                gap: "2rem",
            }}
        >
            <CustomTable dataBudgetById={dataBudgetById} />
        </Stack>
    );
}

export default OverviewTable;
