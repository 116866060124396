import { Draggable } from "@hello-pangea/dnd";
import {
    IconButton,
    TableCell,
    TableRow,
    Tooltip as MuiTooltip,
} from "@mui/material";
import dayjs from "dayjs";

import { FotoUser, Icons, theme } from "../../../../../../assets";
import { PopoverRadix } from "../../../../../../components";
import { TProject, TResponse } from "../../../../../../models";
import { TUpdateActivity } from "../../../../../../services/activities/update-activity";
import { TSectionActivity } from "../../types";
import { Collaborators } from "../add-collaborators-button/add-collaborators-button";
import { HourProgress } from "../hour-progress/hour-progress";
import StatusDropdown, {
    IOptionStatus,
} from "../status-dropdown/status-dropdown";
import * as s from "./styled-activity-row";

type TActivityRow = {
    activity: TSectionActivity;
    project: TProject;
    archive?: boolean;
    isClosedScope: boolean;
    setDeleteConfirmationActivityId: React.Dispatch<
        React.SetStateAction<string>
    >;
    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    toggleActivityEdit?: (activityId: string) => void;
    handleOpenDetailsActivity: (activity: TSectionActivity) => void;
    handleToggleActivityStatus: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        activityId: string
    ) => void;
    index: number;
    handleStatusModal: (status?: IOptionStatus) => void;
    statusOptions: {
        name: string;
        id_e: string;
        color: string;
        secondary_color: string;
        categoryId: string;
    }[];
    handleDuplicateActivity: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        activityId: string
    ) => void;
    updateActivity: TResponse<unknown, TUpdateActivity>;
    handleArchiveActivity: (activityId: string) => void;
    handleUnarchiveActivity: (activityId: string) => Promise<void>;
};

export const ActivityRow = ({
    archive,
    activity,
    toggleActivityEdit,
    setActivityToAddCollaborators,
    setDeleteConfirmationActivityId,
    handleOpenDetailsActivity,
    isClosedScope,
    index,
    handleToggleActivityStatus,
    handleStatusModal,
    statusOptions,
    handleDuplicateActivity,
    updateActivity,
    handleArchiveActivity,
    handleUnarchiveActivity,
}: TActivityRow) => {
    const priorityMapper = {
        low: "Baixa",
        medium: "Média",
        high: "Alta",
    };

    return (
        <Draggable
            key={activity.id_e}
            index={index}
            draggableId={activity.id_e}
        >
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={snapshot.isDragging ? "dragging" : ""}
                    onClick={() => handleOpenDetailsActivity(activity)}
                >
                    {/* Ícone de Drag e Nome */}
                    <TableCell>
                        <IconButton {...provided.dragHandleProps}>
                            <Icons.Drag />
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        <MuiTooltip
                            title={
                                activity.finished
                                    ? "Marcar como não finalizada"
                                    : "Marcar como finalizada"
                            }
                            arrow
                        >
                            <IconButton
                                onClick={(e) =>
                                    handleToggleActivityStatus(e, activity.id_e)
                                }
                            >
                                {activity.finished ? (
                                    <Icons.IconCheckCircle2
                                        width="24"
                                        height="24"
                                    />
                                ) : (
                                    <Icons.CheckCircle
                                        color={theme.gray300}
                                        width={"24"}
                                        height={"24"}
                                    />
                                )}
                            </IconButton>
                        </MuiTooltip>
                        {activity.name}
                    </TableCell>

                    {/* Profile */}
                    {isClosedScope && (
                        <TableCell>
                            {/* <Collaborators
                                                                        openModal={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            setActivityToAddCollaborators(
                                                                                activity
                                                                            );
                                                                        }}
                                                                        activity={
                                                                            activity
                                                                        }
                                                                    /> */}
                            {activity.Users?.slice(0, 2).map((user) => (
                                <MuiTooltip
                                    key={user.User?.id_e}
                                    title={user.User?.name}
                                >
                                    <s.CollaboratorImage
                                        key={user.User?.id_e}
                                        src={user.User?.avatar ?? FotoUser}
                                    />
                                </MuiTooltip>
                            ))}
                        </TableCell>
                    )}

                    {/* Colaboradores */}
                    <TableCell>
                        <Collaborators
                            openModal={(e) => {
                                e.stopPropagation();
                                setActivityToAddCollaborators(activity);
                            }}
                            activity={activity}
                        />
                    </TableCell>

                    {/* Prazo */}
                    <TableCell>
                        {activity.deadline && activity.created_at ? (
                            <>
                                {dayjs(activity.created_at).format(
                                    "DD/MM/YYYY"
                                )}{" "}
                                - <br />
                                {dayjs(activity.deadline).format("DD/MM/YYYY")}
                            </>
                        ) : (
                            "Sem prazo"
                        )}
                    </TableCell>

                    {/* Prioridade */}
                    <TableCell>
                        <s.Priority type={activity.priority}>
                            <span>•</span>
                            {priorityMapper[activity.priority]}
                        </s.Priority>
                    </TableCell>

                    <TableCell>
                        <StatusDropdown
                            activity={activity}
                            updateActivity={updateActivity}
                            handleStatusModal={handleStatusModal}
                            statusOptions={statusOptions}
                        />
                    </TableCell>
                    {/* Progresso de Horas */}
                    <TableCell>
                        <HourProgress
                            hourProgress={activity.hourProgress}
                            expectedMinutes={activity.expectedMinutes}
                        />
                    </TableCell>
                    {/* Ações */}
                    <TableCell onClick={(e) => e.stopPropagation()}>
                        <PopoverRadix
                            align="end"
                            trigger={
                                <IconButton>
                                    <Icons.More />
                                </IconButton>
                            }
                        >
                            <s.MenuContainer>
                                <s.MenuOption
                                    onClick={() =>
                                        handleOpenDetailsActivity(activity)
                                    }
                                >
                                    <Icons.ExpandContent
                                        color={theme.gray800}
                                    />
                                    Ver detalhes
                                </s.MenuOption>
                                {!archive && (
                                    <s.MenuOption
                                        onClick={() =>
                                            toggleActivityEdit?.(activity.id_e)
                                        }
                                    >
                                        <Icons.PencilOutline
                                            color={theme.gray800}
                                        />
                                        Editar
                                    </s.MenuOption>
                                )}
                                {archive ? (
                                    <s.MenuOption
                                        onClick={() =>
                                            handleUnarchiveActivity(
                                                activity.id_e
                                            )
                                        }
                                    >
                                        <Icons.Unarchive
                                            color={theme.gray600}
                                        />
                                        Desarquivar
                                    </s.MenuOption>
                                ) : (
                                    <s.MenuOption
                                        onClick={() =>
                                            handleArchiveActivity(activity.id_e)
                                        }
                                    >
                                        <Icons.IconArchive
                                            color={theme.gray600}
                                        />
                                        Arquivar
                                    </s.MenuOption>
                                )}
                                {!archive && (
                                    <s.MenuOption
                                        onClick={(e) =>
                                            handleDuplicateActivity(
                                                e,
                                                activity.id_e
                                            )
                                        }
                                    >
                                        <Icons.Duplicate
                                            color={theme.gray600}
                                        />
                                        Duplicar
                                    </s.MenuOption>
                                )}
                                <s.MenuOption
                                    danger
                                    disabled={Boolean(
                                        activity.hourProgress.minutesDone
                                    )}
                                    onClick={() => {
                                        if (
                                            !activity.hourProgress.minutesDone
                                        ) {
                                            setDeleteConfirmationActivityId(
                                                activity.id_e
                                            );
                                        }
                                    }}
                                >
                                    <Icons.Trash
                                        color={
                                            activity.hourProgress.minutesDone
                                                ? theme.gray300
                                                : theme.red
                                        }
                                    />
                                    Deletar
                                </s.MenuOption>
                            </s.MenuContainer>
                        </PopoverRadix>
                    </TableCell>
                </TableRow>
            )}
        </Draggable>
    );
};
