import { yupResolver } from "@hookform/resolvers/yup";
import { AddOutlined, InfoOutlined } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import CustomSelect from "../../../../../../components/molecules/custom-select/custom-select";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { useUser } from "../../../../../../context";
import { useReadBudgetById } from "../../../../../../hooks/react-query/use-read-budget-by-id";
import { useReadBudgetDetailedLevelById } from "../../../../../../hooks/react-query/use-read-budget-detailed-levels";
import { useReadProfile } from "../../../../../../hooks/react-query/use-read-profile";
import { axiosHttpClient } from "../../../../../../services/axios";
import {
    addProfileBudget,
    deleteProfileFromBudget,
    updateProfile,
} from "../../../../../../services/budget";
import {
    NewProfile,
    NewProfileDialog,
} from "../../../../create/third-step/new-profile-dialog";
import { ResponsibleDialog } from "../../../../create/third-step/responsible-dialog";
import { EditProfileDialog, TProfile } from "./edit-profile-dialog";
import ProfileTable from "./profile-table";

const schemaAddProfileBudget = yup.object().shape({
    profile: yup.string(),
    abbreviation: yup.string(),
});

export type NewResponsible = {
    id?: string;
    name?: string;
    avatar?: string;
};

const { REACT_APP_BASE_URL } = process.env;

function ProfileTab() {
    const [isCreationDialogOpen, setIsCreationDialogOpen] =
        useState<boolean>(false);
    const [isOpenEditProfileDialog, setIsOpenEditProfileDialog] =
        useState(false);
    const [selectProfile, setSelectProfile] = useState<undefined | TProfile>();
    const [isResponsibleDialogOpen, setOpenModalResponsible] = useState(false);
    const [responsibles, setResponsibles] = useState<NewResponsible | null>(
        null
    );
    const profileId = useRef<string>();
    const { budgetId } = useParams();
    const { control, setValue, watch, handleSubmit } = useForm({
        defaultValues: {
            profile: "",
            abbreviation: "",
        },
        resolver: yupResolver(schemaAddProfileBudget),
    });
    const { user } = useUser();
    const user_id = user.id_e;
    const params = {
        length: 500,
        page: 1,
        userId: user_id,
    };

    const { data, refetch } = useReadProfile({ ...params });

    const options = data
        ? data.map((profile) => ({
              label: profile.name,
              value: profile.id_e,
          }))
        : [];

    const id = budgetId || "";

    const { data: dataBudgetById, refetch: refetchBudget } = useReadBudgetById({
        budgetId: id,
    });

    const { data: dataBudgetLevelDetails, refetch: refetchLevels } =
        useReadBudgetDetailedLevelById({
            budgetId: id,
            params: {
                length: params.length,
                page: params.page,
            },
        });

    const profiles = dataBudgetById?.BudgetProfiles;

    const addProfile = async (newProfile: NewProfile) => {
        const bodyReq = {
            name: newProfile.name,
            abbreviation: newProfile.abbreviation,
        };

        const tempHeaders = {
            auth: true,
            "x-user-id": user.id_e,
        };

        await axiosHttpClient({
            url: `${REACT_APP_BASE_URL}/budget/profile`,
            method: "post",
            body: bodyReq,
            headers: tempHeaders,
        }).then(async () => {
            await refetch();
            await refetchBudget();
            await refetchLevels();
        });
    };

    const profile = watch("profile");
    const findAbbreviation =
        data?.find((profileData) => profileData.id_e === profile)
            ?.abbreviation ?? "";

    const handleModalResponsible = useCallback(
        (responsibleData?: NewResponsible, projectId?: string) => {
            if (isResponsibleDialogOpen) {
                profileId.current = undefined;
                return setOpenModalResponsible(false);
            }
            profileId.current = projectId;
            setResponsibles(responsibleData || null);

            return setOpenModalResponsible(true);
        },
        [isResponsibleDialogOpen]
    );

    const submit = async (data: FieldValues) => {
        const params = { profileId: data.profile };
        await addProfileBudget({ params, budgetId: id }).then(async () => {
            await refetch();
            await refetchBudget();
            await refetchLevels();
        });
    };

    const handleAddResponsibles = async (
        newResponsible: NewResponsible | null
    ) => {
        if (profileId.current) {
            await updateProfile({
                params: { responsibleId: newResponsible?.id },
                budgetId: id,
                profileId: profileId.current,
            }).then(async () => {
                await refetch();
                await refetchBudget();
                await refetchLevels();
            });
        }
    };

    const handleRemoveProfileFromBudget = async (profileId: string) => {
        await deleteProfileFromBudget({
            budgetId: id,
            budgetProfileId: profileId,
        }).then(async () => {
            await refetch();
            await refetchBudget();
            await refetchLevels();
        });
    };

    const handleOpenEditProfile = (profile: TProfile) => {
        setIsOpenEditProfileDialog((prev) => !prev);
        setSelectProfile(profile);
    };

    const handleEditProfile = async (
        params: {
            name?: string;
            abbreviation?: string;
            costPerHour?: number;
            dependsOn?: string[];
            dependencyPercentage?: string;
            dependencyMode?: string | null;
        },
        profileId: string
    ) => {
        const dataUpdateProfile = {
            params: {
                ...params,
                costPerHour: Number(params.costPerHour),
                dependencyPercentage: Number(params.dependencyPercentage),
            },
            budgetId: id,
            profileId,
        };
        await updateProfile(dataUpdateProfile).then(async () => {
            await refetch();
            await refetchBudget();
            await refetchLevels();
            setIsOpenEditProfileDialog(false);
        });
    };

    const haveProfile = watch("profile").length > 0;

    useEffect(() => {
        setValue("abbreviation", findAbbreviation);
    }, [profile]);

    return (
        <Stack
            sx={{
                gap: "2rem",
            }}
        >
            <Stack
                sx={{
                    p: "1.5rem",
                    gap: "1.5rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                        }}
                    >
                        <Typography variant="h5">Horas de Escopo</Typography>
                        <Tooltip
                            side="bottom"
                            backgroundColor="rgba(97, 97, 97, 0.9)"
                            content={
                                <Box
                                    sx={{
                                        padding: "0.25rem 0.5rem",
                                        maxWidth: "27.5rem",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#FFF",
                                            fontFamily: "Noto Sans",
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "1.25rem",
                                            letterSpacing: "0.0088rem",
                                        }}
                                    >
                                        Perfis de Atividade permitem definir os
                                        profissionais envolvidos em um
                                        orçamento, garantindo um planejamento
                                        detalhado dos custos e
                                        responsabilidades. Esse recurso auxilia
                                        na transparência da alocação de recursos
                                        e otimiza a gestão do orçamento do
                                        projeto. <br />
                                        <br />
                                        Responsável: Define o gestor que será
                                        notificado sobre as horas desse perfil e
                                        ficará encarregado de auxiliar a Estimar
                                        as horas.
                                    </span>
                                </Box>
                            }
                        >
                            <InfoOutlined
                                sx={{
                                    color: "#B93BDC",
                                }}
                            />
                        </Tooltip>
                    </Box>
                    <Button
                        variant="outlined"
                        onClick={() => setIsCreationDialogOpen(true)}
                    >
                        Criar Perfil
                    </Button>
                </Box>
                <form onSubmit={handleSubmit(submit)}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.5rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1.5rem",
                                width: "90%",
                            }}
                        >
                            <CustomSelect
                                control={control}
                                label="Perfil"
                                name="profile"
                                options={options}
                                fullWidth
                            />
                            <CustomTextField
                                control={control}
                                name="abbreviation"
                                label="Sigla"
                                value={findAbbreviation}
                                disabled
                                fullWidth
                            />
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                gap: "0.5rem",
                                p: "0.5rem 1.375rem",
                                width: "10rem",
                            }}
                            type="submit"
                            disabled={!haveProfile}
                        >
                            <AddOutlined /> ADICIONAR
                        </Button>
                    </Box>
                </form>
            </Stack>
            <ProfileTable
                handleRemoveProfileFromBudget={handleRemoveProfileFromBudget}
                handleModalResponsible={handleModalResponsible}
                handleOpenEditProfile={handleOpenEditProfile}
                dataBudgetById={dataBudgetById}
                dataBudgetLevelDetails={dataBudgetLevelDetails?.data}
            />
            <NewProfileDialog
                open={isCreationDialogOpen}
                setOpen={setIsCreationDialogOpen}
                addProfile={addProfile}
            />
            <ResponsibleDialog
                open={isResponsibleDialogOpen}
                onClose={handleModalResponsible}
                addResponsibles={handleAddResponsibles}
                responsibles={responsibles}
                setResponsibles={setResponsibles}
            />
            {isOpenEditProfileDialog && (
                <EditProfileDialog
                    open={isOpenEditProfileDialog}
                    setOpen={setIsOpenEditProfileDialog}
                    handleEditProfile={handleEditProfile}
                    profilesFromBudget={profiles}
                    profile={selectProfile}
                />
            )}
        </Stack>
    );
}

export default ProfileTab;
