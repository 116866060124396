import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TUpdateProfile = {
    budgetId: string;
    profileId: string;
    params: {
        name?: string;
        abbreviation?: string;
        costPerHour?: number;
        responsibleId?: string;
        dependsOn?: string[];
        dependencyPercentage?: number;
        levelTimes?: {
            level: string;
            bestPrediction: number;
            realPrediction: number;
            worstPrediction: number;
        }[];
        dependencyMode?: string | null;
        hoursEstimationStatus?: string;
    };
};

export default async ({ params, budgetId, profileId }: TUpdateProfile) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/profile/${profileId}`,
        method: "patch",
        body: params,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({ body, statusCode });
};
