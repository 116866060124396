import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useState } from "react";
import { Control, Controller, Path } from "react-hook-form";

import { useFetch } from "../../../../hooks";
import { TCreateClient, TGetClient } from "../../../../models";
import createClient, {
    TCreateClientResponse,
} from "../../../../services/clients/create-client";
import { TGetClientResponse } from "../../../../services/clients/get-clients";

export type ClientOption = TGetClientResponse;

type TBudgetClientSelect<T extends Record<string, any>> = {
    clients: ClientOption[];
    refetch: (params?: TGetClient) => void;
    control: Control<T>;
    name: Path<T>;
    error?: boolean;
};

export const ClientSelect = <T extends Record<string, any>>({
    clients,
    refetch,
    control,
    error,
    name,
}: TBudgetClientSelect<T>) => {
    const [inputValue, setInputValue] = useState<string>("");
    const createClientFetch = useFetch<
        TCreateClient,
        TCreateClientResponse,
        any
    >({
        fn: createClient,
        params: { name: inputValue },
        start: false,
        subSequentReqs: [
            {
                fn: () => refetch() as any,
            },
        ],
    });

    const handleCreateClient = async () => {
        if (!inputValue.trim()) return;
        await createClientFetch.onRefresh({ name: inputValue });
        refetch();
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    freeSolo
                    options={
                        inputValue &&
                        !clients.some((opt) => opt.name === inputValue)
                            ? [...clients, { id_e: "new", name: inputValue }]
                            : clients
                    }
                    getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                    }
                    isOptionEqualToValue={(option, value) =>
                        typeof option === "string"
                            ? option === value
                            : option.id_e === value.id_e
                    }
                    value={
                        clients.find((opt) => opt.id_e === field.value) || null
                    }
                    onChange={(_, newValue) => {
                        if (typeof newValue === "string") {
                            setInputValue(newValue);
                        } else if (newValue?.id_e === "new") {
                            handleCreateClient();
                        } else {
                            field.onChange(newValue?.id_e);
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={(_, newInputValue) =>
                        setInputValue(newInputValue)
                    }
                    loading={createClientFetch.loading}
                    renderOption={(props, option) => (
                        <li
                            {...props}
                            key={
                                typeof option === "string"
                                    ? option
                                    : option.id_e
                            }
                        >
                            {option.name}
                            {option.id_e === "new" &&
                                createClientFetch.loading && (
                                    <CircularProgress
                                        size={16}
                                        sx={{ ml: 1 }}
                                    />
                                )}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nome do Cliente*"
                            placeholder="Selecione um Cliente"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: createClientFetch.loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null,
                            }}
                            error={!!error}
                        />
                    )}
                />
            )}
        />
    );
};
