import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Icons, theme } from "../../../../../assets";
import CustomSelect from "../../../../../components/molecules/custom-select/custom-select";
import CustomTextField from "../../../../../components/molecules/custom-textfield/custom-textfield";
import DayPicker from "../../../../../components/molecules/day-picker/day-picker";
import { TProject, TResponse } from "../../../../../models";
import { CreateActivityVariables } from "../../../../../services/activities/types";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import { validatePermittedStrings } from "../../../../../utils/validation";
import { TPriority, TSectionActivity } from "../types";
import { Collaborators } from "./add-collaborators-button/add-collaborators-button";
import { formatPredictedHours } from "./edit-activity";
import * as s from "./styled-components";

type TPriorityOption = {
    value: TPriority;
    label: string;
    color: string;
};

const validationSchema = yup.object().shape({
    activityName: yup
        .string()
        .required("Nome da atividade é obrigatório")
        .min(3, "O nome deve ter no mínimo 3 caracteres")
        .max(150, "O nome deve ter no máximo 150 caracteres"),

    deadline: yup
        .date()
        .typeError("Data inválida")
        .required("O prazo é obrigatório"),

    priority: yup.string(),
    // .shape({
    //     value: yup.string().required("A prioridade é obrigatória"),
    // })
    // .nullable()
    // .required("A prioridade é obrigatória"),

    predictedHours: yup
        .string()
        // .matches(/^\d{1,2}:\d{2}$/, "Formato inválido (HH:MM)")
        .required("Horas previstas são obrigatórias"),
});

type TFields = {
    activityName: string;
    deadline: Date | undefined;
    priority: TPriorityOption;
    predictedHours: string;
};

type TAddActivity = {
    project: TProject;
    createActivity: TResponse<unknown, CreateActivityVariables>;
    sectionId?: string;

    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    activityToAddCollaborators: TSectionActivity | undefined;
    setIsCreating?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddActivity = ({
    sectionId,
    createActivity,
    project,
    setActivityToAddCollaborators,
    activityToAddCollaborators,
    setIsCreating,
}: TAddActivity) => {
    const { handleSubmit, control, reset } = useForm<TFields>({
        resolver: yupResolver(validationSchema),
    });
    const priorityOptions: TPriorityOption[] = [
        {
            value: "low",
            label: "Baixa",
            color: theme.green1,
        },
        {
            value: "medium",
            label: "Média",
            color: theme.orange,
        },
        {
            value: "high",
            label: "Alta",
            color: "#D32F2F",
        },
    ];

    const [isToCreate, setIsToCreate] = useState<boolean>(false);
    const [users, setUsers] = useState<TSectionActivity["Users"]>([]);

    useEffect(() => {
        if (isToCreate) {
            setUsers([]);
        }
    }, [isToCreate]);

    useEffect(() => {
        if (activityToAddCollaborators?.Users) {
            setUsers(activityToAddCollaborators?.Users);
        }
    }, [activityToAddCollaborators]);

    const predictedHoursToMinutes = (
        predictedHours: string
    ): number | undefined => {
        if (!predictedHours) return undefined;
        const [hours, minutes] = predictedHours.split(":");
        return Number(hours) * 60 + Number(minutes);
    };

    const mapData = (data: TFields): CreateActivityVariables => {
        return {
            name: data?.activityName,
            priority: data?.priority.value,
            deadLine: data?.deadline,
            expectedMinutes: predictedHoursToMinutes(data?.predictedHours),
            users: users.map((user) => user.User.id_e),
        };
    };

    const clearData = () => {
        setUsers([]);
        setActivityToAddCollaborators(undefined);
        reset();
    };

    const onSubmit = handleSubmit((data) => {
        const isValid = validatePermittedStrings(data.activityName);
        if (!isValid) return;

        createActivity.onRefresh({
            ...mapData(data),
            sectionId,
            projectId: project.id_e,
        });
    });

    useEffect(() => {
        if (createActivity.response) {
            clearData();
            setIsToCreate(false);
            setIsCreating?.(true);
        }
    }, [createActivity.response]);

    // const formatOptionLabel = (
    //     option: string | TOption | null | undefined,
    //     input: { inputValue: string }
    // ) => {
    //     if (typeof option !== "object") return <p>{input.inputValue}</p>;
    //     const optionValue = priorityOptions.find(
    //         (priority) => priority.value === (option as TPriorityOption).value
    //     );
    //     return (
    //         <s.PriorityOption color={optionValue?.color}>
    //             <span>•</span>
    //             {optionValue?.label}
    //         </s.PriorityOption>
    //     );
    // };

    // const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const inputValue = e.target.value;
    //     const formattedValue = inputValue
    //         .replace(/[^0-9:]/g, "")
    //         .replace(/(\d{3})(\d{2})/, "$1:$2")
    //         .replace(/(:\d{2})\d+?$/, "$1");
    //     return formattedValue;
    // };

    return isToCreate ? (
        <form
            onSubmit={onSubmit}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    setIsToCreate(false);
                    setIsCreating?.(false);
                    reset();
                }
                if (e.key === "Enter") {
                    document.getElementById("save")?.click();
                }
            }}
        >
            <Stack
                sx={{
                    p: "1.5rem",
                    gap: "1.5rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "1.5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            maxWidth: "40%",
                        }}
                    >
                        <CustomTextField
                            control={control}
                            fullWidth
                            ref={(el) => el?.focus()}
                            name="activityName"
                            maxCharacters={150}
                            placeholder="Escreva o nome da atividade"
                        />
                    </Box>
                    <Collaborators
                        key={users.length}
                        openModal={() => {
                            setActivityToAddCollaborators({
                                Users: users,
                            } as never);
                        }}
                        activity={
                            {
                                Users: users,
                            } as never
                        }
                    />

                    <Controller
                        name="deadline"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DayPicker
                                value={value}
                                placeholder="Data"
                                heigth="3.65rem"
                                backgroundColor="transparent"
                                borderColor={theme.gray200}
                                onSelect={(date) => {
                                    onChange(date);
                                }}
                                toYear={new Date().getFullYear() + 1}
                            />
                        )}
                    />

                    <CustomSelect
                        label="Prioridade"
                        control={control}
                        fullWidth
                        name="priority"
                        options={priorityOptions}
                        // formatOptionLabel={formatOptionLabel}
                    />

                    <CustomTextField
                        control={control}
                        name="predictedHours"
                        label="Horas Previstas"
                        fullWidth
                        formatValue={formatPredictedHours}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        onClick={() => {
                            clearData();
                            setIsToCreate(false);
                            setIsCreating?.(false);
                        }}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        id="add"
                        type="submit"
                        loading={createActivity.loading}
                    >
                        SALVAR
                    </Button>
                </Box>
            </Stack>
        </form>
    ) : (
        <s.AddActivityButton
            onClick={() => {
                setIsToCreate(true);
                setIsCreating?.(true);
                Mixpanel.track("Adicionar atividade");
            }}
        >
            <Icons.Plus color={theme.gray800} width="20" height="20" />
            Adicionar atividade
        </s.AddActivityButton>
    );
};
