import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { theme } from "../../../assets";
import { ModalAction } from "../../../components";
import StepsMui from "../../../components/atoms/steps-mui/steps-mui";
import BreadCrumbsMUI from "../../../components/molecules/breadcrumpMUI/breadcrumpMUI";
import { Loading } from "../../../components/molecules/modals/modal-document/styled-modal-document";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { useReadBudgetById } from "../../../hooks/react-query/use-read-budget-by-id";
import { TProject } from "../../../models";
import { projects, sectors, manageUser } from "../../../services";
import { updateProjectClosedScope } from "../../../services/projects";
import { Step1Project, Step2Project } from "./components/project-steps";
import * as s from "./styled-projects-form";

export type IProject = {
    name?: string;
    client?: { label: string; value: string };
    description?: string;
    color?: string;
    start_at?: Date;
    end_at?: Date;
    projectId?: string;
    sectorsId?: string[];
    usersId?: string[];
    reviewersId?: string[];
    scopePercentage?: number;
    cost?: number;
    estimated_hours?: string;
    status?: string;
    technologies?: string;
    userProfiles?: {
        userId: string;
        userProcessProfileIds: string[];
        isReviewer: boolean;
        isBiller: boolean;
        isValidator: boolean;
    }[];
};

type TUser = {
    id_e: string;
    avatar?: string;
    name?: string;
    role?: string;
    Permissions: string[];
};

type TSector = {
    id_e: string;
    Projects?: [
        {
            color?: string;
            id_e?: string;
            name?: string;
        }
    ];
    Users: TUser[];
    created_at?: string;
    description?: string;
    name?: string;
};

const rotes = [
    {
        label: "Projetos",
        href: "/projetos",
    },
];

export const ProjectsForm = () => {
    const { budgetId, projectId } = useParams<{
        budgetId: string;
        projectId: string;
    }>();

    const {
        user: { Permissions: userPerms },
        refreshProject,
    } = useUser();
    const getAllSectors = userPerms.includes("all_sectors");
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const { project }: { project: TProject } = location.state || "";

    const [data, setData] = useState<IProject>();
    const [steps, setSteps] = useState<1 | 2>(1);
    const [done, setIsDone] = useState(false);
    const [sectorsData, setSectorsData] = useState<TSector[]>([]);
    const [linkedSectors, setLinkedSectors] = useState<string[]>([]);
    const [billersData, setBillersData] = useState<TUser[]>([]);
    const [projectTeam, setProjectTeam] = useState<TUser[]>([]);
    const [projectReviewer, setProjectReviewer] = useState<TUser[]>([]);
    const [projectBiller, setProjectBiller] = useState<TUser[]>([]);
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);

    const budgetIdToReq = budgetId ?? "";

    const stepsOptions = ["Dados Iniciais", "Montar equipe"];

    const getSectors = useFetch({
        fn: sectors.getSector,
        start: false,
    });

    const getUsers = useFetch({
        fn: manageUser.getAllUsers,
        start: false,
    });

    const createProject = useFetch({
        fn: projects.createProject,
        start: false,
    });

    const updateProject = useFetch({
        fn: projects.updateProject,
        start: false,
    });

    const deleteProject = useFetch({
        fn: projects.deleteProject,
        start: false,
    });

    const { data: dataBudgetById, isLoading } = useReadBudgetById({
        budgetId: budgetIdToReq,
        start: !!budgetId,
    });

    const handleVerifyColor = (color: string) => {
        let resp = "";
        const colors = [
            "#0B49EC",
            theme.pink1,
            theme.orange,
            "#9D0000",
            "#03BA78",
            theme.green1,
            "#0CB8B8",
            "#FF015C",
            "#9933FF",
            "#824700",
            "#800380",
            "#4B07BF",
            "#D14141",
            "#8BB908",
            "#333333",
        ];

        if (color) resp = color;
        else resp = colors[Math.floor(Math.random() * colors.length)];
        return resp;
    };

    const verifyErrors = () => {
        if (linkedSectors.length < 1) {
            return toast.error(
                "Verifique os dados do setor e tente novamente!"
            );
        }

        if (projectTeam.length < 1) {
            return toast.error("Seu time deve possuir ao menos um membro!");
        }
        if (projectReviewer.length < 1) {
            return toast.error("Seu time deve possuir ao menos um revisor!");
        }

        if (projectBiller.length < 1) {
            return toast.error("Seu time deve possuir ao menos um faturador!");
        }
        return true;
    };

    const storeData = <IProject,>(data: IProject) => {
        setData((prevState) => ({ ...prevState, ...data }));
    };

    const handleDeleteProject = () => {
        deleteProject.onRefresh({
            projectId: project?.id_e,
        });
    };

    const handleSubmitProject = async (operation: "create" | "update") => {
        if (data) {
            if (data.start_at === undefined) {
                data.start_at = new Date();
            }
        }
        const body = {
            name: data?.name,
            clientId:
                data?.client?.value ?? (data?.client as unknown as string),
            description: data?.description,
            color: handleVerifyColor(data?.color ?? theme.gray400),
            estimatedHours: data?.estimated_hours
                ? parseInt(data.estimated_hours, 10)
                : undefined,
            start_at: data?.start_at,
            end_at: data?.end_at,
            sectorsId: linkedSectors?.join(", "),
            scopePercentage: data?.scopePercentage,
            cost: data?.cost?.toString(),
            usersId: projectTeam?.map((col: TUser) => col.id_e).join(", "),
            reviewersId: projectReviewer
                ?.map((rev: TUser) => rev.id_e)
                .join(", "),
            billersId: projectBiller
                ?.map((biller: TUser) => biller.id_e)
                .join(", "),
            status: data?.status,
            technologies: data?.technologies,
        };

        const closedScopeBody = {
            ...body,
            usersId: undefined,
            reviewersId: undefined,
            billersId: undefined,
            clientId: undefined,
            estimatedHours: undefined,
            start_at: undefined,
            end_at: undefined,
            sectorsId: undefined,
            cost: undefined,
            userProfiles: data?.userProfiles,
        };

        switch (operation) {
            case "create":
                if (budgetId) {
                    await updateProjectClosedScope({
                        projectId: projectId ?? "",
                        params: closedScopeBody,
                    });
                    toast.success("Projeto criado com sucesso!");
                    window.scrollTo(0, 0);
                    return navigate("/projetos");
                }
                return createProject.onRefresh(body);

            case "update":
                return updateProject.onRefresh({
                    name: data?.name,
                    clientId:
                        data?.client?.value ??
                        (data?.client as unknown as string),
                    description: data?.description,
                    color: data?.color,
                    start_at: data?.start_at,
                    end_at: data?.end_at,
                    scopePercentage: data?.scopePercentage,
                    cost: data?.cost?.toString(),
                    projectId: project?.id_e,
                    estimatedHours: data?.estimated_hours
                        ? parseInt(data.estimated_hours, 10)
                        : undefined,
                    sectorsId: linkedSectors?.join(", "),
                    usersId: projectTeam?.map((t) => t.id_e).join(", "),
                    reviewersId: projectReviewer
                        ?.map((rev: TUser) => rev.id_e)
                        .join(", "),
                    billersId: projectBiller
                        ?.map((biller: TUser) => biller.id_e)
                        .join(", "),
                });

            default:
                toast.success(`Operação ${operation} inválida!`);
        }
        return setIsDone(false);
    };

    useEffect(() => {
        getSectors.onRefresh({
            all: getAllSectors,
            users: true,
            projects: true,
        });
        getUsers.onRefresh({
            searchPermission: "bill_reports",
        });
    }, []);

    useEffect(() => {
        if (done) {
            setLoading(true);
            if (verifyErrors() === true) handleSubmitProject("create");
            setTimeout(() => setLoading(false), 2000);
        }
    }, [done]);

    useEffect(() => {
        if (getSectors.error) toast.error(`${getSectors.error}`);
        if (getSectors.response) {
            setSectorsData(getSectors.response.sectors);
        }
    }, [getSectors.response]);

    useEffect(() => {
        if (getUsers.error) toast.error(`${getUsers.error}`);
        if (getUsers.response) {
            setBillersData(getUsers.response);
        }
    }, [getUsers.response]);

    useEffect(() => {
        if (createProject.error) toast.error(`${createProject.error?.message}`);
        if (createProject.response) {
            toast.success("Projeto criado com sucesso!");
            window.scrollTo(0, 0);
            navigate("/projetos");
            refreshProject({
                sectors: true,
                showOnlyActiveProjects: true,
            });
        }
    }, [createProject.response]);

    useEffect(() => {
        if (updateProject.error) toast.error(`${updateProject.error?.message}`);
        if (updateProject.response) {
            toast.success("Projeto atualizado com sucesso!");
            window.scrollTo(0, 0);
            navigate("/projetos");
            refreshProject({
                sectors: true,
                showOnlyActiveProjects: true,
            });
        }
    }, [updateProject.response]);

    useEffect(() => {
        if (deleteProject.error) toast.error(`${deleteProject.error?.message}`);
        if (deleteProject.response) {
            toast.success("Projeto deletado com sucesso!");
            setShowDialogDelete(false);
            window.scrollTo(0, 0);
            navigate("/projetos");
        }
    }, [deleteProject.response]);

    if (budgetId && isLoading) return <Loading />;

    return (
        <s.Container>
            <Stack sx={{ gap: "1rem" }}>
                <BreadCrumbsMUI
                    breadcrumpRotes={rotes}
                    currentRote="Criar Projeto"
                />

                <Typography
                    variant="h3"
                    sx={{
                        color: "rgba(62, 55, 78, 0.87)",

                        fontFamily: "Noto Sans",
                        fontSize: "2.125rem",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "2.9375rem",
                        letterSpacing: "0.0053rem",
                    }}
                >
                    Novo Projeto
                </Typography>
            </Stack>

            <StepsMui stepsOptions={stepsOptions} step={steps} />

            <div style={{ display: steps === 1 ? "block" : "none" }}>
                <Step1Project
                    storeData={storeData}
                    manageSteps={setSteps}
                    dataBudgetById={dataBudgetById}
                />
            </div>

            <div style={{ display: steps === 2 ? "block" : "none" }}>
                <Step2Project
                    storeData={storeData}
                    manageSteps={setSteps}
                    sectorsData={sectorsData}
                    billersData={billersData}
                    loading={loading}
                    setLinkedSectors={setLinkedSectors}
                    setProjectTeam={setProjectTeam}
                    setProjectReviewer={setProjectReviewer}
                    setProjectBiller={setProjectBiller}
                    projectTeam={projectTeam}
                    projectReviewer={projectReviewer}
                    projectBiller={projectBiller}
                    dataBudgetById={dataBudgetById}
                    setIsDone={setIsDone}
                />
            </div>
            {showDialogDelete && (
                <ModalAction
                    setOpen={setShowDialogDelete}
                    title={"Deseja mesmo deletar o projeto?"}
                    description={"Essa ação não pode ser revertida"}
                    primaryAction={handleDeleteProject}
                    secondaryAction={() => setShowDialogDelete(false)}
                    primaryText={"Cancelar"}
                    secondaryText={"Deletar"}
                />
            )}
        </s.Container>
    );
};
