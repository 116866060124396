import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TUpdateSection = {
    name?: string;
    position?: number;
    parentId?: string;
    archiveSection?: boolean;
    desarquiveSection?: boolean;
    sectionId?: string;
};

export default async ({
    name,
    position,
    archiveSection,
    desarquiveSection,
    parentId,
    sectionId,
}: TUpdateSection = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/section/update`,
        method: "put",
        body: {
            name,
            position,
            archiveSection,
            desarquiveSection,
            parentId,
        },
        headers: {
            "x-section-id": sectionId,
            auth: true,
        },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
