import { useQuery } from "@tanstack/react-query";

import { readBudgetComment } from "../../services/budget";
import { TReadBudgetCommentResponse } from "../../services/budget/read-budget-comment";

type ReadBudgetCommentParams = {
    budgetId: string;
};

export function useReadBudgetComment({ budgetId }: ReadBudgetCommentParams) {
    return useQuery<
        TReadBudgetCommentResponse,
        unknown,
        TReadBudgetCommentResponse
    >({
        enabled: true,
        queryKey: [`budget/${budgetId}/comment`],
        queryFn: () => readBudgetComment({ budgetId }),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
