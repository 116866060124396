import {
    ArrowDownwardOutlined,
    Delete,
    InboxTwoTone,
    MoreVertTwoTone,
} from "@mui/icons-material";
import {
    IconButton,
    ListItemIcon,
    MenuItem,
    Popover,
    Typography,
} from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Tag from "../../../components/atoms/tag/tag";
import {
    arquiveBudget,
    deleteBudget,
    unarquiveBudget,
} from "../../../services/budget";
import { formatCurrency } from "../../organization/project/tab-costs/utils";
import * as s from "./styled-tab-budget";

function formatHoursToTime(hours: number): string {
    const wholeHours = Math.floor(hours); // Parte inteira das horas
    const decimalPart = hours - wholeHours; // Parte decimal das horas
    const minutes = Math.round((decimalPart * 100) / 6); // Conversão especial dos minutos

    // Se os minutos forem 60 ou mais, ajustar a hora e os minutos
    if (minutes >= 60) {
        return `${wholeHours + 1}:${String(minutes - 60).padStart(2, "0")}`;
    }

    return `${wholeHours}:${String(minutes).padStart(2, "0")}`;
}

export type DisplayedBudgetStatus =
    | "Andamento"
    | "Aprovado"
    | "Aguardando"
    | "Reprovado";

type BudgetForTable = {
    id: string;
    name: string;
    created_at: string;
    status: string;
    client: string;
    hours?: number;
    cost?: number;
    type: string;
    color: string;
    secondary_color: string;
    color_status: string;
    isArchived: boolean;
};

type TBudgetTable = {
    budgets: BudgetForTable[];
    refetchBudget: () => void;
};

const ArrowdownStyle = {
    width: "1.125rem",
    heigth: "1.125rem",
};

export const BudgetTable = ({ budgets, refetchBudget }: TBudgetTable) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [sortConfig, setSortConfig] = useState<{
        key: string;
        direction: "asc" | "desc";
    } | null>({ key: "created_at", direction: "asc" });
    const [selectedBudget, setSelectedBudget] = useState<string | null>(null);

    const navigate = useNavigate();
    const handleRedirectToBudgetModules = (id: string) =>
        navigate(`/financial/budget/modules/${id}`);

    const handleOpen = (
        event: React.MouseEvent<HTMLElement>,
        budgetId: string
    ) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedBudget(budgetId);
    };

    const handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        setAnchorEl(null);
        setSelectedBudget(null);
    };

    const handleSort = (key: string) => {
        setSortConfig((prev) => ({
            key,
            direction:
                prev?.key === key && prev.direction === "asc" ? "desc" : "asc",
        }));
    };

    const handleArquiveBudget = async () => {
        if (selectedBudget) {
            await arquiveBudget({ budgetId: selectedBudget });
            await refetchBudget();
            toast.success("Orçamento arquivado com sucesso!");
        }
    };

    const handleUnarquiveBudget = async () => {
        if (selectedBudget) {
            await unarquiveBudget({ budgetId: selectedBudget });
            await refetchBudget();
            toast.success("Orçamento desarquivado com sucesso!");
        }
    };

    const hadleDeleteBudget = async () => {
        if (selectedBudget) {
            await deleteBudget({ budgetId: selectedBudget });
            await refetchBudget();
            toast.success("Orçamento deletado com sucesso!");
        }
    };

    const sortedBudgets = [...budgets].sort((a, b) => {
        if (!sortConfig) return 0;
        const { key, direction } = sortConfig;

        const valueA = a[key as keyof typeof a];
        const valueB = b[key as keyof typeof a];

        if (typeof valueA === "number" && typeof valueB === "number") {
            return direction === "asc" ? valueA - valueB : valueB - valueA;
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
            return direction === "asc"
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
        }

        return 0;
    });

    return (
        <>
            <s.Table>
                <tr>
                    <th>Nome</th>
                    <th>
                        Data{" "}
                        <IconButton
                            sx={{ p: "0.0625rem" }}
                            onClick={() => handleSort("created_at")}
                        >
                            <ArrowDownwardOutlined
                                sx={{
                                    ...ArrowdownStyle,
                                    transform:
                                        sortConfig?.key === "created_at" &&
                                        sortConfig.direction === "asc"
                                            ? "rotate(180deg)"
                                            : "none",
                                    transition: "0.2s",
                                }}
                            />
                        </IconButton>
                    </th>
                    <th>Status</th>
                    <th>
                        Horas{" "}
                        <IconButton
                            sx={{ p: "0.0625rem" }}
                            onClick={() => handleSort("hours")}
                        >
                            <ArrowDownwardOutlined
                                sx={{
                                    ...ArrowdownStyle,
                                    transform:
                                        sortConfig?.key === "hours" &&
                                        sortConfig.direction === "asc"
                                            ? "rotate(180deg)"
                                            : "none",
                                    transition: "0.2s",
                                }}
                            />
                        </IconButton>
                    </th>
                    <th>
                        Custo{" "}
                        <IconButton
                            sx={{ p: "0.0625rem" }}
                            onClick={() => handleSort("cost")}
                        >
                            <ArrowDownwardOutlined
                                sx={{
                                    ...ArrowdownStyle,
                                    transform:
                                        sortConfig?.key === "cost" &&
                                        sortConfig.direction === "asc"
                                            ? "rotate(180deg)"
                                            : "none",
                                    transition: "0.2s",
                                }}
                            />
                        </IconButton>
                    </th>
                    <th>Cliente</th>
                    <s.CenteredTableHead>Tipo</s.CenteredTableHead>
                    <th></th>
                </tr>
                {sortedBudgets.map((budget) => {
                    return (
                        <s.StyledRow
                            onClick={() =>
                                handleRedirectToBudgetModules(budget.id)
                            }
                        >
                            <td>
                                <Tag
                                    maxWidth="14.75rem"
                                    color={budget.color}
                                    isFavorite={false}
                                >
                                    {budget.name}
                                </Tag>
                            </td>
                            <td>{budget.created_at}</td>
                            <td>
                                <s.StatusText color={budget.color_status}>
                                    {budget.status}
                                </s.StatusText>
                            </td>
                            <td>
                                {formatHoursToTime(
                                    Number(budget?.hours?.toFixed(0)) ?? 0
                                )}
                            </td>
                            <td>
                                {formatCurrency(
                                    String(budget.cost?.toFixed(2))
                                )}
                            </td>
                            <td>{budget.client}</td>
                            <s.CenteredTableCell>
                                {budget.type}
                            </s.CenteredTableCell>
                            <td>
                                <IconButton
                                    onClick={(e) => handleOpen(e, budget.id)}
                                >
                                    <MoreVertTwoTone />
                                </IconButton>
                            </td>
                            {/* Popover de Ações */}
                            <Popover
                                open={
                                    Boolean(anchorEl) &&
                                    selectedBudget === budget.id
                                }
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                {/* <MenuItem
                        onClick={() => console.log("Duplicar", selectedBudget)}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="body1">
                            Duplicar Orçamento
                        </Typography>
                        <ListItemIcon
                            sx={{
                                p: "0.5rem",
                            }}
                        >
                            <ContentCopy fontSize="small" />
                        </ListItemIcon>
                    </MenuItem> */}
                                <MenuItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        hadleDeleteBudget();
                                    }}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography variant="body1">
                                        Excluir Orçamento
                                    </Typography>
                                    <ListItemIcon
                                        sx={{
                                            p: "0.5rem",
                                        }}
                                    >
                                        <Delete
                                            fontSize="small"
                                            sx={{ color: "red" }}
                                        />
                                    </ListItemIcon>
                                </MenuItem>
                                {budget.isArchived ? (
                                    <MenuItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleUnarquiveBudget();
                                        }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography variant="body1">
                                            Desarquivar Orçamento
                                        </Typography>
                                        <ListItemIcon
                                            sx={{
                                                p: "0.5rem",
                                            }}
                                        >
                                            <InboxTwoTone fontSize="small" />
                                        </ListItemIcon>
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleArquiveBudget();
                                        }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography variant="body1">
                                            Arquivar Orçamento
                                        </Typography>
                                        <ListItemIcon
                                            sx={{
                                                p: "0.5rem",
                                            }}
                                        >
                                            <InboxTwoTone fontSize="small" />
                                        </ListItemIcon>
                                    </MenuItem>
                                )}
                            </Popover>
                        </s.StyledRow>
                    );
                })}
            </s.Table>
        </>
    );
};
