import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { theme } from "../../../../assets";
import ColorPicker, {
    PickableColor,
} from "../../../../components/atoms/color-picker/color-picker";
import CustomTextField from "../../../../components/molecules/custom-textfield/custom-textfield";
import { useFetch } from "../../../../hooks";
import { TGetClient, TOption } from "../../../../models";
import readBudgetTypes, {
    TReadBudgetTypes,
    TReadBudgetTypesResponse,
} from "../../../../services/budget/type/read-budget-types";
import { getClients } from "../../../../services/clients";
import { TGetClientResponse } from "../../../../services/clients/get-clients";
import { StepData } from "../form-screen";
import * as s from "../styled-form-screen";
import { ClientOption, ClientSelect } from "./client-select";
import { TypeSelect } from "./type-select";

export type FirstStepData = {
    name: string;
    budgetTypeId: string;
    color: PickableColor;
    secondaryColor: string;
    clientId: string;
    description: string;
};
type BudgetTypeOption = TReadBudgetTypesResponse & {
    options: TOption[];
};

const budgetSchema = yup.object().shape({
    name: yup.string().required("O nome do orçamento é obrigatório."),
    type: yup.string().required("O tipo de orçamento é obrigatório."),
    color: yup.string().required("A cor do orçamento é obrigatória."),
    client: yup.string().required("O cliente é obrigatório."),
    description: yup
        .string()
        .nullable()
        .transform((value) => (value === "" ? null : value))
        .notRequired(),
});

type FormValues = {
    color: PickableColor;
    name: string;
    description: string | null;
    client: string;
    type: string;
};

type TFindStep = {
    nextStep: (currentData: StepData) => void;
    fullData: StepData;
};

const FirstStep = ({ fullData, nextStep }: TFindStep) => {
    const navigate = useNavigate();
    const handleBack = () => navigate("/financial");

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        watch,
    } = useForm<FormValues>({
        defaultValues: {
            name: fullData?.name ?? "",
            type: fullData.budgetTypeId ?? "",
            color: fullData?.color,
            client: fullData?.clientId ?? "",
            description: fullData?.description ?? null,
        },
        resolver: yupResolver(budgetSchema),
        mode: "onChange",
    });
    const [budgetTypes, setBudgetTypes] = useState<BudgetTypeOption[]>([]);
    const [clients, setClients] = useState<ClientOption[]>([]);

    const readBudgetTypeFetch = useFetch<
        TReadBudgetTypes,
        TReadBudgetTypesResponse[],
        any
    >({
        fn: readBudgetTypes,
        params: {},
    });

    const getClientsFetch = useFetch<TGetClient, TGetClientResponse[], any>({
        fn: getClients,
        params: { active: true },
    });

    const getSecondaryColor = (color: PickableColor): string => {
        const SECONDARY_COLOR_MAP = {
            "#03BA78": "#a9d4c4",
            "#0B49EC": "#abc2ff",
            "#0CB8B8": "#b5ffff",
            "#278027": theme.lightGreen,
            "#333333": "#d1d1d1",
            "#4B07BF": "#cbb3f5",
            "#800380": "#b594b5",
            "#824700": "#e8c090",
            "#8BB908": "#cee09b",
            "#9933FF": theme.purple100,
            "#9D0000": "#e8a7a7",
            "#D14141": theme.softRed,
            "#DD04DD": theme.pink200,
            "#F28500": "#e0caaf",
            "#FF015C": theme.pink100,
        } satisfies Record<PickableColor, string>;

        return SECONDARY_COLOR_MAP[color];
    };

    const submit = (data: FormValues) => {
        const currentData = {
            name: data.name,
            budgetTypeId: data.type,
            color: data.color,
            secondaryColor: getSecondaryColor(data.color),
            clientId: data.client,
            description: data.description !== null ? data.description : "",
            levelConfigs: fullData.levelConfigs,
        };

        nextStep(currentData);
    };

    const handleSelectColor = (color: PickableColor) => {
        setValue("color", color, { shouldDirty: true, shouldValidate: true });
    };

    useEffect(() => {
        if (readBudgetTypeFetch.response) {
            const responseWithOptions: BudgetTypeOption[] =
                readBudgetTypeFetch.response.map(
                    (bt) =>
                        ({
                            ...bt,
                            options: [
                                {
                                    label: bt.name,
                                    value: bt.id_e,
                                },
                            ],
                        } satisfies BudgetTypeOption)
                );

            setBudgetTypes(responseWithOptions);
        }
    }, [readBudgetTypeFetch.response]);

    useEffect(() => {
        if (getClientsFetch.response) {
            const responseWithOptions: ClientOption[] =
                getClientsFetch.response.map(
                    (bt) =>
                        ({
                            ...bt,
                            options: [
                                {
                                    label: bt.name,
                                    value: bt.id_e,
                                },
                            ],
                        } satisfies BudgetTypeOption)
                );

            setClients(responseWithOptions);
        }
    }, [getClientsFetch.response]);

    return (
        <form
            style={{
                width: "100%",
            }}
            onSubmit={handleSubmit(submit)}
        >
            <Stack
                sx={{
                    width: "100%",
                    gap: "1.2rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "1.2rem",
                    }}
                >
                    <s.SelectContainer className="half">
                        <CustomTextField
                            control={control}
                            name="name"
                            label="Nome do Orçamento*"
                            fullWidth
                        />
                    </s.SelectContainer>
                    <s.SelectContainer className="half">
                        <TypeSelect
                            control={control}
                            name="type"
                            budgetTypes={budgetTypes}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            refetch={readBudgetTypeFetch.onRefresh}
                            error={!!errors?.type?.message}
                        />
                        {errors?.type?.message && (
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    position: "absolute",
                                    bottom: "-1.5rem",
                                    fontSize: "0.75rem",
                                    marginLeft: "0.875rem",
                                    marginRight: "0.875rem",
                                    color: "#d32f2f",
                                }}
                            >
                                {errors?.type?.message}
                            </Typography>
                        )}
                    </s.SelectContainer>
                </Box>

                <s.SelectContainer>
                    {clients && (
                        <ClientSelect
                            control={control as any}
                            name="client"
                            clients={clients}
                            refetch={getClientsFetch.onRefresh}
                        />
                    )}
                </s.SelectContainer>

                <CustomTextField
                    control={control}
                    name="description"
                    label="Descrição do Orçamento"
                    placeholder="Digite aqui uma pequena descrição sobre este projeto"
                    fullWidth
                />

                <span>
                    Defina a cor da tag do seu projeto selecionando um dos
                    quadrados de cores*
                </span>

                <Box
                    sx={{
                        position: "relative",
                    }}
                >
                    <ColorPicker
                        onSelect={handleSelectColor}
                        title="Escolher a cor da tag"
                        value={watch("color")}
                    />
                    {errors?.color?.message && (
                        <Typography
                            variant="subtitle1"
                            sx={{
                                position: "absolute",
                                bottom: "-1.5rem",
                                fontSize: "0.75rem",
                                marginLeft: "0.875rem",
                                marginRight: "0.875rem",
                                color: "#d32f2f",
                            }}
                        >
                            {errors?.color?.message}
                        </Typography>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        gap: "1rem",
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            border: "0px",
                            color: theme.purple600,
                            fontFamily: "revert",
                            "&:hover": {
                                border: 0,
                                background: "transparent",
                            },
                        }}
                        onClick={handleBack}
                    >
                        VOLTAR
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!isValid}
                        sx={{
                            backgroundColor: theme.purple500,
                            "&:hover": {
                                backgroundColor: theme.purple600,
                            },
                        }}
                    >
                        AVANÇAR
                    </Button>
                </Box>
            </Stack>
        </form>
    );
};

export { FirstStep };
