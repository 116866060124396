/* eslint-disable no-restricted-syntax */
import { Draggable, DraggableLocation, Droppable } from "@hello-pangea/dnd";
import {
    IconButton,
    TableCell,
    TableRow,
    Tooltip as MuiTooltip,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
    UseQueryResult,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";

import { FotoUser, Icons, theme } from "../../../../../../assets";
import { Collapse, PopoverRadix } from "../../../../../../components";
import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import { TProject, TResponse } from "../../../../../../models";
import { TArchiveActivity } from "../../../../../../services/activities/archive-activity";
import { TArchiveSection } from "../../../../../../services/activities/archive-section";
import { TDuplicateActivity } from "../../../../../../services/activities/duplicate-activity";
import { TProjectCategoryStatusResponse } from "../../../../../../services/activities/read-category-status";
import { TToggleActivityStatus } from "../../../../../../services/activities/toggle-activity-status";
import { CreateActivityVariables } from "../../../../../../services/activities/types";
import { TUnarchiveActivity } from "../../../../../../services/activities/unarchive-activity";
import { TUnarchiveSection } from "../../../../../../services/activities/unarchive-section";
import { TUpdateActivity } from "../../../../../../services/activities/update-activity";
import { TUpdateSection } from "../../../../../../services/activities/update-section";
import { ActivityDetailsDialog } from "../../dialogs/activity-details";
import { TActivityIdsRef, TSection, TSectionActivity } from "../../types";
import { ActivityRow } from "../activity-row/activity-row";
import ActivityTable from "../activity-row/activity-table";
import * as s from "../activity-row/styled-activity-row";
import { AddActivity } from "../add-activity";
import { Collaborators } from "../add-collaborators-button/add-collaborators-button";
import { EditActivity } from "../edit-activity";
import { EditSection } from "../edit-section";
import { HourProgress } from "../hour-progress/hour-progress";
import { StatusDialog } from "../status-dialog/status-dialog";
import StatusDropdown, {
    IOptionStatus,
} from "../status-dropdown/status-dropdown";
import { SectionHeader } from "./section-header/section-header";

function calculateProgress(section: TSection): {
    minutesDone: number;
    expectedMinutes: number;
} {
    let totalMinutesDone = 0;
    let totalExpectedMinutes = 0;

    const activities: TSectionActivity[] = Array.isArray(section.Activities)
        ? section.Activities
        : Object.values(section.Activities ?? {});

    for (const activity of activities) {
        totalMinutesDone += activity.hourProgress?.minutesDone ?? 0;
        totalExpectedMinutes += activity.expectedMinutes ?? 0;
    }

    if (section.Sections && section.Sections.length > 0) {
        for (const subSection of section.Sections) {
            const subProgress = calculateProgress(subSection);
            totalMinutesDone += subProgress.minutesDone;
            totalExpectedMinutes += subProgress.expectedMinutes;
        }
    }

    const percentageOfHoursDone =
        totalExpectedMinutes > 0
            ? (totalMinutesDone / totalExpectedMinutes) * 100
            : 0;

    section.hourProgress = {
        minutesDone: totalMinutesDone,
        percentageOfHoursDone,
    };
    section.expectedMinutes = totalExpectedMinutes;

    return {
        minutesDone: totalMinutesDone,
        expectedMinutes: totalExpectedMinutes,
    };
}

type TSectionProps = {
    sectionName?: string;
    archive?: boolean;
    sections?: TSection[];
    updateActivity: TResponse<unknown, TUpdateActivity>;
    updateSection: TResponse<unknown, TUpdateSection>;
    unarchiveActivity: TResponse<unknown, TUnarchiveActivity>;
    archiveActivity: TResponse<unknown, TArchiveActivity>;
    createActivity: TResponse<unknown, CreateActivityVariables>;
    toggleActivityStatus: TResponse<unknown, TToggleActivityStatus>;
    archiveSection?: TResponse<unknown, TArchiveSection>;
    unarchiveSection?: TResponse<unknown, TUnarchiveSection>;
    duplicateActivity?: TResponse<unknown, TDuplicateActivity>;
    activitiesWithoutSection?: TSectionActivity[];
    activityToAddCollaborators: TSectionActivity | undefined;
    setDeleteConfirmationSectionId: React.Dispatch<
        React.SetStateAction<string>
    >;
    project: TProject;
    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    setDeleteConfirmationActivityId: React.Dispatch<
        React.SetStateAction<string>
    >;
    updateActivityStates: string[];
    setUpdateActivityStates: React.Dispatch<React.SetStateAction<string[]>>;
    openedSectionsIds: string[];
    setOpenedSectionsIds: React.Dispatch<React.SetStateAction<string[]>>;
    activitiesIdsRef?: TActivityIdsRef;
    getSections: UseQueryResult<any, unknown>;
    draggingDestination?: DraggableLocation | null | undefined;
    isClosedScope: boolean;
    statusData?: TProjectCategoryStatusResponse;
    refetchStatus: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TProjectCategoryStatusResponse, unknown>>;
};

export const Sections = ({
    sections,
    project,
    updateActivity,
    updateSection,
    unarchiveActivity,
    archiveActivity,
    createActivity,
    activityToAddCollaborators,
    setActivityToAddCollaborators,
    setDeleteConfirmationActivityId,
    setDeleteConfirmationSectionId,
    toggleActivityStatus,
    archiveSection,
    unarchiveSection,
    archive,
    activitiesWithoutSection,
    sectionName,
    duplicateActivity,
    updateActivityStates,
    setUpdateActivityStates,
    activitiesIdsRef,
    openedSectionsIds,
    setOpenedSectionsIds,
    getSections,
    draggingDestination,
    isClosedScope,
    statusData,
    refetchStatus,
}: TSectionProps) => {
    const [activity, setActicity] = useState<TSectionActivity>();
    const [activityDetailsOpen, setActivityDetailsOpen] =
        useState<boolean>(false);
    const [updateSectionStates, setUpdateSectionStates] = useState<boolean[]>(
        []
    );
    const [isCreatingActivity, setIsCreatingActivity] =
        useState<boolean>(false);
    const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
    const [editStatus, setEditStatus] = useState<IOptionStatus>();
    const [sortCriteria, setSortCriteria] = useState({
        key: undefined as keyof TSectionActivity | undefined,
        order: undefined as "asc" | "desc" | undefined,
    });

    const toggleSectionVisibility = (sectionId: string) => {
        if (openedSectionsIds.includes(sectionId)) {
            setOpenedSectionsIds(
                openedSectionsIds.filter((id) => id !== sectionId)
            );
            return;
        }
        setOpenedSectionsIds([...openedSectionsIds, sectionId]);
    };

    const toggleSectionEdit = (index: number) => {
        const newUpdateSectionStates = [...updateSectionStates];
        newUpdateSectionStates[index] = !newUpdateSectionStates[index];
        setUpdateSectionStates(newUpdateSectionStates);
    };

    const handleUnarchiveActivity = async (activityId: string) => {
        unarchiveActivity.onRefresh({
            activityIds: [activityId],
            projectId: project.id_e,
        });
    };

    const handleArchiveActivity = (activityId: string) => {
        archiveActivity.onRefresh({
            activityIds: [activityId],
            projectId: project.id_e,
        });
    };

    const handleToggleActivityStatus = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        activityId: string
    ) => {
        e.stopPropagation();
        toggleActivityStatus.onRefresh({
            activityId,
        });
    };

    const handleDuplicateActivity = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        activityId: string
    ) => {
        e.stopPropagation();
        duplicateActivity?.onRefresh({
            activityId,
        });
    };

    const handleOpenDetailsActivity = (activity: TSectionActivity) => {
        setActicity(activity);
        setActivityDetailsOpen(true);
    };

    const toggleActivityEdit = (activityId: string) => {
        setUpdateActivityStates((prev) => {
            if (prev.includes(activityId)) {
                return prev.filter((id) => id !== activityId);
            }
            return [...prev, activityId];
        });
    };

    const handleDialog = useCallback(() => {
        setIsOpenModalStatus((prev) => !prev);
    }, [isOpenModalStatus]);

    const handleStatusModal = (status?: IOptionStatus) => {
        setEditStatus(status);
        handleDialog();
    };

    function processSections(sections: TSection[]): TSection[] {
        const result = sections.map((section) => {
            calculateProgress(section);
            return section;
        });
        return result;
    }

    const sectionsToMap = sections ? processSections(sections) : [];

    const statusOptions =
        statusData?.data?.flatMap((category) =>
            category.ActivityStatuses.map((status) => ({
                name: status.name,
                id_e: status.id_e,
                color: status.color,
                secondary_color: status.secondary_color,
                categoryId: status.activityStatusCategory_id_e,
            }))
        ) ?? [];

    const priorityMapper: Record<string, string> = {
        low: "Baixa",
        medium: "Média",
        high: "Alta",
    };

    const generateActivitys = (section: TSection) => {
        const getSortedData = () => {
            const activitysToMap = Object.values(section?.Activities) ?? [];

            if (!sortCriteria.key || !sortCriteria.order) {
                return activitysToMap;
            }

            return [...activitysToMap].sort((a, b) => {
                const aValue = a[sortCriteria.key!] ?? 0;
                const bValue = b[sortCriteria.key!] ?? 0;

                if (aValue < bValue)
                    return sortCriteria.order === "asc" ? -1 : 1;
                if (aValue > bValue)
                    return sortCriteria.order === "asc" ? 1 : -1;
                return 0;
            });
        };

        const handleSort = (
            key: keyof TSectionActivity,
            order: "asc" | "desc"
        ) => {
            setSortCriteria({ key, order });
        };

        const sortedData = getSortedData();

        return (
            <>
                <ActivityTable
                    onSort={handleSort}
                    isClosedScope={isClosedScope}
                    sortCriteria={sortCriteria}
                >
                    <Droppable
                        droppableId={`activitysFrom${section.id_e}`}
                        type="activity"
                    >
                        {(provided) => {
                            return (
                                <>
                                    <tbody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {sortedData.map((activity, index) => {
                                            const isEditActivity =
                                                updateActivityStates.some(
                                                    (editActicity) =>
                                                        editActicity ===
                                                        activity.id_e
                                                );

                                            if (isEditActivity)
                                                return (
                                                    <EditActivity
                                                        key={activity.id_e}
                                                        updateActivity={
                                                            updateActivity
                                                        }
                                                        activity={activity}
                                                        sectionId={section.id_e}
                                                        setUpdateActivityStates={
                                                            setUpdateActivityStates
                                                        }
                                                        updateActivityStates={
                                                            updateActivityStates
                                                        }
                                                        setActivityToAddCollaborators={
                                                            setActivityToAddCollaborators
                                                        }
                                                        activityToAddCollaborators={
                                                            activityToAddCollaborators
                                                        }
                                                    />
                                                );
                                            return (
                                                <Draggable
                                                    key={activity.id_e}
                                                    index={index}
                                                    draggableId={activity.id_e}
                                                >
                                                    {(provided, snapshot) => (
                                                        <TableRow
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={
                                                                snapshot.isDragging
                                                                    ? "dragging"
                                                                    : ""
                                                            }
                                                            onClick={() =>
                                                                handleOpenDetailsActivity(
                                                                    activity
                                                                )
                                                            }
                                                        >
                                                            {/* Ícone de Drag e Nome */}
                                                            <TableCell>
                                                                <IconButton
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Icons.Drag />
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MuiTooltip
                                                                    title={
                                                                        activity.finished
                                                                            ? "Marcar como não finalizada"
                                                                            : "Marcar como finalizada"
                                                                    }
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            handleToggleActivityStatus(
                                                                                e,
                                                                                activity.id_e
                                                                            )
                                                                        }
                                                                    >
                                                                        {activity.finished ? (
                                                                            <Icons.IconCheckCircle2
                                                                                width="24"
                                                                                height="24"
                                                                            />
                                                                        ) : (
                                                                            <Icons.CheckCircle
                                                                                color={
                                                                                    theme.gray300
                                                                                }
                                                                                width={
                                                                                    "24"
                                                                                }
                                                                                height={
                                                                                    "24"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </IconButton>
                                                                </MuiTooltip>
                                                                {activity.name}
                                                            </TableCell>

                                                            {/* Profile */}
                                                            {isClosedScope && (
                                                                <TableCell>
                                                                    {activity.Users?.slice(
                                                                        0,
                                                                        2
                                                                    ).map(
                                                                        (
                                                                            user
                                                                        ) => (
                                                                            <Tooltip
                                                                                key={
                                                                                    user
                                                                                        .User
                                                                                        ?.id_e
                                                                                }
                                                                                content={
                                                                                    <s.StyledTooltip>
                                                                                        {
                                                                                            user
                                                                                                .User
                                                                                                ?.name
                                                                                        }
                                                                                    </s.StyledTooltip>
                                                                                }
                                                                            >
                                                                                <s.CollaboratorImage
                                                                                    key={
                                                                                        user
                                                                                            .User
                                                                                            ?.id_e
                                                                                    }
                                                                                    src={
                                                                                        user
                                                                                            .User
                                                                                            ?.avatar ??
                                                                                        FotoUser
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            )}

                                                            {/* Colaboradores */}
                                                            <TableCell>
                                                                <Collaborators
                                                                    openModal={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setActivityToAddCollaborators(
                                                                            activity
                                                                        );
                                                                    }}
                                                                    activity={
                                                                        activity
                                                                    }
                                                                />
                                                            </TableCell>

                                                            {/* Prazo */}
                                                            <TableCell>
                                                                {activity.deadline &&
                                                                activity.created_at ? (
                                                                    <>
                                                                        {dayjs(
                                                                            activity.created_at
                                                                        ).format(
                                                                            "DD/MM/YYYY"
                                                                        )}{" "}
                                                                        - <br />
                                                                        {dayjs(
                                                                            activity.deadline
                                                                        ).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    "Sem prazo"
                                                                )}
                                                            </TableCell>

                                                            {/* Prioridade */}
                                                            <TableCell>
                                                                <s.Priority
                                                                    type={
                                                                        activity.priority
                                                                    }
                                                                >
                                                                    <span>
                                                                        •
                                                                    </span>
                                                                    {
                                                                        priorityMapper[
                                                                            activity
                                                                                .priority
                                                                        ]
                                                                    }
                                                                </s.Priority>
                                                            </TableCell>

                                                            <TableCell>
                                                                <StatusDropdown
                                                                    activity={
                                                                        activity
                                                                    }
                                                                    updateActivity={
                                                                        updateActivity
                                                                    }
                                                                    handleStatusModal={
                                                                        handleStatusModal
                                                                    }
                                                                    statusOptions={
                                                                        statusOptions
                                                                    }
                                                                />
                                                            </TableCell>
                                                            {/* Progresso de Horas */}
                                                            <TableCell>
                                                                <HourProgress
                                                                    hourProgress={
                                                                        activity.hourProgress
                                                                    }
                                                                    expectedMinutes={
                                                                        activity.expectedMinutes
                                                                    }
                                                                />
                                                            </TableCell>
                                                            {/* Ações */}
                                                            <TableCell
                                                                onClick={(e) =>
                                                                    e.stopPropagation()
                                                                }
                                                            >
                                                                <PopoverRadix
                                                                    align="end"
                                                                    trigger={
                                                                        <IconButton>
                                                                            <Icons.More />
                                                                        </IconButton>
                                                                    }
                                                                >
                                                                    <s.MenuContainer>
                                                                        <s.MenuOption
                                                                            onClick={() =>
                                                                                handleOpenDetailsActivity(
                                                                                    activity
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icons.ExpandContent
                                                                                color={
                                                                                    theme.gray800
                                                                                }
                                                                            />
                                                                            Ver
                                                                            detalhes
                                                                        </s.MenuOption>
                                                                        {!archive && (
                                                                            <s.MenuOption
                                                                                onClick={() =>
                                                                                    toggleActivityEdit?.(
                                                                                        activity.id_e
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Icons.PencilOutline
                                                                                    color={
                                                                                        theme.gray800
                                                                                    }
                                                                                />
                                                                                Editar
                                                                            </s.MenuOption>
                                                                        )}
                                                                        {archive ? (
                                                                            <s.MenuOption
                                                                                onClick={() =>
                                                                                    handleUnarchiveActivity(
                                                                                        activity.id_e
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Icons.Unarchive
                                                                                    color={
                                                                                        theme.gray600
                                                                                    }
                                                                                />
                                                                                Desarquivar
                                                                            </s.MenuOption>
                                                                        ) : (
                                                                            <s.MenuOption
                                                                                onClick={() =>
                                                                                    handleArchiveActivity(
                                                                                        activity.id_e
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Icons.IconArchive
                                                                                    color={
                                                                                        theme.gray600
                                                                                    }
                                                                                />
                                                                                Arquivar
                                                                            </s.MenuOption>
                                                                        )}
                                                                        {!archive && (
                                                                            <s.MenuOption
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    handleDuplicateActivity(
                                                                                        e,
                                                                                        activity.id_e
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Icons.Duplicate
                                                                                    color={
                                                                                        theme.gray600
                                                                                    }
                                                                                />
                                                                                Duplicar
                                                                            </s.MenuOption>
                                                                        )}
                                                                        <s.MenuOption
                                                                            danger
                                                                            disabled={Boolean(
                                                                                activity
                                                                                    .hourProgress
                                                                                    .minutesDone
                                                                            )}
                                                                            onClick={() => {
                                                                                if (
                                                                                    !activity
                                                                                        .hourProgress
                                                                                        .minutesDone
                                                                                ) {
                                                                                    setDeleteConfirmationActivityId(
                                                                                        activity.id_e
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Icons.Trash
                                                                                color={
                                                                                    activity
                                                                                        .hourProgress
                                                                                        .minutesDone
                                                                                        ? theme.gray300
                                                                                        : theme.red
                                                                                }
                                                                            />
                                                                            Deletar
                                                                        </s.MenuOption>
                                                                    </s.MenuContainer>
                                                                </PopoverRadix>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            );
                                        })}

                                        {provided.placeholder}
                                    </tbody>
                                </>
                            );
                        }}
                    </Droppable>
                </ActivityTable>
                {!archive && (
                    <AddActivity
                        project={project}
                        sectionId={section.id_e}
                        createActivity={createActivity}
                        setActivityToAddCollaborators={
                            setActivityToAddCollaborators
                        }
                        activityToAddCollaborators={activityToAddCollaborators}
                        setIsCreating={setIsCreatingActivity}
                    />
                )}
            </>
        );
    };

    const generateSubSection = (
        Sections: TSection[],
        paddingLeftTitle: string,
        parentId: string,
        maxWidthHourProgess: number
    ) =>
        Sections?.map((subSection, subSectionIndex) => {
            const hourProgressWidth = maxWidthHourProgess - 100;
            if (updateSectionStates[subSectionIndex])
                return (
                    <EditSection
                        updateSection={updateSection}
                        section={subSection}
                        toggleSectionEdit={toggleSectionEdit}
                        index={subSectionIndex}
                    />
                );
            return (
                <Droppable
                    droppableId={`${subSection.id_e}Subsections`}
                    type={`section${parentId}`}
                >
                    {(subProvided) => (
                        <ul
                            {...subProvided.droppableProps}
                            ref={subProvided.innerRef}
                        >
                            <Draggable
                                key={subSection.id_e}
                                index={subSectionIndex}
                                draggableId={subSection.id_e}
                                isDragDisabled={
                                    updateSectionStates.includes(true) ||
                                    updateActivityStates.length > 0 ||
                                    isCreatingActivity
                                }
                            >
                                {(subProvided, subSnapshot) => (
                                    <ul
                                        {...subProvided.draggableProps}
                                        {...subProvided.dragHandleProps}
                                        ref={subProvided.innerRef}
                                    >
                                        <SectionHeader
                                            isSubSection={true}
                                            archiveSection={archiveSection}
                                            unarchiveSection={unarchiveSection}
                                            setDeleteConfirmationSectionId={
                                                setDeleteConfirmationSectionId
                                            }
                                            hourProgressMaxWidth={`${hourProgressWidth}px`}
                                            toggleSectionVisibility={
                                                toggleSectionVisibility
                                            }
                                            openedSectionsIds={
                                                openedSectionsIds
                                            }
                                            index={subSectionIndex}
                                            archive={archive}
                                            section={subSection}
                                            toggleSectionEdit={
                                                toggleSectionEdit
                                            }
                                            paddingRightTitle={paddingLeftTitle}
                                            isClosedScope={isClosedScope}
                                        />
                                        {!subSnapshot.isDragging && (
                                            <Collapse
                                                open={openedSectionsIds.includes(
                                                    subSection.id_e
                                                )}
                                                height={"100"}
                                            >
                                                {subSection.Sections &&
                                                subSection.Sections.length > 0
                                                    ? generateSubSection(
                                                          subSection.Sections,
                                                          "8rem",
                                                          subSection.id_e,
                                                          hourProgressWidth
                                                      )
                                                    : generateActivitys(
                                                          subSection
                                                      )}
                                            </Collapse>
                                        )}
                                    </ul>
                                )}
                            </Draggable>
                            {subProvided.placeholder}
                        </ul>
                    )}
                </Droppable>
            );
        });

    useEffect(() => {
        if (draggingDestination) {
            setOpenedSectionsIds([
                ...openedSectionsIds,
                draggingDestination.droppableId,
            ]);
        }
    }, [draggingDestination, setOpenedSectionsIds]);

    return (
        <>
            <div
                style={{
                    minHeight: (sections?.length || 0) * 80,
                    border: "1px solid #EDEDED",
                    borderRadius: "0.25rem",
                }}
            >
                <Droppable droppableId={`${sectionName}Groups`} type="group">
                    {(provided) => {
                        return (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {sectionsToMap.map((section, sectionIndex) =>
                                    updateSectionStates[sectionIndex] ? (
                                        <EditSection
                                            updateSection={updateSection}
                                            section={section}
                                            toggleSectionEdit={
                                                toggleSectionEdit
                                            }
                                            index={sectionIndex}
                                        />
                                    ) : (
                                        <Draggable
                                            key={String(section.id_e)}
                                            index={sectionIndex}
                                            draggableId={String(section.id_e)}
                                            isDragDisabled={
                                                updateSectionStates.includes(
                                                    true
                                                ) ||
                                                updateActivityStates.length >
                                                    0 ||
                                                isCreatingActivity
                                            }
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <ul
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <SectionHeader
                                                            isClosedScope={
                                                                isClosedScope
                                                            }
                                                            hourProgressMaxWidth="34.375rem"
                                                            archiveSection={
                                                                archiveSection
                                                            }
                                                            unarchiveSection={
                                                                unarchiveSection
                                                            }
                                                            setDeleteConfirmationSectionId={
                                                                setDeleteConfirmationSectionId
                                                            }
                                                            toggleSectionVisibility={
                                                                toggleSectionVisibility
                                                            }
                                                            openedSectionsIds={
                                                                openedSectionsIds
                                                            }
                                                            index={sectionIndex}
                                                            archive={archive}
                                                            section={section}
                                                            toggleSectionEdit={
                                                                toggleSectionEdit
                                                            }
                                                        />
                                                        {snapshot.isDragging ===
                                                            false && (
                                                            <Collapse
                                                                open={
                                                                    openedSectionsIds.includes(
                                                                        section.id_e
                                                                    ) &&
                                                                    !snapshot.isDragging
                                                                }
                                                                height={"100"}
                                                            >
                                                                {" "}
                                                                {isClosedScope &&
                                                                section.Sections
                                                                    ? generateSubSection(
                                                                          section.Sections,
                                                                          "4rem",
                                                                          section.id_e,
                                                                          550
                                                                      )
                                                                    : generateActivitys(
                                                                          section
                                                                      )}
                                                            </Collapse>
                                                        )}
                                                    </ul>
                                                );
                                            }}
                                        </Draggable>
                                    )
                                )}
                                {provided.placeholder}
                            </ul>
                        );
                    }}
                </Droppable>
            </div>
            {!isClosedScope && (
                <div>
                    <Droppable
                        droppableId="withoutSectionSections"
                        type="activity"
                    >
                        {(provided) => (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {activitiesWithoutSection?.map(
                                    (activity, index) => (
                                        <Draggable
                                            key={activity.id_e}
                                            index={index}
                                            draggableId={String(activity.id_e)}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div
                                                        ref={
                                                            activitiesIdsRef?.[
                                                                activity.id_e
                                                            ]
                                                        }
                                                    >
                                                        <ActivityRow
                                                            isClosedScope={
                                                                isClosedScope
                                                            }
                                                            statusOptions={
                                                                statusOptions
                                                            }
                                                            handleStatusModal={
                                                                handleStatusModal
                                                            }
                                                            handleOpenDetailsActivity={
                                                                handleOpenDetailsActivity
                                                            }
                                                            handleDuplicateActivity={
                                                                handleDuplicateActivity
                                                            }
                                                            handleToggleActivityStatus={
                                                                handleToggleActivityStatus
                                                            }
                                                            index={index}
                                                            updateActivity={
                                                                updateActivity
                                                            }
                                                            handleArchiveActivity={
                                                                handleArchiveActivity
                                                            }
                                                            handleUnarchiveActivity={
                                                                handleUnarchiveActivity
                                                            }
                                                            archive={archive}
                                                            project={project}
                                                            activity={activity}
                                                            toggleActivityEdit={
                                                                toggleActivityEdit
                                                            }
                                                            setActivityToAddCollaborators={
                                                                setActivityToAddCollaborators
                                                            }
                                                            setDeleteConfirmationActivityId={
                                                                setDeleteConfirmationActivityId
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </div>
            )}
            {activity && (
                <ActivityDetailsDialog
                    open={activityDetailsOpen}
                    setOpen={setActivityDetailsOpen}
                    activity={activity}
                    refetchActivity={() => {
                        getSections.refetch();
                    }}
                />
            )}
            {isOpenModalStatus && (
                <StatusDialog
                    handleClose={handleDialog}
                    open={isOpenModalStatus}
                    refetch={getSections.refetch}
                    refetchStatus={refetchStatus}
                    selectStatusToEdit={editStatus}
                    categorys={statusData?.data ?? []}
                    statusOptions={statusOptions}
                />
            )}
        </>
    );
};
