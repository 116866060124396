import { useCallback, useEffect, useState } from "react";

import { Icons, theme } from "../../assets";
import { useFetch } from "../../hooks";
import { countBudgets, readBudgets } from "../../services/budget";
import {
    TCountBudgets,
    TCountBudgetsResponse,
} from "../../services/budget/count-budgets";
import { TReadBudgetsResponse } from "../../services/budget/read-budgets";
import * as s from "./styled-finances-home";
import { TabBudget } from "./tab-budget/tab-budget";

export type filterPopover = {
    budgetTypeIds?: string[];
    clientIds?: string[];
    statusIds?: string[];
    isArchived?: boolean;
};

export const BudgetsHome = () => {
    const [tab, setTab] = useState<"budget" | "activity_profiles">("budget");
    const [budgets, setBudgets] = useState<TReadBudgetsResponse[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [filterPopoverData, setFilterPopoverData] = useState<filterPopover>({
        isArchived: false,
    });
    const [budgetsCount, setBudgetsCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const readBudgetsFetch = useFetch({
        fn: readBudgets,
        params: {
            page: currentPage,
            length: 10,
            filter,
            ...filterPopoverData,
        },
    });
    const countBudgetsFetch = useFetch<
        TCountBudgets,
        TCountBudgetsResponse,
        any
    >({
        fn: countBudgets,
        params: { filter, ...filterPopoverData },
    });

    const handleSetPopoverData = useCallback(
        (data: filterPopover) => {
            setFilterPopoverData(data);
        },
        [filterPopoverData, setFilterPopoverData, readBudgetsFetch]
    );

    const refetchBudget = () => {
        readBudgetsFetch.onRefresh({
            page: currentPage,
            length: 10,
            filter,
            ...filterPopoverData,
        });
        countBudgetsFetch.onRefresh({ filter, ...filterPopoverData });
    };

    useEffect(() => {
        if (readBudgetsFetch.response) setBudgets(readBudgetsFetch.response);
    }, [readBudgetsFetch.response]);

    useEffect(() => {
        if (countBudgetsFetch.response)
            setBudgetsCount(countBudgetsFetch.response.count);
    }, [countBudgetsFetch.response]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            refetchBudget();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [filter, filterPopoverData, currentPage]);

    return (
        <s.Container>
            <s.Title>Orçamentos</s.Title>
            <s.TabContainer>
                <s.Tab
                    active={tab === "budget"}
                    onClick={() => setTab("budget")}
                >
                    <Icons.Bill
                        color={
                            tab === "budget"
                                ? theme.primary.main
                                : theme.gray600
                        }
                        fill={
                            tab === "budget"
                                ? theme.primary.main
                                : theme.gray600
                        }
                    />
                    <strong>ORÇAMENTOS</strong>
                </s.Tab>
                {/* <s.Tab
                    active={tab === "activity_profiles"}
                    onClick={() => setTab("activity_profiles")}
                >
                    <Icons.Worker
                        color={
                            tab === "activity_profiles"
                                ? theme.primary.main
                                : theme.gray600
                        }
                        fill={
                            tab === "activity_profiles"
                                ? theme.primary.main
                                : theme.gray600
                        }
                    />
                    <strong>PERFIS DE ATIVIDADE</strong>
                </s.Tab> */}
            </s.TabContainer>
            {tab === "budget" && (
                <TabBudget
                    filterState={[filter, setFilter]}
                    budgets={budgets}
                    budgetsCountState={[budgetsCount, setBudgetsCount]}
                    currentPageState={[currentPage, setCurrentPage]}
                    filterPopoverData={filterPopoverData}
                    handleSetPopoverData={handleSetPopoverData}
                    refetchBudget={refetchBudget}
                />
            )}
        </s.Container>
    );
};
