import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TReadBudgetComment = {
    budgetId: string;
};

export type TCommentBudget = {
    Author: {
        id_e: string;
        name: string;
        avatar: string;
        Role: {
            id_e: string;
            name: string;
        };
    };
    budget_id_e: string;
    created_at: string;
    id_e: string;
    id_i: number;
    text: string;
    updated_at: string;
    user_id_e: string;
    wasUpdated: boolean;
};

export type TReadBudgetCommentResponse = {
    data: TCommentBudget[];
};

export default async ({ budgetId }: TReadBudgetComment) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/comment`,
        method: "get",
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetCommentResponse>({
        body,
        statusCode,
    });
};
