import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../../../assets";
import {
    Input,
    LoadingGray,
    PopoverRadix,
    Scroll,
    SelectInput,
    SelectProjects,
    Tag,
    Tooltip,
    TooltipHourValueHistory,
} from "../../../../../components";
import { HideInformation } from "../../../../../components/atoms/hide-information/hide-information";
import {
    DesignedAlert,
    ModalUserLimitReached,
} from "../../../../../components/molecules";
import { useUser } from "../../../../../context";
import { useFetch } from "../../../../../hooks";
import { useReadProfile } from "../../../../../hooks/react-query/use-read-profile";
import { TOption, TProject, TUser } from "../../../../../models";
import {
    manageUser,
    projects,
    sectors as sectorsFn,
    roles,
} from "../../../../../services";
import { Auth } from "../../../../../services/auth/Authentication";
import { GetUserVariables } from "../../../../../services/user/types";
import { formatToBRMoney, onlyNumbers } from "../../../../../utils";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import { formatCurrency } from "../../../../organization/project/tab-costs/utils";
import { DeleteAccountModal } from "./delete-account-modal/delete-account-modal";
import { OneAdminModal } from "./one-admin-modal/one-admin-modal";
import * as s from "./styled-tab-contract";
import { ToggleAccountActivationModal } from "./toggle-account-activation-modal/toggle-account-activation-modal";
import schema from "./validation";

type TProjectsOptions = {
    color: string;
} & TOption;

type TSectors = {
    id_e: string;
    name: string;
    Projects: {
        id_e: string;
        name: string;
    }[];
}[];

type TFields = {
    viewValues?: boolean;
    role?: string;
    contract: string;
    hourValue: string;
    workload: string;
    projects?: TProjectsOptions[];
    sectors?: TOption[];
    status?: "active" | "inactive";
    active?: boolean;
    monthlyValue?: string;
    profilesId: string[];
};

type TTabContract = {
    loading: boolean;
    userData: TUser;
    setUserData: Dispatch<SetStateAction<TUser>>;
    userId: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
    getUserOnRefresh: (params: GetUserVariables) => void;
};

export const TabContract = ({
    loading,
    userData,
    setUserData,
    userId,
    setSelectedTab,
    getUserOnRefresh,
}: TTabContract) => {
    const { user, verifyPermission } = useUser();
    const manageUsers = verifyPermission({
        id: "professionals",
        rule: "manage",
    });
    const manageRoles = verifyPermission({
        id: "roles_permissions",
        rule: "manage",
    });
    const viewSectorsAndProjects = verifyPermission({
        id: "projects_sectors",
        rule: "view",
    });
    const viewProfessionalsValues = verifyPermission({
        id: "professionals_values",
        rule: "view",
    });
    const manageProfessionalsValues = verifyPermission({
        id: "professionals_values",
        rule: "manage",
    });

    const [contractLoading, setContractLoading] = useState<boolean>(false);
    const [showInformation, setShowInformation] = useState<boolean>(
        viewProfessionalsValues || false
    );
    const [userFirstRole, setUserFirstRole] = useState<
        | {
              id_e: string;
              name: string;
          }
        | undefined
    >();
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);
    const [toggleAccountActivationModal, setToggleAccountActivationModal] =
        useState(false);
    const [onlyOneAdminModal, setOnlyOneAdminModal] = useState(false);
    const [isOpenUserLimitReached, setIsOpenUserLimitReached] = useState(false);

    const formatMoney = (money: number, withSign?: boolean) => {
        const moneyString = money.toString();
        const splitMoney = moneyString.split(".");

        if (splitMoney.length > 1) {
            if (splitMoney[1].length === 1) {
                return `${withSign ? `R$ ` : ""}${moneyString.replace(
                    ".",
                    ","
                )}0`;
            }
            if (splitMoney[1].length === 2) {
                return `${withSign ? `R$ ` : ""}${moneyString.replace(
                    ".",
                    ","
                )}`;
            }
        }
        const formattedMoney =
            moneyString.length > 1 ? moneyString : `0${moneyString}`;
        return `${withSign ? `R$ ` : ""}${formattedMoney},00`;
    };

    const defaultValues = {
        manageProfessionalsValues,
        contract: userData?.ContractFormat?.name,
        hourValue: userData.hourValue
            ? formatMoney(userData.hourValue, true)
            : "",
        workload: userData.originalWorkload
            ? userData.originalWorkload.toString()
            : "",
        role: userData.Role?.id_e,
        projects: userData?.Projects?.map(
            (project: { name: string; id_e: string }) => {
                return { label: project.name, value: project.id_e };
            }
        ),
        sectors: userData?.Sectors?.map(
            (sector: { name: string; id_e: string }) => {
                return { label: sector.name, value: sector.id_e };
            }
        ),
        profilesId: userData?.UserProcessProfiles?.map(
            (profile) => profile.Profile.id_e
        ),
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        setValue,
        watch,
    } = useForm<TFields>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const defaultValuesProfiles = userData?.UserProcessProfiles?.map(
        (profile) => profile.Profile.id_e
    );

    const [projectsOptions, setProjectsOptions] = useState<TProjectsOptions[]>(
        []
    );
    const [rolesOptions, setRolesOptions] = useState<TOption[]>([]);
    const [statusOptions] = useState<TOption[]>([
        {
            label: "Ativo",
            value: "active",
        },
        {
            label: "Inativo",
            value: "inactive",
        },
    ]);
    const [selectedStatus] = useState<TFields["status"]>(
        userData?.active ? "active" : "inactive"
    );

    const [showEdit, setShowEdit] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState<TOption[]>(
        userData?.Projects?.map((project: { name: string; id_e: string }) => {
            return { label: project.name, value: project.id_e };
        }) || []
    );
    const [sectorsOptions, setSectorsOptions] = useState<TOption[]>([]);
    const [reportModel] = useState<string>(
        user.Organization?.OrganizationSettings?.report_model || "project"
    );
    const [selectedSectors, setSelectedSectors] = useState(
        userData?.Sectors?.map((sector: { name: string; id_e: string }) => {
            return { label: sector.name, value: sector.id_e };
        }) || []
    );

    const updateUser = useFetch({
        fn: manageUser.updateUser,
        start: false,
    });

    const getProjects = useFetch({
        fn: projects.getProject,
        errorMessage: "Erro ao pegar projetos",
        start: false,
    });

    const getSectors = useFetch({
        fn: sectorsFn.getSector,
        errorMessage: "Erro ao pegar setores",
        params: { all: true },
        start: false,
    });

    const getRoles = useFetch({
        fn: roles.getRole,
        errorMessage: "Erro ao pegar atuações",
        params: { all: true },
        start: false,
    });

    const handleInactiveSelf = () => {
        Auth.getInstance().logout();
        window.location.href = "/sucesso-inativacao";
    };

    const params = {
        length: 500,
        page: 1,
        userId,
    };

    const { data } = useReadProfile({ ...params });

    const options = data
        ? data.map((profile) => ({
              label: profile.name,
              value: profile.id_e,
          }))
        : [];

    useEffect(() => {
        if (manageUsers) {
            getProjects.onRefresh({
                all: !!viewSectorsAndProjects,
                sectors: true,
            });
            getSectors.onRefresh({ all: !!viewSectorsAndProjects });
            getRoles.onRefresh({ all: !!manageRoles });
        }
        setUserFirstRole(userData?.Role);
    }, []);

    useEffect(() => {
        if (getProjects.loading) setContractLoading(true);
        else setContractLoading(false);
    }, [getProjects.loading]);

    useEffect(() => {
        if (getProjects.response) {
            const tempProjectsOptions: TProjectsOptions[] = [];
            getProjects.response.projects.forEach(
                (project: {
                    name: string;
                    id_e: string;
                    color: string;
                    Sectors: TSectors;
                }) => {
                    tempProjectsOptions.push({
                        label: project.name,
                        value: project.id_e,
                        color: project.color,
                    });
                }
            );
            setProjectsOptions(tempProjectsOptions);

            setSelectedProjects((selectedProjects) =>
                selectedProjects.filter((project) =>
                    tempProjectsOptions.some(
                        (option) => option.value === project.value
                    )
                )
            );
        }
    }, [getProjects.response]);

    useEffect(() => {
        if (getSectors.response) {
            const tempSectorsOptions: TOption[] = [];
            getSectors.response.sectors.forEach(
                (sector: { name: string; id_e: string }) =>
                    tempSectorsOptions.push({
                        label: sector.name,
                        value: sector.id_e,
                    })
            );
            setSectorsOptions(tempSectorsOptions);
        }
    }, [getSectors.response]);

    useEffect(() => {
        if (selectedSectors && !getSectors.loading) {
            let tempSectors = "";
            selectedSectors.forEach((sector: { value: string }, index) => {
                if (selectedSectors.length === index + 1) {
                    tempSectors += `${sector.value}`;
                } else {
                    tempSectors += `${sector.value}, `;
                }
            });
            if (selectedSectors.length === 0) setSelectedProjects([]);

            getProjects.onRefresh({ sectorId: tempSectors, all: true });
        }
    }, [selectedSectors]);

    useEffect(() => {
        if (getRoles.response) {
            const tempRolesOptions: TOption[] = [];
            getRoles.response.roles.forEach(
                (role: { name: string; id_e: string }) =>
                    tempRolesOptions.push({
                        label: role.name,
                        value: role.id_e,
                    })
            );
            setRolesOptions(tempRolesOptions);
        }
    }, [getRoles.response]);

    useEffect(() => {
        if (updateUser.error) {
            userData.Role = userFirstRole;
            userData.role = userFirstRole?.id_e;
            setValue("role", userFirstRole?.id_e);
        }
    }, updateUser.response);

    useEffect(() => {
        if (
            updateUser.error?.body?.message ===
            "there must be at least one admin in the organization"
        ) {
            setToggleAccountActivationModal(false);
            setOnlyOneAdminModal(true);
        }
        if (updateUser.error) {
            getUserOnRefresh({
                userId,
                sectors: true,
                projects: true,
            });
        }
        if (updateUser.error?.body?.message === "max users reached") {
            setToggleAccountActivationModal(false);
            setIsOpenUserLimitReached(true);
        }
    }, [updateUser.error]);

    useEffect(() => {
        if (updateUser.response && !updateUser.response?.message) {
            toast.success("Profissional editado com sucesso");
            setUserData({
                ...userData,
                active: updateUser.response.active,
            });
            setToggleAccountActivationModal(false);

            const isSelf = updateUser.response.id_e === user.id_e;
            const isInactive = !updateUser.response.active;
            if (isSelf && isInactive) {
                handleInactiveSelf();
            }
            getUserOnRefresh({
                userId,
                sectors: true,
                projects: true,
            });
            Mixpanel.track("Perfil colaborador - Salvar Contrato");
        }
    }, [updateUser.response]);

    const onSubmit = (data: TFields) => {
        Reflect.deleteProperty(data, "manageProfessionalsValues");
        Reflect.deleteProperty(data, "monthlyValue");
        const numberHourValue = Number(
            data.hourValue.slice(3).replace(",", ".")
        );
        const numberWorkload = Number(data.workload);
        let selectedRole: TOption = { label: "", value: "" };

        rolesOptions.forEach((role) => {
            if (role.value === data.role) selectedRole = role;
        });
        const filteredData = structuredClone(data);
        filteredData.active = data.status === "active";
        delete filteredData.status;
        delete filteredData.role;
        delete filteredData.projects;
        delete filteredData.sectors;
        let tempProjects = "";
        let tempSectors = "";
        const tempSelectedProjects: {
            id_e: string;
            name: string;
            color: string;
        }[] = [];

        selectedSectors.forEach((sector: { value: string }, index) => {
            if (selectedSectors.length === index + 1) {
                tempSectors += `${sector.value}`;
            } else {
                tempSectors += `${sector.value}, `;
            }
        });

        selectedProjects.forEach((project: { value: string }, index) => {
            if (selectedProjects.length === index + 1) {
                tempProjects += `${project.value}`;
            } else {
                tempProjects += `${project.value}, `;
            }

            projectsOptions.forEach((projectOption) => {
                if (project.value === projectOption.value)
                    tempSelectedProjects.push({
                        name: projectOption.label,
                        color: projectOption.color,
                        id_e: projectOption.value,
                    });
            });
        });
        const selectedProjectsIds = selectedProjects.map(
            (selectedProject) => selectedProject.value
        );
        const selectedProjectsWithSectors =
            getProjects.response.projects.filter((project: TProject) =>
                selectedProjectsIds.includes(project.id_e)
            );
        const sectorsIds: string[] = [];
        selectedProjectsWithSectors.forEach((project: TProject) =>
            project?.Sectors?.forEach((sector) => {
                if (!sectorsIds.includes(sector.id_e))
                    sectorsIds.push(sector.id_e);
            })
        );
        const sectorsByProjects = selectedProjectsWithSectors.map(
            (project: TProject) => {
                return project.Sectors?.map((sector) => {
                    return { label: sector.name, value: sector.id_e };
                });
            }
        );
        setUserData({
            ...userData,
            ...filteredData,
            workload: parseInt(data.workload, 10),
            hourValue: numberHourValue,
            Role: { name: selectedRole.label, id_e: selectedRole.value },
            Projects: tempSelectedProjects,
            Sectors:
                reportModel === "sector"
                    ? selectedSectors.map(
                          (sector: { label: string; value: string }) => {
                              return { name: sector.label, id_e: sector.value };
                          }
                      )
                    : sectorsByProjects,
        });
        setSelectedTab("contract");
        setShowEdit(!showEdit);
        updateUser.onRefresh({
            userData: {
                ...filteredData,
                hourValue: numberHourValue || undefined,
                workload: numberWorkload || undefined,
            },
            userId,
            projectsId: tempProjects,
            sectorsId:
                reportModel === "sector" ? tempSectors : sectorsIds.join(", "),
            roleId: data.role,
        });
    };

    const handleToggleAccountActivation = () => {
        updateUser.onRefresh({
            userId,
            userData: {
                active: !userData.active,
            },
        });
    };

    const monthlyPayment =
        (userData?.hourValue || 0) * Number(watch("workload") || 1);

    return (
        <s.Content>
            {deleteAccountModal && (
                <DeleteAccountModal onOpenChange={setDeleteAccountModal} />
            )}
            {toggleAccountActivationModal && (
                <ToggleAccountActivationModal
                    onOpenChange={setToggleAccountActivationModal}
                    onConfirm={handleToggleAccountActivation}
                    isActive={userData.active}
                />
            )}
            {onlyOneAdminModal && (
                <OneAdminModal onOpenChange={setOnlyOneAdminModal} />
            )}
            {isOpenUserLimitReached && (
                <ModalUserLimitReached setOpen={setIsOpenUserLimitReached} />
            )}

            <div className="content-title">Dados de contratação:</div>

            <s.Contract onSubmit={handleSubmit(onSubmit)} isEditing={showEdit}>
                {loading ? (
                    <LoadingGray />
                ) : (
                    <>
                        <div className="form-column">
                            <div className="contact-field">
                                <span className="field-title">
                                    Contrato{showEdit && "*"}:
                                </span>

                                {showEdit ? (
                                    <Controller
                                        name={"contract"}
                                        control={control}
                                        render={({
                                            field: { ref, onChange },
                                        }) => (
                                            <SelectInput
                                                icon={
                                                    <Icons.Chevron
                                                        color={theme.gray600}
                                                    />
                                                }
                                                error={errors.contract?.message}
                                                noError={
                                                    !errors.contract?.message
                                                }
                                                inputRef={ref}
                                                options={[
                                                    {
                                                        value: "CLT",
                                                        label: "CLT (Celetista)",
                                                    },
                                                    {
                                                        value: "PJ",
                                                        label: "PJ (Pessoa jurídica)",
                                                    },
                                                    {
                                                        value: "Estágio",
                                                        label: "Estágio ",
                                                    },
                                                ]}
                                                positionY="1"
                                                placeholder={
                                                    userData?.ContractFormat
                                                        ?.name || "Setor"
                                                }
                                                onChange={(val: {
                                                    value: string;
                                                }) => {
                                                    onChange(val.value);
                                                }}
                                            />
                                        )}
                                    />
                                ) : (
                                    <span className="field-value">
                                        {userData?.ContractFormat?.name || "-"}
                                    </span>
                                )}
                            </div>

                            {viewProfessionalsValues && (
                                <div className="contact-field">
                                    <span className="field-title">
                                        Valor mensal:
                                    </span>
                                    {showEdit && manageProfessionalsValues ? (
                                        <Input
                                            {...register("monthlyValue", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    e.target.value =
                                                        formatCurrency(value);
                                                },
                                                onBlur: (e) => {
                                                    const { value } = e.target;
                                                    if (watch("workload")) {
                                                        const monthlyValue =
                                                            Number(
                                                                onlyNumbers(
                                                                    value
                                                                )
                                                            ) / 100;
                                                        const workload = Number(
                                                            watch("workload")
                                                        );
                                                        const hourValue =
                                                            monthlyValue /
                                                            workload;
                                                        setValue(
                                                            "hourValue",
                                                            formatCurrency(
                                                                hourValue.toFixed(
                                                                    2
                                                                )
                                                            ) ||
                                                                watch(
                                                                    "hourValue"
                                                                )
                                                        );
                                                    }
                                                },
                                            })}
                                            defaultValue={
                                                userData.hourValue
                                                    ? formatToBRMoney(
                                                          monthlyPayment
                                                      )
                                                    : ""
                                            }
                                            placeholder={"R$ 00,00"}
                                            className="input-contact-data"
                                            error={
                                                errors.hourValue?.message || ""
                                            }
                                            disabled={
                                                !manageProfessionalsValues
                                            }
                                        />
                                    ) : (
                                        <HideInformation
                                            isToShowInformation={
                                                showInformation
                                            }
                                        >
                                            <span className="field-value">
                                                {monthlyPayment
                                                    ? formatCurrency(
                                                          monthlyPayment.toFixed(
                                                              2
                                                          )
                                                      )
                                                    : "-"}
                                            </span>
                                        </HideInformation>
                                    )}
                                </div>
                            )}

                            {viewProfessionalsValues && (
                                <div className="contact-field">
                                    <span className="field-title">
                                        Valor hora{showEdit && "*"}:
                                    </span>
                                    {showEdit && manageProfessionalsValues ? (
                                        <Input
                                            {...register("hourValue", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    e.target.value =
                                                        formatCurrency(value);
                                                },
                                                onBlur: (e) => {
                                                    const { value } = e.target;
                                                    if (watch("monthlyValue")) {
                                                        const hourValue =
                                                            Number(
                                                                onlyNumbers(
                                                                    value
                                                                )
                                                            ) / 100;
                                                        const workload = Number(
                                                            watch("workload")
                                                        );
                                                        const monthlyValue =
                                                            hourValue *
                                                            workload;
                                                        setValue(
                                                            "monthlyValue",
                                                            formatCurrency(
                                                                monthlyValue.toFixed(
                                                                    2
                                                                )
                                                            ) ||
                                                                watch(
                                                                    "monthlyValue"
                                                                )
                                                        );
                                                    }
                                                },
                                            })}
                                            type="text"
                                            defaultValue={
                                                userData.hourValue
                                                    ? formatCurrency(
                                                          watch("workload")
                                                              ? (
                                                                    monthlyPayment /
                                                                    Number(
                                                                        watch(
                                                                            "workload"
                                                                        )
                                                                    )
                                                                ).toFixed(2)
                                                              : userData.hourValue.toString()
                                                      )
                                                    : ""
                                            }
                                            placeholder={"R$ 00,00"}
                                            className="input-contact-data"
                                            error={
                                                errors.hourValue?.message || ""
                                            }
                                            disabled={
                                                !manageProfessionalsValues
                                            }
                                        />
                                    ) : (
                                        <HideInformation
                                            isToShowInformation={
                                                showInformation
                                            }
                                        >
                                            <span className="field-value">
                                                {userData.hourValue
                                                    ? formatMoney(
                                                          userData.hourValue,
                                                          true
                                                      )
                                                    : "-"}
                                            </span>
                                        </HideInformation>
                                    )}
                                    {manageProfessionalsValues && (
                                        <TooltipHourValueHistory
                                            dataList={
                                                userData.UserHourValueHistory
                                            }
                                        />
                                    )}
                                </div>
                            )}

                            {viewProfessionalsValues && (
                                <div className="contact-field">
                                    <span className="field-title">
                                        Carga horária{showEdit && "*"}:
                                    </span>
                                    {showEdit && manageProfessionalsValues ? (
                                        <Input
                                            {...register("workload", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    if (
                                                        Number.isNaN(
                                                            Number(value)
                                                        )
                                                    ) {
                                                        e.target.value = null;
                                                        return;
                                                    }
                                                    e.target.value = value;
                                                },
                                                onBlur: (e) => {
                                                    const { value } = e.target;
                                                    const hourValue =
                                                        Number(
                                                            onlyNumbers(
                                                                watch(
                                                                    "hourValue"
                                                                )
                                                            )
                                                        ) / 100;
                                                    const workload =
                                                        Number(value);
                                                    const monthlyValue =
                                                        hourValue * workload;
                                                    setValue(
                                                        "monthlyValue",
                                                        formatCurrency(
                                                            monthlyValue.toFixed(
                                                                2
                                                            )
                                                        ) ||
                                                            watch(
                                                                "monthlyValue"
                                                            )
                                                    );
                                                },
                                            })}
                                            maxLength={3}
                                            type="text"
                                            defaultValue={
                                                userData.originalWorkload
                                                    ? userData.originalWorkload.toString()
                                                    : ""
                                            }
                                            mask=""
                                            className="input-contact-data"
                                            error={
                                                errors.workload?.message || ""
                                            }
                                            disabled={
                                                !manageProfessionalsValues
                                            }
                                        />
                                    ) : (
                                        <HideInformation
                                            isToShowInformation={
                                                showInformation
                                            }
                                        >
                                            <span className="field-value">
                                                {userData?.originalWorkload ||
                                                    "-"}
                                            </span>
                                        </HideInformation>
                                    )}
                                </div>
                            )}

                            <div className="contact-field">
                                <span className="field-title">Status:</span>

                                {showEdit ? (
                                    <Controller
                                        name={"status"}
                                        control={control}
                                        defaultValue={selectedStatus}
                                        render={({
                                            field: { ref, onChange },
                                        }) => (
                                            <SelectInput
                                                icon={
                                                    <Icons.Chevron
                                                        color={theme.gray600}
                                                    />
                                                }
                                                inputRef={ref}
                                                options={statusOptions}
                                                placeholder={
                                                    userData?.active
                                                        ? "Ativo"
                                                        : "Inativo"
                                                }
                                                positionY="1"
                                                onChange={({
                                                    value,
                                                }: {
                                                    value: TFields["status"];
                                                }) => {
                                                    onChange(value);
                                                }}
                                            />
                                        )}
                                    />
                                ) : (
                                    <s.Status $active={userData.active}>
                                        {userData?.active ? "Ativo" : "Inativo"}
                                    </s.Status>
                                )}
                            </div>
                            <div className="contact-field">
                                <span className="field-title">
                                    Perfil de Atividade:
                                </span>

                                {showEdit ? (
                                    <Controller
                                        name={"profilesId"}
                                        control={control}
                                        defaultValue={defaultValuesProfiles}
                                        render={({
                                            field: { ref, onChange },
                                        }) => (
                                            <SelectInput
                                                icon={
                                                    <Icons.Chevron
                                                        color={theme.gray600}
                                                    />
                                                }
                                                inputRef={ref}
                                                options={options}
                                                placeholder="Defina o perfil de atividade"
                                                positionY="1"
                                                isMulti
                                                onChange={(
                                                    event: TOption[]
                                                ) => {
                                                    const newValue = event.map(
                                                        (option) => option.value
                                                    );
                                                    onChange(newValue);
                                                }}
                                            />
                                        )}
                                    />
                                ) : (
                                    <span className="field-value">
                                        {userData?.UserProcessProfiles?.map(
                                            (profile, i) =>
                                                i + 1 ===
                                                userData?.UserProcessProfiles
                                                    ?.length
                                                    ? profile?.Profile?.name
                                                    : `${profile?.Profile?.name}, `
                                        ) || "-"}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="form-column">
                            <div className="contact-field">
                                <span className="field-title">
                                    Atuação{showEdit && "*"}:
                                </span>

                                {showEdit ? (
                                    <Controller
                                        name={"role"}
                                        control={control}
                                        render={({
                                            field: { ref, onChange },
                                        }) => (
                                            <SelectInput
                                                icon={
                                                    <Icons.Chevron
                                                        color={theme.gray600}
                                                    />
                                                }
                                                error={
                                                    errors.role?.message || ""
                                                }
                                                inputRef={ref}
                                                options={rolesOptions}
                                                positionY="1"
                                                placeholder={
                                                    userData?.Role?.name ||
                                                    "Atuação"
                                                }
                                                onChange={(val: {
                                                    value: string;
                                                }) => {
                                                    onChange(val.value);
                                                }}
                                                disabled={getRoles.loading}
                                            />
                                        )}
                                    />
                                ) : (
                                    <span className="field-value">
                                        {userData?.Role?.name || "-"}
                                    </span>
                                )}
                            </div>

                            {reportModel === "sector" && (
                                <div className="contract-multi-select">
                                    <span className="field-title">
                                        Setores{showEdit && "*"}:
                                        {!showEdit && (
                                            <span className="sectors">
                                                {userData?.Sectors?.length
                                                    ? userData?.Sectors?.map(
                                                          (sector, index) => {
                                                              if (
                                                                  userData
                                                                      ?.Sectors
                                                                      ?.length ===
                                                                  index + 1
                                                              ) {
                                                                  return `${sector.name}`;
                                                              }
                                                              return `${sector.name}, `;
                                                          }
                                                      )
                                                    : "-"}
                                            </span>
                                        )}
                                    </span>

                                    {showEdit && (
                                        <Controller
                                            name={"sectors"}
                                            control={control}
                                            render={() => (
                                                <SelectInput
                                                    icon={
                                                        <Icons.Chevron
                                                            color={
                                                                theme.gray600
                                                            }
                                                        />
                                                    }
                                                    options={sectorsOptions}
                                                    positionY="1"
                                                    placeholder={"Setor"}
                                                    value={selectedSectors}
                                                    onChange={(
                                                        opt: TOption[]
                                                    ) => {
                                                        setSelectedSectors(opt);
                                                        setValue(
                                                            "sectors",
                                                            opt
                                                        );
                                                    }}
                                                    isMulti
                                                    disabled={
                                                        getSectors.loading
                                                    }
                                                    error={
                                                        errors.sectors
                                                            ?.message || ""
                                                    }
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                            )}

                            <div className="contract-multi-select">
                                <span className="field-title">
                                    Projetos{showEdit && "*"}:
                                </span>

                                {showEdit ? (
                                    <Controller
                                        name={"projects"}
                                        control={control}
                                        render={() => (
                                            <SelectProjects
                                                positionY="0.5"
                                                options={projectsOptions}
                                                placeholder={"Projeto"}
                                                value={selectedProjects}
                                                onChange={(opt) => {
                                                    setSelectedProjects(
                                                        opt as TOption[]
                                                    );
                                                    setValue(
                                                        "projects",
                                                        opt as TProjectsOptions[]
                                                    );
                                                }}
                                                disabled={
                                                    getProjects.loading ||
                                                    (reportModel === "sector" &&
                                                        selectedSectors.length ===
                                                            0)
                                                }
                                                error={
                                                    errors.projects?.message ||
                                                    ""
                                                }
                                            />
                                        )}
                                    />
                                ) : (
                                    <div className="contract-projects">
                                        <Scroll>
                                            <ul>
                                                {userData?.Projects?.map(
                                                    (project) => (
                                                        <Tag
                                                            color={
                                                                project.color
                                                            }
                                                        >
                                                            {project.name}
                                                        </Tag>
                                                    )
                                                )}
                                            </ul>
                                        </Scroll>
                                    </div>
                                )}
                            </div>
                        </div>

                        {showEdit ? (
                            <div className="icons-group">
                                {contractLoading ? (
                                    <LoadingGray height="34px" />
                                ) : (
                                    <>
                                        <Tooltip
                                            side="left"
                                            positionx={-10}
                                            content={
                                                <s.P>Salvar alterações</s.P>
                                            }
                                        >
                                            <span className="tooltip">
                                                <DesignedAlert
                                                    textBtn={"Salvar"}
                                                    onClickBtn={handleSubmit(
                                                        onSubmit
                                                    )}
                                                    trigger={
                                                        <Icons.Save
                                                            color={
                                                                theme.purple600
                                                            }
                                                        />
                                                    }
                                                    open={
                                                        Object.keys(errors)
                                                            .length > 0
                                                            ? false
                                                            : undefined
                                                    }
                                                    description={
                                                        "Ao salvar alterações as informações serão substituídas. Deseja salvar as alterações?"
                                                    }
                                                    title={"Salvar alterações"}
                                                />
                                            </span>
                                        </Tooltip>

                                        <Tooltip
                                            side="left"
                                            positionx={-10}
                                            content={
                                                <s.P>Descartar alterações</s.P>
                                            }
                                        >
                                            <span className="tooltip">
                                                <DesignedAlert
                                                    textBtn={"Descartar"}
                                                    onClickBtn={() =>
                                                        setShowEdit(!showEdit)
                                                    }
                                                    trigger={
                                                        <Icons.Trash
                                                            color={
                                                                theme.purple600
                                                            }
                                                        />
                                                    }
                                                    description={
                                                        "Ao salvar alterações as informações serão substituídas. Deseja salvar as alterações?"
                                                    }
                                                    title={
                                                        "Descartar alterações"
                                                    }
                                                />
                                            </span>
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                        ) : (
                            <s.Actions>
                                {(viewProfessionalsValues ||
                                    user.id_e === userData.id_e) && (
                                    <Tooltip
                                        side="bottom"
                                        content={
                                            <s.Tooltip>
                                                {showInformation
                                                    ? "Ocultar informações"
                                                    : "Visualizar informações"}
                                            </s.Tooltip>
                                        }
                                    >
                                        <div
                                            onClick={() =>
                                                setShowInformation(
                                                    !showInformation
                                                )
                                            }
                                        >
                                            {showInformation ? (
                                                <Icons.SenhaFechado
                                                    color={theme.purple600}
                                                    title=" "
                                                />
                                            ) : (
                                                <Icons.Eye
                                                    color={theme.purple600}
                                                    title=" "
                                                />
                                            )}
                                        </div>
                                    </Tooltip>
                                )}
                                {manageUsers && (
                                    <Tooltip
                                        side="bottom"
                                        content={<s.Tooltip>Editar</s.Tooltip>}
                                    >
                                        <span className="tooltip">
                                            <Icons.EditOutline
                                                color={theme.purple600}
                                                onClick={() =>
                                                    setShowEdit(!showEdit)
                                                }
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                                {!(
                                    !manageUsers ||
                                    (userData.Role?.name === "Administrador" &&
                                        userData.id_e !== user.id_e)
                                ) && (
                                    <Tooltip
                                        side="bottom"
                                        content={
                                            <s.Tooltip>Configurações</s.Tooltip>
                                        }
                                    >
                                        <PopoverRadix
                                            align="end"
                                            trigger={<Icons.Settings />}
                                        >
                                            <s.Menu>
                                                <div
                                                    onClick={() =>
                                                        setToggleAccountActivationModal(
                                                            true
                                                        )
                                                    }
                                                    className="item"
                                                >
                                                    <Icons.ArrowsCircle
                                                        color={theme.gray800}
                                                    />
                                                    {userData.active
                                                        ? "Inativar profissional"
                                                        : "Ativar profissional"}
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        setDeleteAccountModal(
                                                            true
                                                        )
                                                    }
                                                    className="item item--delete"
                                                >
                                                    <Icons.Trash
                                                        color={theme.red}
                                                    />
                                                    Excluir profissional
                                                </div>
                                            </s.Menu>
                                        </PopoverRadix>
                                    </Tooltip>
                                )}
                            </s.Actions>
                        )}
                    </>
                )}
            </s.Contract>
        </s.Content>
    );
};
