import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TAddBudgetProfile = {
    budgetId: string;
    params: {
        profileId?: string;
        costPerHour?: number;
        responsibleId?: string;
    };
};

export default async ({ params, budgetId }: TAddBudgetProfile) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/add-profile`,
        method: "post",
        body: params,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({ body, statusCode });
};
