import { Tabs, Tab, Box, Typography } from "@mui/material";
import React, { useState } from "react";

import { useUser } from "../../../../context";
import AnnotationTab from "./tabs-content/annotation-tab/annotation-tab";
import FilesTab from "./tabs-content/files-tab/files-tab";
import OverviewTab from "./tabs-content/overview/overview-tab";
import ProfileTab from "./tabs-content/profile-tab/profile-tab";
import ScopeTab from "./tabs-content/scope-tab/scope-tab";

const TabPanel = ({
    children,
    value,
    index,
}: {
    children: React.ReactNode;
    value: number;
    index: number;
}) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

const CustomTabs: React.FC = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const { verifyPermission } = useUser();
    const organizationPermission = verifyPermission({
        id: "manage_teams_projects",
        rule: "enabled",
    });
    return (
        <Box>
            <Tabs
                value={tabIndex}
                onChange={(_, newIndex) => setTabIndex(newIndex)}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="ESCOPO" />

                <Tab
                    label="PERFIS DE ATIVIDADE"
                    disabled={!organizationPermission}
                />
                <Tab label="VISÃO GERAL" disabled={!organizationPermission} />

                <Tab label="ANOTAÇÕES" disabled={!organizationPermission} />
                <Tab label="ANEXOS" disabled={!organizationPermission} />
                {/* <Tab label="LINHA DO TEMPO" disabled />
                 */}
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
                <ScopeTab />
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
                <ProfileTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <OverviewTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                <AnnotationTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
                <FilesTab />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
                <Typography>Conteúdo de Anexos</Typography>
            </TabPanel>
        </Box>
    );
};

export default CustomTabs;
