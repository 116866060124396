import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: "#663398",
        },
        text: {
            primary: "#021547",
        },
        background: {
            default: "#FFFFFF",
        },
    },
    typography: {
        fontFamily: "Manrope, sans-serif",
        h1: {
            fontSize: "6rem",
            fontWeight: 300,
            lineHeight: "7.0019rem",
            letterSpacing: "-1.5px",
        },
        h2: {
            fontSize: "3.75rem",
            fontWeight: 300,
            lineHeight: "4.5rem",
            letterSpacing: "-0.5px",
        },
        h3: {
            fontSize: "3rem",
            fontWeight: 400,
            lineHeight: "3.5rem",
        },
        h4: {
            fontSize: "2.125rem",
            fontWeight: 400,
            lineHeight: "2.625rem",
            letter: "0.0156rem",
        },
        h5: {
            fontFamily: "Noto Sans",
            fontSize: "1.25rem",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "1.75rem",
            letterSpacing: "0.0019rem",
        },
        h6: {
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: "2rem",
            letter: "0.0094rem",
        },

        subtitle1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "1.75rem",
            letter: "0.0094rem",
        },
        subtitle2: {
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: "1.375rem",
            letter: "0.0063rem",
        },
        body1: {
            fontFamily: "Work Sans",
            fontSize: "1rem",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%",
            letterSpacing: "0.0094rem",
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
            letter: "0.0106rem",
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
            letter: "0.025rem",
        },
        overline: {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: "2rem",
            letter: "0.0625rem",
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: "h1",
                    h2: "h2",
                    h3: "h3",
                    h4: "h4",
                    h5: "h5",
                    h6: "h6",
                    subtitle1: "h6",
                    subtitle2: "h6",
                    body1: "p",
                    body2: "p",
                    caption: "span",
                    overline: "span",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    borderRadius: "8px", // Borda arredondada
                    "& .MuiInputBase-root": {
                        color: "#021547", // Cor do texto
                    },
                    "& .MuiInputLabel-root": {
                        color: "#666", // Cor do label
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#663398", // Cor do border quando focado
                        },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "1.5rem",
                    letterSpacing: "0.07rem",
                    padding: "0.5rem 1.375rem",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: "Work Sans",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    color: "#6F6C80",
                    minWidth: "auto",
                    padding: "6px 16px",
                    "&.Mui-selected": {
                        color: "#4C3478",
                        fontWeight: 600,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontFamily: "Noto Sans",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "1.5rem",
                    letterSpacing: "0.0106rem",
                    color: "#333",
                },
            },
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: "#663398",
        },
        text: {
            primary: "#021547",
        },
        background: {
            default: "#121212",
        },
    },
    typography: {
        fontFamily: "Manrope, sans-serif",
        h1: {
            fontSize: "6rem",
            fontWeight: 300,
            lineHeight: "7.0019rem",
            letterSpacing: "-1.5px",
        },
        h2: {
            fontSize: "3.75rem",
            fontWeight: 300,
            lineHeight: "4.5rem",
            letterSpacing: "-0.5px",
        },
        h3: {
            fontSize: "3rem",
            fontWeight: 400,
            lineHeight: "3.5rem",
        },
        h4: {
            fontSize: "2.125rem",
            fontWeight: 400,
            lineHeight: "2.625rem",
            letter: "0.0156rem",
        },
        h5: {
            fontSize: "1.5rem",
            fontWeight: 400,
            lineHeight: "2rem",
        },
        h6: {
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: "2rem",
            letter: "0.0094rem",
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "1.75rem",
            letter: "0.0094rem",
        },
        subtitle2: {
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: "1.375rem",
            letter: "0.0063rem",
        },
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "1.5rem",
            letter: "0.0094rem",
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
            letter: "0.0106rem",
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
            letter: "0.025rem",
        },
        overline: {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: "2rem",
            letter: "0.0625rem",
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: "h1",
                    h2: "h2",
                    h3: "h3",
                    h4: "h4",
                    h5: "h5",
                    h6: "h6",
                    subtitle1: "h6",
                    subtitle2: "h6",
                    body1: "p",
                    body2: "p",
                    caption: "span",
                    overline: "span",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    borderRadius: "8px",
                    "& .MuiInputBase-root": {
                        color: "#021547",
                    },
                    "& .MuiInputLabel-root": {
                        color: "#666",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "#663398",
                        },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Noto Sans",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "1.5rem",
                    letterSpacing: "0.07rem",
                    padding: "0.5rem 1.375rem",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: "Work Sans",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    color: "#6F6C80",
                    minWidth: "auto",
                    padding: "6px 16px",
                    "&.Mui-selected": {
                        color: "#4C3478",
                        fontWeight: 600,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontFamily: "Noto Sans",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "1.5rem",
                    letterSpacing: "0.0106rem",
                    color: "#fff",
                },
            },
        },
    },
});

export const chooseMUITheme = () => {
    if (localStorage.getItem("tema") === "light") return lightTheme;

    const themesAreCorrect =
        Object.keys(lightTheme).length !== Object.keys(darkTheme).length;
    if (themesAreCorrect && process.env.NODE_ENV === "development")
        throw new Error(
            "Os temas não possuem a mesma quantidade de propriedades"
        );

    return darkTheme;
};
