import styled from "styled-components";

export const Container = styled.div`
    padding: 3vh;
    width: 100%;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
`;

export const DeleteWrapper = styled.div`
    position: absolute;
    top: 150px;
    right: 50px;

    button {
        padding: 0.5rem 1rem;
    }
`;

export const BreadcrumbContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: left;
    margin-top: 2rem;
`;
