import { Close, InfoOutlined } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    Paper,
    Popover,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { Tooltip } from "../../../../../../components";
import { colors } from "../../../../../../components/atoms/color-picker/color-picker";
import { useFetch } from "../../../../../../hooks";
import { manageUser } from "../../../../../../services";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budgets";

type TUser = {
    id_e: string;
    avatar?: string;
    name?: string;
    role?: string;
    Role?: {
        name?: string;
    };
    hidden?: boolean;
    Sectors?: {
        id_e: string;
        name: string;
    }[];
    Permissions: string[];
    UserProcessProfiles?: {
        Profile: {
            abbreviation: string;
            name: string;
            id_e: string;
        };
    }[];
};

type TSector = {
    id_e: string;
    Projects?: [
        {
            color?: string;
            id_e?: string;
            name?: string;
        }
    ];
    Users: TUser[];
    created_at?: string;
    description?: string;
    name?: string;
};

type TProfiles = TUser &
    Partial<{
        id_e: string;
        userProcessProfileIds: string[];
        isReviewer: boolean;
    }>;

type TFields = {
    profiles: TProfiles[];
};

type TStep2 = {
    storeData: <IProject>(data: IProject) => void;
    manageSteps: Dispatch<SetStateAction<1 | 2>>;
    sectorsData?: TSector[];
    billersData: TUser[];
    loading: boolean;
    setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
    setLinkedSectors: React.Dispatch<string[]>;
    setProjectTeam: React.Dispatch<React.SetStateAction<TUser[]>>;
    setProjectReviewer: React.Dispatch<React.SetStateAction<TUser[]>>;
    setProjectBiller: React.Dispatch<React.SetStateAction<TUser[]>>;
    projectTeam: TUser[];
    projectReviewer: TUser[];
    projectBiller: TUser[];
    dataBudgetById?: TReadBudgetsResponse;
};

type TActiviteProfileTypes = {
    color:
        | "#278027"
        | "#F28500"
        | "#DD04DD"
        | "#0B49EC"
        | "#9D0000"
        | "#03BA78"
        | "#0CB8B8"
        | "#FF015C"
        | "#9933FF"
        | "#824700"
        | "#800380"
        | "#4B07BF"
        | "#D14141"
        | "#8BB908"
        | "#333333";
    id_e: string;
    name: string;
    abbreviation: string;
}[];

const Step2Project = ({
    storeData,
    manageSteps,
    sectorsData,
    billersData,
    loading,
    setProjectTeam,
    setProjectReviewer,
    setProjectBiller,
    projectTeam,
    projectReviewer,
    projectBiller,
    setIsDone,
    setLinkedSectors,
    dataBudgetById,
}: TStep2) => {
    const [usersData, setUsersData] = useState<TUser[]>([]);
    const [list, setList] = useState<
        {
            id: string;
            name: string;
        }[]
    >([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedUser, setSelectedUser] = useState<TUser | null>(null);
    const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);
    const [
        activiteProfilesFromUsersSelected,
        setActiviteProfilesFromUsersSelected,
    ] = useState<TActiviteProfileTypes>();

    const isCreatedByBudget = !!dataBudgetById;

    const { control, handleSubmit } = useForm<TFields>({
        defaultValues: {
            profiles: projectTeam ?? [],
        },
    });
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "profiles",
    });

    const getAllUsers = useFetch({
        fn: manageUser.getAllUsers,
        errorMessage: "Erro ao pegar usuários da organização",
        params: { sectors: true },
        start: true,
    });

    const addTeamUser = (collaborator: TUser) => {
        const profiles = fields;
        const added = profiles?.find((user) => user.id_e === collaborator.id_e);

        if (projectTeam) {
            if (added) {
                toast.error("Profissional já adicionado");
            } else {
                append({
                    ...collaborator,
                    userProcessProfileIds: [],
                    isReviewer: false,
                });
            }
        }
    };

    const correctUsersList = (userId: string) => {
        sectorsData?.forEach((sector: TSector) => {
            const removedUser = sector.Users.find(
                (user: TUser) => user.id_e === userId
            );
            if (removedUser) {
                setUsersData((prevUsers) => {
                    const isAlreadyInList = prevUsers.some((user: TUser) => {
                        return user.id_e === removedUser.id_e;
                    });
                    if (!isAlreadyInList) {
                        return [...prevUsers, removedUser];
                    }
                    return prevUsers;
                });
            }
        });
    };

    const removeTeamUser = (id: string, index: number) => {
        const newReviewers = projectReviewer?.filter(
            (rev: TUser) => rev.id_e !== id
        );
        remove(index);
        setProjectReviewer(newReviewers);
        correctUsersList(id);
    };

    const addBiller = (biller: TUser) => {
        const added = projectBiller?.find((user) => user.id_e === biller.id_e);
        if (!projectBiller) return;
        if (added) {
            toast.error("Profissional já adicionado");
            return;
        }

        setProjectBiller([...projectBiller, biller]);
    };

    const removeBiller = (id: string) => {
        const newBiller = projectBiller?.filter((rev) => rev.id_e !== id);
        setProjectBiller(newBiller);
    };

    const handleSelectUserForTeam = (collaborator: TUser) => {
        const usersWithoutSelected = usersData.filter(
            (user: TUser) => user.id_e !== collaborator.id_e
        );
        addTeamUser(collaborator);
        setUsersData(usersWithoutSelected);
    };
    const handleOpen = (
        event: React.MouseEvent<HTMLElement>,
        user: TProfiles
    ) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
        setActiviteProfilesFromUsersSelected((prev) => {
            const userProcessProfiles = user?.UserProcessProfiles ?? [];
            const profilesMap = userProcessProfiles
                .map((profile) => ({
                    ...profile.Profile,
                    color: colors[Math.floor(Math.random() * colors.length)],
                }))
                .filter((profile) => {
                    const validateProfileExistence = prev?.some(
                        (findProfile) => findProfile.id_e === profile.id_e
                    );
                    if (validateProfileExistence === false) return profile;
                    return false;
                });

            const uniqueProfiles = new Map(
                [...(prev ?? []), ...profilesMap].map((profile) => [
                    profile.id_e,
                    profile,
                ])
            );

            return Array.from(uniqueProfiles.values());
        });

        const foundUser = fields.find((profile) => profile.id_e === user.id_e);
        if (foundUser) {
            setSelectedProfiles(foundUser.userProcessProfileIds || []);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedUser(null);
    };

    const open = Boolean(anchorEl);
    const profileAtiviteOptions = selectedUser?.UserProcessProfiles?.map(
        (profile) => ({
            label: profile.Profile.name,
            value: profile.Profile.id_e,
        })
    );

    const handleCheckboxChange = (profileId: string) => {
        setSelectedProfiles((prev) =>
            prev.includes(profileId)
                ? prev.filter((id) => id !== profileId)
                : [...prev, profileId]
        );
    };

    const handleAdduserProcessProfileIds = (
        field: TProfiles,
        activitesProfilesId: string[]
    ) => {
        const profiles = fields;
        const getIndexFromProfiles = profiles.findIndex(
            (profile) => profile.id_e === field.id_e
        );
        update(getIndexFromProfiles, {
            ...field,
            userProcessProfileIds: activitesProfilesId,
        });
    };

    const handleConfirmProfiles = () => {
        if (!selectedUser) return;

        handleAdduserProcessProfileIds(selectedUser, selectedProfiles);
        handleClose();
    };

    const TeamTable = () => {
        if (fields.length)
            return (
                <TableContainer
                    sx={{
                        boxShadow: "unset",
                        backgroundColor: "transparent",
                    }}
                    component={Paper}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                <TableCell>Atuação</TableCell>
                                {isCreatedByBudget && (
                                    <TableCell>Perfil de atividade*</TableCell>
                                )}
                                <TableCell>Revisor</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((collaborator, i) => {
                                const findProfilesById =
                                    activiteProfilesFromUsersSelected?.filter(
                                        (profile) =>
                                            collaborator.userProcessProfileIds?.includes(
                                                profile.id_e
                                            )
                                    );

                                return (
                                    <>
                                        <TableRow key={collaborator.id_e}>
                                            <TableCell>
                                                <Typography>
                                                    {collaborator.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {collaborator.role}
                                            </TableCell>
                                            {isCreatedByBudget && (
                                                <TableCell>
                                                    <Box display="flex" gap={1}>
                                                        {findProfilesById &&
                                                        findProfilesById?.length >
                                                            0 ? (
                                                            <IconButton
                                                                onClick={(e) =>
                                                                    handleOpen(
                                                                        e,
                                                                        collaborator
                                                                    )
                                                                }
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {findProfilesById?.map(
                                                                        (
                                                                            profile,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <Box
                                                                                    key={
                                                                                        profile.abbreviation
                                                                                    }
                                                                                    sx={{
                                                                                        display:
                                                                                            "flex",
                                                                                        alignItems:
                                                                                            "center",
                                                                                        justifyContent:
                                                                                            "center",
                                                                                        height: "2.5rem",
                                                                                        width: "2.5rem",
                                                                                        borderRadius:
                                                                                            "50%",
                                                                                        backgroundColor:
                                                                                            profile.color,
                                                                                        border: "2px solid #FFF",
                                                                                        marginLeft:
                                                                                            index ===
                                                                                            0
                                                                                                ? "0"
                                                                                                : "-0.5rem",
                                                                                        zIndex:
                                                                                            findProfilesById.length -
                                                                                            index,
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            color: "#FFF",
                                                                                            textAlign:
                                                                                                "center",
                                                                                            fontFamily:
                                                                                                "Noto Sans",
                                                                                            fontSize:
                                                                                                "0.75rem",
                                                                                            fontWeight: 600,
                                                                                            lineHeight:
                                                                                                "1.25rem",
                                                                                            letterSpacing:
                                                                                                "0.0088rem",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            profile.abbreviation
                                                                                        }
                                                                                    </Typography>
                                                                                </Box>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Box>
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                onClick={(e) =>
                                                                    handleOpen(
                                                                        e,
                                                                        collaborator
                                                                    )
                                                                }
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        justifyContent:
                                                                            "center",
                                                                        height: "2.5rem",
                                                                        width: "2.5rem",
                                                                        borderRadius:
                                                                            "50%",
                                                                        backgroundColor:
                                                                            "#BDBDBD",
                                                                    }}
                                                                >
                                                                    <Divider
                                                                        sx={{
                                                                            backgroundColor:
                                                                                "#fff",
                                                                            width: "0.25rem",
                                                                            height: "0.125rem",
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            )}
                                            <TableCell>
                                                <Controller
                                                    name={`profiles.${i}.isReviewer`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Switch
                                                            {...field}
                                                            checked={
                                                                field.value
                                                            }
                                                            color="secondary"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() =>
                                                        removeTeamUser(
                                                            collaborator.id_e,
                                                            i
                                                        )
                                                    }
                                                >
                                                    <Close />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        {selectedUser?.id_e ===
                                            collaborator.id_e && (
                                            <Popover
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "left",
                                                }}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    sx={{
                                                        p: "1rem",
                                                        minWidth: "21.625rem",
                                                    }}
                                                    gap={1}
                                                >
                                                    {profileAtiviteOptions?.map(
                                                        (profile) => (
                                                            <Box
                                                                key={
                                                                    profile.value
                                                                }
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    gap: "0.5rem",
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={selectedProfiles?.includes(
                                                                        profile.value
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            profile.value
                                                                        )
                                                                    }
                                                                />
                                                                <Typography variant="body2">
                                                                    {
                                                                        profile.label
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    )}
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "flex-end",
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            onClick={
                                                                handleConfirmProfiles
                                                            }
                                                        >
                                                            CONFIRMAR
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Popover>
                                        )}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            );

        return null;
    };

    const renderBillerTable = () => {
        if (projectBiller?.length)
            return (
                <TableContainer
                    sx={{
                        boxShadow: "unset",
                        backgroundColor: "transparent",
                    }}
                    component={Paper}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Faturador</TableCell>
                                <TableCell>Atuação</TableCell>

                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectBiller.map((biller) => (
                                <TableRow key={biller.id_e}>
                                    <TableCell>
                                        <Typography>{biller.name}</Typography>
                                    </TableCell>
                                    <TableCell>{biller?.Role?.name}</TableCell>

                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() =>
                                                removeBiller(biller.id_e)
                                            }
                                        >
                                            <Close />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        return null;
    };

    const retrieveSelectedUsersSectors = (users: TUser[]): string[] => {
        const sectors: string[] = [];
        const usersSectors = users.map((user) => user?.Sectors);
        usersSectors.forEach((userSectors) => {
            userSectors?.forEach((sector) => {
                if (!sectors.includes(sector.id_e)) {
                    sectors.push(sector.id_e);
                }
            });
        });
        return sectors;
    };

    const onSubmit = (data: TFields) => {
        const projectTeamData = data.profiles;
        const projectReviewerData = data.profiles.filter(
            (profile) => profile.isReviewer === true
        );
        const usersProfile = projectTeamData.map((user) => ({
            userId: user.id_e,
            userProcessProfileIds: user.userProcessProfileIds,
            isReviewer: !!user?.isReviewer,
            isBiller: projectBiller.some(
                (billerUser) => billerUser.id_e === user.id_e
            ),
        }));
        const dataToFetch = {
            projectTeam: projectTeamData,
            projectReviewer: projectReviewerData,
            userProfiles: usersProfile,
        };
        setProjectTeam(projectTeamData);
        setProjectReviewer(projectReviewerData);
        setProjectBiller(projectBiller);
        setLinkedSectors(retrieveSelectedUsersSectors(projectTeamData));
        storeData({ ...dataToFetch });
        setIsDone(true);
    };

    useEffect(() => {
        if (getAllUsers.response) {
            setUsersData(getAllUsers.response);
            setList(
                getAllUsers.response.map(
                    (user: { id_e: string; name: string }) => {
                        return {
                            id: user.id_e,
                            name: user.name,
                        };
                    }
                )
            );
        }
    }, [getAllUsers.response]);

    // useEffect(() => {
    //     setBillers(billersData);
    // }, [billersData]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
                sx={{
                    gap: "2rem",
                }}
            >
                <Stack
                    sx={{
                        gap: "1.5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1.5rem",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h5">
                            Defina os Colaboradores de acordo com cada perfil
                        </Typography>
                        <Tooltip
                            side="bottom"
                            backgroundColor="rgba(97, 97, 97, 0.9)"
                            content={
                                <Box
                                    sx={{
                                        padding: "0.25rem 0.5rem",
                                        maxWidth: "27.5rem",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#FFF",
                                            fontFamily: "Noto Sans",
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "1.25rem",
                                            letterSpacing: "0.0088rem",
                                        }}
                                    >
                                        Defina o time que ira compor esse
                                        projeto, e quais colaboradores serão
                                        revisores do projeto (Possuem acesso a
                                        relatórios de atividade)
                                    </span>
                                </Box>
                            }
                        >
                            <InfoOutlined
                                sx={{
                                    color: "#B93BDC",
                                }}
                            />
                        </Tooltip>
                    </Box>

                    <Autocomplete
                        options={list}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Montar Time *"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        fullWidth
                        onChange={(_, newValue) => {
                            const collaborator = usersData.find(
                                (data) => data.id_e === newValue.id
                            );
                            if (collaborator) {
                                handleSelectUserForTeam(collaborator);
                            }
                        }}
                        isOptionEqualToValue={(option, value) =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            option?.id === value?.id
                        }
                        disableClearable
                    />
                </Stack>

                {TeamTable()}

                <Stack
                    sx={{
                        gap: "1.5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1.5rem",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h5">
                            Defina os Faturadores do projeto
                        </Typography>
                        <Tooltip
                            side="bottom"
                            backgroundColor="rgba(97, 97, 97, 0.9)"
                            content={
                                <Box
                                    sx={{
                                        padding: "0.25rem 0.5rem",
                                        maxWidth: "27.5rem",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#FFF",
                                            fontFamily: "Noto Sans",
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "1.25rem",
                                            letterSpacing: "0.0088rem",
                                        }}
                                    >
                                        Aqui você define quem será responsável
                                        por faturar os relatórios dos
                                        profissionais deste projeto, ou seja,
                                        responsável reportar ao profissional o
                                        valor que deve ser gerado na nota fiscal
                                        de pagamento.
                                    </span>
                                </Box>
                            }
                        >
                            <InfoOutlined
                                sx={{
                                    color: "#B93BDC",
                                }}
                            />
                        </Tooltip>
                    </Box>

                    <Autocomplete
                        options={fields}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Montar Time *"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        fullWidth
                        onChange={(_, newValue) => {
                            addBiller(newValue);
                        }}
                        isOptionEqualToValue={(option, value) =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            option?.id === value?.id
                        }
                        disableClearable
                    />
                </Stack>

                <div className="container-table">{renderBillerTable()}</div>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "0.5rem",
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={() => manageSteps(1)}
                        type="button"
                        disabled={loading}
                    >
                        Voltar
                    </Button>
                    <Button variant="contained" type="submit" loading={loading}>
                        Criar projeto
                    </Button>
                </Box>
            </Stack>
        </form>
    );
};

export default Step2Project;
