import { useQuery } from "@tanstack/react-query";

import { readBudgetsLevelsDetailedById } from "../../services/budget";
import { TReadBudgetsLevelsDetailsByIdResponse } from "../../services/budget/read-budgets-levels-detailed-by-id";

type ReadBudgetDetailedLevelsById = {
    budgetId: string;
    params: {
        page: number;
        length: number;
        search?: string;
    };
};

export function useReadBudgetDetailedLevelById({
    budgetId,
    params,
}: ReadBudgetDetailedLevelsById) {
    return useQuery<
        { data: TReadBudgetsLevelsDetailsByIdResponse },
        unknown,
        { data: TReadBudgetsLevelsDetailsByIdResponse }
    >({
        enabled: true,
        queryKey: [
            `readBudgetDetailedLevelsById/${budgetId}?page=${params.page}&`,
        ],
        queryFn: () => readBudgetsLevelsDetailedById({ budgetId, params }),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
