import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import {
    Box,
    Button,
    DialogActions,
    IconButton,
    InputAdornment,
    Stack,
    Typography,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { theme } from "../../../../../../assets";
import ColorPicker, {
    PickableColor,
} from "../../../../../../components/atoms/color-picker/color-picker";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import {
    createBudgetLevel,
    updateBudgetLevel,
} from "../../../../../../services/budget";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import { TLevel } from "../../../../../../services/budget/read-budgets";

const validationSchema = yup.object().shape({
    modules: yup
        .array()
        .of(
            yup.object().shape({
                name: yup.string().required("O nome é obrigatório"),
                color: yup.string().required("A cor é obrigatória"),
                id_e: yup.string().optional(),
            })
        )
        .test("unique-names", "", function (modules) {
            if (!modules) return true;

            const nameCounts = new Map<string, number>();
            let lastDuplicateIndex: number | null = null;

            modules.forEach((module, index) => {
                const count = nameCounts.get(module.name!) || 0;
                nameCounts.set(module.name!, count + 1);

                if (count > 0) {
                    lastDuplicateIndex = index;
                }
            });

            if (lastDuplicateIndex !== null) {
                return this.createError({
                    path: `modules[${lastDuplicateIndex}].name`,
                    message: "Este nome já foi utilizado anteriormente",
                });
            }

            return true;
        }),
});

interface IModule {
    name: string;
    color: string;
    id_e: string;
}

interface IFormValues {
    modules: IModule[];
}

export const ScopeDialogLevel1Content = ({
    budgetId,
    handleClose,
    modulesData,
    refetch,
}: {
    budgetId: string;
    handleClose: () => void;
    modulesData: TLevel;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsResponse, unknown>>;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const defaultValues = modulesData.Level.map((level) => ({
        name: level.name,
        color: level.MainLevel.color,
        id_e: level.id_e,
    }));

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IFormValues>({
        defaultValues: { modules: defaultValues },
        resolver: yupResolver(validationSchema),
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "modules",
    });

    const submit = async (data: IFormValues) => {
        setIsLoading(true);
        const updatedModules = data.modules.filter((module, index) => {
            const original = defaultValues[index];
            if (original)
                return (
                    module.name !== original?.name ||
                    module.color !== original?.color ||
                    module.id_e !== original?.id_e
                );
            return module;
        });

        // Separa os módulos entre criação e atualização
        const modulesToUpdate = updatedModules.filter(
            (module) => module.id_e !== ""
        );
        const modulesToCreate = updatedModules.filter(
            (module) => module.id_e === ""
        );

        try {
            const updatePromises = modulesToUpdate.map((module) => {
                const { name, color, id_e } = module;
                const dataUpdated = {
                    name,
                    color,
                };
                return updateBudgetLevel(budgetId, id_e, dataUpdated);
            });

            const createPromises = modulesToCreate.map((module) =>
                createBudgetLevel(budgetId, {
                    ...module,
                    levelOrder: 1,
                    id_e: undefined,
                })
            );

            await Promise.all([...updatePromises, ...createPromises]);
            await refetch().then((data) => {
                const newValuesToForm = data.data?.LevelConfig.find(
                    (level) => level.levelOrder === 1
                )?.Level.map((level) => ({
                    name: level.name,
                    color: level.MainLevel.color,
                    id_e: level.id_e,
                }));
                if (newValuesToForm) {
                    setValue("modules", newValuesToForm);
                }
            });
            toast.success(`${modulesData.name} editado com sucesso!`);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Erro ao processar as requisições:", error);
        }
    };

    const setColor = (index: number, color: PickableColor) => {
        setValue(`modules.${index}.color`, color);
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack
                sx={{
                    gap: "1.5rem",
                    overflowY: "auto",
                    pt: "0.4rem",
                    maxHeight: "37.5rem",
                }}
            >
                {fields.map((field, index) => {
                    const errorColor =
                        errors && errors?.modules?.[index]?.color;
                    return (
                        <Box
                            key={field.id}
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                gap: "1rem",
                                width: "100%",
                            }}
                        >
                            <CustomTextField
                                control={control}
                                name={`modules.${index}.name`}
                                label={`Nome do ${modulesData.name}`}
                                fullWidth
                                endAdornment={
                                    field.id_e.length === 0 ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => remove(index)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined
                                }
                            />
                            <Stack
                                sx={{
                                    position: "relative",
                                    pr: "0.25rem",
                                }}
                            >
                                <ColorPicker
                                    onSelect={(color) => setColor(index, color)}
                                    title="Escolha a cor do Nível"
                                />
                                {errorColor && (
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            position: "absolute",
                                            bottom: "-1.5rem",
                                            fontSize: "0.75rem",
                                            marginLeft: "0.875rem",
                                            marginRight: "0.875rem",
                                            color: "#d32f2f",
                                        }}
                                    >
                                        {errorColor?.message}
                                    </Typography>
                                )}
                            </Stack>
                        </Box>
                    );
                })}
                <Button
                    variant="text"
                    sx={{ marginTop: "1rem", maxWidth: "20rem" }}
                    onClick={() => append({ name: "", color: "", id_e: "" })}
                >
                    + ADICIONAR NOVO {modulesData.name}
                </Button>
            </Stack>

            <DialogActions>
                <Button
                    variant="text"
                    onClick={handleClose}
                    sx={{
                        color: theme.purple500,
                        fontWeight: 500,
                        fontSize: "0.875rem",
                    }}
                >
                    VOLTAR
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    sx={{
                        color: theme.white,
                        fontWeight: 500,
                        fontSize: "0.875rem",
                    }}
                >
                    SALVAR
                </Button>
            </DialogActions>
        </form>
    );
};
