import { useQuery } from "@tanstack/react-query";

import { readLevelById } from "../../services/budget";
import { TReadLevelByIdResponse } from "../../services/budget/read-level-by-id";

type ReadLevelByIdParams = {
    budgetId: string;
    levelId: string;
};

export function useReadLevelById({ budgetId, levelId }: ReadLevelByIdParams) {
    return useQuery<TReadLevelByIdResponse, unknown, TReadLevelByIdResponse>({
        enabled: true,
        queryKey: [`readLevelById/${budgetId}/level/${levelId}/comment`],
        queryFn: () => readLevelById({ budgetId, levelId }),
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
