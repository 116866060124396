import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
} from "@mui/material";
import { useState } from "react";

import { theme } from "../../../../../../assets";
import { TSectionActivity } from "../../types";

type TActivityTable = {
    isClosedScope: boolean;
    onSort: (key: keyof TSectionActivity, order: "asc" | "desc") => void;
    children: React.ReactNode;
    sortCriteria: {
        key: keyof TSectionActivity | undefined;
        order: "asc" | "desc" | undefined;
    };
};

const ActivityTable = ({
    onSort,
    isClosedScope,
    children,
    sortCriteria,
}: TActivityTable) => {
    const [order, setOrder] = useState<"asc" | "desc">(
        sortCriteria.order ?? "asc"
    );
    const [orderBy, setOrderBy] = useState<keyof TSectionActivity>(
        sortCriteria.key ?? "name"
    );

    const handleSort = (key: keyof TSectionActivity) => {
        const isAsc = orderBy === key && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(key);
        onSort(key, newOrder);
    };

    return (
        <TableContainer
            sx={{
                boxShadow: "unset",
                backgroundColor: "transparent",
                borderLeft: "2px solid #EDEDED",
            }}
            component={Paper}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === "name"}
                                direction={orderBy === "name" ? order : "asc"}
                                onClick={() => handleSort("name")}
                                sx={{
                                    color:
                                        orderBy === "name"
                                            ? theme.primary.main
                                            : "inherit",
                                }}
                            >
                                Nome
                            </TableSortLabel>
                        </TableCell>
                        {isClosedScope && <TableCell>Perfis</TableCell>}
                        <TableCell>Colaborador</TableCell>
                        <TableCell>Prazo</TableCell>
                        <TableCell>Prioridade</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={
                                    orderBy !== null &&
                                    orderBy === "expectedMinutes"
                                }
                                direction={
                                    orderBy === "expectedMinutes"
                                        ? order
                                        : "asc"
                                }
                                onClick={() => handleSort("expectedMinutes")}
                                sx={{
                                    color:
                                        orderBy === "expectedMinutes"
                                            ? theme.primary.main
                                            : "inherit",
                                }}
                            >
                                Horas
                            </TableSortLabel>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                {children}
            </Table>
        </TableContainer>
    );
};

export default ActivityTable;
