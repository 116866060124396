import { FilterListOutlined, Search } from "@mui/icons-material";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../assets";
import { useFetch } from "../../../hooks";
import { useReadBudgetsStatus } from "../../../hooks/react-query/use-read-budgets-status";
import { TGetClient, TOption } from "../../../models";
import { TReadBudgetsResponse } from "../../../services/budget/read-budgets";
import readBudgetTypes, {
    TReadBudgetTypes,
    TReadBudgetTypesResponse,
} from "../../../services/budget/type/read-budget-types";
import getClients, {
    TGetClientResponse,
} from "../../../services/clients/get-clients";
import { filterPopover } from "../budgets-home";
import { BudgetTable, type DisplayedBudgetStatus } from "./budget-table";
import FilterPopover from "./filter-popover";
import * as s from "./styled-tab-budget";

type TTabBudget = {
    budgets: TReadBudgetsResponse[];
    filterState: [string, React.Dispatch<React.SetStateAction<string>>];
    budgetsCountState: [number, React.Dispatch<React.SetStateAction<number>>];
    currentPageState: [number, React.Dispatch<React.SetStateAction<number>>];
    filterPopoverData: filterPopover | undefined;
    handleSetPopoverData: (data: filterPopover) => void;
    refetchBudget: () => void;
};

export const NoBudget = () => {
    const navigate = useNavigate();

    return (
        <s.Container>
            <s.NoBudgetContainer>
                <BudgetTable
                    refetchBudget={() => {
                        console.log("null");
                    }}
                    budgets={[]}
                />
                <s.NoBudgetText>Nenhum Orçamento encontrado</s.NoBudgetText>
                <s.NoBudgetSubtext>
                    Comece um orçamento clicando no botão abaixo
                </s.NoBudgetSubtext>
                <s.NoBudgetCreationButton
                    onClick={() => navigate("/financial/budget/create")}
                >
                    <s.CreationButtonTexts>
                        <Icons.Plus color={theme.purple500} strokeWidth="3" />
                        CRIAR ORÇAMENTO
                    </s.CreationButtonTexts>
                </s.NoBudgetCreationButton>
            </s.NoBudgetContainer>
        </s.Container>
    );
};

export const TabBudget = ({
    budgets,
    budgetsCountState: [budgetsCount, setBudgetsCount],
    filterState,
    currentPageState: [currentPage, setCurrentPage],
    filterPopoverData,
    handleSetPopoverData,
    refetchBudget,
}: TTabBudget) => {
    const [budgetTypes, setBudgetTypes] = useState<TOption[]>([]);
    const [clients, setClients] = useState<TOption[]>([]);
    const [filter, setFilter] = filterState;
    const [pagesList, setPagesList] = useState<number[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const getClientsFetch = useFetch<TGetClient, TGetClientResponse[], any>({
        fn: getClients,
        params: { active: true },
    });

    const readBudgetTypeFetch = useFetch<
        TReadBudgetTypes,
        TReadBudgetTypesResponse[],
        any
    >({
        fn: readBudgetTypes,
        params: {},
    });

    const params = {
        page: 1,
        length: 100,
    };

    const { data: busgetsStatus } = useReadBudgetsStatus({
        ...params,
    });

    const optionsStatus =
        busgetsStatus?.data?.map((status) => ({
            label: status.name,
            value: status.id_e,
            color: status.color,
        })) ?? [];

    const navigate = useNavigate();

    const handleOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [anchorEl, setAnchorEl]
    );

    const handleCleanFilters = () => {
        handleSetPopoverData({
            isArchived: false,
        });
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [anchorEl, setAnchorEl]);

    const isFilterPopoverEmpty = (filterPopover: Record<string, any>) =>
        Object.values(filterPopover).every((value) =>
            Array.isArray(value) ? value.length === 0 : value === false
        );

    const translateDate = (rawDate: string): string => {
        return new Date(rawDate).toLocaleDateString("pt-br");
    };

    useEffect(() => {
        const arrayLen = budgetsCount ? Math.ceil((budgetsCount ?? 1) / 10) : 1;

        setPagesList(
            Array(arrayLen)
                .fill(0)
                .map((_, i) => i + 1)
        );
    }, [budgetsCount]);

    useEffect(() => {
        if (readBudgetTypeFetch.response) {
            const responseWithOptions: TOption[] =
                readBudgetTypeFetch.response.map((bt) => ({
                    label: bt.name,
                    value: bt.id_e,
                }));

            setBudgetTypes(responseWithOptions);
        }
    }, [readBudgetTypeFetch.response]);

    useEffect(() => {
        if (getClientsFetch.response) {
            const responseWithOptions: TOption[] = getClientsFetch.response.map(
                (bt) => ({
                    label: bt.name,
                    value: bt.id_e,
                })
            );

            setClients(responseWithOptions);
        }
    }, [getClientsFetch.response]);

    const havePopoverFiltersApplied =
        filterPopoverData && !isFilterPopoverEmpty(filterPopoverData);

    return (
        <s.Container>
            <s.Operations>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <TextField
                        label="Pesquise pelo nome do Orçamento"
                        variant="standard"
                        sx={{
                            minWidth: "19rem",
                            backgroundColor: "transparent",
                        }}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search
                                        sx={{
                                            color: theme.gray800,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="text"
                        sx={{
                            gap: "0.5rem",
                            color: havePopoverFiltersApplied
                                ? "#B93BDC"
                                : theme.primary.main,
                        }}
                        onClick={handleOpen}
                    >
                        <Box
                            sx={{
                                position: "relative",
                            }}
                        >
                            <FilterListOutlined />
                            {havePopoverFiltersApplied && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "2px",
                                        right: "0px",
                                        width: "0.5rem",
                                        height: "0.5rem",
                                        borderRadius: "50%",
                                        backgroundColor: "#EF6C00",
                                    }}
                                />
                            )}
                        </Box>
                        FILTROS
                    </Button>
                    <FilterPopover
                        anchorEl={anchorEl}
                        budgetTypes={budgetTypes}
                        clients={clients}
                        filterPopoverData={filterPopoverData}
                        handleClose={handleClose}
                        handleSetPopoverData={handleSetPopoverData}
                        optionsStatus={optionsStatus}
                    />
                    <Button
                        variant="text"
                        sx={{
                            gap: "0.5rem",
                            color: "#EF6C00",
                        }}
                        onClick={handleCleanFilters}
                    >
                        LIMPAR FILTROS
                    </Button>
                </Box>
                <s.CreationButton
                    onClick={() => navigate("/financial/budget/create")}
                >
                    <s.CreationButtonTexts>
                        <Icons.Plus color="white" strokeWidth="3" />
                        CRIAR ORÇAMENTO
                    </s.CreationButtonTexts>
                </s.CreationButton>
            </s.Operations>
            {budgets?.length === 0 ? (
                <NoBudget />
            ) : (
                <BudgetTable
                    budgets={budgets.map((budget) => {
                        return {
                            id: budget.id_e,
                            name: budget.name,
                            color: budget.color,
                            secondary_color: budget.secondary_color,
                            type: budget.type.name,
                            client: budget.client.name,
                            status: budget?.Status?.name ?? "Etapa de Criação",
                            created_at: translateDate(budget.created_at),
                            color_status:
                                !budget?.Status ||
                                budget?.Status?.color === "#ffffff"
                                    ? "inherit"
                                    : budget.Status.color,
                            hours: budget.totalHours,
                            cost: budget.totalPrice,
                            isArchived: budget.isArchived,
                        };
                    })}
                    refetchBudget={refetchBudget}
                />
            )}
            <s.PageSelector>
                <s.BackButton
                    onClick={() => {
                        const prevPage = currentPage - 1;
                        if (pagesList.includes(prevPage))
                            setCurrentPage(prevPage);
                    }}
                >
                    <Icons.Chevron
                        width="3"
                        color={currentPage === 1 ? theme.gray300 : ""}
                    />
                </s.BackButton>
                {pagesList.map((page) => {
                    return (
                        <s.PageButton
                            name={page === currentPage ? "selected" : ""}
                            onClick={() => setCurrentPage(page)}
                        >
                            {page}
                        </s.PageButton>
                    );
                })}
                <s.ForwardButton
                    onClick={() => {
                        const nextPage = currentPage + 1;
                        if (pagesList.includes(nextPage))
                            setCurrentPage(nextPage);
                    }}
                >
                    <Icons.Chevron
                        color={
                            currentPage === pagesList.length
                                ? theme.gray300
                                : ""
                        }
                        width="3"
                    />
                </s.ForwardButton>
            </s.PageSelector>
        </s.Container>
    );
};
