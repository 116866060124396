import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TBudgetLevelUpdate = {
    name: string;
    color?: string;
    parent?: string;
    levelOrder?: number;
};

export type TBudgetLevelUpdateResponse = {
    count: number;
};

export default async (
    budgetId: string,
    levelId: string,
    bodyParams: TBudgetLevelUpdate
) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/level/${levelId}`,
        method: "patch",
        body: bodyParams,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TBudgetLevelUpdateResponse[]>({
        body,
        statusCode,
    });
};
