import { theme } from "../../../assets";
import * as s from "./styled-color-picker";

export const colors = [
    "#0B49EC",
    theme.pink1,
    theme.orange,
    "#9D0000",
    "#03BA78",
    theme.green1,
    "#0CB8B8",
    "#FF015C",
    "#9933FF",
    "#824700",
    "#800380",
    "#4B07BF",
    "#D14141",
    "#8BB908",
    "#333333",
] as const;

export type PickableColor = (typeof colors)[number];

type TColorPicker = {
    onSelect: (color: PickableColor) => void;
    title: string;
    value?: string;
};

export default ({ onSelect, title, value }: TColorPicker) => {
    return (
        <s.Container>
            <s.Title>{title}</s.Title>
            <s.ContainerBlocks>
                {colors.map((color) => (
                    <s.BlockColor
                        key={color}
                        onClick={() => onSelect(color)}
                        type="button"
                        style={{ background: color as string }}
                        value={value}
                    />
                ))}
            </s.ContainerBlocks>
        </s.Container>
    );
};
