import { format } from "date-fns";
import { Matcher } from "react-day-picker";

import { Icons } from "../../../assets";
import { Calendar, PopoverRadix } from "../../atoms";
import * as s from "./styled-day-picker";

type TDatePicker = {
    value?: Date;
    onSelect: (date?: Date) => void;
    placeholder?: string;
    label?: string;
    error?: any;
    toYear?: number;
    icon?: React.ReactNode;
    disabled?: Matcher | Matcher[];
    heigth?: string;
    backgroundColor?: string;
    borderColor?: string;
};

export default ({
    value,
    onSelect,
    placeholder,
    heigth,
    label,
    error,
    toYear,
    icon,
    disabled,
    backgroundColor,
    borderColor,
}: TDatePicker) => {
    return (
        <s.Container
            height={heigth}
            backgroundColor={backgroundColor}
            error={!!error}
            borderColor={borderColor}
        >
            <PopoverRadix
                trigger={
                    <>
                        {label && <label className="label">{label}</label>}
                        <button
                            type="button"
                            className={`btn ${error ? "error" : ""}`}
                        >
                            {!value && (
                                <p className="placeholder">{placeholder}</p>
                            )}
                            {value && format(value, "dd/MM/yyyy")}
                            {icon || <Icons.CalendarOutline />}
                        </button>
                    </>
                }
            >
                <Calendar
                    selectedDay={value}
                    onChange={onSelect}
                    navigationMode="dropdown"
                    toYear={toYear}
                    disabled={disabled}
                />
            </PopoverRadix>
            {error && <s.Error>{error || ""}</s.Error>}
        </s.Container>
    );
};
