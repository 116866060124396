import {
    TPriority,
    TSection,
} from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TUpdateActivity = {
    expectedMinutes?: number;
    activityIds?: (string | undefined)[];
    position?: number;
    deadline?: Date;
    priority?: TPriority;
    description?: string;
    name?: string;
    users?: string[];
    statusId?: string;
    sectionId?: string;
};

export default async ({
    name,
    deadline,
    priority,
    expectedMinutes,
    users,
    sectionId,
    activityIds,
    position,
    description,
    statusId,
}: TUpdateActivity = {}) => {
    const headers = {
        "x-section-id": sectionId,
        auth: true,
    };

    if (sectionId?.includes("withoutSection") || !sectionId)
        delete headers["x-section-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/update`,
        method: "put",
        body: {
            name,
            deadline,
            priority,
            expectedMinutes,
            users,
            activityIds,
            position,
            description,
            statusId,
        },
        headers,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
