import { CheckOutlined } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { theme } from "../../../assets";
import { useUser } from "../../../context";
import { TCreateBudget } from "../../../services/budget/create-budget";
import * as Budgets from "../../../services/budget/index";
import { Layout } from "../layout";
import { IntroModulesDialog } from "../modules/components/tabs-content/scope-tab/intro-dialog";
import { FirstStep, FirstStepData } from "./first-step/first-step";
import { SecondStep, SecondStepData } from "./second-step/second-step";
import * as s from "./styled-form-screen";

export type StepData = Partial<FirstStepData> & SecondStepData;

export type TBudgetProfiles =
    | {
          id_e: string;
          name: string;
          abbreviation: string;
      }[]
    | [];

export const Form = () => {
    const [step, setStep] = useState<number>(1);
    const [openIntroDialog, setOpenIntroDialog] = useState(false);
    const [budgetId, setBudgetId] = useState("");
    const { user } = useUser();
    const [fullData, setFullData] = useState<StepData>({
        name: "",
        description: "",
        clientId: "",
        budgetTypeId: "",
        secondaryColor: "#00000",
        levelConfigs: [],
    });

    const createBudget = useMutation({
        mutationKey: ["createBudget"],
        mutationFn: Budgets.createBudget,
        onSuccess(data) {
            const budgetId = data.id_e;
            setBudgetId(budgetId);
            setOpenIntroDialog(true);
        },
    });

    const prevStep = () => {
        if (step <= 1) return;
        setStep(step - 1);
    };

    const nextStep = (stepData: StepData) => {
        if (step >= 2) {
            const data = {
                ...fullData,
                ...stepData,
                organizationId: user.Organization.id_e,
            };
            return createBudget.mutate({
                body: data,
                user_id: user.id_e,
            } as unknown as TCreateBudget);
        }
        setFullData({
            ...fullData,
            ...stepData,
        });
        return setStep(step + 1);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "0.5rem",
                    width: "100%",
                }}
            >
                <s.Step className={step >= 1 ? "active" : ""}>
                    {step > 1 ? (
                        <CheckOutlined
                            sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                backgroundColor: theme.primary.main,
                                borderRadius: "50%",
                                color: theme.white,
                            }}
                        />
                    ) : (
                        <div>1</div>
                    )}
                    <span>Dados Iniciais</span>
                </s.Step>
                <Divider
                    sx={{
                        height: "0.0625rem",
                        width: "80%",
                    }}
                />
                <s.Step className={step >= 2 ? "active" : ""}>
                    {step > 2 ? (
                        <CheckOutlined
                            sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                backgroundColor: theme.primary.main,
                                borderRadius: "50%",
                                color: theme.white,
                            }}
                        />
                    ) : (
                        <div>2</div>
                    )}
                    <span
                        style={{
                            lineBreak: "strict",
                        }}
                    >
                        Nomenclatura de Níveis
                    </span>
                </s.Step>
            </Box>
            {step === 1 && (
                <FirstStep nextStep={nextStep} fullData={fullData} />
            )}
            {step === 2 && (
                <SecondStep
                    prevStep={prevStep}
                    nextStep={nextStep}
                    fullData={fullData}
                />
            )}
            <IntroModulesDialog open={openIntroDialog} budgetId={budgetId} />
        </>
    );
};

export const FormScreen = () => {
    return <Layout children={Form()} />;
};
