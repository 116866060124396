import { CheckOutlined } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";

import { theme } from "../../../assets";

type StepsMuiProps = {
    step: number;
    stepsOptions: string[];
};

function StepsMui({ step, stepsOptions }: StepsMuiProps) {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
                width: "100%",
            }}
        >
            {stepsOptions.map((stepOption, index) => {
                const isLastOption = index + 1 === stepsOptions.length;
                return (
                    <>
                        <Stack
                            sx={{
                                gap: "1rem",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                            key={stepOption}
                        >
                            {index + 1 < step ? (
                                <CheckOutlined
                                    sx={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        backgroundColor: theme.primary.main,
                                        borderRadius: "50%",
                                        color: theme.white,
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        borderRadius: "100%",
                                        height: "1.5rem",
                                        width: "1.5rem",
                                        color: "#fff",
                                        alignItems: "center",
                                        backgroundColor:
                                            index + 1 === step
                                                ? theme.primary.main
                                                : "#B6B3BC",
                                    }}
                                >
                                    {index + 1}
                                </Box>
                            )}
                            <Typography
                                sx={{
                                    color: "rgba(62, 55, 78, 0.87)",
                                    textAlign: "center",
                                    fontFamily: "Noto Sans",
                                    fontSize: "0.875rem",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "normal",
                                }}
                            >
                                {stepOption}
                            </Typography>
                        </Stack>
                        {!isLastOption && (
                            <Divider
                                sx={{
                                    height: "0.0625rem",
                                    width: "80%",
                                }}
                            />
                        )}
                    </>
                );
            })}
        </Box>
    );
}

export default StepsMui;
