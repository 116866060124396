import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../components/molecules/modals/modal-document/styled-modal-document";
import { useReadBudgetById } from "../../../../../../hooks/react-query/use-read-budget-by-id";
import { useReadBudgetLevel } from "../../../../../../hooks/react-query/use-read-budget-level";
import RequirimentsContent from "./requiriments-content";

export type TModulesProps = {
    name: string;
    color: string;
    requiriments?: string;
}[];

const ScopeTab = () => {
    const { budgetId } = useParams();

    const id = budgetId || "";

    const {
        data: dataBudgetById,
        isLoading,
        refetch,
    } = useReadBudgetById({
        budgetId: id,
    });

    const { mutate } = useReadBudgetLevel({
        budgetId: id,
    });

    const levelConfig = dataBudgetById?.LevelConfig ?? [];

    useEffect(() => {
        mutate();
    }, [id]);

    return (
        <Stack
            sx={{
                gap: "1.5rem",
            }}
        >
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <RequirimentsContent
                        levelConfig={levelConfig}
                        budgetId={id}
                        refetch={refetch}
                        dataBudgetById={dataBudgetById}
                    />
                </>
            )}
        </Stack>
    );
};

export default ScopeTab;
