import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCreateProjectFromBudgetBody = {
    name: string;
    color: string;
    transportAnnotations: boolean;
    filesToTransport: string[];
    startDate: string;
    endDate: string;
};

export type TCreateProjectFromBudget = {
    budgetId: string;
    body: TCreateProjectFromBudget;
};

export default async ({
    budgetId,
    body: bodyReq,
}: TCreateProjectFromBudget) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/create-project`,
        method: "post",
        body: bodyReq,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        400: "O orçamento já possui um projeto",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
