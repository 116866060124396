import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { theme } from "../../../../../../assets";
import { DayPicker } from "../../../../../../components";
import ColorPicker, {
    PickableColor,
} from "../../../../../../components/atoms/color-picker/color-picker";
import SelectClientMui from "../../../../../../components/atoms/selects/select-client-mui/select-client-mui";
import CustomSelect from "../../../../../../components/molecules/custom-select/custom-select";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { useUser } from "../../../../../../context";
import { useFetch } from "../../../../../../hooks";
import { TOption, TProject } from "../../../../../../models";
import { manageClients } from "../../../../../../services";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budgets";
import { onlyNumbers } from "../../../../../../utils";
import { IProject } from "../../../projects-form";
import validation from "./validation";

dayjs.extend(utc);

type TStep1 = {
    storeData: <IProject>(data: IProject) => void;
    manageSteps: Dispatch<SetStateAction<1 | 2>>;
    dataBudgetById?: TReadBudgetsResponse;
};
type TFields = {
    name?: string;
    client?: string;
    description?: string;
    start_at?: Date;
    end_at?: Date;
    status?: string;
    estimated_hours: string;
    scopePercentage: string;
    cost?: string;
    technologies?: string;
    color: string;
};

const Step1Project = ({ dataBudgetById, storeData, manageSteps }: TStep1) => {
    const {
        user: { Permissions: userPerms },
    } = useUser();
    const manageCostsAllowed = userPerms.includes("manage_cost");
    const navigate = useNavigate();
    const location = useLocation();
    const [clientsOptions, setClientsOptions] = useState<TOption[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const { project }: { project: TProject } = location.state || "";

    const isCreatedByBudget = !!dataBudgetById;

    const defaultValues: TFields | undefined = isCreatedByBudget
        ? {
              name: dataBudgetById.name,
              cost: String(dataBudgetById.totalPrice),
              color: dataBudgetById.color,
              client: dataBudgetById.client.id_e,
              estimated_hours: String(dataBudgetById.totalHours) ?? "",
              scopePercentage: "",
          }
        : undefined;

    const {
        handleSubmit,
        getValues,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm<TFields>({
        resolver: yupResolver(validation()),
        defaultValues,
    });

    const statusOptions = [
        {
            label: "A iniciar",
            value: "to_start",
        },
        {
            label: "Em andamento",
            value: "in_progress",
        },
        {
            label: "Atrasado",
            value: "late",
        },
        {
            label: "Pausado",
            value: "paused",
        },
        {
            label: "Cancelado",
            value: "canceled",
        },
        {
            label: "Concluído",
            value: "finished",
        },
        {
            label: "Sem informação",
            value: "no_information",
        },
    ];

    const getClients = useFetch({
        fn: manageClients.getClients,
        start: true,
        params: {
            active: true,
        },
    });

    const clearsClientSelect = () => {
        setValue("client", undefined);
    };

    const verifyDates = () => {
        const start_at = getValues("start_at") as Date;
        const end_at = getValues("end_at") as Date;
        const startDate = new Date(start_at);
        const endDate = new Date(end_at);

        if (endDate < startDate) {
            toast.error("Data de início maior que a de fim do projeto!");
        }
    };

    const onSubmit = (data: TFields) => {
        const client = getValues("client");
        let newData = {
            ...data,
            client,
            status: data.status,
            estimatedHours: parseInt(data.estimated_hours, 10),
            scopePercentage: Number(data.scopePercentage),
        } as IProject;
        if (data.cost) {
            newData = {
                ...newData,
                cost: Number(data.cost.replace("R$", "")) / 100,
            };
        }

        storeData(newData);
        manageSteps(2);
        window.scrollTo(0, 0);
    };

    const handleSelectColor = (color: PickableColor) => {
        setValue("color", color, { shouldDirty: true, shouldValidate: true });
    };

    useEffect(() => {
        if (getClients.error) toast.error(`${getClients.error}`);
        if (getClients.response) {
            const tempClientsOptions: TOption[] = [];
            getClients.response.forEach(
                (client: { id_e: string; name: string }) =>
                    tempClientsOptions.push({
                        value: client.id_e,
                        label: client.name,
                    })
            );
            setClientsOptions(tempClientsOptions);
            setLoading(false);
        }
    }, [getClients.response]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
                sx={{
                    gap: "3rem",

                    paddingBottom: "5rem",
                }}
            >
                <Stack
                    sx={{
                        gap: "1.25rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                        }}
                    >
                        <CustomTextField
                            control={control}
                            name="name"
                            fullWidth
                            label="Nome do projeto *"
                            placeholder="Digite aqui o nome do projeto que você está criando"
                        />
                        <SelectClientMui
                            control={control}
                            label="Nome do Cliente *"
                            name="client"
                            fullWidth
                            title={"Nome do Cliente *"}
                            options={clientsOptions}
                            buttonName={"Novo Cliente"}
                            getClients={getClients}
                            disabled={isCreatedByBudget}
                            clearsClientSelect={clearsClientSelect}
                        />
                    </Box>

                    <CustomTextField
                        control={control}
                        name="description"
                        placeholder="Digite aqui uma pequena descrição sobre este projeto"
                        disabled={loading}
                        multiline
                        fullWidth
                        maxCharacters={300}
                    />

                    <CustomTextField
                        control={control}
                        name="technologies"
                        label="Tecnologias do projeto (se houver)"
                        placeholder="Digite aqui as tecnologias que serão utilizadas para o desenvolvimento do projeto. Exemplos: Python, Javascript, AWS..."
                        disabled={loading}
                        fullWidth
                        multiline
                        maxCharacters={300}
                    />

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.25rem",
                        }}
                    >
                        <Controller
                            name="start_at"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <DayPicker
                                        label={"Início do projeto"}
                                        placeholder="--/--/----"
                                        heigth="3.5rem"
                                        value={value}
                                        onSelect={(date) => {
                                            onChange(date);
                                            verifyDates();
                                        }}
                                        error={errors.start_at?.message || ""}
                                        toYear={new Date().getFullYear() + 10}
                                        backgroundColor="transparent"
                                        borderColor={theme.gray300}
                                    />
                                );
                            }}
                        />

                        <Controller
                            name="end_at"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DayPicker
                                    label={"Fim do projeto"}
                                    placeholder="--/--/----"
                                    value={value}
                                    onSelect={(date) => {
                                        onChange(date);
                                        verifyDates();
                                    }}
                                    backgroundColor="transparent"
                                    heigth="3.5rem"
                                    error={errors.end_at?.message || ""}
                                    toYear={new Date().getFullYear() + 10}
                                    borderColor={theme.gray300}
                                />
                            )}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.25rem",
                        }}
                    >
                        <CustomSelect
                            control={control}
                            name="status"
                            options={statusOptions}
                            fullWidth
                            label="Status"
                        />

                        <CustomTextField
                            control={control}
                            name="estimated_hours"
                            label="Horas estimadas"
                            fullWidth
                            formatValue={onlyNumbers}
                            placeholder="1200:00"
                            disabled={isCreatedByBudget}
                            defaultValue={project?.estimatedHours}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.25rem",
                        }}
                    >
                        <CustomTextField
                            control={control}
                            name="scopePercentage"
                            label="Escopo atual"
                            formatValue={onlyNumbers}
                            maxCharacters={4}
                            fullWidth
                            placeholder="20%"
                            isPercentage
                            defaultValue={project?.scopePercentage}
                        />
                        {manageCostsAllowed && (
                            <CustomTextField
                                control={control}
                                name="cost"
                                label="Custo total previsto"
                                placeholder="R$ 100.000.000,00"
                                fullWidth
                                disabled={isCreatedByBudget}
                                onChange={({ target: t }) => {
                                    const justNumbers = onlyNumbers(t.value);
                                    setValue("cost", justNumbers);
                                }}
                            />
                        )}
                    </Box>
                    <span>
                        Defina a cor da tag do seu projeto selecionando um dos
                        quadrados de cores*
                    </span>

                    <Box
                        sx={{
                            position: "relative",
                        }}
                    >
                        <ColorPicker
                            onSelect={handleSelectColor}
                            title="Escolher a cor de Identificação"
                            value={watch("color")}
                        />
                        {errors?.color?.message && (
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    position: "absolute",
                                    bottom: "-1.5rem",
                                    fontSize: "0.75rem",
                                    marginLeft: "0.875rem",
                                    marginRight: "0.875rem",
                                    color: "#d32f2f",
                                }}
                            >
                                {errors?.color?.message}
                            </Typography>
                        )}
                    </Box>
                </Stack>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "auto",
                        gap: "1rem",
                        width: "100%",
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={() => {
                            navigate("/projetos");
                        }}
                        type="button"
                    >
                        Voltar
                    </Button>
                    <Button variant="contained" type="submit">
                        avançar
                    </Button>
                </Box>
            </Stack>
        </form>
    );
};

export default Step1Project;
