import styled from "styled-components";

export const Form = styled.form`
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
`;

export const FormGrouper = styled.form`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${({ className }) => className};
    gap: 1rem;
`;

export const FullLine = styled.hr`
    width: 100%;
    color: #bdbdbd;
`;

export const SelectContainer = styled.div`
    width: ${({ className }) => (className === "half" ? "49.5%" : "100%")};
    height: 100%;
    position: relative;
    & .select-activity {
        height: 100%;
        & .select-activity__control {
            height: 100%;
        }
    }
`;

export const AddActivityButton = styled.button`
    padding: 12px 16px;
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    &:hover {
        background: ${({ theme }) => theme.gray50};
    }
`;

export const StepSelector = styled.div`
    width: 100%;
    height: 7.5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    gap: 1rem;
`;

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 10.0625rem;
    gap: 1rem;
    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme, className }) =>
            className === "active" ? theme.primary.main : theme.gray400};
        border-radius: 100%;
        height: 1.5rem;
        width: 1.5rem;
        color: ${({ theme }) => theme.white};
    }

    & span {
        text-align: center;
        color: ${({ theme, className }) =>
            className === "active" ? theme.black : theme.gray400};
    }
`;

export const NewLevelCreationButton = styled.button`
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.purple500};
    font-style: bold;
    padding: 1vh;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const InfoText = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.gray800};
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    max-width: 75vw;

    & > svg {
        margin-right: 1vw;
    }
`;

export const InfoTextExample = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 25vw;

    & > :first-child {
        width: 20vw;
    }
`;

export const InfoTextHighlight = styled.span`
    font-size: 18px;
`;
