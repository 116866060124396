import { format } from "date-fns";
import dayjs from "dayjs";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Icons, Logo, theme, FotoUser } from "../../../assets";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { useChangeRequestHoursStatus } from "../../../hooks/react-query/use-change-request-hours-status";
import { TNotification, TNotificationReadBody } from "../../../models";
import { notification as notificationService } from "../../../services";
import { PrimaryButton, SecondaryButton } from "../../atoms";
import { Base64ToImage } from "../../atoms/base64-to-image/base-64-to-image";
import * as s from "./styled-card-notification";

type TButtonNameMapper = {
    [key: string]: string;
};

type TPermissionNameMapper = {
    [key: string]: string;
};

type TCardNotification = {
    notification: TNotification;
    getNotification?: {
        response: unknown;
        error:
            | {
                  message?: string | undefined;
                  errorToken: boolean;
                  body?:
                      | {
                            status: string;
                            message: string;
                        }
                      | undefined;
              }
            | undefined;
        loading: boolean;
        onRefresh: (params?: TNotificationReadBody | undefined) => void;
        allSubsequentResponses: unknown;
    };
    updateNotification?: (id: string) => void;
    setOpen?: (open: boolean) => void;
};

const CardNotification = ({
    notification,
    updateNotification,
    getNotification,
    setOpen,
}: TCardNotification) => {
    const navigate = useNavigate();
    const { user, permissions, isAdministrator } = useUser();
    const buttonNameMapper: TButtonNameMapper = {
        "/perfil/configuracoes": "Ir para configurações",
        "/profissionais/convites": "Ver convites",
        "/projetos": "IR PARA PROJETOS",
        "/relatorio/gerados": "IR PARA RELATÓRIOS GERADOS",
        "/pontuacao": "ACESSAR MINHA PONTUAÇÃO",
        "/organizacao/perfil": "ACESSAR PERFIL DA ORGANIZAÇÃO",
    };
    const permissionsMapper: TPermissionNameMapper = {
        "/profissionais/convites": "manage_users",
    };
    const permissionsMessage: TPermissionNameMapper = {
        "/profissionais/convites":
            "Disponível apenas para usuários com permissão de convidar outros profissionais.",
    };

    const deleteNotification = useFetch({
        fn: notificationService.deleteNotification,
        start: false,
    });

    const changeRequestHoursStatus = useChangeRequestHoursStatus({
        onSuccess() {
            getNotification?.onRefresh({
                userId: user.id_e,
            });
            toast.success("Solicitação de horas atualizada com sucesso!");
        },
    });

    const handleDeleteNotification = () => {
        deleteNotification.onRefresh({
            notificationId: notification.id_e,
        });
    };

    const getDateTimeFromIsoString = (Iso: string) => {
        const date = new Date(Iso);
        const dayMonthAndYearformat = format(date, "dd/MM/yyyy");
        const hourAndMinutesFormat = format(date, "HH:mm");
        return `${dayMonthAndYearformat} às ${hourAndMinutesFormat}`;
    };

    const handlePermission = (): boolean => {
        if (
            Object.keys(permissionsMapper).includes(
                notification.path_to_go || ""
            )
        )
            return permissions.includes(
                permissionsMapper[notification.path_to_go || ""]
            );

        return true;
    };

    useEffect(() => {
        if (deleteNotification.response && getNotification) {
            toast.success("Notificação apagada com sucesso!");
            getNotification.onRefresh({
                userId: user.id_e,
            });
        }
    }, [deleteNotification.response]);

    const isBudgetNotification =
        typeof notification.path_to_go === "string" &&
        notification?.path_to_go?.includes("financial");

    const defaultMessage = isBudgetNotification
        ? "Acessar Orçamento"
        : "Acessar nova funcionalidade";

    return (
        <s.Container
            wasRead={notification.wasRead}
            className="notification-card-container"
        >
            {notification.type === "common" && (
                <>
                    <div id="card-top-container">
                        <s.MediumP>{notification.title}</s.MediumP>
                        <button
                            onClick={handleDeleteNotification}
                            className="delete-button"
                        >
                            <Icons.Trash />
                        </button>
                    </div>
                    <div id="card-content">
                        <div id="inner-card">
                            <div id="inner-card-header">
                                <div id="avatar">
                                    <img
                                        src={
                                            notification.Sender?.avatar || Logo
                                        }
                                        alt="Avatar"
                                    />
                                </div>
                                <div id="inner-card-infos">
                                    {!notification.Sender ? (
                                        <div id="inner-card-name">PaperOn</div>
                                    ) : (
                                        <div id="inner-card-name">
                                            {notification.Sender.name !==
                                            undefined
                                                ? notification.Sender.name
                                                : "PaperOn"}
                                        </div>
                                    )}
                                    {!notification.wasRead && <span id="dot" />}
                                    <div id="inner-card-time">
                                        {dayjs(notification.created_at).format(
                                            "DD/MM/YYYY [às] HH:mm"
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div id="inner-card-content">
                                {notification.description}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {notification.type === "maintenance" && (
                <s.BorderContainer>
                    <s.Header maintenance>
                        {notification.title}
                        <Icons.Warning color={theme.orangeWarning} />
                    </s.Header>
                    <s.Content>
                        {!notification.wasRead && <s.Dot />}
                        <s.Avatar
                            src={
                                notification.Sender?.avatar !== undefined
                                    ? notification.Sender?.avatar
                                    : Logo
                            }
                            alt="Avatar"
                        />
                        <s.Description>
                            {notification.description}
                            <s.SenderInformation>
                                {notification.Sender?.name || "PaperOn"}
                                {" - "}
                                {dayjs(notification.created_at).format(
                                    "DD/MM/YYYY HH:mm"
                                )}
                            </s.SenderInformation>
                        </s.Description>
                    </s.Content>
                </s.BorderContainer>
            )}
            {notification.type === "feature" && (
                <s.BorderContainer>
                    <s.Header>
                        {notification.title}
                        <Icons.Trash
                            className="delete-button"
                            onClick={handleDeleteNotification}
                        />
                    </s.Header>
                    <s.FeatureContent>
                        <Icons.Rocket />
                        <div className="sub_title">
                            {notification.sub_title}
                        </div>
                        <p className="description">
                            {notification.description}
                        </p>
                        <div className="image">
                            {notification.image && (
                                <Base64ToImage data={notification.image} />
                            )}
                        </div>
                        {notification.path_to_go &&
                            (handlePermission() ? (
                                <PrimaryButton
                                    onClick={() => {
                                        navigate(
                                            notification.path_to_go || "",
                                            {
                                                state: {
                                                    id: user.id_e,
                                                },
                                            }
                                        );
                                        setOpen?.(false);
                                    }}
                                >
                                    {notification.button_text ||
                                        buttonNameMapper[
                                            notification.path_to_go
                                        ] ||
                                        defaultMessage}
                                </PrimaryButton>
                            ) : (
                                <s.PermissionMessage>
                                    <Icons.Info
                                        color="#0288D1"
                                        width="24px"
                                        height="24px"
                                    />
                                    <p>
                                        {notification.button_text ||
                                            permissionsMessage[
                                                notification.path_to_go
                                            ] ||
                                            "Você não tem permissão para acessar"}
                                    </p>
                                </s.PermissionMessage>
                            ))}
                    </s.FeatureContent>
                </s.BorderContainer>
            )}
            {notification.type === "organization" && isAdministrator && (
                <s.BorderContainer>
                    <s.Header>
                        {notification.title}
                        <Icons.Trash
                            className="delete-button"
                            onClick={handleDeleteNotification}
                        />
                    </s.Header>
                    <s.FeatureContent>
                        <Icons.Rocket />
                        <div className="sub_title">
                            {notification.sub_title}
                        </div>
                        <p className="description">
                            {notification.description}
                        </p>
                        <div className="image">
                            {notification.image && (
                                <Base64ToImage data={notification.image} />
                            )}
                        </div>
                        {notification.path_to_go &&
                            (handlePermission() ? (
                                <PrimaryButton
                                    onClick={() => {
                                        navigate(
                                            notification.path_to_go || "",
                                            {
                                                state: {
                                                    id: user.id_e,
                                                },
                                            }
                                        );
                                        setOpen?.(false);
                                    }}
                                >
                                    {notification.button_text ||
                                        buttonNameMapper[
                                            notification.path_to_go
                                        ] ||
                                        "Acessar nova funcionalidade"}
                                </PrimaryButton>
                            ) : (
                                <s.PermissionMessage>
                                    <Icons.Info
                                        color="#0288D1"
                                        width="24px"
                                        height="24px"
                                    />
                                    <p>
                                        {notification.button_text ||
                                            permissionsMessage[
                                                notification.path_to_go
                                            ] ||
                                            "Você não tem permissão para acessar"}
                                    </p>
                                </s.PermissionMessage>
                            ))}
                    </s.FeatureContent>
                </s.BorderContainer>
            )}
            {notification.type === "report" && (
                <s.BorderContainer>
                    <s.ReportContainer id="report-container-header">
                        <s.Header>
                            {notification.title}
                            <Icons.Trash
                                className="delete-button"
                                onClick={handleDeleteNotification}
                            />
                        </s.Header>
                        <div id="inner-card">
                            <div id="content-card-report">
                                <div id="inner-card-header">
                                    <div id="avatar">
                                        <img
                                            src={
                                                notification.Sender?.avatar ||
                                                Logo
                                            }
                                            alt="Avatar"
                                        />
                                        {!notification.wasRead && (
                                            <span id="dot" />
                                        )}
                                    </div>
                                </div>
                                <div id="inner-card-content">
                                    {notification.description}
                                    <div id="inner-card-time">
                                        {!notification.Sender ? (
                                            <div id="inner-card-name">
                                                PaperOn -{" "}
                                                {getDateTimeFromIsoString(
                                                    notification.created_at
                                                )}
                                            </div>
                                        ) : (
                                            <div id="inner-card-name">
                                                {notification.Sender.name !==
                                                undefined
                                                    ? `${
                                                          notification.Sender
                                                              .name
                                                      } - ${getDateTimeFromIsoString(
                                                          notification.created_at
                                                      )}`
                                                    : `PaperOn - ${getDateTimeFromIsoString(
                                                          notification.created_at
                                                      )}`}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {notification.title ===
                            "Reenvio de relatório revisado" ? (
                                <SecondaryButton
                                    onClick={() => {
                                        navigate(
                                            "/relatorio/gerenciar/revisar"
                                        );
                                    }}
                                >
                                    Ir para revisar relatórios
                                </SecondaryButton>
                            ) : (
                                <SecondaryButton
                                    onClick={() => {
                                        navigate("/meus-relatorios/gerados");
                                    }}
                                >
                                    Ir para relatórios
                                </SecondaryButton>
                            )}
                        </div>
                    </s.ReportContainer>
                </s.BorderContainer>
            )}
            {notification.type === "newUser" && (
                <s.BorderContainer>
                    <s.ReportContainer id="report-container-header">
                        <s.Header>
                            {notification.title}
                            <Icons.Trash
                                className="delete-button"
                                onClick={handleDeleteNotification}
                            />
                        </s.Header>
                        <div id="inner-card">
                            <div id="content-card-report">
                                <div id="inner-card-header">
                                    <div id="avatar">
                                        <img src={FotoUser} alt="Avatar" />
                                        {!notification.wasRead && (
                                            <span id="dot" />
                                        )}
                                    </div>
                                </div>
                                <div id="inner-card-content">
                                    {notification.description}
                                    <div id="inner-card-time">
                                        {!notification.Sender ? (
                                            <div id="inner-card-name">
                                                PaperOn -{" "}
                                                {getDateTimeFromIsoString(
                                                    notification.created_at
                                                )}
                                            </div>
                                        ) : (
                                            <div id="inner-card-name">
                                                PaperOn -
                                                {getDateTimeFromIsoString(
                                                    notification.created_at
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </s.ReportContainer>
                </s.BorderContainer>
            )}
            {notification.type === "additionalHours" && (
                <s.BorderContainer>
                    <s.Header>{notification.title.split(" | ")[0]}</s.Header>
                    <s.AdditionalHoursContent>
                        <p className="description">
                            <img
                                src={notification.Sender.avatar || FotoUser}
                                alt="Avatar"
                                className="avatar"
                            />
                            {notification.description}
                        </p>
                        <p className="explanation">
                            Essas horas serão adicionadas ao cronômetro do
                            usuário e ele poderá registrar essas horas em
                            qualquer projeto.
                        </p>
                        <div className="buttons">
                            <SecondaryButton
                                variation="small"
                                onClick={() => {
                                    changeRequestHoursStatus.mutate({
                                        status: "refused",
                                        requestId:
                                            notification.title.split(" | ")[1],
                                        notificationId: notification.id_e,
                                    });
                                }}
                            >
                                Recusar horas
                            </SecondaryButton>
                            <PrimaryButton
                                variation="small"
                                onClick={() => {
                                    changeRequestHoursStatus.mutate({
                                        status: "accepted",
                                        requestId:
                                            notification.title.split(" | ")[1],
                                        notificationId: notification.id_e,
                                    });
                                }}
                            >
                                Aceitar horas
                            </PrimaryButton>
                        </div>
                    </s.AdditionalHoursContent>
                </s.BorderContainer>
            )}
        </s.Container>
    );
};

export default CardNotification;
