import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
    Select,
    MenuItem,
    SelectChangeEvent,
    ListItemText,
    Button,
    Box,
    IconButton,
    FormControl,
    InputLabel,
} from "@mui/material";
import { useEffect, useState } from "react";

export interface IStatusOption {
    id: string;
    label: string;
    color: string;
}

type TStatusSelectProps = {
    handleDeleteStatus: (statusId: string) => Promise<void>;
    handleStatusDialog: (data?: IStatusOption) => void;
    handleUpdateStatus: (budgetStatusId: string) => Promise<void>;
    status?: {
        id_e: string;
        color: string;
        name: string;
    };
    statusOptions?: IStatusOption[];
};

const StatusSelect = ({
    handleDeleteStatus,
    handleStatusDialog,
    handleUpdateStatus,
    status,
    statusOptions,
}: TStatusSelectProps) => {
    const [selectedStatus, setSelectedStatus] = useState<IStatusOption | null>(
        null
    );

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selected =
            (statusOptions &&
                statusOptions.find(
                    (status) => status.label === event.target.value
                )) ||
            null;
        setSelectedStatus(selected);
        handleUpdateStatus(selected?.id as string);
    };

    useEffect(() => {
        if (status && statusOptions) {
            setSelectedStatus(
                statusOptions.find((option) => option.id === status.id_e)!
            );
        }
    }, [status, statusOptions]);

    return (
        <Box>
            <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                    value={selectedStatus?.label || ""}
                    onChange={handleChange}
                    label="Status"
                    IconComponent={ArrowDropDownIcon}
                    sx={{
                        minWidth: "13.75rem",
                    }}
                    renderValue={(selected) => (
                        <Box
                            sx={{
                                backgroundColor:
                                    selectedStatus?.color ?? "transparent",
                                p: "0.3125rem 0.4375rem",
                                borderRadius: "1rem",
                                width: "auto",
                            }}
                        >
                            {selected}
                        </Box>
                    )}
                >
                    {statusOptions?.map((status) => (
                        <MenuItem key={status.id} value={status.label}>
                            <ListItemText primary={status.label} />
                            {status.label !== "Etapa de Criação" && (
                                <>
                                    <IconButton size="small">
                                        <EditIcon
                                            fontSize="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleStatusDialog(status);
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteStatus(status.id);
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </>
                            )}
                        </MenuItem>
                    ))}
                    <MenuItem
                        disableRipple
                        onMouseDown={(e) => e.preventDefault()}
                        sx={{
                            p: "0",
                        }}
                    >
                        <Button
                            variant="text"
                            fullWidth
                            onClick={(e) => {
                                e.stopPropagation();
                                handleStatusDialog();
                            }}
                            sx={{
                                color: "#3E374E",
                                justifyContent: "flex-start",
                                p: "0.5rem 1rem",
                            }}
                        >
                            <strong>+ Adicionar Status</strong>
                        </Button>
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default StatusSelect;
