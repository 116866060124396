import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { TBudgetProfileLevelDedicatedHours } from "./read-budgets-levels-detailed-by-id";

const { REACT_APP_BASE_URL } = process.env;

type TReadBudgets = {
    filter?: string;
    page: number;
    length: number;
    budgetTypeIds?: string[];
    clientIds?: string[];
    statusIds?: string[];
    isArchived?: boolean;
};

export type BudgetStatus = "approved" | "refused" | "waiting" | "progress";

export type TLevel = {
    Level: {
        id_e: string;
        name: string;
        MainLevel: { color: string };
        DetailedLevel: {
            Parent: {
                id_e: string;
                name: string;
            };
        };
        Children: {
            Level: {
                name: string;
            };
            level_id_e: string;
        }[];
    }[];
    id_e: string;
    levelOrder: number;
    name: string;
};

export type TReadBudgetsResponse = {
    id_e: string;
    name: string;
    color: string;
    secondary_color: string;
    status: BudgetStatus;
    description: string;
    client: {
        id_e: string;
        name: string;
    };
    organization: {
        id_e: string;
        name: string;
    };
    BudgetProfiles: {
        BudgetProfileTimeDependancy: {
            DependsOn: {
                Profile: {
                    name: string;
                };
                id_e: string;
            };
        }[];
        BudgetProfileLevelDedicatedHours: TBudgetProfileLevelDedicatedHours;
        Profile: { id_e: string; name: string; abbreviation: string };
        id_e: string;
        name: string;
        costPerHour: string;
        dependencyPercentage?: number | null;
        dependencyMode: string | null;
        hoursEstimationStatus?: string;
        responsible: {
            avatar: string;
            id_e: string;
            name: string;
        } | null;
        totals: {
            totalHours: number;
            totalPrice: number;
        };
    }[];
    type: {
        id_e: string;
        name: string;
    };
    Status: {
        id_e: string;
        name: string;
        color: string;
        organization_id_e: string | null;
    };
    totalHours: number;
    totalPrice: number;
    isArchived: boolean;
    created_at: string;
    updated_at: string;
    LevelConfig: TLevel[];
};

export default async (
    params: TReadBudgets = { length: 10, page: 1, isArchived: false }
) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget`,
        method: "get",
        params,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetsResponse[]>({
        body,
        statusCode,
    });
};
