import {
    Box,
    Dialog,
    DialogContent,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useState } from "react";

import { theme } from "../../../../../../assets";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import { TLevel } from "../../../../../../services/budget/read-budgets";
import { ScopeDialogLevel1Content } from "./scope-dialog-level1-content";
import { ScopeDialogLevel2Content } from "./scope-dialog-level2-content";

const TabPanel = ({
    children,
    value,
    index,
}: {
    children: React.ReactNode;
    value: number;
    index: number;
}) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export const ScopeDialog = ({
    budgetId,
    handleClose,
    levelConfig,
    open,
    refetch,
}: {
    budgetId: string;
    handleClose: () => void;
    levelConfig: TLevel[];
    open: boolean;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsResponse, unknown>>;
}) => {
    const [tabIndex, setTabIndex] = useState(0);

    const modulesData = levelConfig.find((level) => level.levelOrder === 1);
    const requirimentsData = levelConfig.find(
        (level) => level.levelOrder === 2
    );

    return (
        <Dialog
            open={open}
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "50rem",
                    overflowY: "unset",
                },
            }}
        >
            <Box
                sx={{
                    p: "1rem",
                    width: "100%",
                }}
            >
                <Stack
                    sx={{
                        gap: "0.75rem",
                        px: "1.5rem ",
                        marginTop: "1vh",
                    }}
                >
                    <Typography variant="h6">Configurar Escopo</Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme.gray500,
                            width: "60%",
                        }}
                    >
                        Edite e adicione itens na configuração de escopo. Apenas
                        itens vazios poderão ser excluídos.
                    </Typography>
                </Stack>

                <DialogContent>
                    <Stack
                        sx={{
                            gap: "2rem",
                            width: "100%",
                        }}
                    >
                        <Tabs
                            value={tabIndex}
                            onChange={(_, newIndex) => setTabIndex(newIndex)}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label={`${modulesData?.name}`} />
                            {requirimentsData && (
                                <Tab label={`${requirimentsData.name}`} />
                            )}
                        </Tabs>
                        {modulesData && (
                            <TabPanel value={tabIndex} index={0}>
                                <ScopeDialogLevel1Content
                                    budgetId={budgetId}
                                    handleClose={handleClose}
                                    modulesData={modulesData}
                                    refetch={refetch}
                                />
                            </TabPanel>
                        )}
                        {requirimentsData && modulesData && (
                            <TabPanel value={tabIndex} index={1}>
                                <Typography>
                                    <ScopeDialogLevel2Content
                                        budgetId={budgetId}
                                        handleClose={handleClose}
                                        modulesData={modulesData}
                                        requirimentsData={requirimentsData}
                                        refetch={refetch}
                                    />
                                </Typography>
                            </TabPanel>
                        )}
                    </Stack>
                </DialogContent>
            </Box>
        </Dialog>
    );
};
