import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TBudgetUpdate = {
    name?: string;
    color?: string;
    secondaryColor?: string;
    description?: string;
    statusId?: string;
};

export default async (budgetId: string, bodyParams: TBudgetUpdate) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}`,
        method: "patch",
        body: bodyParams,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases({
        body,
        statusCode,
    });
};
