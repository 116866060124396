import { useQuery } from "@tanstack/react-query";

import { readCategoryStatus } from "../../../services/activities";
import { TProjectCategoryStatusResponse } from "../../../services/activities/read-category-status";

export function useReadProjectCategoryStatus() {
    return useQuery<
        TProjectCategoryStatusResponse,
        unknown,
        TProjectCategoryStatusResponse
    >({
        enabled: true,
        queryKey: [`readCategory`],
        queryFn: () => readCategoryStatus(),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
