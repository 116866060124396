import {
    Autocomplete,
    TextField,
    CircularProgress,
    Box,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";

import { colors } from "../../../../../../components/atoms/color-picker/color-picker";
import { useCreateLevel } from "../../../../../../hooks/react-query/use-create-level";

export type TBudgetLevelSelect = {
    budgetId: string;
    handleSetValueOnForm: (name: string, value: string) => void;
    label: string;
    levelOptions: {
        value: string;
        label: string;
        color: string;
    }[];
    levelOrder: number;
    name: string;
    refetch: () => void;
    parent: string | null;
    control: Control<any>;
    disabled: boolean;
};

export const LevelSelect = ({
    budgetId,
    control,
    disabled,
    handleSetValueOnForm,
    label,
    levelOptions,
    levelOrder,
    name,
    parent,
    refetch,
}: TBudgetLevelSelect) => {
    const [inputValue, setInputValue] = useState<string>("");

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    };

    const color = getRandomColor();

    const params = {
        levelOrder,
        color: levelOrder === 1 ? color : undefined,
        name: inputValue,
        parent: parent ?? undefined,
    };

    const { mutate, isLoading } = useCreateLevel({
        budgetId,
        params,
        onSuccess: (data) => {
            refetch();
            handleSetValueOnForm(name, data.id_e || "");
        },
    });

    const getCurrentValueColor = (value?: string) => {
        const color =
            levelOptions.find((option) => option.value === value)?.color ||
            "inherit";
        return color;
    };

    const handleCreateNewLevel = async () => {
        if (!inputValue.trim()) return;
        mutate();
    };

    useEffect(() => {
        setInputValue("");
    }, [parent]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <Autocomplete
                        {...field}
                        freeSolo
                        options={
                            inputValue &&
                            !levelOptions.some(
                                (opt) => opt.label === inputValue
                            )
                                ? [
                                      ...levelOptions,
                                      {
                                          label: `${inputValue}`,
                                          value: "new",
                                      },
                                  ]
                                : levelOptions
                        }
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.label
                        }
                        isOptionEqualToValue={(option, value) =>
                            typeof option === "string"
                                ? option === value
                                : option.value === value.value
                        }
                        value={
                            levelOptions.find(
                                (opt) => opt.value === field.value
                            ) || null
                        }
                        onChange={(_, newValue) => {
                            if (typeof newValue === "string") {
                                setInputValue(newValue);
                            } else if (newValue?.value === "new") {
                                handleCreateNewLevel();
                            } else {
                                handleSetValueOnForm(
                                    name,
                                    newValue?.value || ""
                                );
                            }
                        }}
                        sx={{
                            ".MuiOutlinedInput-input": {
                                color: `${getCurrentValueColor(
                                    field.value
                                )} !important`,
                            },
                        }}
                        inputValue={inputValue}
                        onInputChange={(_, newInputValue) =>
                            setInputValue(newInputValue)
                        }
                        disabled={disabled}
                        loading={isLoading}
                        renderOption={(props, option) => (
                            <li
                                {...props}
                                key={
                                    typeof option === "string"
                                        ? option
                                        : option.value
                                }
                            >
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography variant="body1">
                                        {option.label}
                                    </Typography>
                                    {option.value === "new" && isLoading && (
                                        <CircularProgress size={16} />
                                    )}
                                </Box>
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                placeholder="Digite ou selecione um nível"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                );
            }}
        />
    );
};
