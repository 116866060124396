import { Box, Dialog } from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useCallback, useState } from "react";

import {
    TProjectCategory,
    TProjectCategoryStatusResponse,
} from "../../../../../../services/activities/read-category-status";
import { TReadBudgetsStatusResponse } from "../../../../../../services/budget/read-budgets-status";
import { IOptionStatus } from "../status-dropdown/status-dropdown";
import DeleteForm from "./delete-form";
import StatusForm from "./status-form";

export const StatusDialog = ({
    categorys,
    handleClose,
    open,
    refetch,
    refetchStatus,
    selectStatusToEdit,
    statusOptions,
}: {
    categorys: TProjectCategory[];
    handleClose: () => void;
    open: boolean;
    refetchStatus: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TProjectCategoryStatusResponse, unknown>>;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsStatusResponse, unknown>>;
    selectStatusToEdit?: IOptionStatus;
    statusOptions: IOptionStatus[];
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteMode, setIsDeleteMode] = useState(false);

    const handleDeleteMode = useCallback(() => {
        setIsDeleteMode((prev) => !prev);
    }, [isDeleteMode]);

    return (
        <Dialog
            open={open}
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "35.9375rem",
                    overflowY: "unset",
                },
            }}
        >
            <Box
                sx={{
                    p: "1rem",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: !isDeleteMode ? "block" : "none",
                    }}
                >
                    <StatusForm
                        categorys={categorys}
                        handleClose={handleClose}
                        handleDeleteMode={handleDeleteMode}
                        isLoading={isLoading}
                        refetch={refetch}
                        refetchStatus={refetchStatus}
                        setIsLoading={setIsLoading}
                        selectStatusToEdit={selectStatusToEdit}
                    />
                </div>
                <div
                    style={{
                        display: isDeleteMode ? "block" : "none",
                    }}
                >
                    <DeleteForm
                        statusOptions={statusOptions}
                        handleClose={handleClose}
                        handleDeleteMode={handleDeleteMode}
                        isLoading={isLoading}
                        refetch={refetch}
                        refetchStatus={refetchStatus}
                        setIsLoading={setIsLoading}
                        selectStatusToEdit={selectStatusToEdit}
                    />
                </div>
            </Box>
        </Dialog>
    );
};
