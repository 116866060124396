import { Layout } from "../layout";
import CustomTabs from "./components/custom-tabs";

export const BudgetModules = () => {
    return (
        <Layout disableTitle>
            <CustomTabs />
        </Layout>
    );
};
