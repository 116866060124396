import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";

import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import { TLevel } from "../../../../../../services/budget/read-budgets";
import FeatureContent from "./feature-content";

type TRequirimentContentProps = {
    levelConfig: TLevel[];
    budgetId: string;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsResponse, unknown>>;
    dataBudgetById: TReadBudgetsResponse | undefined;
};

const RequirimentsContent = ({
    budgetId,
    dataBudgetById,
    levelConfig,
    refetch,
}: TRequirimentContentProps) => {
    const haveLevels = levelConfig.length > 0;
    const haveRequiriments =
        dataBudgetById &&
        haveLevels &&
        levelConfig?.[levelConfig.length - 1]?.Level?.length > 0;

    return (
        <FeatureContent
            haveRequiriments={!!haveRequiriments}
            levelConfig={levelConfig}
            budgetId={budgetId}
            refetch={refetch}
            dataBudgetById={dataBudgetById}
        />
    );
};

export default RequirimentsContent;
