import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TReadBudgetsStatus = {
    filter?: string;
    page: number;
    length: number;
};

export type TReadBudgetsStatusResponse = {
    data: {
        id_e: string;
        name: string;
        color: string;
    }[];
};

export default async (params: TReadBudgetsStatus = { length: 10, page: 1 }) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/status`,
        method: "get",
        params,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetsStatusResponse[]>({
        body,
        statusCode,
    });
};
