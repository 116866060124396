import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
    UseQueryResult,
} from "@tanstack/react-query";

import { Icons, theme } from "../../../../../../assets";
import { Collapse } from "../../../../../../components";
import { TProject, TResponse } from "../../../../../../models";
import { TArchiveActivity } from "../../../../../../services/activities/archive-activity";
import { TProjectCategoryStatusResponse } from "../../../../../../services/activities/read-category-status";
import { TToggleActivityStatus } from "../../../../../../services/activities/toggle-activity-status";
import { CreateActivityVariables } from "../../../../../../services/activities/types";
import { TUnarchiveActivity } from "../../../../../../services/activities/unarchive-activity";
import { TUnarchiveSection } from "../../../../../../services/activities/unarchive-section";
import { TUpdateActivity } from "../../../../../../services/activities/update-activity";
import { TUpdateSection } from "../../../../../../services/activities/update-section";
import { TActivityIdsRef, TSection, TSectionActivity } from "../../types";
import { Sections } from "../sections/sections";
import * as s from "./styled-archive";

type TArchive = {
    archivedSections?: TSection[];
    archiveSection?: TSection;
    project: TProject;
    archiveActivity: TResponse<unknown, TArchiveActivity>;
    unarchiveActivity: TResponse<unknown, TUnarchiveActivity>;
    toggleActivityStatus: TResponse<unknown, TToggleActivityStatus>;
    updateActivity: TResponse<unknown, TUpdateActivity>;
    createActivity: TResponse<unknown, CreateActivityVariables>;
    updateSection: TResponse<unknown, TUpdateSection>;
    unarchiveSection: TResponse<unknown, TUnarchiveSection>;
    getSections: UseQueryResult<any, unknown>;
    setDeleteConfirmationSectionId: React.Dispatch<
        React.SetStateAction<string>
    >;
    activityToAddCollaborators: TSectionActivity | undefined;
    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    setDeleteConfirmationActivityId: React.Dispatch<
        React.SetStateAction<string>
    >;
    updateActivityStates: string[];
    setUpdateActivityStates: React.Dispatch<React.SetStateAction<string[]>>;
    openedSectionsIds: string[];
    setOpenedSectionsIds: React.Dispatch<React.SetStateAction<string[]>>;
    activitiesIdsRef: TActivityIdsRef;
    setArchiveOpen: React.Dispatch<React.SetStateAction<boolean>>;
    archiveOpen: boolean;
    isClosedScope: boolean;
    statusData?: TProjectCategoryStatusResponse;
    refetchStatus: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TProjectCategoryStatusResponse, unknown>>;
};

export const Archive = ({
    project,
    archiveSection,
    archiveActivity,
    unarchiveActivity,
    setActivityToAddCollaborators,
    setDeleteConfirmationActivityId,
    toggleActivityStatus,
    activityToAddCollaborators,
    updateActivity,
    setDeleteConfirmationSectionId,
    createActivity,
    updateSection,
    archivedSections,
    unarchiveSection,
    updateActivityStates,
    setUpdateActivityStates,
    openedSectionsIds,
    setOpenedSectionsIds,
    activitiesIdsRef,
    setArchiveOpen,
    archiveOpen,
    getSections,
    isClosedScope,
    refetchStatus,
    statusData,
}: TArchive) => {
    return (
        <s.ArchiveContainer>
            <s.ArchiveSection
                isArchiveOpen={archiveOpen}
                onClick={() => setArchiveOpen(!archiveOpen)}
            >
                <Icons.Chevron className="chevron" color={theme.gray600} />
                <Icons.IconArchive />
                Arquivo
                <span>
                    <Icons.Info color={theme.gray400} />
                    Atividades desativadas do cronômetro
                </span>
            </s.ArchiveSection>
            <Collapse open={archiveOpen} height="100">
                {archiveSection && (
                    <Sections
                        refetchStatus={refetchStatus}
                        statusData={statusData}
                        isClosedScope={isClosedScope}
                        sectionName="archive"
                        archive
                        activitiesWithoutSection={Object.values(
                            archiveSection?.Activities || {}
                        )}
                        sections={archivedSections}
                        updateActivity={updateActivity}
                        setDeleteConfirmationSectionId={
                            setDeleteConfirmationSectionId
                        }
                        updateSection={updateSection}
                        project={project}
                        setActivityToAddCollaborators={
                            setActivityToAddCollaborators
                        }
                        setDeleteConfirmationActivityId={
                            setDeleteConfirmationActivityId
                        }
                        createActivity={createActivity}
                        activityToAddCollaborators={activityToAddCollaborators}
                        archiveActivity={archiveActivity}
                        unarchiveActivity={unarchiveActivity}
                        toggleActivityStatus={toggleActivityStatus}
                        unarchiveSection={unarchiveSection}
                        updateActivityStates={updateActivityStates}
                        setUpdateActivityStates={setUpdateActivityStates}
                        openedSectionsIds={openedSectionsIds}
                        setOpenedSectionsIds={setOpenedSectionsIds}
                        activitiesIdsRef={activitiesIdsRef}
                        getSections={getSections}
                    />
                )}
            </Collapse>
        </s.ArchiveContainer>
    );
};
