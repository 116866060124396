import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { TCommentBudget } from "../../../../../../services/budget/read-budget-comment";

const schema = yup.object().shape({
    comment: yup.string().required("O comentário não pode ser vazio"),
});

type AnnotationForm = {
    handleCreateAnnotation: (comment: string) => Promise<void>;
    handleEditAnnotation?: () => void;
    editData?: TCommentBudget;
};

type FieldValues = {
    comment: string;
};

function AnnotationForm({
    editData,
    handleEditAnnotation,
    handleCreateAnnotation,
}: AnnotationForm) {
    const [isLoading, setIsLoading] = useState(false);
    const isEditAnnotation = typeof editData !== "undefined";
    const {
        control,
        formState: { isValid },
        handleSubmit,
        reset,
    } = useForm<FieldValues>({
        defaultValues: {
            comment: editData?.text,
        },
        resolver: yupResolver(schema),
    });

    const submit = async (data: FieldValues) => {
        setIsLoading(true);
        await handleCreateAnnotation(data.comment);
        reset();
        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack
                sx={{
                    gap: "1.5rem",
                }}
            >
                <CustomTextField
                    control={control}
                    label="Comentário"
                    placeholder="Escrever Comentário..."
                    multiline
                    fullWidth
                    name="comment"
                />
                <Box
                    sx={{
                        display: "flex",
                        gap: "1rem",
                    }}
                >
                    {isEditAnnotation && (
                        <Button
                            variant="outlined"
                            onClick={handleEditAnnotation}
                        >
                            Cancelar
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        disabled={!isValid || isLoading}
                        type="submit"
                    >
                        Salvar
                    </Button>
                </Box>
            </Stack>
        </form>
    );
}

export default AnnotationForm;
