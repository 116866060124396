import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Popover,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { TOption } from "../../../models";
import { filterPopover } from "../budgets-home";

interface IFilterFormValues {
    status: string[];
    clients: string[];
    projectType: string[];
    showArchived: boolean;
}

type FilterPoppoverProps = {
    anchorEl: HTMLElement | null;
    budgetTypes: TOption[];
    clients: TOption[];
    filterPopoverData: filterPopover | undefined;
    handleClose: () => void;
    handleSetPopoverData: (data: filterPopover) => void;
    optionsStatus: {
        label: string;
        value: string;
        color: string;
    }[];
};

const titleCheckBoxLabelStyle = {
    color: " rgba(62, 55, 78, 0.60)",
    fontFamily: "Noto Sans",
    fontSize: "0.875rem",
    fontStyle: " normal",
    fontWeight: 600,
    lineHeight: "1.5rem",
    letterSpacing: "0.07rem",
    textTransform: "uppercase",
};

function FilterPopover({
    anchorEl,
    budgetTypes,
    clients,
    filterPopoverData,
    handleClose,
    handleSetPopoverData,
    optionsStatus,
}: FilterPoppoverProps) {
    const { control, handleSubmit } = useForm<IFilterFormValues>({
        defaultValues: {
            status: filterPopoverData?.statusIds ?? [],
            clients: filterPopoverData?.clientIds ?? [],
            projectType: filterPopoverData?.budgetTypeIds ?? [],
            showArchived: filterPopoverData?.isArchived ?? false,
        },
    });
    const onSubmit = (data: IFilterFormValues) => {
        const params: filterPopover = {
            budgetTypeIds: data.projectType,
            clientIds: data.clients,
            statusIds: data.status,
            isArchived: data.showArchived,
        };
        handleSetPopoverData(params);
        handleClose();
    };
    const open = Boolean(anchorEl);
    return (
        <Popover
            id={"filter-popover"}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ padding: "1.5rem" }}
            >
                <Stack
                    sx={{
                        gap: "1.5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            gap: "2.125rem",
                            maxHeight: "30.125rem",
                            overflowY: "auto",
                        }}
                    >
                        <Stack
                            sx={{
                                width: "100%",
                                maxWidth: "16.25rem",
                            }}
                        >
                            <Typography
                                variant="button"
                                sx={titleCheckBoxLabelStyle}
                            >
                                Status de Projeto
                            </Typography>
                            {optionsStatus.map((status) => (
                                <Controller
                                    key={status.value}
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            sx={{
                                                color:
                                                    status.color === "#ffffff"
                                                        ? "inherit"
                                                        : status.color,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={field.value.includes(
                                                        status.value
                                                    )}
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                                ? [
                                                                      ...field.value,
                                                                      status.value,
                                                                  ]
                                                                : field.value.filter(
                                                                      (item) =>
                                                                          item !==
                                                                          status.value
                                                                  )
                                                        )
                                                    }
                                                />
                                            }
                                            label={status.label}
                                        />
                                    )}
                                />
                            ))}
                        </Stack>
                        <Stack
                            sx={{
                                width: "100%",
                                maxWidth: "16.25rem",
                            }}
                        >
                            <Typography
                                variant="button"
                                sx={titleCheckBoxLabelStyle}
                            >
                                Lista de Clientes
                            </Typography>
                            {clients.map((client) => (
                                <Controller
                                    key={client.value}
                                    name="clients"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={field.value.includes(
                                                        client.value
                                                    )}
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                                ? [
                                                                      ...field.value,
                                                                      client.value,
                                                                  ]
                                                                : field.value.filter(
                                                                      (item) =>
                                                                          item !==
                                                                          client.value
                                                                  )
                                                        )
                                                    }
                                                />
                                            }
                                            label={client.label}
                                        />
                                    )}
                                />
                            ))}
                        </Stack>
                        <Stack
                            sx={{
                                width: "100%",
                                maxWidth: "16.25rem",
                            }}
                        >
                            <Typography
                                variant="button"
                                sx={titleCheckBoxLabelStyle}
                            >
                                Tipo de Projeto
                            </Typography>
                            {budgetTypes.map((type) => (
                                <Controller
                                    key={type.value}
                                    name="projectType"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={field.value.includes(
                                                        type.value
                                                    )}
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                                ? [
                                                                      ...field.value,
                                                                      type.value,
                                                                  ]
                                                                : field.value.filter(
                                                                      (item) =>
                                                                          item !==
                                                                          type.value
                                                                  )
                                                        )
                                                    }
                                                />
                                            }
                                            label={type.label}
                                        />
                                    )}
                                />
                            ))}
                        </Stack>
                    </Box>

                    <FormControlLabel
                        control={
                            <Controller
                                name="showArchived"
                                control={control}
                                render={({ field }) => (
                                    <Switch
                                        checked={field.value}
                                        onChange={(e) =>
                                            field.onChange(e.target.checked)
                                        }
                                    />
                                )}
                            />
                        }
                        label="Mostrar Orçamentos Arquivados"
                    />

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1rem",
                        }}
                    >
                        <Button onClick={handleClose} color="secondary">
                            Voltar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Filtrar
                        </Button>
                    </Box>
                </Stack>
            </form>
        </Popover>
    );
}

export default FilterPopover;
