import { yupResolver } from "@hookform/resolvers/yup";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Stack, TextField, InputAdornment, IconButton } from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { createBudgetUploadFile } from "../../../../../../services/budget";
import { TReadBudgetFilesResponse } from "../../../../../../services/budget/read-budgets-files";

const schema = yup.object().shape({
    file: yup.mixed().required("É necessário anexar um arquivo"),
});

type FieldValues = {
    file: File | null;
};

type FilesFormProps = {
    budgetId: string;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetFilesResponse, unknown>>;
};

function FilesForm({ budgetId, refetch }: FilesFormProps) {
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
    } = useForm<FieldValues>({
        defaultValues: {
            file: null,
        },
        resolver: yupResolver(schema),
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setValue("file", file);
    };

    const handleFileUploadClick = () => {
        fileInputRef.current?.click();
    };

    const submit = async (data: FieldValues) => {
        setIsLoading(true);

        if (data.file) {
            const formData = new FormData();
            formData.append("file", data.file);
            await createBudgetUploadFile({ budgetId, params: formData });
            await refetch();
            toast.success("Arquivo adicionado com sucesso!");
        }

        reset();
        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack sx={{ gap: "1.5rem", maxWidth: "27.5625rem" }}>
                {/* Input de Upload de Arquivo */}
                <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            label="Arquivo"
                            fullWidth
                            value={field.value ? field.value.name : ""}
                            onClick={handleFileUploadClick}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            type="submit"
                                            onClick={(e) => e.stopPropagation()}
                                            disabled={isLoading}
                                        >
                                            <UploadFileIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={!!errors.file}
                            helperText={errors.file?.message}
                        />
                    )}
                />

                {/* Input oculto para upload */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
            </Stack>
        </form>
    );
}

export default FilesForm;
