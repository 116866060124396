import { useQuery } from "@tanstack/react-query";

import { readBudgetsFiles } from "../../services/budget";
import { TReadBudgetFilesResponse } from "../../services/budget/read-budgets-files";

type ReadBudgetFilesParams = {
    budgetId: string;
};

export function useReadBudgetFiles({ budgetId }: ReadBudgetFilesParams) {
    return useQuery<
        TReadBudgetFilesResponse,
        unknown,
        TReadBudgetFilesResponse
    >({
        enabled: true,
        queryKey: [`readBudgetFiles/${budgetId}`],
        queryFn: () => readBudgetsFiles({ budgetId }),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
