import styled from "styled-components";

import { TPriority } from "../../types";

export const ActivityRow = styled.tr<{
    isDragging?: boolean;
    withoutSection?: boolean;
}>`
    display: grid;
    grid-template-columns: ${({ withoutSection }) =>
        withoutSection
            ? "37.2% 15% 9.5% 9% 23.1% 4%"
            : "35% 15.5% 10% 9% 23.1% 4%"};
    align-items: center;
    width: 100%;
    height: 3.5rem;
    gap: 0.8rem;
    padding: 0 1rem;
    padding-left: ${({ withoutSection }) => (withoutSection ? "0" : " 52px")};
    color: ${({ theme }) => theme.gray600};
    border-bottom: 1px solid ${({ theme }) => theme.gray100};
    font-size: 0.875rem;
    cursor: pointer;

    ${({ isDragging, theme }) =>
        isDragging && `border: 1px solid ${theme.gray100};`}

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }

    .last {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-left: -23px;
    }

    &:hover .hide-on-hover {
        opacity: 1;
        pointer-events: all;
    }

    .hide-on-hover {
        opacity: 0;
        pointer-events: none;
    }
`;

export const RowDescription = styled.td`
    display: flex;
    align-items: center;
    gap: 1rem;

    .description {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

export const Priority = styled.td<{ type?: TPriority }>`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.07px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    color: ${({ theme, type }) => {
        switch (type) {
            case "high":
                return "#D32F2F";
            case "medium":
                return theme.orange;
            case "low":
                return theme.green1;
            default:
                return theme.gray100;
        }
    }};

    span {
        margin-bottom: -2px;
        font-size: 25px;
    }
`;

export const More = styled.div`
    width: 50px;
`;

export const MenuContainer = styled.div`
    width: 163px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
`;

export const MenuOption = styled.div<{
    danger?: boolean;
    edit?: boolean;
    disabled?: boolean;
}>`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    color: ${({ theme, danger, edit, disabled }) => {
        if (disabled) {
            return theme.gray300;
        }
        if (danger) {
            return theme.red;
        }
        if (edit) {
            return theme.gray800;
        }
        return theme.gray600;
    }} !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;

    svg {
        stroke: ${({ theme, danger }) => (danger ? theme.red : theme.gray600)};
    }

    &:hover {
        background-color: ${({ theme }) => theme.pink50};
    }
`;

export const TooltipContent = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
`;

export const CollaboratorImage = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
`;

export const StyledTooltip = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
`;
