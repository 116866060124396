import {
    Delete,
    InsertDriveFileOutlined,
    SearchOutlined,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { theme } from "../../../../../../assets";
import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import {
    TBudgetLevelDetailed,
    TReadBudgetsLevelsDetailsByIdResponse,
} from "../../../../../../services/budget/read-budgets-levels-detailed-by-id";

const CollapsibleRow = ({
    handleDeleteLevel,
    handleFeatureDrawer,
    profiles,
    row,
}: {
    handleDeleteLevel: (levelId: string) => Promise<void>;
    handleFeatureDrawer: (data: boolean, levelId: string) => void;
    profiles?: {
        Profile: {
            id_e: string;
            name: string;
        };
        id_e: string;
        name: string;
        costPerHour: string;
        BudgetProfileLevelDedicatedHours: {
            detailedLevel_id_e: string;
            bestPrediction: number;
            realPrediction: number;
            worstPrediction: number;
        }[];
    }[];
    row: TBudgetLevelDetailed;
}) => {
    const { Level, Parent, hasAnnotation } = row;

    const grandpaFeature = Parent?.Parent ?? null;
    const fatherFeature = Parent;
    let total = 0;

    const generateProfilesValues =
        profiles &&
        profiles.map((profile) => {
            const haveData = profile.BudgetProfileLevelDedicatedHours.find(
                (dedicatedHours) =>
                    dedicatedHours.detailedLevel_id_e === Level.id_e
            );

            const averageHours = haveData
                ? (haveData.bestPrediction +
                      haveData.realPrediction +
                      haveData.worstPrediction) /
                  3
                : 0;
            total += Number(averageHours?.toFixed(0));
            return {
                name: profile.name,
                averageHours: Number(averageHours?.toFixed(0)),
            };
        });

    return (
        <>
            <TableRow
                sx={{
                    boxShadow: "unset",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    ":hover": {
                        backgroundColor: theme.gray100,
                        cursor: "pointer",
                    },
                }}
                onClick={() => handleFeatureDrawer(true, Level.id_e)}
            >
                <TableCell align="left">
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteLevel(row.Level.id_e);
                        }}
                    >
                        <Delete />
                    </IconButton>
                </TableCell>
                {grandpaFeature && (
                    <TableCell align="left">
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: "Noto Sans",
                                color: grandpaFeature.color ?? "inherit",
                            }}
                        >
                            {grandpaFeature.name}
                        </Typography>
                    </TableCell>
                )}
                {fatherFeature && (
                    <TableCell align="left">
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: "Noto Sans",
                                color: fatherFeature.color ?? "inherit",
                            }}
                        >
                            {fatherFeature.name}
                        </Typography>
                    </TableCell>
                )}
                <TableCell align="left">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {Level.name}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    {hasAnnotation && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <IconButton
                                onClick={() =>
                                    handleFeatureDrawer(true, Level.id_e)
                                }
                                sx={{
                                    position: "relative",
                                    width: "2.5rem",
                                }}
                            >
                                <InsertDriveFileOutlined
                                    sx={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0, 0, 0, 0.56)",
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "-0.625rem",
                                        right: "0rem",
                                        borderRadius: "50%",
                                        backgroundColor: "#B93BDC",
                                        width: "0.5rem",
                                        height: "0.5rem",
                                    }}
                                />
                            </IconButton>
                        </Box>
                    )}
                </TableCell>
                {generateProfilesValues?.map((profile) => (
                    <TableCell align="center" key={profile.name}>
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: "Noto Sans",
                            }}
                        >
                            {profile.averageHours ?? 0}
                        </Typography>
                    </TableCell>
                ))}

                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {total?.toFixed(0) ?? 0}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
};

export const CustomTable = ({
    dataBudgetById,
    dataBudgetLevelDetails,
    handleDeleteLevel,
    handleFeatureDrawer,
}: {
    dataBudgetById?: TReadBudgetsResponse;
    dataBudgetLevelDetails?: TReadBudgetsLevelsDetailsByIdResponse;
    handleDeleteLevel: (levelId: string) => Promise<void>;
    handleFeatureDrawer: (data: boolean, levelId: string) => void;
}) => {
    const levelConfig = dataBudgetById?.LevelConfig;
    const profiles = dataBudgetById?.BudgetProfiles;

    return (
        <TableContainer
            component={Paper}
            sx={{
                boxShadow: "unset !important",
                backgroundColor: "transparent",
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {levelConfig?.map((level) => (
                            <TableCell align="left" key={level.id_e}>
                                {level.name}
                            </TableCell>
                        ))}
                        <TableCell align="center">Detalhes</TableCell>
                        {profiles?.map((profile) => {
                            const profileLabel =
                                profile.dependencyMode !== null
                                    ? `(${profile.Profile.abbreviation}) ${profile.Profile.name} ${profile.dependencyPercentage}%`
                                    : `(${profile.Profile.abbreviation}) ${profile.Profile.name}`;
                            return (
                                <TableCell align="center" key={profile.id_e}>
                                    {profileLabel}
                                </TableCell>
                            );
                        })}

                        <TableCell align="center">Total de Horas</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataBudgetLevelDetails?.map((row) => (
                        <CollapsibleRow
                            key={row.Level.id_e}
                            row={row}
                            profiles={profiles}
                            handleDeleteLevel={handleDeleteLevel}
                            handleFeatureDrawer={handleFeatureDrawer}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

function ScopeTable({
    dataBudgetById,
    dataBudgetLevelDetails,
    handleDeleteLevel,
    handleEditTimeDialog,
    handleFeatureDrawer,
    refetchLevels,
    setSearch,
}: {
    dataBudgetById?: TReadBudgetsResponse;
    dataBudgetLevelDetails?: TReadBudgetsLevelsDetailsByIdResponse;
    handleDeleteLevel: (levelId: string) => Promise<void>;
    handleEditTimeDialog: () => void;
    handleFeatureDrawer: (data: boolean, levelId: string) => void;
    refetchLevels: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<
        QueryObserverResult<
            {
                data: TReadBudgetsLevelsDetailsByIdResponse;
            },
            unknown
        >
    >;
    setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
    const { control, watch } = useForm({
        defaultValues: {
            search: "",
        },
    });

    const haveProfile =
        dataBudgetById && dataBudgetById?.BudgetProfiles?.length > 0;

    const search = watch("search");

    useEffect(() => {
        setSearch(search);
        const delayDebounceFn = setTimeout(() => {
            refetchLevels();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    return (
        <Stack
            sx={{
                gap: "2rem",
            }}
        >
            <Box
                sx={{
                    maxWidth: "19.0625rem",
                }}
            >
                <CustomTextField
                    variant="standard"
                    name="search"
                    control={control}
                    label="Pesquise pelo nome do orçamento"
                    fullWidth
                    translate="no"
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchOutlined
                                sx={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                }}
                            />
                        </InputAdornment>
                    }
                />
            </Box>

            <Stack
                sx={{
                    gap: "0.5rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "1.5rem",
                    }}
                >
                    <Typography variant="h5">Horas de Escopo</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                        }}
                    >
                        <Tooltip
                            side="bottom"
                            backgroundColor="rgba(97, 97, 97, 0.9)"
                            content={
                                <Box
                                    sx={{
                                        padding: "0.25rem 0.5rem",
                                        maxWidth: "27.5rem",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#FFF",
                                            fontFamily: "Noto Sans",
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "1.25rem",
                                            letterSpacing: "0.0088rem",
                                        }}
                                    >
                                        Para editar as horas é necessário
                                        definir perfis de atividade que serão
                                        atribuídos ao escopo.
                                        <br />
                                        <br />
                                        Adicione perfis na aba “Perfis de
                                        Atividade” no menu superior.
                                    </span>
                                </Box>
                            }
                        >
                            <Button
                                disabled={!haveProfile}
                                onClick={handleEditTimeDialog}
                                variant="outlined"
                            >
                                EDITAR HORAS
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
                <Divider />
            </Stack>

            <CustomTable
                dataBudgetLevelDetails={dataBudgetLevelDetails}
                dataBudgetById={dataBudgetById}
                handleDeleteLevel={handleDeleteLevel}
                handleFeatureDrawer={handleFeatureDrawer}
            />
        </Stack>
    );
}

export default ScopeTable;
