import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCreateStatus = {
    name: string;
    color: string;
};

export default async ({ params }: { params: TCreateStatus }) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/status`,
        method: "post",
        body: params,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({ body, statusCode });
};
