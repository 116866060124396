import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { TBudgetLevelDetailed } from "./read-budgets-levels-detailed-by-id";

const { REACT_APP_BASE_URL } = process.env;

type TReadLevelById = {
    budgetId: string;
    levelId: string;
};

export type TReadLevelByIdResponse = {
    data: Partial<TBudgetLevelDetailed> & {
        LevelComment: {
            Author: {
                avatar: string;
                id_e: string;
                name: string;
                Role: {
                    id_e: string;
                    name: string;
                };
            };
            created_at: string;
            id_e: string;
            text: string;
        }[];
    };
};

export default async ({ budgetId, levelId }: TReadLevelById) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/level/detailed/${levelId}`,
        method: "get",
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadLevelByIdResponse>({
        body,
        statusCode,
    });
};
