import { EditOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";

import { FotoUser } from "../../../../../../assets";
import { updateBudgetComment } from "../../../../../../services/budget";
import {
    TCommentBudget,
    TReadBudgetCommentResponse,
} from "../../../../../../services/budget/read-budget-comment";
import { formatISOToDate } from "../../../../../../utils";
import AnnotationForm from "./annotation-form";

type TAnnotationCardProps = {
    annotationData: TCommentBudget;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetCommentResponse, unknown>>;
};

function AnnotationCard({ annotationData, refetch }: TAnnotationCardProps) {
    const [isEditMode, setIsEditMode] = useState(false);
    const { Author, text, created_at } = annotationData;
    const handleEditAnnotation = () => {
        setIsEditMode((prev) => !prev);
    };

    const handleUpdateAnnotation = async (comment: string) => {
        await updateBudgetComment({
            budgetId: annotationData.budget_id_e,
            commentId: annotationData.id_e,
            params: { comment },
        });
        await refetch();
        toast.success("Anotação editada com sucesso!");
        handleEditAnnotation();
    };

    if (isEditMode)
        return (
            <AnnotationForm
                handleCreateAnnotation={handleUpdateAnnotation}
                editData={annotationData}
                handleEditAnnotation={handleEditAnnotation}
            />
        );

    return (
        <Stack
            sx={{
                gap: "1.5rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    p: "1.5rem 0.5rem",
                }}
            >
                <div
                    style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        borderRadius: "50%",
                        backgroundImage: `url(${Author?.avatar ?? FotoUser})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Stack>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "rgba(62, 55, 78, 0.87)",
                            fontFamily: "Noto Sans",
                            fontSize: "0.875rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "1.25rem",
                            letterSpacing: "0.0088rem",
                        }}
                    >
                        {Author.name}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "rgba(62, 55, 78, 0.60)",
                            fontFamily: "Noto Sans",
                            fontSize: "0.75rem",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "1.25rem",
                        }}
                    >
                        {Author.Role.name}
                    </Typography>
                </Stack>
            </Box>
            <Typography variant="body1">{text}</Typography>
            <IconButton
                onClick={handleEditAnnotation}
                sx={{
                    width: "2.25rem",
                    height: "2.25rem",
                }}
            >
                <EditOutlined />
            </IconButton>
            <Typography
                variant="body1"
                sx={{
                    color: "rgba(62, 55, 78, 0.60)",
                }}
            >
                Enviado em {formatISOToDate(created_at)}
            </Typography>
        </Stack>
    );
}

export default AnnotationCard;
