import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Stack,
    Typography,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { theme } from "../../../../../../assets";
import ColorPicker from "../../../../../../components/atoms/color-picker/color-picker";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { createStatus, updateStatus } from "../../../../../../services/budget";
import { TReadBudgetsStatusResponse } from "../../../../../../services/budget/read-budgets-status";
import { IStatusOption } from "./status-overview-select";

const validationSchema = yup.object().shape({
    name: yup.string().required("O nome é obrigatório"),
    color: yup.string().required("A cor é obrigatória"),
});

interface IStatusOverview {
    name: string;
    color: string;
}

export const StatusOverviewDialog = ({
    handleClose,
    open,
    refetchBudgetStatus,
    selectStatusToEdit,
}: {
    handleClose: () => void;
    open: boolean;
    refetchBudgetStatus: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsStatusResponse, unknown>>;
    selectStatusToEdit?: IStatusOption;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IStatusOverview>({
        defaultValues: {
            name: selectStatusToEdit?.label ?? "",
            color: selectStatusToEdit?.color ?? "",
        },
        resolver: yupResolver(validationSchema),
    });

    const submit = async (data: IStatusOverview) => {
        setIsLoading(true);
        if (selectStatusToEdit?.id) {
            await updateStatus({
                statusId: selectStatusToEdit?.id,
                params: data,
            }).then(() => toast.success("Status editado com sucesso!"));
        } else {
            await createStatus({ params: data }).then(() =>
                toast.success("Status criado com sucesso!")
            );
        }
        await refetchBudgetStatus();
        setIsLoading(false);
        handleClose();
    };

    const errorColor = errors && errors.color;
    return (
        <Dialog
            open={open}
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "40.375rem",
                    overflowY: "unset",
                },
            }}
        >
            <Box
                sx={{
                    p: "1rem",
                    width: "100%",
                }}
            >
                <form onSubmit={handleSubmit(submit)}>
                    <Stack
                        sx={{
                            gap: "0.75rem",
                            px: "1.5rem ",
                            marginTop: "1vh",
                        }}
                    >
                        <Typography variant="h6">
                            Adicionar um status
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: theme.gray500,
                            }}
                        >
                            Adicionar um status em andamento
                            <br />
                            Os status mostram as etapas do processo de trabalho.
                            <br />
                            <br />
                            Adicione outros status "em andamento" para
                            representar diferentes estágios no processo de
                            desenvolvimento da equipe, como "em revisão de
                            código" ou "no staging".
                        </Typography>
                    </Stack>

                    <DialogContent>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                gap: "1rem",
                                width: "100%",
                            }}
                        >
                            <CustomTextField
                                control={control}
                                name={`name`}
                                label={`Nome do status`}
                                fullWidth
                            />
                            <Stack
                                sx={{
                                    position: "relative",
                                    pr: "0.25rem",
                                }}
                            >
                                <ColorPicker
                                    onSelect={(color) =>
                                        setValue(`color`, color)
                                    }
                                    title="Escolha a cor do Nível"
                                />
                                {errorColor && (
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            position: "absolute",
                                            bottom: "-1.5rem",
                                            fontSize: "0.75rem",
                                            marginLeft: "0.875rem",
                                            marginRight: "0.875rem",
                                            color: "#d32f2f",
                                        }}
                                    >
                                        {errorColor?.message}
                                    </Typography>
                                )}
                            </Stack>
                        </Box>
                    </DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1rem",
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={handleClose}
                            disabled={isLoading}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={isLoading}
                        >
                            ADICIONAR
                        </Button>
                    </Box>
                </form>
            </Box>
        </Dialog>
    );
};
