import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TReadBudgetsLevels = {
    name?: string;
    levelOrder?: number;
};

export type BudgetStatus = "approved" | "refused" | "waiting" | "progress";

export type TReadBudgetsLevelsResponse = {
    id_e: string;
    name: string;
    MainLevel: {
        color: string;
    }[];
    Children: {
        id_e: string;
    }[];
    DetailedLevel: {
        Parent: {
            id_e: string;
            name: string;
        };
    }[];
    levelConfig: {
        id_e: string;
        name: string;
        levelOrder: number;
    };
};

export default async (
    budgetId: string,
    params: TReadBudgetsLevels = { name: "", levelOrder: 1 }
) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/level`,
        method: "get",
        params,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetsLevelsResponse[]>({
        body,
        statusCode,
    });
};
