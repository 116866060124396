import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { CustomTabs } from "../../../components";
import BreadCrumbsMUI from "../../../components/molecules/breadcrumpMUI/breadcrumpMUI";
import { TMuiOptionTabType } from "../../../components/molecules/custom-tab/custom-tab";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { TProject } from "../../../models";
import { projects } from "../../../services";
import * as s from "./styled-project";
import { TabActivities } from "./tab-activities/tab-activities";
import { TabCollabs } from "./tab-collabs/tab-collabs";
import { TabDescription } from "./tab-description/tab-description";

const rotes = [
    {
        label: "Projetos",
        href: "/projetos",
    },
];

export const Project = () => {
    const { verifyPermission } = useUser();

    const viewProjectsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "view",
    });

    const location = useLocation();
    const [project, setProject] = useState<TProject>(location.state.project);
    const [loading, setLoading] = useState<boolean>(true);

    const getProject = useFetch({
        fn: projects.getProject,
        start: false,
    });

    const currentRouteName = location?.state?.project?.name ?? "";

    const options: TMuiOptionTabType = [
        {
            title: "ATIVIDADES",
            content: <TabActivities loading={loading} project={project} />,
        },
        {
            title: "GANTT",
            isDisabled: true,
            content: <>GANTT</>,
        },
        {
            title: "TIME",

            content: (
                <TabCollabs
                    project={project}
                    loading={loading}
                    setLoading={setLoading}
                    getProject={getProject}
                />
            ),
        },
        {
            title: "DESCRIÇÃO",
            content: (
                <TabDescription
                    project={project}
                    loading={loading}
                    setLoading={setLoading}
                    getProject={getProject}
                />
            ),
        },
        {
            title: "DASHBOARD",
            isDisabled: true,
            content: <>DASHBOARD</>,
        },
        {
            title: "ANEXOS",
            isDisabled: true,
            content: <>TIME</>,
        },
        {
            title: "ANOTAÇÕES",
            isDisabled: true,
            content: <>TIME</>,
        },
    ];

    const tabOptions = !viewProjectsPermission
        ? options.filter(
              (option) =>
                  option.title !== "DESCRIÇÃO" && option.title !== "TIME"
          )
        : options;
    useEffect(() => {
        if (getProject.error) {
            toast.error(`${getProject.error}`);
            setLoading(getProject.loading);
        }
        if (getProject.response) {
            setProject(getProject.response.projects[0]);
            setLoading(getProject.loading);
        }
    }, [getProject.response, getProject.error, getProject.loading]);
    return (
        <>
            <s.Header>
                <BreadCrumbsMUI
                    breadcrumpRotes={rotes}
                    currentRote={currentRouteName}
                />
            </s.Header>

            <s.Container>
                <CustomTabs tabOptions={tabOptions} />
            </s.Container>
        </>
    );
};
