import { Box, LinearProgress, Typography } from "@mui/material";

interface IHourProgressProps {
    hourProgress: {
        minutesDone: number;
        percentageOfHoursDone: number;
    };
    expectedMinutes?: number;
}

const lessThanTen = (value: number) => (value < 10 ? `0${value}` : value);

const minutesToHours = (receivedMinutes: number) => {
    const hours = Math.floor(receivedMinutes / 60);
    const minutes = receivedMinutes % 60;
    return `${lessThanTen(hours)}:${lessThanTen(minutes)}`;
};

export const HourProgress = ({
    hourProgress,
    expectedMinutes,
}: IHourProgressProps) => {
    const timeDone = hourProgress?.minutesDone ?? 0;
    const percentageDoneValue = hourProgress?.percentageOfHoursDone ?? 0;
    const totalExpectedMinutes = expectedMinutes ?? 0;
    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body2" color="text.secondary">
                    {minutesToHours(timeDone)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {percentageDoneValue.toFixed(2)}%
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {minutesToHours(totalExpectedMinutes)}
                </Typography>
            </Box>
            <LinearProgress
                variant="determinate"
                value={percentageDoneValue}
                sx={{
                    width: "100%",
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: "#E0E0E0",
                }}
            />
        </Box>
    );
};
