import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    Typography,
    Box,
    IconButton,
    Button,
} from "@mui/material";
import { useState } from "react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

import { Icons, theme } from "../../../../assets";
import { TOption } from "../../../../models";
import { TUseFetch } from "../../../../types/TUseFetch";
import { ModalClient } from "../../../molecules";

type SelectClientProps<T extends FieldValues> = SelectProps & {
    name: Path<T>;
    control: Control<T>;
    label: string;
    options: TOption[];
    required?: boolean;
    fullWidth?: boolean;
    isLoading?: boolean;
    buttonName?: string;
    getClients?: TUseFetch;
    clearsClientSelect?: () => void;
};

const SelectClientMui = <T extends FieldValues>({
    name,
    control,
    label,
    options,
    required = false,
    fullWidth,
    isLoading,
    buttonName,
    getClients,

    clearsClientSelect,
    ...selectProps
}: SelectClientProps<T>) => {
    const [showDialog, setShowDialog] = useState(false);
    const [editRegister, setEditRegister] = useState(false);
    const [selectedOption, setSelectedOption] = useState<TOption>();

    const handleEdit = (option: TOption) => {
        setSelectedOption(option);
        setEditRegister(true);
        setShowDialog(true);
    };

    const handleCreate = () => {
        setEditRegister(false);
        setShowDialog(true);
    };

    return (
        <>
            {showDialog && (
                <ModalClient
                    setOpen={setShowDialog}
                    editRegister={editRegister}
                    option={selectedOption}
                    getClients={getClients}
                    clearsClientSelect={clearsClientSelect}
                />
            )}

            <Controller
                name={name}
                control={control}
                rules={{ required: required ? "Campo obrigatório" : false }}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <Box
                            sx={{
                                position: "relative",
                                width: fullWidth ? "100%" : "auto",
                            }}
                        >
                            <FormControl fullWidth error={!!error}>
                                <InputLabel>{label}</InputLabel>

                                <Select
                                    {...field}
                                    {...selectProps}
                                    label={label}
                                    sx={{
                                        height: "3.5rem",
                                    }}
                                >
                                    {options.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {option.label}
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleEdit(option)
                                                }
                                            >
                                                <Icons.PencilOutline
                                                    height="0.4rem"
                                                    width="0.4rem"
                                                />
                                            </IconButton>
                                        </MenuItem>
                                    ))}
                                    <Button
                                        variant="text"
                                        sx={{
                                            width: "100%",
                                            textAlign: "en",
                                            gap: "0.5rem",
                                            borderTop: `1px solid ${theme.gray200}`,
                                            borderRadius: "0",
                                        }}
                                        onClick={handleCreate}
                                    >
                                        <Icons.Plus />
                                        {buttonName?.toUpperCase() ||
                                            "ADICIONAR"}
                                    </Button>
                                </Select>
                            </FormControl>
                            {error && (
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        position: "absolute",
                                        bottom: "-1.5rem",
                                        fontSize: "0.75rem",
                                        marginLeft: "0.875rem",
                                        marginRight: "0.875rem",
                                        color: "#d32f2f",
                                    }}
                                >
                                    {error.message}
                                </Typography>
                            )}
                        </Box>
                    );
                }}
            />
        </>
    );
};

export default SelectClientMui;
