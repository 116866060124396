import { CalendarToday } from "@mui/icons-material";
import { Popover, TextField, IconButton, Box } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Control, Controller } from "react-hook-form";

interface ICalendarProps {
    name: string;
    label?: string;
    placeholder?: string;
    toYear?: number;
    disabledDays?: any;
    control: Control<any, any>;
}

export const CalendarMui = ({
    control,
    name,
    label,
    placeholder,
    toYear,
    disabledDays,
}: ICalendarProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
                <>
                    <Box onClick={handleOpen} sx={{ width: "100%" }}>
                        <TextField
                            label={label}
                            value={
                                field.value
                                    ? format(field.value, "dd/MM/yyyy")
                                    : ""
                            }
                            placeholder={placeholder}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={handleOpen}>
                                        <CalendarToday />
                                    </IconButton>
                                ),
                            }}
                            fullWidth
                        />
                    </Box>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <DayPicker
                            mode="single"
                            selected={field.value}
                            onSelect={(date) => {
                                field.onChange(date);
                                handleClose();
                            }}
                            fromYear={1970}
                            toYear={toYear || new Date().getFullYear()}
                            // locale={ptBR}
                            weekStartsOn={0}
                            disabled={disabledDays}
                        />
                    </Popover>
                </>
            )}
        />
    );
};
