import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TParams = {
    page: number;
    length: number;
};

type TParentType = {
    Parent?: {
        color?: string;
        id_e: string;
        name: string;
    };
    color?: string;
    id_e: string;
    name: string;
};

type TLevelType = {
    id_e: string;
    name: string;
};

export type TBudgetProfileLevelDedicatedHours = {
    bestPrediction: number;
    realPrediction: number;
    worstPrediction: number;
    detailedLevel_id_e: string;
    BudgetProfile: {
        id_e: string;
        Profile: {
            name: string;
            abbreviation: string;
        };
        responsible: {
            id_e: string;
            name: string;
        };
    };
}[];

export type TBudgetLevelDetailed = {
    BudgetProfileLevelDedicatedHours: TBudgetProfileLevelDedicatedHours;
    Level: TLevelType;
    Parent: TParentType;
    hasAnnotation: boolean;
};

export type TReadBudgetsLevelsDetailsByIdResponse = TBudgetLevelDetailed[];

type TBudgetsLevelsDetailedById = {
    budgetId: string;
    params: TParams;
};

export default async ({ budgetId, params }: TBudgetsLevelsDetailedById) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/level/detailed`,
        method: "get",
        params,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetsLevelsDetailsByIdResponse[]>({
        body,
        statusCode,
    });
};
