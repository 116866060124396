import { Delete, Edit } from "@mui/icons-material";
import {
    Avatar,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import { TReadBudgetsLevelsDetailsByIdResponse } from "../../../../../../services/budget/read-budgets-levels-detailed-by-id";
import {
    defaultCostToFormattedCurrency,
    formatCurrency,
} from "../../../../../organization/project/tab-costs/utils";
import { TProfile } from "./edit-profile-dialog";
import { NewResponsible } from "./profile-tab";

const CollapsibleRow = ({
    handleRemoveProfileFromBudget,
    handleModalResponsible,
    handleOpenEditProfile,
    profile,
    row,
}: {
    handleRemoveProfileFromBudget: (levelId: string) => void;
    handleModalResponsible: (
        responsibleData?: NewResponsible,
        projectId?: string
    ) => void;
    handleOpenEditProfile: (profile: TProfile) => void;
    profile?: TProfile;
    row?: TReadBudgetsLevelsDetailsByIdResponse;
}) => {
    const responsible = profile?.responsible?.id_e
        ? profile?.responsible
        : undefined;

    const total = profile?.totals?.totalPrice ?? 0;
    const average = profile?.totals?.totalHours ?? 0;

    return (
        <>
            <TableRow>
                <TableCell align="center">
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveProfileFromBudget(profile?.id_e || "");
                        }}
                    >
                        <Delete />
                    </IconButton>
                </TableCell>

                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {profile?.Profile?.abbreviation}
                    </Typography>
                </TableCell>

                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {profile?.Profile?.name}
                    </Typography>
                </TableCell>

                <TableCell align="center">
                    <IconButton
                        onClick={() => {
                            handleOpenEditProfile(profile!);
                        }}
                    >
                        <Edit
                            sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                            }}
                        />
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {/* Cálculo de porcentagem de horas */}
                        {profile?.dependencyPercentage || "-"}
                    </Typography>
                </TableCell>
                <TableCell
                    align="center"
                    sx={{
                        maxWidth: "11.875rem",
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                            lineBreak: "strict",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                        }}
                    >
                        {/* Perfis Cálculados */}
                        {profile?.BudgetProfileTimeDependancy
                            ? profile?.BudgetProfileTimeDependancy.map(
                                  (profileTimeDependency, index) =>
                                      index ===
                                      (profile?.BudgetProfileTimeDependancy
                                          ?.length || 0) -
                                          1
                                          ? profileTimeDependency.DependsOn
                                                .Profile.name
                                          : `${profileTimeDependency.DependsOn.Profile.name}, `
                              )
                            : "-"}
                    </Typography>
                </TableCell>
                <TableCell
                    align="center"
                    translate="no"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {/* Responsável */}
                    {responsible !== null ? (
                        <>
                            <Tooltip
                                side="bottom"
                                backgroundColor="rgba(97, 97, 97, 0.9)"
                                content={
                                    <span
                                        style={{
                                            color: "#FFF",
                                            fontFamily: "Noto Sans",
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "1.25rem",
                                            letterSpacing: "0.0088rem",
                                        }}
                                    >
                                        {responsible?.name}
                                    </span>
                                }
                            >
                                <IconButton
                                    key={responsible?.name}
                                    onClick={() =>
                                        handleModalResponsible(
                                            responsible,
                                            profile?.id_e
                                        )
                                    }
                                    sx={{
                                        width: "3rem",
                                        height: "3rem",
                                    }}
                                >
                                    {responsible?.avatar ? (
                                        <img
                                            key={responsible?.name}
                                            src={`${responsible?.avatar}`}
                                            alt={`${responsible?.name} `}
                                            style={{
                                                width: "2.5rem",
                                                height: "2.5rem",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    ) : (
                                        <Avatar
                                            sx={{
                                                height: "2.5rem",
                                                width: "2.5rem",
                                            }}
                                        />
                                    )}
                                </IconButton>
                            </Tooltip>
                            {responsible?.name ?? ""}
                        </>
                    ) : (
                        <IconButton onClick={() => handleModalResponsible()}>
                            <Avatar
                                translate="no"
                                sx={{
                                    height: "2.5rem",
                                    width: "2.5rem",
                                }}
                            />
                        </IconButton>
                    )}
                </TableCell>
                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {profile?.costPerHour
                            ? defaultCostToFormattedCurrency(
                                  profile?.costPerHour
                              )
                            : "R$ 0,00"}
                    </Typography>
                </TableCell>

                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {average?.toLocaleString("pt-br", {
                            maximumFractionDigits: 2,
                        }) ?? 0}
                    </Typography>
                </TableCell>

                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {total
                            ? defaultCostToFormattedCurrency(
                                  String(total?.toFixed(2))
                              )
                            : "R$ 0,00"}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
};

export const CustomTable = ({
    dataBudgetById,
    dataBudgetLevelDetails,
    handleRemoveProfileFromBudget,
    handleModalResponsible,
    handleOpenEditProfile,
}: {
    dataBudgetById?: TReadBudgetsResponse;
    dataBudgetLevelDetails?: TReadBudgetsLevelsDetailsByIdResponse;
    handleRemoveProfileFromBudget: (levelId: string) => void;
    handleModalResponsible: (
        responsibleData?: NewResponsible,
        projectId?: string
    ) => void;
    handleOpenEditProfile: (profile: TProfile) => void;
}) => {
    const profiles = dataBudgetById?.BudgetProfiles;

    return (
        <TableContainer
            component={Paper}
            sx={{
                boxShadow: "unset !important",
                backgroundColor: "transparent",
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">Sigla</TableCell>
                        <TableCell align="center">Perfil</TableCell>
                        <TableCell align="center" />
                        <TableCell align="center">
                            Cálculo da % de Horas
                        </TableCell>
                        <TableCell align="center">Perfis Calculados</TableCell>
                        <TableCell align="center">Responsável</TableCell>
                        <TableCell align="center">Custo/Hora</TableCell>
                        <TableCell align="center">Horas Estimadas</TableCell>
                        <TableCell align="center">Valor Final</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {profiles?.map((profile) => (
                        <CollapsibleRow
                            key={profile.id_e}
                            row={dataBudgetLevelDetails}
                            profile={profile}
                            handleRemoveProfileFromBudget={
                                handleRemoveProfileFromBudget
                            }
                            handleModalResponsible={handleModalResponsible}
                            handleOpenEditProfile={handleOpenEditProfile}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

function ProfileTable({
    dataBudgetById,
    dataBudgetLevelDetails,
    handleRemoveProfileFromBudget,
    handleModalResponsible,
    handleOpenEditProfile,
}: {
    dataBudgetById?: TReadBudgetsResponse;
    dataBudgetLevelDetails?: TReadBudgetsLevelsDetailsByIdResponse;
    handleRemoveProfileFromBudget: (profileId: string) => void;
    handleModalResponsible: (
        responsibleData?: NewResponsible,
        projectId?: string
    ) => void;
    handleOpenEditProfile: (profile: TProfile) => void;
}) {
    return (
        <Stack
            sx={{
                gap: "2rem",
            }}
        >
            <CustomTable
                dataBudgetLevelDetails={dataBudgetLevelDetails}
                dataBudgetById={dataBudgetById}
                handleRemoveProfileFromBudget={handleRemoveProfileFromBudget}
                handleModalResponsible={handleModalResponsible}
                handleOpenEditProfile={handleOpenEditProfile}
            />
        </Stack>
    );
}

export default ProfileTable;
