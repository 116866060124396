import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { Dispatch, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { theme } from "../../../../assets";
import Tooltip from "../../../../components/atoms/tooltip/tooltip";
import { useFetch } from "../../../../hooks";
import { TUser } from "../../../../models";
import { manageUser } from "../../../../services";
import * as s from "./styled-third-step";
import { NewResponsible } from "./third-step";

export const ResponsibleDialog = ({
    addResponsibles,
    open,
    onClose,
    responsibles,
    setResponsibles,
}: {
    open: boolean;
    onClose: () => void;
    addResponsibles: (NewResponsibles: NewResponsible | null) => void;
    responsibles: NewResponsible | null;
    setResponsibles: Dispatch<React.SetStateAction<NewResponsible | null>>;
}) => {
    const [users, setUsers] = useState<TUser[]>([]);
    const [list, setList] = useState<{ id: string; name: string }[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const getUsers = useFetch({
        fn: manageUser.getAllUsers,
        start: true,
    });

    const submit = () => {
        addResponsibles(responsibles);
        onClose();
    };

    const handleCancel = () => {
        setResponsibles(null);
        onClose();
    };

    const selecteResponsible = (id: string) => {
        const findResponsible = users.find(
            (userFromList) => userFromList.id_e === id
        );

        const valueToSet = {
            id: findResponsible?.id_e,
            name: findResponsible?.name,
            avatar: findResponsible?.avatar,
        };

        return setResponsibles(valueToSet);
    };

    const removeResponsibleSelected = () => {
        setResponsibles(null);
    };

    useEffect(() => {
        if (getUsers.error) toast.error(`${getUsers.error}`);
        if (getUsers.response) {
            setUsers(getUsers.response);
            setList(
                getUsers.response.map(
                    (user: { id_e: string; name: string }) => {
                        return {
                            id: user.id_e,
                            name: user.name,
                        };
                    }
                )
            );
            setIsLoading(false);
        }
    }, [getUsers.response]);

    return (
        <Dialog
            open={open}
            sx={{
                ".css-1il5h00-MuiPaper-root-MuiDialog-paper": {
                    maxWidth: "50rem",
                },
            }}
        >
            <s.DialogBox>
                <Typography
                    variant="h6"
                    sx={{ marginTop: "1vh", p: "1rem 1.5rem" }}
                >
                    Responsável
                </Typography>

                <DialogContent
                    sx={{
                        width: "100%",
                    }}
                >
                    <Stack
                        sx={{
                            gap: "1.5rem",
                        }}
                    >
                        <Typography variant="subtitle1">
                            O responsável pelo perfil será aquele que será
                            notificado para orçar aquela área.
                        </Typography>
                        {!isLoading ? (
                            <Autocomplete
                                options={list}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                getOptionLabel={(option) => option.name}
                                value={
                                    responsibles
                                        ? {
                                              id: responsibles.id!,
                                              name: responsibles.name!,
                                          }
                                        : undefined
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Responsável"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(_, newValue) =>
                                    selecteResponsible(newValue.id)
                                }
                                isOptionEqualToValue={(option, value) =>
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    option?.id === value?.id
                                } // Evita erro de comparação
                                disableClearable
                            />
                        ) : (
                            <Typography variant="subtitle1">
                                ...Carregando
                            </Typography>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1.5rem",
                                alignItems: "center",
                            }}
                        >
                            {responsibles !== null && (
                                <Tooltip
                                    side="bottom"
                                    backgroundColor="rgba(97, 97, 97, 0.9)"
                                    content={
                                        <span
                                            style={{
                                                color: "#FFF",
                                                fontFamily: "Noto Sans",
                                                fontSize: "0.875rem",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "1.25rem",
                                                letterSpacing: "0.0088rem",
                                            }}
                                        >
                                            {responsibles.name}
                                        </span>
                                    }
                                >
                                    <IconButton
                                        key={responsibles.id}
                                        onClick={removeResponsibleSelected}
                                    >
                                        {responsibles.avatar ? (
                                            <img
                                                src={`${responsibles.avatar}`}
                                                style={{
                                                    height: "2.5rem",
                                                    width: "2.5rem",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        ) : (
                                            <Avatar
                                                sx={{
                                                    heigth: "2.5rem",
                                                    width: "2.5rem",
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        <Typography variant="subtitle1">
                            Uma vez configurado o escopo do projeto, você pode
                            enviar para cada responsável a parte do orçamento
                            relacionada a ele para auxiliar nos custos do
                            projeto.
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={handleCancel}
                        sx={{
                            color: theme.purple500,
                            fontWeight: 500,
                            fontSize: "0.875rem",
                        }}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        onClick={submit}
                        variant="contained"
                        sx={{
                            color: theme.white,
                            fontWeight: 500,
                            fontSize: "0.875rem",
                        }}
                    >
                        CONFIRMAR
                    </Button>
                </DialogActions>
            </s.DialogBox>
        </Dialog>
    );
};
