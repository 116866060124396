import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TDeleteActivityStatus = {
    statusId: string;
    params: {
        newStatusIdForActivities: string;
    };
};

export default async ({ statusId, params }: TDeleteActivityStatus) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/status/${statusId}`,
        method: "delete",
        body: params,
        headers: {
            auth: true,
        },
    });

    const { body, statusCode } = httpResponse;

    return handleStatusCases<TSection>({
        body,
        statusCode,
    });
};
