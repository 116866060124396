import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    Typography,
    Box,
} from "@mui/material";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

type Option = {
    value: string | number;
    label: string;
};

type CustomSelectProps<T extends FieldValues> = SelectProps & {
    name: Path<T>;
    control: Control<T>;
    label: string;
    options: Option[];
    required?: boolean;
    fullWidth?: boolean;
};

const CustomSelect = <T extends FieldValues>({
    name,
    control,
    label,
    options,
    required = false,
    fullWidth,
    ...selectProps
}: CustomSelectProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: required ? "Campo obrigatório" : false }}
            render={({ field, fieldState: { error } }) => (
                <Box
                    sx={{
                        position: "relative",
                        width: fullWidth ? "100%" : "auto",
                    }}
                >
                    <FormControl fullWidth error={!!error}>
                        <InputLabel>{label}</InputLabel>
                        <Select {...field} {...selectProps} label={label}>
                            {options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {error && (
                        <Typography
                            variant="subtitle1"
                            sx={{
                                position: "absolute",
                                bottom: "-1.5rem",
                                fontSize: "0.75rem",
                                marginLeft: "0.875rem",
                                marginRight: "0.875rem",
                                color: "#d32f2f",
                            }}
                        >
                            {error.message}
                        </Typography>
                    )}
                </Box>
            )}
        />
    );
};

export default CustomSelect;
