import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TBudgetLevelCreate = {
    name: string;
    color?: string;
    parent?: string;
    id_e?: string;
    levelOrder: number;
};

export type TBudgetLevelCreateResponse = {
    count: number;
};

export default async (
    budgetId: string,
    bodyParams: TBudgetLevelCreate = {
        name: "",
        color: "",
        parent: "",
        levelOrder: 0,
    }
) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/level`,
        method: "post",
        body: bodyParams,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TBudgetLevelCreateResponse[]>({
        body,
        statusCode,
    });
};
