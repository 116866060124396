import { Tabs, Tab, Box } from "@mui/material";
import React, { useState } from "react";

export type TMuiOptionTabType = {
    title: string;
    isDisabled?: boolean;
    content: React.ReactNode;
}[];

type TCustomTab = {
    tabOptions: TMuiOptionTabType;
};

const TabPanel = ({
    children,
    value,
    index,
}: {
    children: React.ReactNode;
    value: number;
    index: number;
}) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

const CustomTabs = ({ tabOptions }: TCustomTab) => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <Box>
            <Tabs
                value={tabIndex}
                onChange={(_, newIndex) => setTabIndex(newIndex)}
                indicatorColor="primary"
                textColor="primary"
            >
                {tabOptions.map((option) => (
                    <Tab
                        label={option.title}
                        disabled={option?.isDisabled}
                        key={option.title}
                    />
                ))}
            </Tabs>
            {tabOptions.map((option, i) => (
                <TabPanel key={option.title} value={tabIndex} index={i}>
                    {option.content}
                </TabPanel>
            ))}
        </Box>
    );
};

export default CustomTabs;
