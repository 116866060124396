import { Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { useReadBudgetComment } from "../../../../../../hooks/react-query/use-budget-comment";
import { createBudgetComment } from "../../../../../../services/budget";
import AnnotationCard from "./annotation-card";
import AnnotationForm from "./annotation-form";

function AnnotationTab() {
    const { budgetId } = useParams();
    const id = budgetId || "";
    const { data, refetch } = useReadBudgetComment({
        budgetId: id,
    });

    const handleCreateAnnotation = useCallback(
        async (comment: string) => {
            await createBudgetComment({ budgetId: id, params: { comment } });
            await refetch();
            toast.success("Anotação criada com sucesso!");
        },
        [id, data, refetch]
    );

    const annotationsData = data?.data ?? [];

    return (
        <Stack>
            <Stack
                sx={{
                    justifyContent: "center",
                    gap: "0.5rem",
                    p: "1.5rem",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
            >
                <Typography variant="h5">Anotações</Typography>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: "rgba(62, 55, 78, 0.60)",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "18px",
                        letterSpacing: "0.07px",
                    }}
                >
                    Escreva insights do orçamento e projeto aqui.
                </Typography>
            </Stack>
            <Stack
                sx={{
                    p: "1.5rem",
                    gap: "1.5rem",
                }}
            >
                <Typography variant="h5">Anotações</Typography>
                <AnnotationForm
                    handleCreateAnnotation={handleCreateAnnotation}
                />
                <Stack
                    sx={{
                        gap: "1.5rem",
                        maxHeight: "28.5rem",
                        pt: "1rem",
                        overflowY: "auto",
                    }}
                >
                    {annotationsData
                        .map((annotation) => (
                            <AnnotationCard
                                key={annotation.id_e}
                                annotationData={annotation}
                                refetch={refetch}
                            />
                        ))
                        .reverse()}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default AnnotationTab;
