import styled from "styled-components";

type TTab = {
    active: boolean;
};

export const Container = styled.div`
    padding: 3vh;
    width: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray700};
    font-weight: normal;
    margin-bottom: 1rem;
`;

export const TabContainer = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
`;

export const Tab = styled.button<TTab>`
    padding: 1vh;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ active, theme }) =>
        active ? theme.primary.main : theme.gray500};
    border-bottom: 4px solid
        ${({ active, theme }) =>
            active ? ` ${theme.primary.main}` : "transparent"};
`;
