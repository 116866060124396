import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import {
    Box,
    Button,
    DialogActions,
    IconButton,
    InputAdornment,
    Stack,
    Typography,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { theme } from "../../../../../../assets";
import CustomSelect from "../../../../../../components/molecules/custom-select/custom-select";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import {
    createBudgetLevel,
    updateBudgetLevel,
} from "../../../../../../services/budget";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import { TLevel } from "../../../../../../services/budget/read-budgets";

const validationSchema = yup.object().shape({
    requiriments: yup
        .array()
        .of(
            yup.object().shape({
                name: yup.string().required("O nome é obrigatório"),
                parent: yup.string().nullable(),
                id_e: yup.string().optional(),
            })
        )
        .test("validate-parent-on-name", "", function (requiriments) {
            if (!requiriments) return true;

            const nameCounts = new Map<string, number>();
            let lastDuplicateIndex: number | null = null;
            let missingParentIndex: number | null = null;

            requiriments.forEach((item, index) => {
                const count = nameCounts.get(item.name!) || 0;
                nameCounts.set(item.name!, count + 1);
                if (count > 0) {
                    lastDuplicateIndex = index;
                }
                if (!item.parent) {
                    missingParentIndex = index;
                }
            });

            if (lastDuplicateIndex !== null) {
                return this.createError({
                    path: `requiriments[${lastDuplicateIndex}].name`,
                    message: "Este nome já foi utilizado anteriormente",
                });
            }

            if (missingParentIndex !== null) {
                return this.createError({
                    path: `requiriments[${missingParentIndex}].name`,
                    message: "Relacionar esse Nível com algum Item do Nível 1",
                });
            }

            return true;
        }),
});

interface IModule {
    name: string;
    parent: string;
    id_e: string;
}

interface IFormValues {
    requiriments: IModule[];
}

export const ScopeDialogLevel2Content = ({
    budgetId,
    handleClose,
    modulesData,
    requirimentsData,
    refetch,
}: {
    budgetId: string;
    handleClose: () => void;
    modulesData: TLevel;
    requirimentsData: TLevel;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsResponse, unknown>>;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const defaultValues = requirimentsData.Level.map((level) => ({
        name: level.name,
        id_e: level.id_e,
        parent: level.DetailedLevel.Parent.name,
    }));

    const options = modulesData.Level.map((level) => ({
        label: level.name,
        value: level.name,
    }));

    const { control, handleSubmit, setValue } = useForm<IFormValues>({
        defaultValues: { requiriments: defaultValues },
        resolver: yupResolver(validationSchema),
        mode: "onSubmit",
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "requiriments",
    });

    const submit = async (data: IFormValues) => {
        setIsLoading(true);
        const updatedRequiriments = data.requiriments.filter(
            (module, index) => {
                const original = defaultValues[index];
                if (original) return module.name !== original?.name;
                return module;
            }
        );

        const requirimentsToUpdate = updatedRequiriments.filter(
            (module) => module.id_e !== ""
        );
        const requirimentsToCreate = updatedRequiriments.filter(
            (module) => module.id_e === ""
        );

        try {
            const updatePromises = requirimentsToUpdate.map((module) => {
                const { name, id_e } = module;
                const dataUpdated = {
                    name,
                };
                return updateBudgetLevel(budgetId, id_e, dataUpdated);
            });

            const createPromises = requirimentsToCreate.map((module) => {
                const parent = modulesData.Level.find(
                    (level) => level.name === module.parent
                )?.id_e;
                return createBudgetLevel(budgetId, {
                    name: module.name,
                    levelOrder: 2,
                    parent,
                });
            });

            await Promise.all([...updatePromises, ...createPromises]);
            await refetch().then((data) => {
                const newValuesToForm = data.data?.LevelConfig.find(
                    (level) => level.levelOrder === 2
                )?.Level.map((level) => ({
                    name: level.name,
                    parent: level.DetailedLevel.Parent.name,
                    id_e: level.id_e,
                }));
                if (newValuesToForm) {
                    setValue("requiriments", newValuesToForm);
                }
            });
            toast.success(`${requirimentsData.name} editado com sucesso!`);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Erro ao processar as requisições:", error);
        }
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack
                sx={{
                    gap: "1.5rem",
                    overflowY: "auto",
                    pt: "0.4rem",
                    maxHeight: "37.5rem",
                }}
            >
                {fields.map((field, index) => {
                    return (
                        <Box
                            key={field.id}
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                gap: "1rem",
                                width: "100%",
                            }}
                        >
                            <CustomTextField
                                control={control}
                                name={`requiriments.${index}.name`}
                                label={`Nome do ${requirimentsData.name}`}
                                fullWidth
                                sx={{
                                    ".css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input":
                                        {
                                            width: "50% !important",
                                        },
                                }}
                                endAdornment={
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "50%",
                                        }}
                                    >
                                        {field.parent ? (
                                            <InputAdornment
                                                position="end"
                                                sx={{
                                                    flexGrow: 1,
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography>
                                                    {field.parent}
                                                </Typography>
                                            </InputAdornment>
                                        ) : (
                                            <InputAdornment
                                                position="end"
                                                sx={{
                                                    flexGrow: 1,
                                                    textAlign: "center",
                                                }}
                                            >
                                                <CustomSelect
                                                    variant="outlined"
                                                    control={control}
                                                    label=""
                                                    name={`requiriments.${index}.parent`}
                                                    options={options}
                                                    fullWidth
                                                    sx={{
                                                        height: "2rem",
                                                    }}
                                                />
                                            </InputAdornment>
                                        )}
                                        {field.id_e.length === 0 ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        remove(index)
                                                    }
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : undefined}
                                    </Box>
                                }
                            />
                        </Box>
                    );
                })}

                <Button
                    variant="text"
                    sx={{ marginTop: "1rem", maxWidth: "20rem" }}
                    onClick={() => append({ name: "", parent: "", id_e: "" })}
                >
                    + ADICIONAR NOVO {requirimentsData.name}
                </Button>
            </Stack>

            <DialogActions>
                <Button
                    variant="text"
                    onClick={handleClose}
                    sx={{
                        color: theme.purple500,
                        fontWeight: 500,
                        fontSize: "0.875rem",
                    }}
                >
                    VOLTAR
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    sx={{
                        color: theme.white,
                        fontWeight: 500,
                        fontSize: "0.875rem",
                    }}
                >
                    SALVAR
                </Button>
            </DialogActions>
        </form>
    );
};
