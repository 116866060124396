import { yupResolver } from "@hookform/resolvers/yup";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Stack,
} from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { Icons, theme } from "../../../../assets";
import CustomTextField from "../../../../components/molecules/custom-textfield/custom-textfield";
import { StepData } from "../form-screen";
import * as s from "../styled-form-screen";

const validationSchema = yup.object().shape({
    levelConfigs: yup.array().of(
        yup.object().shape({
            name: yup.string().required("O nome do nível é obrigatório"),
            levelOrder: yup.number().oneOf([1, 2, 3]),
        })
    ),
});

export type SecondStepData = {
    levelConfigs: { name: string; levelOrder: 1 | 2 | 3 }[];
};

type TSecondStepProps = {
    nextStep: (currentData: SecondStepData) => void;
    prevStep: () => void;
    fullData: StepData;
};

export const SecondStep = ({
    fullData,
    nextStep,
    prevStep,
}: TSecondStepProps) => {
    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm<SecondStepData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            levelConfigs:
                fullData?.levelConfigs.length > 0
                    ? fullData?.levelConfigs
                    : [
                          { name: "", levelOrder: 1 },
                          { name: "", levelOrder: 2 },
                      ],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "levelConfigs",
    });

    const handleNextStep = (data: SecondStepData) => {
        nextStep(data);
    };

    return (
        <form onSubmit={handleSubmit(handleNextStep)}>
            <Stack
                sx={{
                    gap: "1rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    {fields.map((field, index) => (
                        <CustomTextField
                            control={control}
                            name={`levelConfigs.${index}.name`}
                            key={field.id}
                            label={`Nível ${index + 1}`}
                            fullWidth
                            endAdornment={
                                index === 2 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => remove(index)}
                                        >
                                            <Icons.Trash />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null
                            }
                        />
                    ))}
                    {fields.length < 3 && (
                        <Box
                            sx={{
                                display: "flex",
                                width: "50%",
                            }}
                        >
                            <Button
                                variant="text"
                                onClick={() =>
                                    append({
                                        name: "",
                                        levelOrder: (fields.length + 1) as
                                            | 1
                                            | 2
                                            | 3,
                                    })
                                }
                                sx={{
                                    gap: "0.5rem",
                                }}
                            >
                                <Icons.Plus />
                                <strong>ADICIONAR NOVO NÍVEL</strong>
                            </Button>
                        </Box>
                    )}
                </Box>
                <s.FullLine />
                <Accordion sx={{ width: "100%" }}>
                    <AccordionSummary expandIcon={<Icons.Chevron />}>
                        Como Funciona?
                    </AccordionSummary>
                    <AccordionDetails>
                        <s.InfoText>
                            A funcionalidade de Níveis de Escopo permite
                            organizar informações hierárquicas dentro de um
                            projeto, proporcionando flexibilidade para
                            diferentes setores e cenários de uso.
                        </s.InfoText>
                        <s.InfoText>
                            Essa estrutura pode conter dois ou três níveis,
                            dependendo da complexidade do projeto e do tipo de
                            informação que precisa ser gerenciada
                        </s.InfoText>
                        <s.InfoTextExample>
                            <s.InfoText>
                                <Icons.Dev
                                    fill={theme.gray600}
                                    width="32"
                                    height="32"
                                />
                                <div>
                                    Desenvolvimento de Software (2 níveis)
                                    <br /> Nível 1: Requisitos Funcionais
                                    <br /> Nível 2: Features dentro de cada
                                    requisito
                                </div>
                            </s.InfoText>
                            <s.InfoText>
                                Exemplo:
                                <br />
                                Requisito Funcional: Autenticação de Usuário
                                <br /> Feature: Login com email e senha
                                <br /> Feature: Login via redes sociais
                            </s.InfoText>
                        </s.InfoTextExample>
                        <s.InfoTextExample>
                            <s.InfoText>
                                <Icons.Car color={theme.gray600} />
                                <div>
                                    Manutenção de Veículos (3 níveis)
                                    <br /> Nível 1: Oficina
                                    <br /> Nível 2: Tipo de Veículo
                                    <br /> Nível 3: Peças ou Serviços aplicados
                                </div>
                            </s.InfoText>
                            <s.InfoText>
                                Exemplo:
                                <br /> Oficina: Mecânica Geral
                                <br /> Tipo de Veículo: Carro
                                <br /> Serviço: Troca de óleo
                            </s.InfoText>
                        </s.InfoTextExample>
                        <s.InfoText>
                            Essa abordagem garante que qualquer área possa
                            estruturar seu escopo de forma lógica e fundamental,
                            permitindo que equipes e gestores tenham um melhor
                            controle sobre suas demandas.
                        </s.InfoText>
                    </AccordionDetails>
                </Accordion>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        gap: "1rem",
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            border: "0px",
                            color: theme.purple600,
                            fontFamily: "revert",
                            "&:hover": {
                                border: 0,
                                background: "transparent",
                            },
                        }}
                        onClick={prevStep}
                    >
                        VOLTAR
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!isValid}
                        sx={{
                            backgroundColor: theme.purple500,
                            "&:hover": {
                                backgroundColor: theme.purple600,
                            },
                        }}
                    >
                        AVANÇAR
                    </Button>
                </Box>
            </Stack>
        </form>
    );
};
