import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TGetSections = {
    projectId?: string;
    userIds?: string;
    activityUserIds?: string[];
    profileIds?: string[];
    statusIds?: string[];
};

export default async ({
    projectId,
    activityUserIds,
    profileIds,
    statusIds,
    userIds,
}: TGetSections = {}) => {
    const headers = {
        "x-project-id": projectId,
        "x-users-ids": userIds,
        auth: true,
    };

    if (!userIds) delete headers["x-users-ids"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/section/read`,
        method: "get",
        headers,
        params: {
            activityUserIds,
            profileIds,
            statusIds,
        },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
