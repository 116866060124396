import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import CustomTextField from "../../../../../components/molecules/custom-textfield/custom-textfield";
import { TResponse } from "../../../../../models";
import { TUpdateSection } from "../../../../../services/activities/update-section";
import { TSection } from "../types";
import * as s from "./styled-components";

const validationSchema = yup.object().shape({
    sectionName: yup
        .string()
        .required("Nome da sessão é obrigatório")
        .max(150, "O nome deve ter no máximo 150 caracteres"),
});

type TEditSection = {
    section: TSection;
    updateSection: TResponse<unknown, TUpdateSection>;
    toggleSectionEdit: (index: number) => void;
    index: number;
};

export const EditSection = ({
    section,
    updateSection,
    toggleSectionEdit,
    index,
}: TEditSection) => {
    const { control, reset, handleSubmit, setFocus, setValue } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const submit = handleSubmit(({ sectionName }) => {
        updateSection.onRefresh({
            name: sectionName,
            sectionId: section.id_e,
        });
    });

    useEffect(() => {
        updateSection.response = undefined;
    }, []);

    useEffect(() => {
        if (updateSection.response) {
            toggleSectionEdit(index);
        }
    }, [updateSection.response]);

    useEffect(() => {
        if (section) {
            setFocus("sectionName");
            setValue("sectionName", section.name);
        }
    }, [section]);

    return (
        <form
            onSubmit={submit}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    reset();
                    toggleSectionEdit(index);
                }
                if (e.key === "Enter") {
                    document.getElementById("save")?.click();
                }
            }}
        >
            <Stack
                sx={{
                    maxWidth: "20rem",
                }}
            >
                <CustomTextField
                    control={control}
                    maxCharacters={150}
                    placeholder="Escreva o nome da sessão"
                    fullWidth
                    name="sectionName"
                />
                <s.ButtonsContainer>
                    <Button
                        type="button"
                        variant="text"
                        onClick={() => {
                            reset();
                            toggleSectionEdit(index);
                        }}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        id="save"
                        type="submit"
                        variant="contained"
                        loading={updateSection.loading}
                    >
                        SALVAR
                    </Button>
                </s.ButtonsContainer>
            </Stack>
        </form>
    );
};
