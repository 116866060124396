import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TReadBudgetFiles = {
    budgetId: string;
};

export type TReadBudgetFilesResponse = {
    data: {
        created_at: string;
        id_e: string;
        name: string;
        type: string;
        updated_at: string;
        url: string;
    }[];
};

export default async ({ budgetId }: TReadBudgetFiles) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/attachment`,
        method: "get",
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetFilesResponse[]>({
        body,
        statusCode,
    });
};
