import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { theme } from "../../../../../../assets";

export const IntroModulesDialog = ({
    budgetId,
    open,
}: {
    budgetId: string;
    open: boolean;
}) => {
    const navigate = useNavigate();
    const backToBudgets = () => navigate("/financial");
    const handleContinue = () =>
        navigate(`/financial/budget/modules/${budgetId}`);
    return (
        <Dialog open={open}>
            <Box
                sx={{
                    p: "1rem",
                    maxWidth: "30.75rem",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ marginTop: "1vh", px: "1.5rem " }}
                >
                    Orçamento criado com sucesso!
                </Typography>

                <DialogContent>
                    <Stack
                        sx={{
                            gap: "2rem",
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: theme.gray500,
                            }}
                        >
                            Crie um escopo personalizado e acompanhe o gasto de
                            cada perfil dentro desse orçamento.
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={backToBudgets}
                        sx={{
                            color: theme.purple500,
                            fontWeight: 500,
                            fontSize: "0.875rem",
                            textTransform: "uppercase",
                        }}
                    >
                        Voltar para orçamentos
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleContinue}
                        sx={{
                            color: theme.white,
                            fontWeight: 500,
                            fontSize: "0.875rem",
                            textTransform: "uppercase",
                        }}
                    >
                        continuar
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
