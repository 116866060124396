import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormControlLabel,
    FormGroup,
    Stack,
    Typography,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";

import { createSolicitationEstimate } from "../../../../../../services/budget";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budgets";

interface IOption {
    label: string;
    value: string | number;
}

export const SendNotificationDialog = ({
    budgetId,
    handleClose,
    open,
    options,
    refetch,
}: {
    budgetId: string;
    handleClose: () => void;
    open: boolean;
    options?: IOption[];
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsResponse, unknown>>;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState<(string | number)[]>();

    const handleChange = (value: string | number) => {
        const newSelected = (selected ?? []).includes(value)
            ? (selected ?? []).filter((v) => v !== value)
            : [...(selected ?? []), value];
        setSelected(newSelected);
    };

    const submit = async () => {
        setIsLoading(true);
        await createSolicitationEstimate({
            budgetId,
            params: {
                profileIds: selected as string[],
            },
        })
            .then(() => toast.success("Solicitação criada com sucesso!"))
            .catch(() => toast.error("Erro ao criar solicitação"));
        await refetch();
        setIsLoading(false);
    };

    return (
        <Dialog
            open={open}
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "50rem",
                    width: "100%",
                    overflowY: "unset",
                },
            }}
        >
            <Box
                sx={{
                    p: "1rem",
                    width: "100%",
                }}
            >
                <Stack
                    sx={{
                        gap: "0.75rem",
                        px: "1.5rem ",
                        marginTop: "1vh",
                    }}
                >
                    <Typography variant="h6">
                        Reenviar para Responsaveis
                    </Typography>
                </Stack>

                <DialogContent>
                    <FormGroup>
                        {options?.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                control={
                                    <Checkbox
                                        checked={selected?.includes(
                                            option.value
                                        )}
                                        onChange={() =>
                                            handleChange(option.value)
                                        }
                                    />
                                }
                                label={option.label}
                            />
                        ))}
                    </FormGroup>
                </DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1rem",
                    }}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isLoading || selected?.length === 0}
                        onClick={submit}
                    >
                        ENVIAR
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};
