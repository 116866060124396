import { ReactElement, type FC } from "react";

import BreadCrumbsMUI from "../../components/molecules/breadcrumpMUI/breadcrumpMUI";
import { useUser } from "../../context";
import * as s from "./styled-finances-home";

const rotes = [
    {
        label: "Orçamentos",
        href: "/financial",
    },
];

export const Layout = ({
    children,
    disableTitle = false,
}: {
    children: ReactElement;
    disableTitle?: boolean;
}) => {
    const { verifyPermission } = useUser();
    const organizationPermission = verifyPermission({
        id: "manage_teams_projects",
        rule: "enabled",
    });
    return (
        <s.Container>
            {organizationPermission && (
                <BreadCrumbsMUI
                    breadcrumpRotes={rotes}
                    currentRote="Novo Orçamento"
                />
            )}
            {!disableTitle && <s.Title>Orçamentos</s.Title>}
            {children}
        </s.Container>
    );
};
