import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogContent, Stack, Typography } from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { theme } from "../../../../../../assets";
import CustomSelect from "../../../../../../components/molecules/custom-select/custom-select";
import { deleteActivityStatus } from "../../../../../../services/activities";
import { TProjectCategoryStatusResponse } from "../../../../../../services/activities/read-category-status";
import { TReadBudgetsStatusResponse } from "../../../../../../services/budget/read-budgets-status";
import { IOptionStatus } from "../status-dropdown/status-dropdown";

const validationSchema = yup.object().shape({
    newStatusIdForActivities: yup.string().required("O status é obrigatório"),
});

interface IStatusProject {
    newStatusIdForActivities: string;
}

function DeleteForm({
    handleClose,
    handleDeleteMode,
    refetch,
    refetchStatus,
    selectStatusToEdit,
    setIsLoading,
    isLoading,
    statusOptions,
}: {
    handleClose: () => void;
    handleDeleteMode: () => void;
    refetchStatus: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TProjectCategoryStatusResponse, unknown>>;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsStatusResponse, unknown>>;
    selectStatusToEdit?: IOptionStatus;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    statusOptions: IOptionStatus[];
}) {
    const { control, handleSubmit } = useForm<IStatusProject>({
        resolver: yupResolver(validationSchema),
    });

    const submit = async (data: IStatusProject) => {
        setIsLoading(true);

        await deleteActivityStatus({
            statusId: selectStatusToEdit?.id_e || "",
            params: data,
        }).then(() => toast.success("Status editado com sucesso!"));

        await refetch();
        await refetchStatus();
        setIsLoading(false);
        handleClose();
    };
    const options = statusOptions
        .map((status) => ({
            label: status.name,
            value: status.id_e,
        }))
        .filter((status) => status.value !== selectStatusToEdit?.id_e);
    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack
                sx={{
                    gap: "0.75rem",
                    px: "1.5rem ",
                    marginTop: "1vh",
                }}
            >
                <Typography variant="h6">Status de Atividades</Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.gray500,
                    }}
                >
                    Você possui X atividades atreladas a esse status.
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.gray500,
                    }}
                >
                    Para continuar a exclusão selecione um dos Status existentes
                    para substituí-los.
                </Typography>
            </Stack>

            <DialogContent>
                <CustomSelect
                    control={control}
                    name="newStatusIdForActivities"
                    label={"Status"}
                    options={options}
                />
            </DialogContent>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "1rem",
                }}
            >
                <Button
                    variant="text"
                    onClick={handleDeleteMode}
                    disabled={isLoading}
                >
                    CANCELAR
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="error"
                    disabled={isLoading}
                >
                    Excluir
                </Button>
            </Box>
        </form>
    );
}

export default DeleteForm;
