import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TDeleteLevelBudget = {
    budgetId: string;
    levelId: string;
};

export default async ({ budgetId, levelId }: TDeleteLevelBudget) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/level/${levelId}`,
        method: "delete",

        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({ body, statusCode });
};
