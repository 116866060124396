import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { TLevel } from "./read-budgets";
import { TBudgetProfileLevelDedicatedHours } from "./read-budgets-levels-detailed-by-id";

const { REACT_APP_BASE_URL } = process.env;

type TReadBudgetById = {
    budgetId: string;
};

export type BudgetStatus = "approved" | "refused" | "waiting" | "progress";

export type TReadBudgetsResponse = {
    id_e: string;
    name: string;
    color: string;
    secondary_color: string;
    status: BudgetStatus;
    description: string;
    client: {
        id_e: string;
        name: string;
    };
    organization: {
        id_e: string;
        name: string;
    };
    BudgetProfiles: {
        BudgetProfileLevelDedicatedHours: TBudgetProfileLevelDedicatedHours;
        BudgetProfileTimeDependancy: {
            DependsOn: {
                Profile: {
                    name: string;
                };
                id_e: string;
            };
        }[];
        dependencyPercentage?: number | null;
        Profile: { id_e: string; name: string; abbreviation: string };
        id_e: string;
        name: string;
        costPerHour: string;
        hoursEstimationStatus?: string;
        responsible: {
            avatar: string;
            id_e: string;
            name: string;
        } | null;
        dependencyMode: string | null;
        totals: {
            totalHours: number;
            totalPrice: number;
        };
    }[];
    type: {
        id_e: string;
        name: string;
    };
    isArchived: boolean;
    totalHours: number;
    totalPrice: number;
    created_at: string;
    updated_at: string;
    LevelConfig: TLevel[];
};

export default async ({ budgetId }: TReadBudgetById) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}`,
        method: "get",
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    return handleStatusCases<TReadBudgetsResponse>({
        body,
        statusCode,
    });
};
