import { useMutation } from "@tanstack/react-query";

import createBudgetLevel, {
    TBudgetLevelCreate,
} from "../../services/budget/create-budget-level";

type Params = {
    budgetId: string;
    params: TBudgetLevelCreate;
    onSuccess?: (data: any) => void;
};

export function useCreateLevel({ budgetId, params, onSuccess }: Params) {
    return useMutation({
        mutationKey: [`level/${budgetId}/${params.parent}`],
        mutationFn: () => createBudgetLevel(budgetId, params),
        onSuccess,
    });
}
