import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { theme } from "../../../../assets";
import {
    ColorPicker,
    LoadingGray,
    ModalAction,
    PopoverRadix,
    PrimaryButton,
    SecondaryButton,
    Tag,
    TooltipHistory,
} from "../../../../components";
import SelectClientMui from "../../../../components/atoms/selects/select-client-mui/select-client-mui";
import CustomSelect from "../../../../components/molecules/custom-select/custom-select";
import CustomTextField from "../../../../components/molecules/custom-textfield/custom-textfield";
import { CalendarMui } from "../../../../components/molecules/day-picker-mui/day-picker-mui";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { TOption, TProject } from "../../../../models";
import { manageClients, projects } from "../../../../services";
import { TUseFetch } from "../../../../types/TUseFetch";
import { formatToBRMoney, onlyNumbers } from "../../../../utils";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import {
    renderProjectDuration,
    statusOptions,
    verifyProjectStatus,
} from "../../utils/projects-utils";
import { SelectTechnologies } from "./components/select-technologies/select-technologies";
import * as s from "./styled-tab-description";
import schema from "./validation";

dayjs.extend(utc);

const s4Style = {
    color: "rgba(62, 55, 78, 0.6)",
    fontFamily: "Noto Sans",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.125rem",
    letterSpacing: "0.0044rem",
};

const s4StyleValues = {
    ...s4Style,
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
    color: "rgba(62, 55, 78, 0.87)",
};

type TTabDescription = {
    project?: TProject;
    loading?: boolean;
    setLoading: (bool: boolean) => void;
    getProject: TUseFetch;
};

type TFields = {
    name?: string;
    client?: string;
    description?: string;
    color?: string;
    start_at?: Date | string;
    end_at?: Date | string;
    status?: string;
    projectId?: string;
    usersId?: string;
    sectorsId?: string;
    reviewersId?: string;
    billersId?: string;
    estimatedHours?: string;
    scopePercentage?: string;
    cost?: string;
    generalCosts?: number;
    technologies?: string;
    archived?: string;
};

export const TabDescription = ({
    project,
    loading,
    setLoading,
    getProject,
}: TTabDescription) => {
    const { verifyPermission } = useUser();
    const [edit, setEdit] = useState(false);
    const [clientsOptions, setClientsOptions] = useState<TOption[]>([]);
    const [color, setColor] = useState<string>(project?.color || theme.gray400);
    const [modalData, setModalData] = useState<{
        isOpen: boolean;
        title: string;
        description: string;
        secondDescription?: string;
        primaryAction: () => unknown;
        secondaryAction: () => unknown;
        primaryText: string;
        secondaryText: string;
    }>({
        isOpen: false,
        title: "",
        description: "",
        secondDescription: "",
        primaryAction: () => ({}),
        secondaryAction: () => ({}),
        primaryText: "",
        secondaryText: "",
    });
    const [selectedTechnologies, setSelectedTechnologies] = useState<TOption[]>(
        project?.technologies?.split(", ").map((tech) => ({
            label: tech,
            value: tech,
        })) || []
    );

    const defaultValues = {
        name: project?.name || "",
        client: project?.Client?.id_e || "",

        description: project?.description || "",
        start_at: project?.start_at && new Date(project.start_at),
        end_at:
            typeof project?.end_at === "string" ? new Date(project.end_at) : "",
        status: project?.status || "no_information",
    };

    const canManageProjects = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });
    const canViewCost = verifyPermission({
        id: "projects_values",
        rule: "view",
    });
    const canEditCost = verifyPermission({
        id: "projects_values",
        rule: "manage",
    });

    const {
        register,
        trigger,
        control,
        getValues,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm<TFields>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const getClients = useFetch({
        fn: manageClients.getClients,
        start: true,
    });

    const updateProject = useFetch({
        fn: projects.updateProject,
        start: false,
    });

    const clearsClientSelect = () => {
        setValue("client", undefined);
    };

    const onSubmit = (data?: TFields) => {
        setLoading(true);

        updateProject.onRefresh({
            name: data?.name,
            clientId: data?.client,
            description: data?.description,
            color: data?.color,
            start_at: dayjs.utc(data?.start_at).add(3, "hour").toDate(),
            end_at: dayjs.utc(data?.end_at).add(3, "hour").toDate(),
            status: data?.status,
            projectId: project?.id_e,
            usersId: project?.Users?.map((user) => user.id_e).join(", "),
            reviewersId: project?.ProjectReviewers?.map(
                (reviewer) => reviewer.id_e
            ).join(", "),
            billersId: project?.ProjectBiller?.map(
                (biller) => biller.id_e
            ).join(", "),
            estimatedHours: parseInt(data?.estimatedHours || "0", 10),
            scopePercentage: parseInt(data?.scopePercentage || "0", 10),
            cost: data?.cost
                ? (parseFloat(onlyNumbers(data?.cost || "")) / 100).toString()
                : undefined,
            technologies: selectedTechnologies
                .map((tech) => tech.value)
                .join(", "),
            archived: data?.archived === "true",
        });

        setEdit(!edit);
    };

    useEffect(() => {
        if (getClients.error) {
            setLoading(false);
        }
        if (getClients.response) {
            const tempClientsOptions: TOption[] = [];
            getClients.response.forEach(
                (client: { id_e: string; name: string }) =>
                    tempClientsOptions.push({
                        value: client.id_e,
                        label: client.name,
                    })
            );
            setClientsOptions(tempClientsOptions);
            setLoading(false);
        }
    }, [getClients.response]);

    useEffect(() => {
        if (updateProject.error) {
            setLoading(false);
        }
        if (updateProject.response) {
            getProject.onRefresh({
                projectId: project?.id_e,
                users: true,
                hours: true,
                finished: false,
                sectors: true,
                reviewers: true,
                all: true,
            });
            setLoading(false);
            Mixpanel.track("Salvar edição do projeto na aba descrição");
        }
    }, [updateProject.response, updateProject.error]);

    const handlePrimaryAction = (value: string) => {
        setValue("status", value);
        setModalData({ ...modalData, isOpen: false });
    };

    const handleSecondaryAction = () => {
        setModalData({ ...modalData, isOpen: false });
    };

    const canActivateClient = (value: string) => {
        if (!project) return false;
        const projectInactiveStatus = ["canceled", "finished"];
        const isActivating =
            projectInactiveStatus.includes(project.status) &&
            projectInactiveStatus.includes(value) === false;
        return isActivating && project?.Client?.active === false;
    };

    const modalAction = (value: string) => {
        if (canActivateClient(value)) {
            setModalData({
                isOpen: true,
                title: "Cliente inativo",
                description:
                    "Ao retirar o projeto de pausado/cancelado, você também estará ativando o cliente.",
                secondDescription: "Você deseja ativar o projeto novamente?",
                primaryAction: () => handlePrimaryAction(value),
                secondaryAction: handleSecondaryAction,
                primaryText: "Ativar projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        if (value === "paused") {
            setModalData({
                isOpen: true,
                title: "Pausar projeto",
                description:
                    "Enquanto o projeto estiver pausado, o time não poderá utilizar a tag deste projeto no cronômetro para inserir horas.",
                secondDescription: "Você deseja pausar o projeto?",
                primaryAction: () => handlePrimaryAction(value),
                secondaryAction: handleSecondaryAction,
                primaryText: "Pausar Projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        if (value === "canceled") {
            setModalData({
                isOpen: true,
                title: "Cancelar projeto",
                description:
                    "Enquanto o projeto estiver cancelado, o time não poderá utilizar a tag deste projeto no cronômetro para inserir horas.",
                secondDescription: "Você deseja cancelar o projeto?",
                primaryAction: () => handlePrimaryAction(value),
                secondaryAction: handleSecondaryAction,
                primaryText: "Cancelar Projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        if (value === "finished") {
            setModalData({
                isOpen: true,
                title: "Concluir projeto",
                description:
                    "Enquanto o projeto estiver concluído, o time não poderá utilizar a tag deste projeto no cronômetro para inserir horas.",
                secondDescription: "Você deseja concluir o projeto?",
                primaryAction: () => handlePrimaryAction(value),
                secondaryAction: handleSecondaryAction,
                primaryText: "Concluir projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        setValue("status", value);
    };

    const openArchivedModal = (option: TOption, onChange: any) => {
        if (option.value === "true") {
            setModalData({
                isOpen: true,
                title: "Arquivar projeto",
                description:
                    "Ao arquivar o projeto, ele não será exibido para os colaboradores inseridos e será ocultado da tela de projetos.",
                secondDescription: "Você deseja arquivar o projeto?",
                primaryAction: () => {
                    onChange(option.value);
                    handleSecondaryAction();
                },
                secondaryAction: handleSecondaryAction,
                primaryText: "Arquivar projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        onChange(option.value);
    };

    const archivedOptions = [
        {
            label: "Não",
            value: "false",
        },
        {
            label: "Sim",
            value: "true",
        },
    ];

    return (
        <>
            {modalData.isOpen && (
                <ModalAction
                    setOpen={() =>
                        setModalData({
                            ...modalData,
                            isOpen: !modalData.isOpen,
                        })
                    }
                    title={modalData.title}
                    description={modalData.description}
                    secondDescription={modalData.secondDescription}
                    primaryAction={modalData.primaryAction}
                    primaryText={modalData.primaryText}
                    secondaryAction={modalData.secondaryAction}
                    secondaryText={modalData.secondaryText}
                />
            )}
            <s.Content
                onSubmit={handleSubmit(onSubmit)}
                /* onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSubmit(onSubmit)();
                    }
                }} */
            >
                {loading ? (
                    <LoadingGray />
                ) : (
                    <div className="fields">
                        <s.FieldsWrapper>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    maxWidth: edit ? "48.25rem" : "26.5625rem",
                                }}
                            >
                                {edit ? (
                                    <CustomTextField
                                        control={control}
                                        name="name"
                                        fullWidth
                                        label="Nome do Projeto"
                                    />
                                ) : (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4Style}
                                        >
                                            Nome do Projeto{edit && `*`}:
                                        </Typography>
                                        <div className="tag-container">
                                            <Tag color={project?.color || ""}>
                                                {project?.name || "-"}
                                            </Tag>
                                        </div>
                                    </>
                                )}
                            </Box>
                            {edit && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        maxWidth: edit
                                            ? "48.25rem"
                                            : "26.5625rem",
                                    }}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={s4Style}
                                    >
                                        Tag do Projeto:
                                    </Typography>

                                    <PopoverRadix
                                        side="right"
                                        sideOffset={20}
                                        closeOnSelect
                                        align="end"
                                        trigger={
                                            <div className="tag-container">
                                                <Tag color={color}>
                                                    {project?.name || "-"}
                                                </Tag>
                                            </div>
                                        }
                                    >
                                        <Controller
                                            name="color"
                                            control={control}
                                            render={({
                                                field: { onChange },
                                            }) => (
                                                <ColorPicker
                                                    title="Escolha a cor da tag"
                                                    onSelect={(
                                                        selectedColor: string
                                                    ) => {
                                                        onChange(selectedColor);
                                                        setColor(selectedColor);
                                                    }}
                                                />
                                            )}
                                        />
                                    </PopoverRadix>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    maxWidth: edit ? "48.25rem" : "26.5625rem",
                                }}
                            >
                                {edit ? (
                                    <SelectClientMui
                                        control={control}
                                        label="Cliente"
                                        name="client"
                                        fullWidth
                                        title={"Nome do Cliente"}
                                        options={clientsOptions}
                                        buttonName={"Novo Cliente"}
                                        getClients={getClients}
                                        clearsClientSelect={clearsClientSelect}
                                    />
                                ) : (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4Style}
                                        >
                                            Cliente{edit && `*`}:
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4StyleValues}
                                        >
                                            {project?.Client?.name || "-"}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            {edit ? (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0.75rem",
                                            maxWidth: edit
                                                ? "48.25rem"
                                                : "26.5625rem",
                                        }}
                                    >
                                        <CalendarMui
                                            label="Início do projeto"
                                            name="start_at"
                                            control={control}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0.75rem",
                                            maxWidth: edit
                                                ? "48.25rem"
                                                : "26.5625rem",
                                        }}
                                    >
                                        <CalendarMui
                                            label="Fim do projeto"
                                            name="end_at"
                                            control={control}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        maxWidth: edit
                                            ? "48.25rem"
                                            : "26.5625rem",
                                    }}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={s4Style}
                                    >
                                        Duração do projeto:
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        sx={s4StyleValues}
                                    >
                                        {renderProjectDuration(
                                            project?.start_at || "",
                                            project?.end_at || ""
                                        )}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    maxWidth: edit ? "48.25rem" : "26.5625rem",
                                }}
                            >
                                {edit ? (
                                    <CustomSelect
                                        control={control}
                                        name="status"
                                        label={"Status"}
                                        fullWidth
                                        options={statusOptions}
                                    />
                                ) : (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4Style}
                                        >
                                            Status:
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4StyleValues}
                                        >
                                            {verifyProjectStatus(
                                                project?.status || ""
                                            )}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    maxWidth: edit ? "48.25rem" : "26.5625rem",
                                }}
                            >
                                {edit ? (
                                    <CustomTextField
                                        control={control}
                                        name="estimatedHours"
                                        placeholder="1200:00"
                                        label="Horas previstas"
                                        fullWidth
                                    />
                                ) : (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4Style}
                                        >
                                            Horas previstas:
                                        </Typography>

                                        <Typography
                                            variant="subtitle2"
                                            sx={s4StyleValues}
                                        >
                                            {project?.estimatedHours || "0"}
                                            <TooltipHistory
                                                dataList={
                                                    project?.ProjectEstimatedHoursHistory
                                                }
                                                valueKey="estimated_hours"
                                            />
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    maxWidth: edit ? "48.25rem" : "26.5625rem",
                                }}
                            >
                                {edit ? (
                                    <CustomTextField
                                        control={control}
                                        name="scopePercentage"
                                        label="Escopo atual"
                                        isPercentage
                                        fullWidth
                                        placeholder="20%"
                                    />
                                ) : (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4Style}
                                        >
                                            Escopo atual:
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4StyleValues}
                                        >
                                            {project?.scopePercentage || "0"}
                                            %
                                            <TooltipHistory
                                                dataList={
                                                    project?.ProjectScopeHistory
                                                }
                                                valueKey="scopePercentage"
                                            />
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            {canViewCost && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        maxWidth: edit
                                            ? "48.25rem"
                                            : "26.5625rem",
                                    }}
                                >
                                    {edit && canEditCost ? (
                                        <CustomTextField
                                            control={control}
                                            name="cost"
                                            isCurrency
                                            fullWidth
                                            label="Custo total previsto"
                                            placeholder="R$ 100.000.000,00"
                                        />
                                    ) : (
                                        <>
                                            <Typography
                                                variant="subtitle2"
                                                sx={s4Style}
                                            >
                                                Custo total previsto:
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={s4StyleValues}
                                            >
                                                {formatToBRMoney(
                                                    parseFloat(
                                                        project?.cost as unknown as string
                                                    ) || 0
                                                )}
                                                <TooltipHistory
                                                    dataList={
                                                        project?.ProjectCostHistory
                                                    }
                                                    valueKey="cost"
                                                />
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    maxWidth: edit ? "48.25rem" : "26.5625rem",
                                }}
                            >
                                {edit ? (
                                    <CustomSelect
                                        control={control}
                                        name="archived"
                                        label="Arquivado"
                                        fullWidth
                                        options={archivedOptions}
                                    />
                                ) : (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4Style}
                                        >
                                            Arquivado:
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4StyleValues}
                                        >
                                            {project?.archived === true
                                                ? "Sim"
                                                : "Não"}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            {project && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        maxWidth: edit
                                            ? "48.25rem"
                                            : "26.5625rem",
                                    }}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={s4StyleValues}
                                    >
                                        Projeto criado por{" "}
                                        {project.CreatedBy?.name || "---"} em{" "}
                                        {dayjs(project.created_at).format(
                                            "DD/MM/YYYY [às] HH:mm"
                                        )}
                                    </Typography>
                                </Box>
                            )}
                        </s.FieldsWrapper>

                        <div>
                            <s.ButtonWrapper>
                                {canManageProjects && (
                                    <div className="action-buttons">
                                        {edit ? (
                                            <>
                                                <SecondaryButton
                                                    variation="small"
                                                    onClick={() =>
                                                        setEdit(false)
                                                    }
                                                >
                                                    Cancelar
                                                </SecondaryButton>
                                                <PrimaryButton
                                                    variation="small"
                                                    type="submit"
                                                >
                                                    Salvar
                                                </PrimaryButton>
                                            </>
                                        ) : (
                                            <PrimaryButton
                                                variation="small"
                                                onClick={() => setEdit(!edit)}
                                            >
                                                Editar
                                            </PrimaryButton>
                                        )}
                                    </div>
                                )}
                            </s.ButtonWrapper>
                            <s.TextAreaWrapper isEditing={edit}>
                                <div className="description-text">
                                    {edit ? (
                                        <s.FieldText>
                                            <CustomTextField
                                                control={control}
                                                name="description"
                                                label="Descrição do projeto"
                                                fullWidth
                                                placeholder="Digite aqui uma pequena descrição sobre este projeto"
                                                multiline
                                                maxCharacters={300}
                                            />
                                        </s.FieldText>
                                    ) : (
                                        <>
                                            <div className="description-header">
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={s4Style}
                                                >
                                                    Descrição do projeto:
                                                </Typography>
                                            </div>
                                            <Typography
                                                variant="subtitle2"
                                                sx={s4StyleValues}
                                            >
                                                {project?.description || "-"}
                                            </Typography>
                                        </>
                                    )}
                                </div>
                            </s.TextAreaWrapper>
                            <s.TextAreaWrapper isEditing={edit}>
                                <div className="description-text">
                                    {edit ? (
                                        <SelectTechnologies
                                            control={control}
                                            name="technologies"
                                            label="Tecnologias do projeto (se houver)"
                                            externalState={selectedTechnologies}
                                            setExternalState={
                                                setSelectedTechnologies
                                            }
                                        />
                                    ) : (
                                        <>
                                            <div className="description-header">
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={s4Style}
                                                >
                                                    Tecnologias do projeto (se
                                                    houver):
                                                </Typography>
                                            </div>
                                            <Typography
                                                variant="subtitle2"
                                                sx={s4StyleValues}
                                            >
                                                {project?.technologies || "-"}
                                            </Typography>
                                        </>
                                    )}
                                </div>
                                <div className="description-text">
                                    {edit && (
                                        <Typography
                                            variant="subtitle2"
                                            sx={s4Style}
                                        >
                                            Dica: Digite a tecnologia desejada e
                                            pressione [Enter], [Espaço] ou [Tab]
                                            para adicionar.
                                            <br />
                                            As opções disponíveis são baseadas
                                            nas habilidades dos usuários
                                            vinculados ao projeto.
                                        </Typography>
                                    )}
                                </div>
                            </s.TextAreaWrapper>
                        </div>
                    </div>
                )}
            </s.Content>
        </>
    );
};
