import { yupResolver } from "@hookform/resolvers/yup";
import { CloseOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { theme } from "../../../../../../assets";
import { useUser } from "../../../../../../context";
import { updateProfile } from "../../../../../../services/budget";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import { TReadBudgetsLevelsDetailsByIdResponse } from "../../../../../../services/budget/read-budgets-levels-detailed-by-id";
import { EditTimeTable } from "./edit-time-table";

const validationSchema = yup.object().shape({
    profileEditTime: yup.array().of(
        yup.object().shape({
            worstPrediction: yup
                .number()
                .transform((value, originalValue) =>
                    String(originalValue).trim() === "" ? 0 : value
                )
                .typeError("O valor deve ser um número")
                .required("Campo obrigatório"),
            realPrediction: yup
                .number()
                .transform((value, originalValue) =>
                    String(originalValue).trim() === "" ? 0 : value
                )
                .typeError("O valor deve ser um número")
                .required("Campo obrigatório"),
            bestPrediction: yup
                .number()
                .transform((value, originalValue) =>
                    String(originalValue).trim() === "" ? 0 : value
                )
                .typeError("O valor deve ser um número")
                .required("Campo obrigatório"),
        })
    ),
});

const tabDecoration = {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "1.28px",
    textDecorationStyle: "solid",
    textDecorationThickness: "auto",
    textUnderlineOffset: "auto",
    textUnderlinePosition: "from-font",
    textTransform: "uppercase",
};

export const ScopeEditTimeDialog = ({
    budgetId,
    dataBudgetById,
    dataBudgetLevelDetails,
    handleClose,
    handleFeatureDrawer,
    open,
    refetch,
    refetchLevels,
}: {
    budgetId: string;
    dataBudgetById?: TReadBudgetsResponse;
    dataBudgetLevelDetails?: TReadBudgetsLevelsDetailsByIdResponse;
    handleClose: () => void;
    handleFeatureDrawer: (data: boolean, levelId: string) => void;
    open: boolean;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadBudgetsResponse, unknown>>;
    refetchLevels: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<
        QueryObserverResult<
            {
                data: TReadBudgetsLevelsDetailsByIdResponse;
            },
            unknown
        >
    >;
}) => {
    const { user } = useUser();
    const { verifyPermission } = useUser();
    const organizationPermission = verifyPermission({
        id: "manage_teams_projects",
        rule: "enabled",
    });
    const profiles = dataBudgetById?.BudgetProfiles.filter(
        (profileData) => profileData?.dependencyMode === null
    );
    const myProfile = profiles?.find(
        (profile) => profile?.responsible?.id_e === user.id_e
    );
    const [selectedProfile, setSelectedProfile] = useState(
        !organizationPermission
            ? myProfile?.Profile.id_e
            : profiles?.[0]?.Profile.id_e || ""
    );
    const [isLoading, setIsLoading] = useState(false);

    const findProfile = profiles?.find(
        (profile) => profile.Profile.id_e === selectedProfile
    );

    const tableDetailedLevelData = useMemo(() => {
        return dataBudgetLevelDetails
            ? dataBudgetLevelDetails.map((data) => {
                  const { BudgetProfileLevelDedicatedHours } = data;
                  const haveData = BudgetProfileLevelDedicatedHours.find(
                      (budgetProfile) =>
                          budgetProfile.BudgetProfile.id_e === findProfile?.id_e
                  );

                  const worstPrediction = haveData?.worstPrediction || 0;
                  const realPrediction = haveData?.realPrediction || 0;
                  const bestPrediction = haveData?.bestPrediction || 0;

                  const result = {
                      worstPrediction,
                      realPrediction,
                      bestPrediction,
                  };

                  return result;
              })
            : [];
    }, [
        dataBudgetLevelDetails,
        dataBudgetById,
        selectedProfile,
        setSelectedProfile,
    ]);

    const { control, handleSubmit, setValue, watch, getValues } = useForm({
        defaultValues: {
            profileEditTime: tableDetailedLevelData,
        },
        resolver: yupResolver(validationSchema),
    });

    const profileEditTimeFromWatch = watch("profileEditTime");
    const averageHours = profileEditTimeFromWatch.map((dataLevel) => {
        const avarage =
            (Number(dataLevel.worstPrediction) +
                Number(dataLevel.realPrediction) +
                Number(dataLevel.bestPrediction)) /
            3;
        return avarage;
    });

    const submit = async (data: FieldValues) => {
        if (!dataBudgetLevelDetails) return;

        const updatedItems = dataBudgetLevelDetails
            .map((level, index) => ({
                ...data.profileEditTime[index],
                level: level.Level.id_e,
            }))
            .filter((item, index) => {
                const original = tableDetailedLevelData[index];
                return (
                    item.worstPrediction !== original.worstPrediction ||
                    item.realPrediction !== original.realPrediction ||
                    item.bestPrediction !== original.bestPrediction
                );
            });

        if (updatedItems.length === 0) {
            return;
        }

        // try {
        //     // Executa todas as atualizações em paralelo
        //     await Promise.all(
        //         updatedItems.map((item) =>
        setIsLoading(true);
        await updateProfile({
            budgetId,
            profileId: findProfile?.id_e ?? "",
            params: {
                levelTimes: updatedItems,
            },
        })
            .then(async () => {
                await refetch();
                await refetchLevels();
                setIsLoading(false);
                toast.success("O perfil foi atualizado com sucesso!");
            })
            .catch((error) => {
                toast.error(`Erro ao atualizar perfis: ${error}`);
                setIsLoading(true);
            });
        //     )
        // );

        // } catch (error) {
        //     toast.error(`Erro ao atualizar perfis: ${error}`);
        // }
    };

    const handleSaveResponsibleEstimative = async (isDraft?: boolean) => {
        const data = getValues("profileEditTime");
        if (!dataBudgetLevelDetails) return;

        const updatedItems = dataBudgetLevelDetails
            .map((level, index) => ({
                bestPrediction: Number(data[index].bestPrediction),
                worstPrediction: Number(data[index].worstPrediction),
                realPrediction: Number(data[index].realPrediction),
                level: level.Level.id_e,
            }))
            .filter((item, index) => {
                const original = tableDetailedLevelData[index];
                return (
                    item.worstPrediction !== original.worstPrediction ||
                    item.realPrediction !== original.realPrediction ||
                    item.bestPrediction !== original.bestPrediction
                );
            });

        const params = {
            levelTimes: updatedItems.length === 0 ? undefined : updatedItems,
            hoursEstimationStatus: isDraft ? "draft" : "sent",
        };
        setIsLoading(true);
        await updateProfile({
            budgetId,
            profileId: findProfile?.id_e ?? "",
            params,
        })
            .then(async () => {
                await refetch();
                await refetchLevels();
                setIsLoading(false);
                toast.success(
                    isDraft
                        ? "O rascunho foi salvo com sucesso!"
                        : "Estimativa enviada com sucesso!"
                );
            })
            .catch(() => {
                toast.error(
                    isDraft
                        ? `Erro ao salvar o rascunho`
                        : "Erro ao enviar estimativa!"
                );
                setIsLoading(true);
            })
            .finally(() => handleClose());
    };

    const handleSelectTab = (profileId: string) => {
        setSelectedProfile(profileId);
    };

    const isSent = findProfile?.hoursEstimationStatus === "sent";
    const lockForm =
        !organizationPermission && findProfile?.responsible?.id_e !== user.id_e;

    const generateButtons = () => {
        if (organizationPermission)
            return (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <Button variant="outlined" onClick={handleClose}>
                        CANCELAR
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                    >
                        SALVAR
                    </Button>
                </Box>
            );

        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <Button
                    variant="outlined"
                    onClick={() => handleSaveResponsibleEstimative(true)}
                    disabled={!!isSent || lockForm}
                >
                    Salvar Rascunho
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={isLoading || !!isSent || lockForm}
                    onClick={() => handleSaveResponsibleEstimative(false)}
                >
                    Salvar Estimativa
                </Button>
            </Box>
        );
    };

    const profileCostPerHour = findProfile?.costPerHour || "0";

    const HeaderStackStyle = organizationPermission
        ? {
              gap: "1.5rem",
              p: "1.5rem",
              boxShadow: "0px 4px 4px 0px #E0E0E0",
              border: "1px solid #E0E0E0",
              borderRadius: "0.25rem",
          }
        : {
              gap: "1.5rem",
              p: "1.5rem",
              borderRadius: "0.25rem",
          };

    useEffect(() => {
        setValue("profileEditTime", tableDetailedLevelData);
    }, [selectedProfile]);

    return (
        <Dialog
            open={open}
            sx={{
                zIndex: 9995,
                ".MuiDialog-paper": {
                    width: "100%",
                    maxWidth: "102rem",
                    overflowY: "unset",
                    zIndex: 9,
                },
            }}
            PaperComponent={Paper}
        >
            <Box
                sx={{
                    p: "1rem",
                    width: "100%",
                }}
            >
                <form onSubmit={handleSubmit(submit)}>
                    <Stack sx={{ ...HeaderStackStyle, position: "relative" }}>
                        <Typography variant="h6">
                            Perfis de Atividade
                        </Typography>
                        {!organizationPermission && (
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    top: "0rem",
                                    right: "0rem",
                                }}
                            >
                                <CloseOutlined />
                            </IconButton>
                        )}
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: theme.gray500,
                            }}
                        >
                            Selecione um perfil e defina as horas cliclando nos
                            campos abaixo
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1.5rem",
                                }}
                            >
                                {profiles &&
                                    profiles.map((profile) => {
                                        const profileSelected =
                                            profile.Profile.id_e ===
                                            selectedProfile;
                                        return (
                                            <IconButton
                                                key={profile.id_e}
                                                onClick={() =>
                                                    handleSelectTab(
                                                        profile.Profile.id_e
                                                    )
                                                }
                                                disabled={isLoading}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: profileSelected
                                                            ? theme.primary.main
                                                            : theme.textPrimary,
                                                        textDecoration:
                                                            profileSelected
                                                                ? "underline"
                                                                : "unset",
                                                        ...tabDecoration,
                                                    }}
                                                >
                                                    {
                                                        profile.Profile
                                                            .abbreviation
                                                    }
                                                </Typography>
                                            </IconButton>
                                        );
                                    })}
                            </Box>
                            {generateButtons()}
                        </Box>
                    </Stack>

                    <DialogContent>
                        <Stack
                            sx={{
                                gap: "2rem",
                                width: "100%",
                            }}
                        >
                            <EditTimeTable
                                averageHours={averageHours}
                                control={control}
                                dataBudgetById={dataBudgetById}
                                dataBudgetLevelDetails={dataBudgetLevelDetails}
                                handleFeatureDrawer={handleFeatureDrawer}
                                profileCostPerHour={profileCostPerHour}
                                profileTitle={findProfile?.Profile.name ?? ""}
                                lockForm={lockForm}
                                isSent={!!isSent}
                            />
                        </Stack>
                    </DialogContent>
                </form>
            </Box>
        </Dialog>
    );
};
