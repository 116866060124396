import { Autocomplete, Chip, TextField, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

import { TOption } from "../../../../../../models";

interface IProps<T extends FieldValues> {
    control: Control<T>;
    label?: string;
    name: Path<T>;
    externalState: TOption[];
    setExternalState: React.Dispatch<React.SetStateAction<TOption[]>>;
}

export function SelectTechnologies<T extends FieldValues>({
    control,
    label,
    name,
    externalState,
    setExternalState,
}: IProps<T>) {
    const [technologiesInput, setTechnologiesInput] = useState("");
    const [options, setOptions] = useState<TOption[]>(externalState);

    function handleAddTechnology(newValue: string[]) {
        const newOptions = newValue
            .filter((tech) => !options.some((option) => option.value === tech))
            .map((tech) => ({ label: tech, value: tech }));

        if (newOptions.length > 0) {
            setOptions((prev) => [...prev, ...newOptions]); // Adiciona ao select
            setExternalState((prev) => [...prev, ...newOptions]); // Atualiza o state externo
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Box>
                    {label && (
                        <Typography variant="body1" gutterBottom>
                            {label}
                        </Typography>
                    )}
                    <Autocomplete
                        multiple
                        freeSolo
                        options={options.map((option) => option.value)}
                        value={field.value ? field.value.split(", ") : []}
                        onChange={(_, newValue) => {
                            field.onChange(newValue.join(", "));
                            handleAddTechnology(newValue);
                        }}
                        inputValue={technologiesInput}
                        onInputChange={(_, newInput) =>
                            setTechnologiesInput(newInput)
                        }
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Digite e pressione Enter"
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />
                </Box>
            )}
        />
    );
}
