import { useQuery } from "@tanstack/react-query";

import { readBudgetById } from "../../services/budget";
import { TReadBudgetsResponse } from "../../services/budget/read-budgets";

type ReadBudgetByIdParams = {
    budgetId: string;
    start?: boolean;
};

export function useReadBudgetById({
    budgetId,
    start = true,
}: ReadBudgetByIdParams) {
    return useQuery<TReadBudgetsResponse, unknown, TReadBudgetsResponse>({
        enabled: start,
        queryKey: [`readBudgetById/${budgetId}`],
        queryFn: () => readBudgetById({ budgetId }),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
