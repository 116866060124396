import { yupResolver } from "@hookform/resolvers/yup";
import {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Drawer,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { useReadLevelById } from "../../../../../../hooks/react-query/use-read-level-annotation";
import {
    createLevelAnnotation,
    updateBudgetLevel,
} from "../../../../../../services/budget";
import { TReadBudgetsLevelsDetailsByIdResponse } from "../../../../../../services/budget/read-budgets-levels-detailed-by-id";
import { TReadLevelByIdResponse } from "../../../../../../services/budget/read-level-by-id";
import { formatISOToDate } from "../../../../../../utils";

const titleStyle = {
    fontFamily: "Noto Sans",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.15px",
};

const subtittleStyle = {
    fontFamily: "Noto Sans",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0.0075rem",
};

type FeatureDrawerProps = {
    budgetId: string;
    levelId: string;
    open: boolean;
    dataBudgetLevelDetails?: TReadBudgetsLevelsDetailsByIdResponse;
    refetchLevelDetail: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<
        QueryObserverResult<
            {
                data: TReadBudgetsLevelsDetailsByIdResponse;
            },
            unknown
        >
    >;
    toggleDrawer: (data: boolean) => void;
    refetchLevels: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<
        QueryObserverResult<
            {
                data: TReadBudgetsLevelsDetailsByIdResponse;
            },
            unknown
        >
    >;
};

type TAnnotation = {
    budgetId: string;
    levelId: string;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<TReadLevelByIdResponse, unknown>>;
    refetchLevels: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) =>
        | Promise<
              QueryObserverResult<
                  {
                      data: TReadBudgetsLevelsDetailsByIdResponse;
                  },
                  unknown
              >
          >
        | undefined;
};

type TComment = {
    avatar?: string;
    created_at: string;
    name: string;
    text: string;
    role: string;
};

const AnnotationSchema = yup.object().shape({
    annotation: yup
        .string()
        .required("Você deve enviar algo no seu comentário"),
});

const FeatureNameSchema = yup.object().shape({
    name: yup.string().required("Você deve enviar algo no seu comentário"),
});

const Annotation = ({
    budgetId,
    levelId,
    refetch,
    refetchLevels,
}: TAnnotation) => {
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            annotation: "",
        },
        resolver: yupResolver(AnnotationSchema),
        mode: "onSubmit",
    });

    const submit = (data: FieldValues) => {
        createLevelAnnotation({
            budgetId,
            levelId,
            params: { comment: data.annotation },
        }).then(() => {
            toast.success("Seu comentário foi enviado com sucesso!");
            refetch();
            refetchLevels();
            reset();
        });
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack
                sx={{
                    p: "1.5rem",
                    gap: "1.5rem",
                    borderTop: "1px solid  #E0E0E0",
                    borderBottom: "1px solid  #E0E0E0",
                }}
            >
                <Typography variant="h5">Anotações</Typography>
                <CustomTextField
                    control={control}
                    name="annotation"
                    label="Comentário"
                    placeholder="Novo comentário"
                    fullWidth
                />
                <Button
                    variant="contained"
                    sx={{
                        width: "4.8125rem",
                    }}
                    type="submit"
                >
                    SALVAR
                </Button>
            </Stack>
        </form>
    );
};

const CommentCard = ({ avatar, created_at, name, role, text }: TComment) => {
    return (
        <Stack
            sx={{
                gap: "1.5rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    py: "0.5rem",
                    px: "1.5rem",
                    gap: "0.9375rem",
                }}
            >
                {avatar ? (
                    <img
                        src={avatar}
                        alt={name}
                        style={{
                            width: "2.5rem",
                            height: "2.5rem",
                            borderRadius: "50%",
                        }}
                    />
                ) : (
                    <Avatar
                        sx={{
                            width: "2.5rem",
                            height: "2.5rem",
                            borderRadius: "50%",
                        }}
                    />
                )}
                <Stack>
                    <Typography variant="body2">{name}</Typography>
                    <Typography
                        sx={{
                            color: "var(--text-secondary, rgba(62, 55, 78, 0.60))",
                            fontFamily: "Noto Sans",
                            fontSize: "0.75rem",
                            fontStyle: " normal",
                            fontWeight: 600,
                            lineHeight: "1.25rem",
                        }}
                    >
                        {role}
                    </Typography>
                </Stack>
            </Box>
            <Typography variant="body1">{text}</Typography>
            <Typography
                variant="body1"
                sx={{
                    color: "rgba(62, 55, 78, 0.60)",
                }}
            >
                Enviado em {formatISOToDate(created_at)}
            </Typography>
        </Stack>
    );
};

const FeatureDrawer = ({
    budgetId,
    dataBudgetLevelDetails,
    levelId,
    open,
    toggleDrawer,
    refetchLevels,
    refetchLevelDetail,
}: FeatureDrawerProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const levelData = dataBudgetLevelDetails?.find(
        (levelDetail) => levelDetail.Level.id_e === levelId
    );
    const grandFatherLevel = levelData?.Parent?.Parent;
    const fatherLevel = levelData?.Parent;
    const level = levelData?.Level;
    const parentColor =
        grandFatherLevel?.color ?? fatherLevel?.color ?? "inherit";
    const [editFeatureMode, setEditFeatureMode] = useState(false);

    const { control, handleSubmit, setValue } = useForm({
        defaultValues: {
            name: level?.name || "",
        },
        resolver: yupResolver(FeatureNameSchema),
    });

    const { data, refetch } = useReadLevelById({ budgetId, levelId });

    const levelDetailData = data?.data;
    const historicComments = levelDetailData?.LevelComment;
    const haveComments =
        historicComments?.length && historicComments?.length > 0;

    const submit = (data: FieldValues) => {
        setIsLoading(true);
        updateBudgetLevel(budgetId, levelId, {
            name: data.name,
        })
            .then(async () => {
                await refetchLevelDetail();
                setIsLoading(false);
                setEditFeatureMode(false);
            })
            .catch(() => toast.error("Erro ao editar level"));
    };
    const handleEditFeatureMode = () => setEditFeatureMode((prev) => !prev);
    const handleClose = () => toggleDrawer(false);

    // eslint-disable-next-line consistent-return
    const handleRefetchLevels = () => {
        if (!haveComments) return refetchLevels();
    };

    useEffect(() => {
        if (level?.name) {
            setValue("name", level?.name);
        }
    }, [level]);
    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor="right"
            container={document.body}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                zIndex: 99999,
                "& .MuiDrawer-paper": {
                    width: "40%",
                    boxSizing: "border-box",
                },
            }}
        >
            <Stack
                sx={{
                    p: "2.75rem",
                    gap: "3.25rem",
                }}
            >
                {/* HEADER */}
                <form onSubmit={handleSubmit(submit)}>
                    <Stack
                        sx={{
                            gap: "0.75rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Stack>
                                {grandFatherLevel && (
                                    <Typography
                                        sx={{
                                            ...titleStyle,
                                            color: parentColor,
                                        }}
                                    >
                                        {grandFatherLevel.name}
                                    </Typography>
                                )}
                                {fatherLevel && (
                                    <Typography
                                        sx={{
                                            ...subtittleStyle,
                                            color: parentColor,
                                        }}
                                    >
                                        {fatherLevel.name}
                                    </Typography>
                                )}
                            </Stack>
                            <IconButton
                                sx={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                }}
                                onClick={handleClose}
                            >
                                <CloseOutlined />
                            </IconButton>
                        </Box>
                        {editFeatureMode ? (
                            <Stack
                                sx={{
                                    alignItems: "flex-end",
                                    width: "100%",
                                    gap: "0.75rem",
                                }}
                            >
                                <CustomTextField
                                    control={control}
                                    name="name"
                                    fullWidth
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "0.75rem",
                                    }}
                                >
                                    <IconButton
                                        sx={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                        }}
                                        onClick={handleEditFeatureMode}
                                        disabled={isLoading}
                                    >
                                        <CloseOutlined color="error" />
                                    </IconButton>
                                    <IconButton
                                        sx={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                        }}
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        <CheckOutlined color="success" />
                                    </IconButton>
                                </Box>
                            </Stack>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1.25rem",
                                }}
                            >
                                <IconButton
                                    sx={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        color: parentColor,
                                    }}
                                    onClick={handleEditFeatureMode}
                                >
                                    <EditOutlined />
                                </IconButton>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: "rgba(62, 55, 78, 0.87)",
                                    }}
                                >
                                    {level?.name}
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                </form>
                {/* FORM */}
                <Annotation
                    budgetId={budgetId}
                    levelId={levelId}
                    refetch={refetch}
                    refetchLevels={handleRefetchLevels}
                />
                {/* Historic */}
                {!!haveComments && (
                    <Stack
                        sx={{
                            maxHeight: "21rem",
                            overflowY: "auto",
                            gap: "0.75rem",
                        }}
                    >
                        {historicComments
                            .map((comment) => (
                                <CommentCard
                                    created_at={comment.created_at}
                                    name={comment.Author.name}
                                    text={comment.text}
                                    avatar={comment.Author.avatar}
                                    role={comment.Author.Role.name}
                                />
                            ))
                            .reverse()}
                    </Stack>
                )}
                <Stack
                    sx={{
                        gap: "1.5rem",
                    }}
                >
                    <Typography
                        sx={{
                            color: "rgba(62, 55, 78, 0.87)",
                            fontFamily: "Noto Sans",
                            fontSize: "0.875rem",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "1.125rem",
                            letterSpacing: "0.0044rem",
                        }}
                    >
                        Histórico
                    </Typography>
                    <Typography
                        sx={{
                            color: "rgba(62, 55, 78, 0.87)",
                            fontFamily: "Noto Sans",
                            fontSize: "0.75rem",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px",
                        }}
                    >
                        Ainda não existem horas realizadas vinculadas a esta
                        atividade.
                    </Typography>
                </Stack>
            </Stack>
        </Drawer>
    );
};

export default FeatureDrawer;
