import { useMutation } from "@tanstack/react-query";

import { readBudgetLevel } from "../../services/budget";

type Params = {
    enabled?: boolean;
    cacheTime?: number;
    onSuccess?: (data: any) => void;
};

type ReadBudgetLevelParams = {
    budgetId: string;
    name?: string;
    levelOrder?: number;
};

export function useReadBudgetLevel({
    budgetId,
    levelOrder,
    name,
    onSuccess,
}: ReadBudgetLevelParams & Params) {
    const filters = {
        levelOrder,
        name,
    };

    return useMutation({
        mutationKey: ["readLevels"],
        mutationFn: () => readBudgetLevel(budgetId, filters),
        onSuccess: onSuccess ? (data) => onSuccess(data) : undefined,
    });
}
