import {
    AccessTimeOutlined,
    AttachMoneyTwoTone,
    InsertDriveFileOutlined,
} from "@mui/icons-material";
import {
    Box,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Control } from "react-hook-form";

import { theme } from "../../../../../../assets";
import CustomTextField from "../../../../../../components/molecules/custom-textfield/custom-textfield";
import { TReadBudgetsResponse } from "../../../../../../services/budget/read-budget-by-id";
import {
    TBudgetLevelDetailed,
    TReadBudgetsLevelsDetailsByIdResponse,
} from "../../../../../../services/budget/read-budgets-levels-detailed-by-id";
import { onlyNumbers } from "../../../../../../utils";
import { formatCurrency } from "../../../../../organization/project/tab-costs/utils";

const CollapsibleRow = ({
    averageHours,
    control,
    handleFeatureDrawer,
    isSent,
    lockForm,
    row,
    index,
}: {
    averageHours: number[];
    control: Control<
        {
            profileEditTime: {
                worstPrediction: number;
                realPrediction: number;
                bestPrediction: number;
            }[];
        },
        any
    >;
    handleFeatureDrawer: (data: boolean, levelId: string) => void;
    isSent: boolean;
    lockForm: boolean;
    row: TBudgetLevelDetailed;
    index: number;
}) => {
    const { Level, Parent, hasAnnotation } = row;

    const grandpaFeature = Parent?.Parent ?? null;
    const fatherFeature = Parent;

    return (
        <>
            <TableRow
                sx={{
                    boxShadow: "unset",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    ":hover": {
                        backgroundColor: theme.gray100,
                        cursor: "pointer",
                    },
                }}
                onClick={() => handleFeatureDrawer(true, Level.id_e)}
            >
                {grandpaFeature && (
                    <TableCell align="center">
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: "Noto Sans",
                                color: grandpaFeature.color ?? "inherit",
                            }}
                        >
                            {grandpaFeature.name}
                        </Typography>
                    </TableCell>
                )}
                {fatherFeature && (
                    <TableCell align="center">
                        <Typography
                            variant="body2"
                            sx={{
                                fontFamily: "Noto Sans",
                                color: fatherFeature.color ?? "inherit",
                            }}
                        >
                            {fatherFeature.name}
                        </Typography>
                    </TableCell>
                )}
                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {Level.name}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    {hasAnnotation && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <IconButton
                                onClick={() =>
                                    handleFeatureDrawer(true, Level.id_e)
                                }
                                sx={{
                                    position: "relative",
                                    width: "2.5rem",
                                }}
                            >
                                <InsertDriveFileOutlined
                                    sx={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        color: "rgba(0, 0, 0, 0.56)",
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "-0.625rem",
                                        right: "0rem",
                                        borderRadius: "50%",
                                        backgroundColor: "#B93BDC",
                                        width: "0.5rem",
                                        height: "0.5rem",
                                    }}
                                />
                            </IconButton>
                        </Box>
                    )}
                </TableCell>

                <TableCell align="center">
                    <CustomTextField
                        control={control}
                        name={`profileEditTime.${index}.worstPrediction`}
                        label=""
                        maxCharacters={4}
                        sx={{
                            width: "3.875rem",
                            backgroundColor: theme.white,
                        }}
                        formatValue={onlyNumbers}
                        onClick={(e) => e.stopPropagation()}
                        disabled={lockForm || isSent}
                    />
                </TableCell>
                <TableCell align="center">
                    <CustomTextField
                        control={control}
                        name={`profileEditTime.${index}.realPrediction`}
                        label=""
                        maxCharacters={4}
                        sx={{
                            width: "3.875rem",
                            backgroundColor: theme.white,
                        }}
                        formatValue={onlyNumbers}
                        onClick={(e) => e.stopPropagation()}
                        disabled={lockForm || isSent}
                    />
                </TableCell>
                <TableCell align="center">
                    <CustomTextField
                        control={control}
                        name={`profileEditTime.${index}.bestPrediction`}
                        label=""
                        maxCharacters={4}
                        sx={{
                            width: "3.875rem",
                            backgroundColor: theme.white,
                        }}
                        formatValue={onlyNumbers}
                        onClick={(e) => e.stopPropagation()}
                        disabled={lockForm || isSent}
                    />
                </TableCell>

                <TableCell align="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: "Noto Sans",
                        }}
                    >
                        {averageHours[index]?.toFixed(0) ?? 0}
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    );
};

export const EditTimeTable = ({
    averageHours,
    control,
    dataBudgetById,
    dataBudgetLevelDetails,
    handleFeatureDrawer,
    isSent,
    lockForm,
    profileCostPerHour,
    profileTitle,
}: {
    averageHours: number[];
    control: Control<
        {
            profileEditTime: {
                worstPrediction: number;
                realPrediction: number;
                bestPrediction: number;
            }[];
        },
        any
    >;
    dataBudgetById?: TReadBudgetsResponse;
    dataBudgetLevelDetails?: TReadBudgetsLevelsDetailsByIdResponse;
    handleFeatureDrawer: (data: boolean, levelId: string) => void;
    isSent: boolean;
    lockForm: boolean;
    profileCostPerHour?: string;
    profileTitle: string;
}) => {
    const levelConfig = dataBudgetById?.LevelConfig;
    let totalAvarage = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < averageHours.length; i++) {
        totalAvarage += averageHours[i];
    }

    const total = Number(profileCostPerHour) * totalAvarage;

    return (
        <>
            <Stack
                sx={{
                    gap: "1.5rem",
                    px: "1.5rem",
                    py: "1rem",
                }}
            >
                <Typography variant="h5">{profileTitle}</Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1.5rem",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                        }}
                    >
                        <AttachMoneyTwoTone
                            sx={{
                                color: theme.primary.main,
                                width: "1.5rem",
                                height: "1.5rem",
                            }}
                        />
                        {formatCurrency(String(total.toFixed(2)))}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                        }}
                    >
                        <AccessTimeOutlined
                            sx={{
                                color: theme.primary.main,
                                width: "1.5rem",
                                height: "1.5rem",
                            }}
                        />
                        {totalAvarage?.toFixed(0) ?? 0} Horas médias
                    </Typography>
                </Box>
            </Stack>
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: "unset !important",
                    backgroundColor: "transparent",

                    maxHeight: "32rem !important",
                    overflowY: "auto",
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {levelConfig?.map((level) => (
                                <TableCell align="center" key={level.id_e}>
                                    {level.name}
                                </TableCell>
                            ))}
                            <TableCell align="center">Detalhes</TableCell>
                            <TableCell align="center">Pior</TableCell>
                            <TableCell align="center">Real</TableCell>
                            <TableCell align="center">Melhor</TableCell>
                            <TableCell align="center">Média</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        {dataBudgetLevelDetails?.map((row, index) => (
                            <CollapsibleRow
                                key={row.Level.id_e}
                                averageHours={averageHours}
                                control={control}
                                index={index}
                                row={row}
                                handleFeatureDrawer={handleFeatureDrawer}
                                lockForm={lockForm}
                                isSent={isSent}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
