import toast from "react-hot-toast";

import { axiosHttpClient } from "../axios";
import { handleErrors } from "../errors";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TDeleteProfileBudget = {
    budgetId: string;
    budgetProfileId: string;
};

export default async ({ budgetId, budgetProfileId }: TDeleteProfileBudget) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/budget/${budgetId}/profile/${budgetProfileId}`,
        method: "delete",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        500: "Erro! Antes de excluir, remova o Perfil Calculado.",
    };

    const isProfileCalculateConflict =
        body?.message ===
        "Internal Server Error - \nInvalid `prisma.budgetProfile.delete()` invocation:\n\n\nForeign key constraint failed on the field: `BudgetProfileTimeDependency_dependsOn_id_e_fkey (index)`";

    if (isProfileCalculateConflict) {
        toast.error("Erro! Antes de excluir, remova o Perfil Calculado.");
        return handleErrors({
            body,
            message: "Erro! Antes de excluir, remova o Perfil Calculado.",
        });
    }
    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
